import React from 'react';
import { Outlet } from 'react-router-dom';
import ClassRoomSidebar from '../classrooms/ClassRoomSidebar';
import ClassRoomTopbar from '../classrooms/ClassRoomTopbar';
import { Navigate } from 'react-router-dom';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import Axios from 'axios';
import getCookies from '../utils/getCookies';

const ClassRoomLayout = () => {
    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }
    
    const courseUUID = window.location.pathname.split('/')[2];
    const currentUser = getCookies("_auth_state");

    const id = currentUser ? currentUser.split(',')[0]?.split(':')[1]?.split('"')[1] : null;
    const who = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;

    const userRole = who;
    const userId = id;
    const [courseData, setCourseData] = React.useState(null);
    const [lectureModules, setLectureModules] = React.useState(null);

    async function getCourseInfo() {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${courseUUID}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setCourseData(response.data);
        }).catch((error) => {
            if (error.response.status === 401)
                handleLogout();
            
            console.log(error.response.data.message);
        });
    }

    // for teachers and admins
    async function getLectureModules() {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${courseUUID}/events`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setLectureModules(response.data);
            // console.log(response.data);
        }).catch((error) => {
            if (error.response.status === 401)
                handleLogout();
            
            console.log(error.response.data.message);
            setLectureModules([]);
        });
    }

    // for students
    async function getLectureModulesStudents() {
        // courses/:cid/events/students/:sid
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${courseUUID}/events/students/${userId}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setLectureModules(response.data);
        }).catch((error) => {
            if (error.response.status === 401)
                handleLogout();
                
            console.log(error.response.data.message);
            setLectureModules([]);
        });
    };


    async function checkifStudentIsEnrolled() {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students/${userId}/courses/${courseUUID}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            // do nothing and allow student to access the page
        }).catch((error) => {
            if (error.response.status === 401)
                handleLogout();
            
            // student is not enrolled in this course, redirect to classes page
            console.log(error.response.data.message);
            window.location.href = '/classrooms/classes';
        });
    }

    async function checkifTeacherIsAssigned() {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/teachers/${userId}/courses/${courseUUID}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            // do nothing and allow teacher to access the page
        }).catch((error) => {
            if (error.response.status === 401)
                handleLogout();

            // teacher is not assigned to this course, redirect to classes page
            console.log(error.response.data.message);
            window.location.href = '/classrooms/classes';
        });
    }

    React.useEffect(() => {
        if ((userRole === null || userRole === undefined) || (userId === null || userId === undefined)) {
            // currentUser = null;
            // const cookies = document.cookie?.split(";");
            // for (let i = 0; i < cookies.length; i++) {
            //     const cookie = cookies[i];
            //     const eqPos = cookie.indexOf("=");
            //     const name = eqPos > -1 ? cookie.substring(0, eqPos).trim() : cookie;
            //     if (name !== "_auth" && name !== "_auth_storage" && name !== "_auth_type" && name !== "_auth_state")
            //         document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            // }
            // navigate("/signin");
            handleLogout();
        }

        getCourseInfo();

        if (userRole !== "student") {
            getLectureModules();
        } else {
            getLectureModulesStudents();
        }

        // check if user is in this course
        if (userRole === "student")
            checkifStudentIsEnrolled();
        else if (userRole === "teacher")
            checkifTeacherIsAssigned();
        
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    return currentUser ? (
        <>
            <ClassRoomSidebar courseData={courseData} />
            <main className="content">
                <ClassRoomTopbar userRole={userRole} userId={userId} />
                <Outlet context={{
                    courseData: courseData,
                    lectureModules: lectureModules,
                }} />
            </main>
        </>
    ) : <Navigate to="/signin"/>;
};

export default ClassRoomLayout;