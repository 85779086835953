import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useOutletContext } from "react-router-dom";
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { tokens } from "../../theme";
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const ForgotPassword = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigateTo = useNavigate();

    const setOptForForgotPassword = useOutletContext().setOptForForgotPassword;
    const setEmailForForgotPassword = useOutletContext().setEmailForForgotPassword;

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const email = data.get('email');

        if (email === null || email === undefined || email === "") {
            alert("Email is required");
            return;
        }

        if (email.length > 50) {
            alert("Email must be less than 50 characters");
            return;
        }

        const opt = Math.floor(100000 + Math.random() * 900000);
        setOptForForgotPassword(opt.toString());
        setEmailForForgotPassword(email);

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/forgot-password`, {
            email: email,
            opt: opt,
        }).then((response) => {
            navigateTo('/reset-password');
        }).catch((error) => {
            alert(error.response.data.message);
        });
    };

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div></div>
            <div>
                <Typography variant="h2" style={{ textAlign: 'center' }}>
                    Forgot Password
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '24vw', padding: '1rem' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1, mb: 2 }}
                        color='secondary'
                    >
                        <Typography color={colors.primary[400]}>Send OTP</Typography>
                    </Button>
                    <Grid container>
                        <Grid item>
                            <Link href="/signin" variant="body2">
                                {"Go Back to Sign In"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div style={{display: "flex", padding: "2rem", gap: "1rem"}}>
                <img
                    alt="ETT logo"
                    width="160px"
                    height="60px"
                    src={`../../imgs/ETT_logo_tran.png`}
                    style={{margin: "auto"}}
                />
                <CloseIcon fontSize='large' sx={{ m: "auto" }} />
                <img
                    alt="solple logo"
                    width="200px"
                    height="64px"
                    src={`../../imgs/aone-logo.png`}
                    style={{margin: "auto"}}
                />
            </div>
        </Box>
    )
}

export default ForgotPassword