import * as React from 'react';
import { tokens } from "../../theme";
import { Button, useTheme } from "@mui/material";
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const SemesterChipWithDialog = ({ 
    semester,
    handleChipClick,
    season,
    selectedSemester,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    // handle dialog open
    const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
    const handleDialogClose = () => {
        setOpenAlertDialog(false);
    };
    const handleDialogOpen = () => setOpenAlertDialog(true);

    return (
        <>
            <Chip 
                label={semester.year + " " + season[semester.season]} 
                sx={{
                    padding: 3,
                    bgcolor: selectedSemester === semester.id ? colors.greenAccent[500] : null,
                    border: selectedSemester === semester.id ? "1px solid " + colors.primary[100] : "1px solid transparent",
                    fontSize: 13,
                }}
                onClick={handleDialogOpen} 
            />
            <Dialog
                open={openAlertDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Going to another semester. The content of the email will be saved.
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Make sure to check the correct courses for this semester again before you leave.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {handleDialogClose(); handleChipClick(semester.id);}}
                        color='success'
                        autoFocus
                    >
                        Ok
                    </Button>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SemesterChipWithDialog