import React from 'react'
import { tokens } from "../../theme";
import { Box, Typography, useTheme } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import ConsultationCard from './ConsultationCard';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import RestoreModal from './RestoreModal';

const PotentialStudentsHorizView = ({ 
    students, 
    setStudentUpdated,
    setPrevActions,
    prevActions,
    updatePotentialStudentsInfo,
    updateInfoMail,
    updateMemo,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // restore modal
    const [restoreModalOpen, setRestoreModalOpen] = React.useState(false);

    const [beingModified, setBeingModified] = React.useState(false);

    function savePrevActions(student_uuid, memo_id_or_info_mail_id, action, prev_data, new_data, consultation_number) {
        const newPrevActions = [];

        // Add new action to the beginning of the array
        newPrevActions.push({
            student_uuid: student_uuid,
            memo_id_or_info_mail_id: memo_id_or_info_mail_id,
            consultation_number: consultation_number,
            action: action,
            prev_data: prev_data,
            new_data: new_data,
        });

        // Add previous actions to the array
        prevActions.forEach((prevAction) => {
            newPrevActions.push(prevAction);
        });

        setPrevActions(newPrevActions);
    }

    const handleKeyDown = (event) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
            if (beingModified)
                return;

            event.preventDefault(); // Prevent default browser behavior
            // if (!prevActions || prevActions.length === 0) {
            //     window.alert("No actions to undo");
            //     return;
            // }
            setRestoreModalOpen(true);
        }
    };

    // Add event listener when component mounts
    React.useEffect(() => {
        document.removeEventListener('keydown', handleKeyDown);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            // Remove event listener when component unmounts
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [beingModified]);

    return (<>
        <Box
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                height: "calc(100vh - 220px)",
                overflowY: "auto",
            }}
            onScroll={(e) => {
                const consultationNumber = document.getElementById("consultation-number");
                const stickyTopbar = document.getElementById("sticky-topbar");

                if (consultationNumber && stickyTopbar && e.target.scrollTop - 50 < consultationNumber.offsetTop + consultationNumber.offsetHeight) {
                    stickyTopbar.style.opacity = "0";
                    stickyTopbar.style.zIndex = "-1";
                } else if (consultationNumber&& stickyTopbar && e.target.scrollTop > consultationNumber.offsetTop + consultationNumber.offsetHeight) {
                    stickyTopbar.style.opacity = "1";
                    stickyTopbar.style.zIndex = "3";
                }
            }}
        >
            <Box
                id="sticky-topbar"
                sx={{
                    position: "sticky",
                    top: "0",
                    opacity: "0",
                    zIndex: "-1",
                    marginTop: "-50px",
                    minHeight: "50px",
                    minWidth: (140 + 600) + "px",
                    transition: "opacity 0.1s",
                    display: "flex",
                    gap: "1px",
                    alignItems: "center",
                }}
            >
                <Box 
                    id="consultation-number"
                    sx={{ 
                        position: "sticky",
                        left: "0",
                        minWidth: "140px",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: colors.primary[100],
                        borderRight: `1px solid ${colors.grey[900]}`,
                    }}
                >
                    <Box 
                        sx={{ 
                            flex: 1,
                            display: "flex", 
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 0.5, 
                            color: colors.primary[750],
                        }}
                    >
                        <Typography variant="h5">
                            안내 번호
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        gap: "1px",
                        borderBottom: `1px solid ${colors.grey[900]}`,
                        backgroundColor: colors.primary[100],
                        height: "100%",
                    }}
                >
                    {students && students.length > 0 && students.map((student) => {
                        return (
                            <Box 
                                key={student.uuid}
                                sx={{ 
                                    flex: 1,
                                    display: "flex", 
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minWidth: "600px",
                                    borderRight: `1px solid ${colors.grey[900]}`,
                                    gap: 0.5,
                                    color: colors.primary[750],
                                }}
                            >
                                <Typography variant="h3">
                                    {student.consultation_number}
                                </Typography>
                            </Box>
                        )})
                    }
                </Box>
            </Box>
            <motion.div
                style={{
                    display: "flex",
                    gap: "1px",
                    minWidth: students && students.length > 0 ? (140 + 600 * students.length) + "px" : (140 + 600) + "px",
                    width: "100%",
                }}
                layout
            >
                <AnimatePresence>
                    <Card
                        sx={{
                            position: "sticky",
                            left: "0",
                            paddingTop: "5px",
                            borderRadius: 0,
                            minHeight: "100%",
                            minWidth: "140px",
                            width: "140px",
                            backgroundColor: colors.grey[800],
                            zIndex: 2,
                        }}
                    >
                        <CardContent
                            sx={{
                                paddingX: 0,
                            }}
                        >
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Box 
                                    id="consultation-number"
                                    sx={{ 
                                        flex: 1,
                                        display: "flex", 
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 0.5, 
                                        mb: 2 
                                    }}
                                >
                                    <Typography variant="h5">
                                        안내 번호
                                    </Typography>
                                </Box>
                            </Box>
                            <br />
                            <br />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: "10px",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: '100%',
                                        minHeight: 50,
                                        borderTop: `1px solid ${colors.grey[900]}`,
                                        borderBottom: `1px solid ${colors.grey[900]}`,
                                        textAlign: "center",
                                    }}
                                >
                                    <Typography 
                                        variant="h6" 
                                        sx={{ 
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%", 
                                            flex: 1, 
                                        }}
                                    >
                                        상담날짜
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: '100%',
                                        minHeight: 50,
                                        borderBottom: `1px solid ${colors.grey[900]}`,
                                        textAlign: "center",
                                    }}
                                >
                                    <Typography 
                                        variant="h6" 
                                        sx={{ 
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%", 
                                            flex: 1, 
                                        }}
                                    >
                                        수강여부
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: '100%',
                                        minHeight: 70,
                                        borderBottom: `1px solid ${colors.grey[900]}`,
                                        textAlign: "center",
                                    }}
                                >
                                    <Typography 
                                        variant="h6" 
                                        sx={{ 
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%", 
                                            flex: 1, 
                                        }}
                                    >
                                        이메일 보낸 날짜
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: '100%',
                                        minHeight: 50,
                                        borderBottom: `1px solid ${colors.grey[900]}`,
                                        textAlign: "center",
                                    }}
                                >
                                    <Typography 
                                        variant="h6" 
                                        sx={{ 
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%", 
                                            flex: 1, 
                                        }}
                                    >
                                        이름
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: '100%',
                                        minHeight: 50,
                                        borderBottom: `1px solid ${colors.grey[900]}`,
                                        textAlign: "center",
                                    }}
                                >
                                    <Typography 
                                        variant="h6" 
                                        sx={{ 
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%", 
                                            flex: 1, 
                                        }}
                                    >
                                        전화번호
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: '100%',
                                        minHeight: 50,
                                        borderBottom: `1px solid ${colors.grey[900]}`,
                                        textAlign: "center",
                                    }}
                                >
                                    <Typography 
                                        variant="h6" 
                                        sx={{ 
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%", 
                                            flex: 1, 
                                        }}
                                    >
                                        이메일
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: '100%',
                                        minHeight: 50,
                                        // borderBottom: `1px solid ${colors.grey[800]}`,
                                        textAlign: "center",
                                    }}
                                >
                                    <Typography 
                                        variant="h6" 
                                        sx={{ 
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%", 
                                            minHeight: 400,
                                            flex: 1, 
                                        }}
                                    >
                                        상담과목
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                    {students && students.length > 0 && students.map((student) => {
                        return (
                            <ConsultationCard 
                                key={student.uuid}
                                student={student} 
                                setStudentUpdated={setStudentUpdated} 
                                savePrevActions={savePrevActions}
                                prevActions={prevActions}
                                setBeingModified={setBeingModified}
                                updatePotentialStudentsInfo={updatePotentialStudentsInfo}
                                updateInfoMail={updateInfoMail}
                                updateMemo={updateMemo}
                            />
                        )})
                    }
                </AnimatePresence>
            </motion.div>
        </Box>
        {/* Restore Modal */}
        <RestoreModal
            students={students}
            setPrevActions={setPrevActions}
            prevActions={prevActions}
            modalOpen={restoreModalOpen}
            setModalOpen={setRestoreModalOpen}
            updatePotentialStudentsInfo={updatePotentialStudentsInfo}
            updateInfoMail={updateInfoMail}
            updateMemo={updateMemo}
        />
    </>)
}

export default PotentialStudentsHorizView