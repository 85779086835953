import React from 'react'
import { Box, useTheme, Typography, IconButton } from "@mui/material";
import { tokens } from "../theme";
import FileUploadOutlined from '@mui/icons-material/FileUploadOutlined';
import ClearIcon from '@mui/icons-material/Clear';

const UploadComp = ({ text, files, setFiles }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleFileRemove = (event, index) => {
        event.preventDefault();
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };
    
    const handleUpload = (event) => {
        setFiles([...files, ...Array.from(event.target.files)]);
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={2}
        >
            <Box
                display="flex"
                alignItems="center"
                mb={-2}
            >
                {text === undefined ? 
                    <Typography variant="h6" fontWeight="bold" color={colors.primary[450]}>
                        Upload Files
                    </Typography>
                    :
                    <Typography variant="h6" fontWeight="bold" color={colors.primary[450]}>
                        {text}
                    </Typography>
                }
                <IconButton component="label">
                    <FileUploadOutlined />
                    <input
                        multiple={true}
                        styles={{display:"none"}}
                        type="file"
                        hidden
                        onChange={handleUpload}
                        name="[licenseFile]"
                    />
                </IconButton>
            </Box>
            <Box
                display="flex"
                minWidth="100%"
                flexDirection="column"
            >
                {files.length > 0 && files.map((file, index) => {
                    return (
                        <Box key={index} display="flex" alignItems="center">
                            <Typography variant="body1" color={colors.primary[450]}>
                                {file.name}
                            </Typography>
                            <IconButton onClick={(e) => handleFileRemove(e, index)}>
                                <ClearIcon />
                            </IconButton>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default UploadComp