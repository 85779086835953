import React from 'react'
import MemoTable from './MemoTable';
import AddMemoAccordion from './AddMemoAccordion';


export default function MemosTab({ 
    memos, 
    student_uuid,
    setStudentUpdated,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,  
}) {
    return (
        <>
            <AddMemoAccordion 
                student_uuid={student_uuid} 
                setStudentUpdated={setStudentUpdated}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setSuccessAlertMessage={setSuccessAlertMessage}
                setErrorAlertOpen={setErrorAlertOpen}
                setErrorAlertMessage={setErrorAlertMessage}
            />
            <MemoTable 
                memos={memos} 
                student_uuid={student_uuid} 
                setStudentUpdated={setStudentUpdated}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setSuccessAlertMessage={setSuccessAlertMessage}
                setErrorAlertOpen={setErrorAlertOpen}
                setErrorAlertMessage={setErrorAlertMessage}
            />
        </>
    )
}