import * as React from "react";
import { Box, Button, TextField } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Axios from 'axios';
import Alert from '../../components/alert/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime, Settings } from 'luxon';
import Typography from '@mui/material/Typography';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

Settings.defaultZone = "system";

const CopyCourseForm = ({ 
    potentialCourseType, 
    courseData, 
    student_courses, 
    handleClose 
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const gridItemStyle = {
        '& > :not(style)': { m: 1, width: '25rem' }
    };

    // for course "from date" and "to date" (required)
    const [startDate, setStartDate] = React.useState(courseData !== null && courseData.start_date !== null ? DateTime.fromISO(courseData.start_date) : null);
    const [endDate, setEndDate] = React.useState(courseData !== null && courseData.end_date !== null ? DateTime.fromISO(courseData.end_date) : null);
    
    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    // for semester autocomplete
    const seasons = ['Spring', 'Summer', 'Fall', 'Winter'];
    const [semesters, setSemesters] = React.useState(null);
    const [semesterForCourse, setSemesterForCourse] = React.useState(courseData.course_semesters.map((course_semester) => {
        return course_semester.semester;
    }));

    // for teacher autocomplete
    const [teacherInfo, setTeacherInfo] = React.useState(null);
    const [selectedTeacher, setSelectedTeacher] = React.useState(null);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const handleChange = (event, value) => {
        setSelectedTeacher(value);
    };
    

    // for days of week for couese schedule
    const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    // const daysValue = {'Monday' : 1, 'Tuesday' : 2, 'Wednesday' : 3, 'Thursday' : 4, 'Friday' : 5, 'Saturday' : 6}
    const [days, setDays] = React.useState(null);

    // for time schedule in each day of class session
    const [fromValue, setFromValue] = React.useState(DateTime.now());
    const [toValue, setToValue] = React.useState(DateTime.now());

    // for private potential course's student
    const [selectedStudent, setSelectedStudent] = React.useState(null);
    const [studentStartDate, setStudentStartDate] = React.useState(null);
    const [studentEndDate, setStudentEndDate] = React.useState(null);

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (event.target.course_title.value === null || event.target.course_title.value.trim() === "") {
            setErrorAlertMessage("Course title cannot be empty.");
            setErrorAlertOpen(true);
            return;
        }

        if (event.target.subject.value === null || event.target.subject.value.trim() === "") {
            setErrorAlertMessage("Subject cannot be empty.");
            setErrorAlertOpen(true);
            return;
        }

        if (potentialCourseType === 1 && selectedStudent === null) {
            setErrorAlertMessage("Please select a student for private course.");
            setErrorAlertOpen(true);
            return;
        }

        if (potentialCourseType === 1 && selectedStudent !== null && (studentStartDate === null || studentEndDate === null)) {
            setErrorAlertMessage("Student course schedule is required.");
            setErrorAlertOpen(true);
            return;
        }

        if (potentialCourseType === 1 && selectedStudent !== null && studentStartDate > studentEndDate) {
            setErrorAlertMessage("Student course schedule: \"From\" date cannot be greater than \"To\" date!");
            setErrorAlertOpen(true);
            return;
        }

        if (potentialCourseType === 1 && selectedStudent !== null && (studentStartDate < startDate || studentEndDate > endDate)) {
            setErrorAlertMessage("Student course schedule: \"From\" and \"To\" dates should be within the course schedule.");
            setErrorAlertOpen(true);
            return;
        }

        if (startDate === null || endDate === null) {
            setErrorAlertMessage("Course Info: \"From\" and \"To\" dates are required.");
            setErrorAlertOpen(true);
            return;
        }
        
        if (startDate > endDate) {
            setErrorAlertMessage("Course Info: \"From\" date cannot be greater than \"To\" date!");
            setErrorAlertOpen(true);
            return;
        }

        const utcStudentStartDate = studentStartDate !== null ? studentStartDate.toUTC() : null;
        const utcStudentEndDate = studentEndDate !== null ? studentEndDate.toUTC() : null;
        const utcStartDate = startDate.toUTC();
        const utcEndDate = endDate.toUTC();

        let moduleStartTime = null;
        let moduleEndTime = null;

        if (days !== null && days.length > 0) {
            if (fromValue === null || toValue === null) {
                setErrorAlertMessage("From time and to time are required.");
                setErrorAlertOpen(true);
                return;
            }

            if (fromValue > toValue) {
                setErrorAlertMessage("From time cannot be greater than to time!");
                setErrorAlertOpen(true);
                return;
            }

            if (fromValue === toValue) {
                setErrorAlertMessage("From time cannot be equal to to time!");
                setErrorAlertOpen(true);
                return;
            }

            // only need hour and minute
            moduleStartTime = fromValue.toISO().split("T")[1].split("-")[0];
            moduleEndTime = toValue.toISO().split("T")[1].split("-")[0];
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/potential-courses/${courseData.uuid}/copy-to-new-course`, {
            code: event.target.code.value ? event.target.code.value.trim() : courseData.code,
            course_title: event.target.course_title.value.trim(),
            subject: event.target.subject.value.trim(),
            textbook: event.target.textbook.value ? event.target.textbook.value.trim() : null,
            price: event.target.price.value ? event.target.price.value : null,
            semesters: semesterForCourse,
            start_date: utcStartDate,
            end_date: utcEndDate,
            course_memo: courseData.course_memo,
            days: days,
            start_time: moduleStartTime,
            end_time: moduleEndTime,
            potential_course_type: potentialCourseType,
            selected_student_uuid: selectedStudent,
            student_start_date: utcStudentStartDate,
            student_end_date: utcStudentEndDate,
            selectedTeacher: selectedTeacher,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message);
            // reload the page with a param to autoscroll to the potential course in the current page
            setTimeout(() => {
                window.location.href = `/admin/potentialCourses?scrollTo=${courseData.uuid}`;
            }, 1000);
        }).catch((error) => {
            console.log(error.response.data.message);
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.message || "Failed to copy the course.");
        });
    };

    React.useEffect(() => {
        async function fetchSemesters() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/semesters`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                    setSemesters(response.data);
                }).catch((error) => {
                    console.log(error.response.data.message);
                    setErrorAlertOpen(true);
                    setErrorAlertMessage(error.response.data.message);
                });
        };

        async function fetchTeachers(){
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/teachers`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                // here, we should display the teachers who are not assigned to the course at the same duration
                // where can we get the selected course duration?
                setTeacherInfo(response.data);
            }).catch((error) => {
                console.log(error.response.data.message);
                setErrorAlertOpen(true);
                setErrorAlertMessage(error.response.data.message);
            });
        }

        fetchSemesters();
        fetchTeachers();
    }, []);

    return (
        <>
            <Box component="form" onSubmit={handleFormSubmit} noValidate maxWidth="60rem">
                {potentialCourseType === 1 && (<>
                    <Typography variant="h5" fontWeight='bold' sx={{ py: 1 }}>
                        Select Student for Private Course
                    </Typography>
                    <Box
                        sx={{
                            mt: 1,
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: 500,
                            minWidth: "100%",
                            maxWidth: "100%",
                            overflowX: 'scroll',
                            border: `1px solid ${colors.primary[400]}`,
                        }}
                    >
                        <Box
                            sx={{
                                position: 'sticky',
                                top: 0,
                                left: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                borderRight: `1px solid ${colors.primary[400]}`,
                                width: 120,
                                minWidth: 120,
                                zIndex: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: "100%",
                                    height: 40,
                                    backgroundColor: colors.primary[900],
                                    borderBottom: `1px solid ${colors.primary[400]}`,
                                }}
                            >
                                <Typography variant="h6">
                                    안내 번호
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: "100%",
                                    height: 40,
                                    backgroundColor: colors.primary[900],
                                    borderBottom: `1px solid ${colors.primary[400]}`,
                                }}
                            >
                                <Typography variant="h6">
                                    학생 이름
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: "100%",
                                    height: 40,
                                    backgroundColor: colors.primary[900],
                                    borderBottom: `1px solid ${colors.primary[400]}`,
                                }}
                            >
                                <Typography variant="body1">
                                    학생 이메일
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: "100%",
                                    height: 40,
                                    backgroundColor: colors.primary[900],
                                    borderBottom: `1px solid ${colors.primary[400]}`,
                                }}
                            >
                                <Typography variant="body1">
                                    부모님 이름
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: "100%",
                                    height: 40,
                                    backgroundColor: colors.primary[900],
                                    borderBottom: `1px solid ${colors.primary[400]}`,
                                }}
                            >
                                <Typography variant="body1">
                                    부모님 이메일
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: "100%",
                                    height: 40,
                                    backgroundColor: colors.primary[900],
                                    borderBottom: `1px solid ${colors.primary[400]}`,
                                }}
                            >
                                <Typography variant="body1">
                                    부모님 전화번호
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: "100%",
                                    height: 40,
                                    backgroundColor: colors.primary[900],
                                }}
                            >
                                <Typography variant="body1"></Typography>
                            </Box>
                        </Box>
                        {student_courses?.length > 0 && student_courses.map((student_course) => {
                            const student = student_course.student;

                            return (
                                <Box
                                    key={student.uuid}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: '1rem',
                                        borderRight: `1px solid ${colors.primary[400]}`,
                                        bgcolor: selectedStudent === student.uuid ? colors.greenAccent[900] : 'transparent',
                                        p: 2,
                                        width: 300,
                                        minWidth: 300,
                                    }}
                                >
                                    <Typography variant="h4" flex={1} fontWeight={"bold"}>
                                        {student.consultation_number ? student.consultation_number : 'N/A'}
                                    </Typography>
                                    <Typography variant="h6" flex={1}>
                                        {student.first_name ? student.first_name : ''} {student.last_name ? student.last_name : ''}
                                    </Typography>
                                    <Typography variant="body1" flex={1}>
                                        {student.email ? student.student_email : 'N/A'}
                                    </Typography>
                                    <Typography variant="body1" flex={1}>
                                        {student.parent_name ? student.parent_name : 'N/A'}
                                    </Typography>
                                    <Typography variant="body1" flex={1}>
                                        {student.parent_email ? student.parent_email : 'N/A'}
                                    </Typography>
                                    <Typography variant="body1" flex={1}>
                                        {student.parent_phone ? student.parent_phone : 'N/A'}
                                    </Typography>
                                    <Button 
                                        variant="contained" 
                                        color={selectedStudent === student.uuid ? "success" : "primary"}
                                        sx={{
                                            mt: "auto",
                                        }}
                                        onClick={() => {
                                            if (selectedStudent === student.uuid) {
                                                setSelectedStudent(null);
                                            } else {
                                                if (student_course.start_date !== null && student_course.end_date !== null) {
                                                    setStudentStartDate(DateTime.fromISO(student_course.start_date));
                                                    setStudentEndDate(DateTime.fromISO(student_course.end_date));
                                                }
                                                setSelectedStudent(student.uuid);
                                            }
                                        }}
                                    >
                                        {selectedStudent === student.uuid ? "Selected" : "Select"}
                                    </Button>
                                </Box>
                            );
                        })}
                    </Box>
                </>)}
                {selectedStudent !== null && (<>
                    <Typography variant="h5" fontWeight='bold' sx={{ py: 1, mt: 1 }}>
                        Student Course Schedule
                    </Typography>
                    <Grid container direction="row" alignItems="center">
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <Grid item sx={gridItemStyle}>
                                <DatePicker
                                    label="From (required)"
                                    value={studentStartDate}
                                    onChange={(newValue) => {
                                        const startOfDate = newValue.startOf('day').plus({ minutes: 1 });
                                        setStudentStartDate(startOfDate);
                                    }}
                                    sx={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item sx={gridItemStyle}>
                                <DatePicker
                                    label="To (required)"
                                    value={studentEndDate}
                                    onChange={(newValue) => {
                                        const endOfDate = newValue.endOf('day').minus({ minutes: 1 });
                                        setStudentEndDate(endOfDate);
                                    }}
                                    sx={{ width: "100%" }}
                                />
                            </Grid>
                        </LocalizationProvider>
                    </Grid>
                </>)}
                <Typography variant="h5" fontWeight='bold' sx={{ py: 1, mt: 1 }}>
                    Course Infomation
                </Typography>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField
                            id="code"
                            name="code"
                            label="Course Code"
                            variant="filled"
                            defaultValue={courseData.code}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField
                            id="course_title"
                            name="course_title"
                            label="Course Title"
                            variant="filled"
                            defaultValue={courseData.course_title}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    {/* teacher(s) for creating a course */}
                    <Grid item sx={gridItemStyle}>
                        <Autocomplete
                            id="teacher"
                            disablePortal
                            multiple
                            onChange={handleChange}
                            options={teacherInfo ? teacherInfo.sort((a, b) => a.first_name.localeCompare(b.first_name)) : []}
                            groupBy={(option) => option.first_name[0].toUpperCase()}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.first_name + " " + option.last_name}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}                                        
                                    />
                                {option.first_name + " " + option.last_name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} label="Teacher(s) (Optional)" variant="filled" />
                            )}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField
                            id="subject"
                            name="subject"
                            label="Subject"
                            variant="filled"
                            defaultValue={courseData.subject}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField
                            id="textbook"
                            name="textbook"
                            label="Textbook"
                            variant="filled"
                            defaultValue={courseData.textbook}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField
                            id="price"
                            name="price"
                            label="Price"
                            variant="filled"
                            defaultValue={courseData.price}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h5" fontWeight='bold' sx={{ py: 1 }}>
                    Course Schedule
                </Typography>
                <Grid container direction="row" alignItems="center">
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <Grid item sx={gridItemStyle}>
                            <DatePicker
                                label="From (required)"
                                value={startDate}
                                onChange={(newValue) => {
                                    const startOfDate = newValue.startOf('day');
                                    setStartDate(startOfDate);
                                }}
                                sx={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item sx={gridItemStyle}>
                            <DatePicker
                                label="To (required)"
                                value={endDate}
                                onChange={(newValue) => {
                                    const endOfDate = newValue.endOf('day');
                                    setEndDate(endOfDate);
                                }}
                                sx={{ width: "100%" }}
                            />
                        </Grid>
                    </LocalizationProvider>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <Autocomplete
                            multiple
                            id="tags-outlined-1"
                            options={semesters !== null ? semesters : []}
                            getOptionLabel={(semester) => semester.year + " " + seasons[semester.season - 1]}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Semester"
                                    placeholder="Select Semester"
                                />
                            )}
                            onChange={(event, value) => setSemesterForCourse(value)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            defaultValue={semesterForCourse}
                        />
                    </Grid>
                </Grid>
                {(DateTime.fromISO(courseData.start_date).toISO() !== startDate?.toISO() || DateTime.fromISO(courseData.end_date).toISO() !== endDate?.toISO()) && (
                    <>
                        <Typography variant="h5" fontWeight='bold' sx={{ py: 1 }}>
                            Class Schedule for Each Session
                        </Typography>
                        <Grid container direction="row" alignItems="center">
                            <Grid item sx={gridItemStyle}>
                                <Autocomplete
                                    multiple
                                    id="tags-outlined-1"
                                    options={daysOfWeek}
                                    getOptionLabel={(day) => day}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Days (required)"
                                            placeholder="Select days"
                                        />
                                    )}
                                    sx={{ gridColumn: "span 3" }}
                                    onChange={(event, value) => setDays(value)}
                                />
                            </Grid>
                            {days !== null && days.length > 0 && (
                                <Grid item sx={gridItemStyle}>
                                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                                        <TimePicker
                                            label="From (class time)"
                                            value={fromValue}
                                            onChange={(newValue) => setFromValue(newValue)}
                                            format="HH:mm"
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TimePicker
                                            label="To"
                                            value={toValue}
                                            onChange={(newValue) => setToValue(newValue)}
                                            format="HH:mm"
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            )}
                        </Grid>
                    </>
                )}
                {/* for group potential course */}
                {potentialCourseType === 0 && (<>
                    <Typography variant="h6" fontWeight='bold' sx={{ pt: 1 }}>
                        Students in this Course
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            marginTop: '1rem',
                            paddingLeft: '0.5rem',
                            gap: '0.3rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        {student_courses.length > 0 && student_courses.map((student_course) => {
                            if (student_course.is_confirmed === null || student_course.is_confirmed === false)
                                return null;
                            
                            return (
                                <Typography 
                                    key={student_course.id} 
                                    variant="body1"
                                    sx={{
                                        minWidth: '160px',
                                        border: '1px solid grey',
                                        bgcolor: colors.primary[900],
                                        p: 1,
                                    }}
                                >
                                    {student_course.student?.first_name ? student_course.student?.first_name : ""} {student_course.student?.last_name ? student_course.student?.last_name : ""}
                                </Typography>
                            )
                        })}
                    </Box>
                </>)}
                <Box display="flex" justifyContent="start" mt="1rem" gap="1rem">
                    <Button 
                        type="submit" 
                        color="secondary" 
                        variant="contained" 
                        sx={{width: "15rem"}}
                    >
                        Copy and Create Course
                    </Button>
                    <Button 
                        type="button" 
                        color="error" 
                        variant="contained" 
                        sx={{width: "10rem"}} 
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
        </>
    );
};

export default CopyCourseForm