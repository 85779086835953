import React from 'react'
// import Chip from '@mui/material/Chip';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import { tokens } from "../../theme";
import { Box, Typography, useTheme } from "@mui/material";
import dayjs from 'dayjs';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

var timezone = require('dayjs/plugin/timezone')
var utc = require('dayjs/plugin/utc')

dayjs.extend(utc);
dayjs.extend(timezone);

const EachTableRow = ({ i, index, questionsLen, question, StyledTableCell, StyledTableRow }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // // for modal
    // const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);

    return (
        <StyledTableRow>
            {i === 0 && index === 0 ? (
                <StyledTableCell rowSpan={questionsLen} style={{ backgroundColor: colors.greenAccent[500], width: "14%" }}>
                    <Box sx={{ display: 'flex', alignContent: 'center', gap: '0.5rem' }}>
                        <Box>
                            {dayjs(question.updatedAt).tz('America/New_York').format('MMM DD, YYYY')}
                        </Box>
                        <Box>
                            <TodayOutlinedIcon />
                        </Box>
                    </Box>
                </StyledTableCell>
            ) : i === 0 && index !== 0 ? (
                <StyledTableCell rowSpan={questionsLen} style={{ width: "14%" }}>
                    {dayjs(question.updatedAt).tz('America/New_York').format('MMM DD, YYYY')}
                </StyledTableCell>
            ) : (null)}
            <StyledTableCell style={{ width: "14%" }}>
                {question.course.code}
                <br />
                {question.course.course_title}
            </StyledTableCell>
            <StyledTableCell style={{ width: "16%" }}>
                {question.author}
            </StyledTableCell>
            <StyledTableCell style={{ width: "48%" }}>
                <span style={{ fontWeight: "bold" }}>
                    {question.title}
                </span>
                <br /><br />
                {question.content.split("\n").map((item, i) => {
                    return (
                        <span key={i}>
                            {item}
                            <br />
                        </span>
                    )
                })}
                {question.course_question_files && question.course_question_files.length > 0 && question.course_question_files.map((file, i) => {
                    return (
                        <div key={i}>
                            {i === 0 && (
                                <Typography variant='h7' sx={{ color: colors.grey[700] }}>
                                    <br />
                                    <b>Attachments:</b>
                                    <br />
                                </Typography>
                            )}
                            <a href={file.file_url}>
                                {file.name}
                            </a>
                            <br />
                        </div>
                    )
                })}
            </StyledTableCell>
            <StyledTableCell align='center' style={{ width: "8%" }}>
                {question.answered ? (
                    <a href={"/classrooms/" + question.course.uuid + "/questions"}>
                        <CheckIcon sx={{ color: colors.greenAccent[200] }} />
                    </a>
                ) : (
                    <a href={"/classrooms/" + question.course.uuid + "/questions"}>
                        <CloseIcon sx={{ color: colors.redAccent[500] }} />
                    </a>
                )}
            </StyledTableCell>
        </StyledTableRow>
    )
}

export default EachTableRow