import React from 'react'
import { tokens } from "../../../theme";
import { Box, useTheme, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '../../../components/alert/Alert';
import Header from "../../../components/Header";
import Axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import getCookies from '../../../utils/getCookies';
import Loading from '../../../components/Loading';
import AdminActionAccordion from './AdminActionAcoordion';
import ZoomRecordingBox from './ZoomRecordingBox';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import dayjs from 'dayjs';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

const ZoomRecordings = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const matches = useMediaQuery('(min-width:1500px)');
    const matches2 = useMediaQuery('(min-width:1200px)');

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [loading, setLoading] = React.useState(true);
    const [courseId, setCourseId] = React.useState(null);
    const courseData = useOutletContext().courseData;
    const lectureModules = useOutletContext().lectureModules;
    const [students, setStudents] = React.useState(null);
    const currentUser = getCookies("_auth_state");
    const userId = currentUser ? currentUser.split(',')[0]?.split(':')[1]?.split('"')[1] : null;
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const [zoomRecordings, setZoomRecordings] = React.useState(null); // [ { module: '', link: '', expirationDate: '' }, ...
    const [dataUpdated, setDataUpdated] = React.useState(false); // to update zoomRecordings
    const [recordingListForStudent, setRecordingListForStudent] = React.useState([]);

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    // useEffect
    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Zoom Recordings');
        setCourseId(window.location.pathname.split('/')[2]);

        async function getStudents() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${window.location.pathname.split('/')[2]}/students`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                const all_students = [];
                for (let i = 0; i < res.data.length; i++)
                    all_students.push(res.data[i].student);

                setStudents(all_students);
            }).catch((err) => {
                if (err.response.status === 401) {
                    handleLogout();
                    return;
                }
                setStudents([]);
            });
        }

        getStudents();
    }, []);

    React.useEffect(() => {
        async function getZoomRecordings() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${window.location.pathname.split('/')[2]}/zoom-recordings`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                setZoomRecordings(res.data.zoom_recordings_list);
                
                const recordings = res.data.zoom_recordings_list;
                const recordingListForStudent = [];
                setRecordingListForStudent([]);
                if (userRole === "student" && recordings !== null && recordings.length > 0) {
                    recordings.map((zoomRecording) => {
                        zoomRecording.student_z_recordings.map((student_z_recording) => {
                            if (student_z_recording.student.uuid === userId 
                                && dayjs(zoomRecording.expiration_date).isAfter(dayjs())
                                && student_z_recording.is_watched === false
                            ) {
                                recordingListForStudent.push(zoomRecording);
                            }

                            return null;
                        })

                        return null;
                    });
                    setRecordingListForStudent(recordingListForStudent);
                }
            }).catch((err) => {
                setZoomRecordings([]);
            });
        }

        getZoomRecordings();
        setDataUpdated(false);
        setLoading(false);
    }, [dataUpdated, userId, userRole]);
    
    return (
        <Box m="20px" marginX={3} marginY={3} minHeight="90%">
            { loading || courseData === null || lectureModules === null || students === null || zoomRecordings === null ? 
                <Loading />
                : <>
                    <Box 
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            px: "25%",
                            ...!matches && {
                                px: "15%",
                            },
                            ...!matches2 && {
                                px: "10%",
                            },
                        }}
                    >
                        <Header 
                            title={
                                courseData.code !== null 
                                ? courseData.code + ". " + courseData.course_title 
                                : courseData.course_title
                            } 
                            subtitle="A ONE Institute"
                        />
                        { userRole === "admin" &&
                            <Box
                                minWidth="20rem"
                                width="100%"
                                minHeight="10rem"
                                borderRadius="3px"
                                padding={3}
                                boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                                backgroundColor={colors.primary[600]}
                                display="flex"
                                flexDirection="column"
                                alignItems="left"
                                gap={3}
                                mb={3}
                            >
                                <Typography variant="h4" color={colors.primary[450]}>
                                    Admin Actions
                                </Typography>
                                <AdminActionAccordion 
                                    courseId={courseId}
                                    lectureModules={lectureModules}
                                    students={students}
                                    setBackdropOpen={setBackdropOpen}
                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                    setErrorAlertOpen={setErrorAlertOpen}
                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                    setErrorAlertMessage={setErrorAlertMessage}
                                    setDataUpdated={setDataUpdated}
                                />
                            </Box>
                        }

                        {/* For Students */}
                        <Box
                            minWidth="20rem"
                            width="100%"
                            minHeight="100%"
                            borderRadius="3px"
                            padding={8}
                            boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                            backgroundColor={colors.primary[600]}
                            alignItems="center"
                        >
                            { (userRole === "admin" && zoomRecordings.length === 0)
                            || (userRole === "student" && recordingListForStudent.length === 0) ? (
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    minWidth: "20rem",
                                    minHeight: "32rem",
                                    gap: "0.4rem",
                                }}>
                                    <InfoOutlinedIcon sx={{ my: "auto" }} />
                                    <Typography variant="h4" color={colors.primary[450]} my="auto">
                                        No Zoom Recordings
                                    </Typography>
                                </Box>
                            ) : userRole === "admin" ? (
                                // admin can see all zoom recordings
                                zoomRecordings.map((zoomRecording, index) => {
                                    return (
                                        <ZoomRecordingBox 
                                            key={index}
                                            courseId={courseId}
                                            userRole={userRole}
                                            userId={userId}
                                            students={students}
                                            lectureModules={lectureModules}
                                            zoomRecording={zoomRecording}
                                            setBackdropOpen={setBackdropOpen}
                                            setSuccessAlertOpen={setSuccessAlertOpen}
                                            setErrorAlertOpen={setErrorAlertOpen}
                                            setSuccessAlertMessage={setSuccessAlertMessage}
                                            setErrorAlertMessage={setErrorAlertMessage}
                                            setDataUpdated={setDataUpdated}
                                        />
                                    )
                                })
                            ) : userRole === "student" && recordingListForStudent.length > 0 ? (
                                recordingListForStudent.map((zoomRecording, index) => {
                                    return (
                                        <ZoomRecordingBox 
                                            key={index}
                                            courseId={courseId}
                                            userRole={userRole}
                                            userId={userId}
                                            students={students}
                                            lectureModules={lectureModules}
                                            zoomRecording={zoomRecording}
                                            setBackdropOpen={setBackdropOpen}
                                            setSuccessAlertOpen={setSuccessAlertOpen}
                                            setErrorAlertOpen={setErrorAlertOpen}
                                            setSuccessAlertMessage={setSuccessAlertMessage}
                                            setErrorAlertMessage={setErrorAlertMessage}
                                            setDataUpdated={setDataUpdated}
                                        />
                                    )
                                })
                            ) : null
                        }
                        </Box>
                    </Box>
                    <Alert
                        successAlertOpen={successAlertOpen}
                        setSuccessAlertOpen={setSuccessAlertOpen}
                        errorAlertOpen={errorAlertOpen}
                        setErrorAlertOpen={setErrorAlertOpen}
                        successMsg={successAlertMessage}
                        errorMsg={errorAlertMessage}
                        vertical="bottom"
                        horizontal="left"
                    />
                    <Backdrop
                        sx={{ color: colors.primary[400], zIndex: 2147483646 }}
                        open={backdropOpen}
                        onClick={handleBackdropClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                </>
            }
        </Box>
    )
}

export default ZoomRecordings