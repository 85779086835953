import React from 'react'
import Popover from '@mui/material/Popover';
import MoreVertIcon from '../../../components/MoreVertIcon';
import CreateCourseModal from './CreateCourseModal';

/*
 * for each course, there is a vert icon 
 */
const EachVertIconAndPopover = ({
    semestersForCreateCourseModal,
    setInfoUpdated, 
    setBackdropOpen,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMoreVertIconClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    return (
        <><MoreVertIcon handleClick={handleMoreVertIconClick} iconColor={'secondary'} />
        <Popover
            id={popoverId}
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopOverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <CreateCourseModal
                semestersForCreateCourseModal={semestersForCreateCourseModal}
                setAnchorEl={setAnchorEl}
                setInfoUpdated={setInfoUpdated}
                setBackdropOpen={setBackdropOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setSuccessAlertMessage={setSuccessAlertMessage}
                setErrorAlertOpen={setErrorAlertOpen}
                setErrorAlertMessage={setErrorAlertMessage}
            />
        </Popover></>
    )
}

export default EachVertIconAndPopover