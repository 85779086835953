import * as React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import Axios from 'axios';
import Alert from '../../components/alert/Alert';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Backdrop from "../../components/Backdrop";
import IconButton from '@mui/material/IconButton';
import { FileUploadOutlined } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import HiddenPDF from '../hwQuestions/HiddenPDF';
import { Document, Page, pdfjs } from "react-pdf";
import { Autocomplete } from "@mui/material";
import { getSubjects } from "../../utils/TextbookSubjects";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AddTextbook = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");
    const isNonMobile = useMediaQuery("(min-width:600px)");

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // for file upload
    const [file, setFile] = React.useState(null);
    const [numPages, setNumPages] = React.useState(null);
    
    const [subject1, setSubject1] = React.useState(null);
    const [subject2, setSubject2] = React.useState(null);
    const [subject3, setSubject3] = React.useState(null);
    const [subject4, setSubject4] = React.useState(null);

    const handleUpload = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFileRemove = (event) => {
        event.preventDefault();
        setFile(null);
        setNumPages(null);
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const documentRender = () => {
        return (
            <Document 
                file={file} 
                onLoadSuccess={onDocumentLoadSuccess} 
                onContextMenu={(e) => e.preventDefault()}
                className="pdf-container"
            >
                <div>
                    <Page
                        pageNumber={1}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        className="react-pdf__Page__canvas" 
                    />
                </div>
            </Document>
        );
    };


    const handleFormSubmit = (values) => {
        setBackdropOpen(true);
        const pages = (values.pages);

        // if file is not pdf, return
        if (file === null || file === undefined || file.type !== "application/pdf") {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please upload a pdf file");
            setBackdropOpen(false);
            return;
        }

        // if level or subject is not selected, return
        if (subject1 === null || subject1 === "" || subject2 === null || subject2 === "") {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please select level and subjects");
            setBackdropOpen(false);
            return;
        }

        if (pages !== Number(numPages).toFixed(0) 
            && !window.confirm("The number of pages you entered does not match the actual number of pages in the file. Do you want to continue?")) {
            setBackdropOpen(false);
            return;
        }

        const formData = new FormData();
        if (file.name !== undefined 
            && file.name !== null 
            && file.name !== ""
            && file.name.length > 150) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("File name should be less than 150 characters");
            setBackdropOpen(false);
            return;
        }

        formData.append('file', file);
        formData.append('pages', values.pages);
        formData.append('subject_1', subject1.value);
        formData.append('subject_2', subject2.value);
        if (subject3 !== null && subject3 !== "") {
            formData.append('subject_3', subject3.value);
            if (subject4 !== null && subject4 !== "") {
                formData.append('subject_4', subject4.value);
            }
        }
        
        Axios.post(`${process.env.REACT_APP_URL}/api/v1/textbooks`, formData, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            setSuccessAlertMessage(response.data.message);
            setSuccessAlertOpen(true);
            handleBackdropClose();
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
            handleBackdropClose();
        });
    };

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Add Textbook');
    }, []);

    const levels = [
        { label: "None", value: 0},
        { label: "Senior High", value: 1 },
        { label: "Junior High", value: 2 },
    ];

    const subjects = getSubjects();

    return (
        <Box m="20px" marginX={3} marginY={3} maxWidth={"70rem"}>
            <Header title="Add Textbook" subtitle="A ONE Institute" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        padding={4}
                        boxShadow={3}
                        borderRadius={3}
                        m={1}
                        bgcolor={colors.primary[600]}
                    >
                        <Typography
                            variant="h4"
                            fontWeight="bold"
                            mb={3}
                        >
                            Textbook Information
                        </Typography>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <Box
                                display="flex"
                                sx={{ gridColumn: "span 4" }}
                            >
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h6" fontWeight="bold" color={colors.primary[450]}>
                                        Upload Files
                                    </Typography>
                                    <IconButton component="label">
                                        <FileUploadOutlined />
                                        <input
                                            multiple={false}
                                            styles={{display:"none"}}
                                            type="file"
                                            hidden
                                            onChange={handleUpload}
                                            name="[licenseFile]"
                                        />
                                    </IconButton>
                                </Box>
                                {file && (
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="body1" color={colors.primary[450]}>
                                                {file.name}
                                            </Typography>
                                            <IconButton onClick={(e) => handleFileRemove(e)}>
                                                <ClearIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                            {numPages && (
                                <Typography variant="h6" fontWeight="bold" color={colors.primary[450]} sx={{ gridColumn: "span 4" }}>
                                    Total {numPages} pages
                                </Typography>
                            )}
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Total Pages"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.pages}
                                name="pages"
                                error={!!touched.pages && !!errors.pages}
                                helperText={touched.pages && errors.pages}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <Autocomplete
                                id="free-solo-demo"
                                options={subjects}
                                sx={{ gridColumn: "span 1" }}
                                renderInput={(params) => <TextField {...params} label="Subject 1" />}
                                isOptionEqualToValue={(option, value) => {
                                    return option.value === value.value;
                                }}
                                onChange={(event, newValue) => {
                                    if (newValue !== null && newValue !== undefined) {
                                        setSubject1(newValue);
                                        setSubject2(null);
                                        setSubject3(null);
                                        setSubject4(null);
                                    } else {
                                        setSubject1(null);
                                        setSubject2(null);
                                        setSubject3(null);
                                        setSubject4(null);
                                    }
                                }}
                            />
                            {subject1 !== null && subject1.subject2 !== undefined && (
                                <Autocomplete
                                    id="free-solo-demo"
                                    options={subject1.subject2}
                                    sx={{ gridColumn: "span 1" }}
                                    renderInput={(params) => <TextField {...params} label="Subject 2" />}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.value === value.value;
                                    }}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null && newValue !== undefined) {
                                            setSubject2(newValue);
                                            setSubject3(null);
                                            setSubject4(null);
                                        } else {
                                            setSubject2(null);
                                            setSubject3(null);
                                            setSubject4(null);
                                        }
                                    }}
                                />
                            )}
                            {subject2 !== null && subject2.subject3 !== undefined && (
                                <Autocomplete
                                    id="free-solo-demo"
                                    options={subject2.subject3}
                                    sx={{ gridColumn: "span 1" }}
                                    renderInput={(params) => <TextField {...params} label="Subject 3" />}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.value === value.value;
                                    }}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null && newValue !== undefined) {
                                            setSubject3(newValue);
                                            setSubject4(null);
                                        } else {
                                            setSubject3(null);
                                            setSubject4(null);
                                        }
                                    }}
                                />
                            )}
                            {subject3 !== null && subject3.subject4 !== undefined && (
                                <Autocomplete
                                    id="free-solo-demo"
                                    options={subject3.subject4}
                                    sx={{ gridColumn: "span 1" }}
                                    renderInput={(params) => <TextField {...params} label="Subject 4" />}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.value === value.value;
                                    }}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null && newValue !== undefined) {
                                            setSubject4(newValue);
                                        } else {
                                            setSubject4(null);
                                        }
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="start" mt="20px">
                        <Button type="submit" color="secondary" variant="contained">
                            Create New Textbook
                        </Button>
                    </Box>
                </form>
                )}
            </Formik>
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
            <Backdrop backdropOpen={backdropOpen} handleBackdropClose={handleBackdropClose} />
            <HiddenPDF documentRender={documentRender} /> 
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    pages: yup.number().required("required"),
});

const initialValues = {
    pages: 0,
};

export default AddTextbook