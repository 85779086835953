import React from 'react'
import { Box, IconButton, Button, Typography, useTheme } from "@mui/material";
import { tokens } from '../../theme';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import Alert from '../../components/alert/Alert';
import Axios from 'axios';
import getCookies from '../../utils/getCookies';

const AccountUpdate = ({
    color,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    // popover for reminders
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => { setAnchorEl(event.currentTarget) };
    const handleClose = () => { setAnchorEl(null) };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const currentUser = getCookies("_auth_state");

    const userId = currentUser ? currentUser.split(',')[0]?.split(':')[1]?.split('"')[1] : null;
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const email = currentUser.split(',')[1]?.split(':')[1]?.split('"')[1] ?? null;

    // const userId = document.cookie ? jwt_decode(document.cookie).id : ""; // uuid
    // const userRole = document.cookie ? jwt_decode(document.cookie).who : ""; // admin, teacher, student
    const userName = localStorage.getItem("userName") ? localStorage.getItem("userName") : "";
    // const email = document.cookie ? jwt_decode(document.cookie).email : "";

    const [oldPassword, setOldPassword] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");

    const handleUpdatePassword = async (e) => {
        e.preventDefault();

        if (oldPassword === "" || newPassword === "" || confirmPassword === "") {
            setErrorAlertMessage("Please fill in all the fields!");
            setErrorAlertOpen(true);
            return;
        }

        if (oldPassword === newPassword) {
            setErrorAlertMessage("New password cannot be the same as old password!");
            setErrorAlertOpen(true);
            return;
        }

        if (newPassword !== confirmPassword) {
            setErrorAlertMessage("Passwords do not match!");
            setErrorAlertOpen(true);
            return;
        }

        if (newPassword.length < 8) {
            setErrorAlertMessage("Password must be at least 8 characters long!");
            setErrorAlertOpen(true);
            return;
        }

        if (newPassword.length > 30) {
            setErrorAlertMessage("Password must be at most 30 characters long!");
            setErrorAlertOpen(true);
            return;
        }

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/users/update/password`, {
            uuid: userId,
            userRole: userRole,
            oldPassword: oldPassword,
            newPassword: newPassword,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("Password has been updated!");
            setSuccessAlertOpen(true);
            handleClose();
        }).catch((error) => {
            setErrorAlertMessage(error.response.data.error || "Something went wrong!");
            setErrorAlertOpen(true);
        });
    };

    return (<>
        <IconButton onClick={handleClick}>
            <ManageAccountsOutlinedIcon color={color} />
        </IconButton>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPopover-paper': {
                    borderRadius: '10px',
                    border: '2px solid',
                    backgroundColor: colors.primary[750],
                    p: 2,
                    width: 300
                },
            }}
        >
            <Box component="form" sx={{ display: "flex", flexDirection: "column", gap: 1 }} >
                <Typography variant="h6" sx={{ my: 1, mx: "auto", fontWeight: "bold" }}>Update Password</Typography>
                <TextField 
                    id="userName"
                    name="userName"
                    label="User Name"
                    variant="filled"
                    defaultValue={userName}
                    disabled
                />
                <TextField 
                    id="email"
                    name="email"
                    label="Email"
                    variant="filled"
                    defaultValue={email}
                    disabled
                />
                <TextField 
                    id='oldPassword'
                    label="Current Password"
                    variant="filled"
                    type="password"
                    onChange={(e) => setOldPassword(e.target.value)}
                />
                <TextField 
                    id='newPassword'
                    label="New Password"
                    variant="filled"
                    type="password"
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <TextField 
                    id='confirmPassword'
                    label="Confirm New Password"
                    variant="filled"
                    type="password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <Button 
                    color="secondary" 
                    variant="contained" 
                    sx={{ mx: "auto", width: "8rem" }}
                    onClick={(e) => handleUpdatePassword(e)}
                >
                    Update
                </Button>
            </Box>
        </Popover>
        <Alert 
            successAlertOpen={successAlertOpen} 
            setSuccessAlertOpen={setSuccessAlertOpen}
            errorAlertOpen={errorAlertOpen}
            setErrorAlertOpen={setErrorAlertOpen}
            successMsg={successAlertMessage}
            errorMsg={errorAlertMessage}
            vert='top'
            horiz='right'
        />
    </>)
}

export default AccountUpdate