import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, useTheme, Typography } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { tokens } from "../../../theme";
import Axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';

const RemoveTeacherModal = ({ 
    setAnchorEl, 
    course, 
    setInfoUpdated,
    setBackdropOpen,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); setAnchorEl(null); };

    const [removeTeacher, setRemoveTeacher] = React.useState(null);

    const handleRemoveTeacher = async () => {
        if (removeTeacher === null) {
            setErrorAlertMessage("Please select a teacher to remove.");
            setErrorAlertOpen(true);
            return;
        }

        setBackdropOpen(true);
        Axios.delete(`${process.env.REACT_APP_URL}/api/v1/courses/${course.uuid}/teachers/${removeTeacher.uuid}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage(response.data.message);
            setSuccessAlertOpen(true);
            setBackdropOpen(false);
            setInfoUpdated(true);
            handleClose();
        }).catch((error) => {
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
            setBackdropOpen(false);
        });
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Button onClick={handleOpen} sx={{ p: 1, width: "100%" }}>Remove Teacher</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]}>
                            Remove a teacher from {course.course_title}
                        </Typography>
                        <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                        <Box display="flex" flexDirection="column" gap={3} width="100%" pt={2}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo-1"
                                    getOptionLabel={(option) => option.first_name + " " + option.last_name}
                                    options={course.teacher_courses !== null ? course.teacher_courses.map(
                                        (teacher_course) => {
                                            return {
                                                id: teacher_course.teacher.id,
                                                uuid: teacher_course.teacher.uuid,
                                                first_name: teacher_course.teacher.first_name,
                                                last_name: teacher_course.teacher.last_name
                                            }
                                        }
                                    ) : []}
                                    renderInput={(params) => <TextField {...params} label="Remove Teacher" />}
                                    onChange={(event, value) => setRemoveTeacher(value)} 
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: "100%", pr: "10rem" }}
                                />
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 2, width: "7rem" }}
                                    onClick={handleRemoveTeacher}
                                >
                                    Remove
                                </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default RemoveTeacherModal