import React from 'react'
import { tokens } from "../../theme";
import { Box, Typography, useTheme } from "@mui/material";
// import Button from '@mui/material/Button';
import Loading from '../../components/Loading';
import Header from "../../components/Header";
import Axios from 'axios';
import Dropdown from '../../components/dropdown/Dropdown';
import Alert from '../../components/alert/Alert';
import BackdropComp from '../../components/Backdrop';
import dayjs from 'dayjs';
import AllInfoBox from './AllInfoBox';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';
// import { styled } from '@mui/material/styles';
// import jwt_decode from "jwt-decode";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [courses, setCourses] = React.useState(null);
    const [courseLoading, setCourseLoading] = React.useState(false);
    const [attendance_map, setAttendanceMap] = React.useState(null); // [student_id][course_id][event_id] = attendance
    const [attendance_count_map, setAttendanceCountMap] = React.useState(null); // [student_id][course_id][event_id] = attendance count
    const [note_count_map, setNoteCountMap] = React.useState(null); // [student_id][course_id][event_id] = note count
    const [event_modules_hash_map, setEventModulesHashMap] = React.useState(null); // [course_id] = events list
    const [semestersInDB, setSemestersInDB] = React.useState(null);
    const [yearsInDB, setYearsInDB] = React.useState(null);
    const [semestersForCreateCourseModal, setSemestersForCreateCourseModal] = React.useState(null); // for create course modal
    const [loading, setLoading] = React.useState(true);
    const [eventCountByStudent, setEventCountByStudent] = React.useState(null);
    // const userRole = jwt_decode(document.cookie).who;
    // const userId = jwt_decode(document.cookie).id;
    const season = {0: 'All', 1: 'Spring', 2: 'Summer', 3: 'Fall'};

    // for dropdown
    const [year, setYear] = React.useState(null);
    const [semester, setSemester] = React.useState('');

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    // for attendance
    const [infoUpdated, setInfoUpdated] = React.useState(false);

    // for course select
    const [selectedCourse, setSelectedCourse] = React.useState("none");    
    const [courseValueLabel, setCourseValueLabel] = React.useState("none");
    const [courseListLabel, setCourseListLabel] = React.useState([]);


    const getCurrentSemester = () => {
        const currMonth = dayjs().month() + 1;
        let semester = null;
        if (currMonth >= 2 && currMonth <= 6) {
            semester = "Spring";
        } else if (currMonth >= 7 && currMonth <= 8) {
            semester = "Summer";
        } else {
            semester = "Fall";
        }

        return semester;
    }

    const buildYearsAndSemestersForDropdown = (data) => {
        let yearsInDB = new Set();
        let semestersInDB = {};
        let dates = {};

        data.map((semester) => {
            yearsInDB.add(semester.year);
            if (semestersInDB[semester.year] === undefined) {
                semestersInDB[semester.year] = [];
                dates[semester.year] = {};
            }
            semestersInDB[semester.year].push(semester.season);
            dates[semester.year][semester.season] = {
                start: semester.start,
                end: semester.end
            };
            return null;
        });

        yearsInDB = Array.from(yearsInDB);
        yearsInDB.sort((a, b) => b - a);
        setYearsInDB(yearsInDB.map((year) => ({ value: year, label: year })));

        for (let year in yearsInDB) {
            semestersInDB[yearsInDB[year]].sort();
            let startDateForAll = null;
            let endDateForAll = null;
            semestersInDB[yearsInDB[year]] = semestersInDB[yearsInDB[year]].map((data) => {
                if (startDateForAll === null) {
                    startDateForAll = dates[yearsInDB[year]][data].start;
                    endDateForAll = dates[yearsInDB[year]][data].end;
                }
                if (startDateForAll > dates[yearsInDB[year]][data].start)
                    startDateForAll = dates[yearsInDB[year]][data].start;
                if (endDateForAll < dates[yearsInDB[year]][data].end)
                    endDateForAll = dates[yearsInDB[year]][data].end;

                return { value: season[data], label: season[data], start: dates[yearsInDB[year]][data].start, end: dates[yearsInDB[year]][data].end }
            });

            semestersInDB[yearsInDB[year]].unshift({ value: 'all', label: 'All', start: startDateForAll, end: endDateForAll });
        }

        setSemestersInDB(semestersInDB);

        let count = 0;
        const semester = getCurrentSemester();

        let intervalId = setInterval(() => {
            const currYear = dayjs().year();

            if (semestersInDB[currYear] !== undefined) {
                for (let i = 0; i < semestersInDB[currYear].length; i++) {
                    if (semestersInDB[currYear][i].label === semester) {
                        setSemester(semester);
                        clearInterval(intervalId);
                    }
                }
            }

            if (count++ > 5) {
                clearInterval(intervalId);
            }
        }, 200);
    }

    // year, semester update
    React.useEffect(() => {
        if (year === null || semester === null || year === undefined || semester === undefined || year === "" || semester === "") {
            return;
        }
        if (courseListLabel === undefined || courseListLabel === null || courseListLabel.length === 0) {
            return;
        }
        // need to set courseValueLabel here because it is used in the AllInfoBox component
        let courseValueLabel = [];
        let uniqueCourseUuids = new Set();

        courseListLabel.map((course) => {
            course.course_semesters.forEach((course_semester) => {
                if (course_semester.semester.year === year && (season[course_semester.semester.season] === semester) || semester === 'all') {
                    uniqueCourseUuids.add(course.uuid);
                }
            });
            return null;
        });
        courseValueLabel = Array.from(uniqueCourseUuids).map((courseId) => {
            const course = courseListLabel.find((c) => c.uuid === courseId);
            return { value: course.uuid, label: course.code + " " + course.course_title };
        });
        courseValueLabel.unshift({ value: 'all', label: 'All' });
        courseValueLabel.unshift({ value: 'none', label: 'None' });
        setCourseValueLabel(courseValueLabel);

    }, [year, semester]);

    // courseValueLabel: select에 나오는 코스 리스트
    // courseListLabel: 코스 코드, 이름만 있는 리스트    
    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Dashboard');

        async function fetchCourseList() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/all/list`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                let courseValueLabel = [];
                let uniqueCourseUuids = new Set();
                const currentYear = dayjs().year();
                const semester = getCurrentSemester();
                response.data.all_courses.map((course) => {
                    course.course_semesters.forEach((course_semester) => {
                        if (course_semester.semester.year === currentYear && (season[course_semester.semester.season] === semester || semester === 'all')) {
                            uniqueCourseUuids.add(course.uuid);
                        }});
                    return null;
                });

                courseValueLabel = Array.from(uniqueCourseUuids).map((courseId) => {
                    const course = response.data.all_courses.find((c) => c.uuid === courseId);
                    return { value: course.uuid, label: course.code + " " + course.course_title };
                });
                courseValueLabel.unshift({ value: 'all', label: 'All' });
                courseValueLabel.unshift({ value: 'none', label: 'None' });
                setCourseValueLabel(courseValueLabel);
                setCourseListLabel(response.data.all_courses);
                setLoading(false);
            }).catch((error) => {
                if (error.response.status === 404) {
                    setCourses([]);
                    setLoading(false);
                    setInfoUpdated(false);
                    return;
                } else if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setLoading(false);
                setInfoUpdated(false);
                setErrorAlertMessage(error.response.data.message);
                setErrorAlertOpen(true);
            });
        }
        fetchCourseList();
    }, []);

    // 클릭했을 때 해당 코스만 불러오기
    React.useEffect(() => {
        async function fetchCourses() {
            setCourseLoading(true);
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/all?query=${selectedCourse}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setCourses(response.data.all_courses);
                setAttendanceMap(response.data.attendance_map);
                setAttendanceCountMap(response.data.attendance_count_map);
                setNoteCountMap(response.data.note_count_map);
                setEventModulesHashMap(response.data.event_modules_hash_map);
                setEventCountByStudent(response.data.event_count_by_student);
                setCourseLoading(false);
                setInfoUpdated(false);
            }).catch((error) => {
                if (error.response.status === 404) {
                    setCourses([]);
                    setCourseLoading(false);
                    setInfoUpdated(false);
                    return;
                } else if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setCourseLoading(false);
                setInfoUpdated(false);
                setErrorAlertMessage(error.response.data.message);
                setErrorAlertOpen(true);
            });
        };

        if(selectedCourse !== 'none') {
            fetchCourses();
        }
    }, [infoUpdated, selectedCourse]);

    

    React.useEffect(() => {
        async function fetchSemesters() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/semesters`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setSemestersInDB(response.data);
                setSemestersForCreateCourseModal(response.data);
                buildYearsAndSemestersForDropdown(response.data);
            }).catch((error) => {
                if (error.response.status === 404) {
                    setSemestersInDB([]);
                    setSemestersForCreateCourseModal([]);
                    buildYearsAndSemestersForDropdown([]);
                    return;
                } else if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message);
                setErrorAlertOpen(true);
            });
        }

        fetchSemesters();
        
    }, []);

    React.useEffect(() => {
        if (year === null || year === undefined || year === "") {
            setYear(dayjs().year());
        } else if (year !== dayjs().year()) {
            setSemester('');
        }
    }, [year]);

    return (
        <Box m="20px" marginX={3} marginY={1} minHeight="88%" borderBottom={`1px solid ${colors.grey[800]}`}>
            <Header title="DASHBOARD" />
            { loading || courseListLabel === null || yearsInDB === null || semestersInDB === null ? 
                <Loading />
            : <>
                <Box display="flex" justifyContent="left" gap={2} p={0} minHeight={70} mt={-2}>
                    {/* YEAR SELECT */}
                    <Box flex={2}>
                        <Dropdown 
                            maxWidth={300} 
                            maxHeight={3} 
                            reactUseState={year} 
                            setReactUseState={setYear} 
                            inputLabel="Year" 
                            data={yearsInDB} 
                        />
                    </Box>
                    {/* SEMESTER SELECT */}
                    <Box flex={2}>
                        <Dropdown
                            maxWidth={300}
                            maxHeight={3}
                            reactUseState={semester}
                            setReactUseState={setSemester}
                            inputLabel="Semester"
                            data={semestersInDB[year]}
                        />
                    </Box>
                    {/* COURSE SELECT */}
                    <Box flex={3}>
                        <Dropdown
                            maxWidth={300}
                            maxHeight={3}
                            reactUseState={selectedCourse}
                            setReactUseState={setSelectedCourse}
                            inputLabel="Course"
                            data={courseValueLabel}
                            // data={courseListLabel}
                        />
                    </Box>
                    <Box flex={7}></Box>
                    <Box flex={1} display='flex' gap={1} alignItems='center' mr={2}>
                        <Box display='flex' alignItems='center' gap={1}>
                            <FiberManualRecordIcon color='success' />
                            <Typography variant='caption'>Present</Typography>
                        </Box>
                        <Box display='flex' alignItems='center' gap={1}>
                            <FiberManualRecordIcon color='warning' />
                            <Typography variant='caption'>Late</Typography>
                        </Box>
                        <Box display='flex' alignItems='center' gap={1}>
                            <FiberManualRecordIcon color='error' />
                            <Typography variant='caption'>Absent</Typography>
                        </Box>
                        <Box display='flex' alignItems='center' gap={1}>
                            <FiberManualRecordIcon sx={{color: "purple"}} />
                            <Typography variant='caption'>Duplicate</Typography>
                        </Box>
                    </Box>
                </Box>
                {/* ALL INFO CONTAINER */}
                {semester && semester !== "" &&
                    <AllInfoBox
                        year={year}
                        semester={semester}
                        courses={courses}
                        attendance_map={attendance_map}
                        attendance_count_map={attendance_count_map}
                        note_count_map={note_count_map}
                        event_modules_hash_map={event_modules_hash_map}
                        setInfoUpdated={setInfoUpdated}
                        setBackdropOpen={setBackdropOpen}
                        setSuccessAlertOpen={setSuccessAlertOpen}
                        setSuccessAlertMessage={setSuccessAlertMessage}
                        setErrorAlertOpen={setErrorAlertOpen}
                        setErrorAlertMessage={setErrorAlertMessage}
                        semestersInDB={semestersInDB}
                        semestersForCreateCourseModal={semestersForCreateCourseModal}
                        eventCountByStudent={eventCountByStudent}
                        setAttendanceCountMap={setAttendanceCountMap}
                        setAttendanceMap={setAttendanceMap}
                        setNoteCountMap={setNoteCountMap}
                        courseLoading={courseLoading}
                    />
                }
            </>}
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
            <BackdropComp backdropOpen={backdropOpen} handleBackdropClose={handleBackdropClose} />
        </Box>
    )
}

export default Home