import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import Axios from 'axios';
import UploadComp from '../../../components/UploadComp';
import getCookies from '../../../utils/getCookies';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const CreateQuestionsAccordion = ({
    courseId, 
    setBackdropOpen, 
    setSuccessAlertOpen, 
    setErrorAlertOpen, 
    setSuccessAlertMessage,
    setErrorAlertMessage,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [files, setFiles] = React.useState([]);
    const matches = useMediaQuery('(min-width:90rem)');
    const currentUser = getCookies("_auth_state");
    const userId = currentUser ? currentUser.split(',')[0]?.split(':')[1]?.split('"')[1] : null;
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;

    const [questionType, setQuestionType] = React.useState('');

    // for dialog to make sure about school material
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleClickDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleSubmit = async ( event ) => {
        event.preventDefault();

        if (userRole !== "student") {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Only students can create a question!");
            return;
        }

        setBackdropOpen(true);

        const data = new FormData(event.currentTarget);

        if (questionType === "" || questionType === null || questionType === undefined) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please select a question type!");
            setBackdropOpen(false);
            return;
        }

        if (questionType === "school_material" && files.length === 0) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please upload some materials!");
            setBackdropOpen(false);
            return;
        }

        if (data.get('title') === "" || data.get('author') === "") {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Title and Author fields cannot be empty!");
            setBackdropOpen(false);
            return;
        }

        if (data.get('title').length > 255) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Title is too long!");
            setBackdropOpen(false);
            return;
        }

        if (data.get('author').length > 255) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Author name is too long!");
            setBackdropOpen(false);
            return;
        }

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            if (files[i].name !== undefined
                && files[i].name !== null
                && files[i].name !== ""
                && files[i].name.length > 150) {
                setErrorAlertOpen(true);
                setErrorAlertMessage("File name should be less than 150 characters");
                setBackdropOpen(false);
                return;
            }
            formData.append('file', files[i], files[i].name);
        }

        formData.append('title', data.get('title'));
        formData.append('author', data.get('author'));
        formData.append('content', data.get('content'));
        formData.append('type', questionType);
        
        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/students/${userId}/course-questions`, formData, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                'Content-Type': 'multipart/form-data',
            },
        }).then((res) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(res.data.message);
            setBackdropOpen(false);
            window.location.reload();
        }).catch((err) => {
            setErrorAlertOpen(true);
            setErrorAlertMessage(err.response.data.message);
            setBackdropOpen(false);
        });
    };

    return (
        <div style={{
            width: "100%",
            marginBottom: "1rem",
        }}>
            <Accordion
                style={{
                    backgroundColor: colors.primary[750],
                }}
                component="div"
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h6'>Create Question</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="h6" color={colors.grey[300]} sx={{ width: "100%", ml: 1, mb: 1, fontWeight: 700 }}>
                        Note: Your question will only be visible to teachers and admins.
                    </Typography>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                            display: "flex",
                            flexDirection: "column",
                            mr: "1rem",
                        }}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <FormControl 
                            variant="filled" 
                            sx={{ 
                                '& .MuiFilledInput-root': {
                                    backgroundColor: colors.primary[600],
                                    ":focus": {
                                        backgroundColor: 'white',
                                    },
                                },
                            }}
                        >
                            <InputLabel id="demo-simple-select-filled-label">Question Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={questionType}
                                onChange={(event) => {
                                    if (event.target.value === "school_material")
                                        handleClickDialogOpen();
                                    else
                                        setQuestionType(event.target.value);
                                }}
                            >
                                <MenuItem value={''}>None</MenuItem>
                                <MenuItem value={'general_question'}>General Question</MenuItem>
                                <MenuItem value={'school_material'}>School Material</MenuItem>
                            </Select>
                        </FormControl>
                        <Box 
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "1rem",
                                minWidth: "100%",
                            }}
                        >
                            <TextField
                                id="filled-basic"
                                label="Title"
                                variant="filled"
                                name='title'
                                sx={{
                                    flex: "1",
                                    maxWidth: "50%",
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[600],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                            />
                            <TextField
                                id="filled-basic"
                                label="Author"
                                variant="filled"
                                name='author'
                                sx={{
                                    flex: "1",
                                    maxWidth: "50%",
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[600],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                                defaultValue={localStorage.getItem('userName')}
                                inputProps={{ readOnly: true }}
                            />
                        </Box>
                        <TextField
                            id="filled-basic"
                            label="Content"
                            variant="filled"
                            name='content'
                            fullWidth={true}
                            multiline={true}
                            rows={10}
                            sx={{
                                minWidth: "100%",
                                borderRadius: "5px",
                                '& .MuiFilledInput-root': {
                                    backgroundColor: colors.primary[600],
                                    ":focus": {
                                        backgroundColor: 'white',
                                    },
                                },
                            }}
                        />
                        <Box
                            display="flex"
                            minWidth="100%"
                            flexDirection="column"
                            justifyContent="flex-start"
                            sx={{
                                gap: "1rem",
                                ...matches && {
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                },
                            }}
                        >
                            <UploadComp files={files} setFiles={setFiles} />
                        </Box>
                        <Button
                            type="submit"
                            variant="contained"
                            style={{
                                backgroundColor: colors.primary[300],
                                color: "white",
                                float: "right",
                                marginTop: "0.5rem",
                                marginBottom: "1rem",
                                width: "10rem",
                            }}
                        >
                            Create
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{ fontSize: '16px', fontWeight: 'bold', maxWidth: '500px' }}>
                    {"Select this question type to upload your school materials that you wish to share with your teacher."}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setQuestionType(''); handleDialogClose();}}>No</Button>
                    <Button onClick={() => {setQuestionType('school_material'); handleDialogClose();}} color="success" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CreateQuestionsAccordion