import React from 'react';
import { useDropzone } from 'react-dropzone';
import AddIcon from '@mui/icons-material/Add';

const FileUpload = ({
    setHWAnswerFiles,
    hwAnswerFiles,
    hw_question_id,
}) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            // only allow image files
            if (!acceptedFiles[0].type.startsWith("image/")) {
                alert("Please upload an image file");
                return;
            }

            if (hwAnswerFiles[hw_question_id]?.prev_file && !window.confirm("Are you sure you want to replace the file?")) {
                return;
            }

            if (setHWAnswerFiles !== null) {
                setHWAnswerFiles((prev) => ({
                    ...prev,
                    [hw_question_id]: {
                        file: acceptedFiles[0],
                        prev_file: hwAnswerFiles[hw_question_id]?.prev_file || null,
                    },
                }));
            }
        },
    });

    const [preview, setPreview] = React.useState(undefined);
    const [prevPreview, setPrevPreview] = React.useState(undefined);

    React.useEffect(() => {
        if (!hwAnswerFiles || !hwAnswerFiles[hw_question_id]) {
            setPreview(undefined)
            return
        }

        if (!hwAnswerFiles[hw_question_id]?.file) {
            if (hwAnswerFiles[hw_question_id]?.prev_file) {
                setPrevPreview(hwAnswerFiles[hw_question_id]?.prev_file.file_url);
            }

            setPreview(undefined)
            return
        }

        // const objectUrl = URL.createObjectURL(uploadedFiles[0])
        const objectUrl = URL.createObjectURL(hwAnswerFiles[hw_question_id].file)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [hwAnswerFiles])
      
    return (
        <div 
            {...getRootProps()}
            style={{
                border: "2px dashed #000",
                borderRadius: "5px",
                padding: "20px",
                textAlign: "center",
                cursor: "pointer",
                marginTop: "10px",
                marginBottom: "10px",
                width: "100%",
            }}
        >
            <input {...getInputProps()} />
            <AddIcon fontSize='large' />
            <p
                style={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                }}
            >
                Drag 'n' drop an image here, or click to select an image
            </p>
            {hwAnswerFiles && setHWAnswerFiles && <>
                <ul 
                    style={{
                        listStyle: "none",
                        padding: "0",
                        margin: "0",
                    }}
                >
                    {hwAnswerFiles[hw_question_id]?.file ? (
                        <li key={hwAnswerFiles[hw_question_id].file.name}>
                            {hwAnswerFiles[hw_question_id].file.name}
                        </li>
                    ) : !hwAnswerFiles[hw_question_id]?.file && hwAnswerFiles[hw_question_id]?.prev_file ? (
                        <li key={hwAnswerFiles[hw_question_id].prev_file.file_name}>
                            {hwAnswerFiles[hw_question_id].prev_file.file_name}
                        </li>
                    ) : null}
                </ul>
                {hwAnswerFiles[hw_question_id]?.file && preview ? (
                    <img src={preview} alt="preview" style={{ width: "200px", height: "200px", }} />
                ) : !hwAnswerFiles[hw_question_id]?.file && prevPreview ? (
                    <img src={prevPreview} alt="preview" style={{ width: "200px", height: "200px", }} />
                ) : null}
            </>}
        </div>
    );
};

export default FileUpload;
