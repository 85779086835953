import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Dropdown from '../../../components/dropdown/Dropdown';
import Axios from 'axios';

const ClassMaterialDeleteModal = ({
    courseId,
    classMaterials,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
    setBackdropOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [classMaterial, setClassMaterial] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);;
    const handleClose = () => setOpen(false);

    const handleDelete = async (event) => {
        event.preventDefault();
        setBackdropOpen(true);

        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/class-materials/${classMaterial}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((res) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(res.data.message);
        }).catch((error) => {
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.message || error.response.data.error);
        }).finally(() => {
            setBackdropOpen(false);
            handleClose();
            window.location.reload();
        });
    };


    return (
        <div>
            <Button
                type="submit"
                variant="contained"
                style={{
                    backgroundColor: colors.primary[300],
                    color: "white",
                    width: "14rem",
                    marginTop: "0.5rem",
                    minHeight: "3rem"
                }}
                size='small'
                onClick={handleOpen}
            >
                Delete Class Materials
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                            <Typography variant="h6" fontWeight="bold" color={colors.primary[450]}>
                                Delete Class Materials
                            </Typography>
                            <Dropdown 
                                reactUseState={classMaterial}
                                setReactUseState={setClassMaterial}
                                inputLabel="Select a Class Material"
                                data={classMaterials !== null ? 
                                    classMaterials.map((item) => {
                                        return {
                                            value: item.id,
                                            label: item.name
                                        }
                                    }) : []
                                }
                            />
                            <Button
                                type="button"
                                variant="contained"
                                style={{
                                    backgroundColor: colors.primary[300],
                                    color: "white",
                                    marginTop: "0.5rem",
                                    width: "10rem",
                                }}
                                onClick={(e) => handleDelete(e)}
                            >
                                Delete
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default ClassMaterialDeleteModal