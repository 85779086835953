import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { Box, useTheme, Typography } from "@mui/material";
import { FileUploadOutlined } from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { tokens } from "../../../theme";
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import Axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';


export default function UpdateModal({ 
    courseId, 
    homework,
    students,
    userRole,
    studentsStartEndDateMap,
    authorDisabledForAdminActions, 
    setBackdropOpen, 
    setSuccessAlertOpen, 
    setErrorAlertOpen, 
    setSuccessAlertMessage,
    setErrorAlertMessage,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const lecture_start_date = homework.event.start.split('T')[0];
    let current_students = [];

    for (let i = 0; i < students.length; i++) {       
        const end_date = dayjs(studentsStartEndDateMap[students[i].id].end_date);
        const start_date = dayjs(studentsStartEndDateMap[students[i].id].start_date);        

        if (dayjs(lecture_start_date).isAfter(end_date) || dayjs(lecture_start_date).isBefore(start_date)) {
            // student was not in the class when the homework was posted
            continue;
        }
        current_students.push(students[i]);        
    }

    let exclude_students = [];
    let unexclude_students = [];
    if (homework.exclude_student !== null) {
        const exclude_student = homework.exclude_student.split('|');
        exclude_students = exclude_student;
        unexclude_students = homework.unexclude_student.split('|');
    }
    
    let exclude_candidates = [];
    let unexclude_candidates = [];
    let current_students_id = current_students.map(student => student.id);

    for (let i = 0; i < students.length; i++) {
        if (!current_students_id.includes(students[i].id))
            continue;

        // a student is not in both exclude_students and unexclude_students,
        // we should consider this student as a unexclude candidate
       
        if (!exclude_students.includes(students[i].id.toString()) && !unexclude_students.includes(students[i].id.toString())) {
            unexclude_candidates.push(students[i]);
            continue;
        }
        
        if (!exclude_students.includes(students[i].id.toString())) {
            exclude_candidates.push(students[i]);
        } else if (exclude_students.includes(students[i].id.toString())) {
            unexclude_candidates.push(students[i]);
        }
    }

    // for homework
    const [dueDate, setDueDate] = React.useState(homework.due_date ? moment(homework.due_date) : null);
    const [files, setFiles] = React.useState(homework.hw_files ? homework.hw_files : []);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [newExcludeStudents, setnewExcludeStudents] = React.useState([]);
    const [newUnexcludeStudents, setNewUnexcludeStudents] = React.useState([]);

    const handleFileRemove = (event, index) => {
        event.preventDefault();
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setBackdropOpen(true);

        let selected_exclude_students = [];
        // exclude selected students
        if (newExcludeStudents !== null && newExcludeStudents.length > 0) {
            for (let i = 0; i < newExcludeStudents.length; i++) {
                selected_exclude_students += newExcludeStudents[i].id;
                if (i !== newExcludeStudents.length - 1) {
                    selected_exclude_students += "|";
                }
            }
        }
       
        let selected_unexclude_students = [];
        // back from excluded to homework
        if (newUnexcludeStudents !== null && newUnexcludeStudents.length > 0) {
            for (let i = 0; i < newUnexcludeStudents.length; i++) {
                selected_unexclude_students += newUnexcludeStudents[i].id;
                if (i !== newUnexcludeStudents.length - 1) {
                    selected_unexclude_students += "|";
                }
            }
        }

        const data = new FormData(event.currentTarget);

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            if (files[i] instanceof File) {
                if (files[i].name !== undefined 
                    && files[i].name !== null 
                    && files[i].name !== ""
                    && files[i].name.length > 150) {
                    setErrorAlertMessage("File name should be less than 150 characters!");
                    setErrorAlertOpen(true);
                    setBackdropOpen(false);
                    return;
                }
                formData.append('file', files[i], files[i].name);
            } else
                formData.append('old_files', files[i].name);
        }

        formData.append('title', data.get('title'));
        formData.append('posted_by', data.get('author'));
        formData.append('content', data.get('instructions'));
        formData.append('due_date', dueDate);
        formData.append('exclude_student', selected_exclude_students);
        formData.append('unexclude_student', selected_unexclude_students);
                     
        
        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/events/${homework.event_id}/homeworks/${homework.id}`, formData, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            setSuccessAlertMessage("Homework updated successfully!");
            setSuccessAlertOpen(true);
            setBackdropOpen(false);
            window.location.reload();
        }).catch((error) => {
            console.log(error);
            setBackdropOpen(false);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    };

    const handleUpload = (event) => {
        setFiles([...files, ...Array.from(event.target.files)]);
    };

    return (
        <div>
            <Button onClick={handleOpen} sx={{ p: 1, width: "100%" }}>Update</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                                display: "flex",
                                flexDirection: "column",
                                mr: "1rem",
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                        >
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "1rem",
                                minWidth: "100%",
                            }}>
                                <TextField
                                    id="filled-basic"
                                    label="Title"
                                    variant="filled"
                                    name='title'
                                    sx={{
                                        flex: "1",
                                        maxWidth: "50%",
                                        '& .MuiFilledInput-root': {
                                            backgroundColor: colors.primary[650],
                                            ":focus": {
                                                backgroundColor: 'white',
                                            },
                                        },
                                    }}
                                    defaultValue={homework.title}
                                />
                                <TextField
                                    id="filled-basic"
                                    label="Author"
                                    variant="filled"
                                    name='author'
                                    sx={{
                                        flex: "1",
                                        maxWidth: "50%",
                                        '& .MuiFilledInput-root': {
                                            backgroundColor: colors.primary[650],
                                            ":focus": {
                                                backgroundColor: 'white',
                                            },
                                        },
                                    }}
                                    defaultValue={localStorage.getItem('userName')}
                                    disabled={authorDisabledForAdminActions}
                                />
                            </Box>
                            
                        {userRole === 'admin' ? 
                            (
                            <>
                                <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "1rem",
                                minWidth: "100%",
                                }}>
                                    {/* a list of students*/}                                
                                    <Autocomplete
                                        multiple
                                        limitTags={4}
                                        id="student-list"
                                        options={exclude_candidates ? exclude_candidates : []}
                                        getOptionLabel={(exclude_candidates) => exclude_candidates.first_name + " " + exclude_candidates.last_name}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="추가로 숙제에서 제외할 학생을 선택하세요."
                                            />
                                        )}
                                        sx={{ width: "100%" }}                                
                                        onChange={(event, value) => {
                                            setnewExcludeStudents(value);
                                        }}                                
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                    />
                                </Box>
                                <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "1rem",
                                minWidth: "100%",
                                }}>
                                    {/* a list of students*/}
                                    <Autocomplete
                                        multiple
                                        limitTags={4}
                                        id="student-list"
                                        options={unexclude_candidates ? unexclude_candidates : []}
                                        getOptionLabel={(unexclude_candidates) => unexclude_candidates.first_name + " " + unexclude_candidates.last_name}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="숙제를 부여하고 싶은 학생을 선택하세요."                                                
                                            />
                                        )}
                                        sx={{ width: "100%" }}                                
                                        onChange={(event, value) => {
                                            setNewUnexcludeStudents(value);
                                        }}                                
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                    />
                                </Box>
                                </>
                            ) : (
                            <></>
                            )
                        }
                            <TextField
                                id="filled-basic"
                                label="Instructions"
                                variant="filled"
                                name='instructions'
                                fullWidth={true}
                                multiline={true}
                                rows={20}
                                sx={{
                                    minWidth: "100%",
                                    borderRadius: "5px",
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[650],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                                defaultValue={homework.content}
                            />
                            <Box
                                display="flex"
                                minWidth="100%"
                                flexDirection="row"
                                justifyContent="space-between"
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        mb={-2}
                                    >
                                        <Typography variant="h6" fontWeight="bold" color={colors.primary[450]}>
                                            Upload Files
                                        </Typography>
                                        <IconButton component="label">
                                            <FileUploadOutlined />
                                            <input
                                                multiple={true}
                                                styles={{display:"none"}}
                                                type="file"
                                                hidden
                                                onChange={handleUpload}
                                                name="[licenseFile]"
                                            />
                                        </IconButton>
                                    </Box>
                                    <Box
                                        display="flex"
                                        minWidth="100%"
                                        flexDirection="column"
                                    >
                                        {files.length > 0 && files.map((file, index) => {
                                            return (
                                                <Box key={index} display="flex" alignItems="center">
                                                    <Typography variant="body1" color={colors.primary[450]}>
                                                        {file.name}
                                                    </Typography>
                                                    <IconButton onClick={(e) => handleFileRemove(e, index)}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Box>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DateTimePicker
                                        label="Due Date"
                                        value={dueDate}
                                        onChange={(newValue) => setDueDate(newValue)}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{
                                    backgroundColor: colors.primary[300],
                                    color: "white",
                                    float: "right",
                                    marginTop: "0.5rem",
                                    marginBottom: "1rem",
                                    width: "10rem",
                                }}
                            >
                                Update
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}