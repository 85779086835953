import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useOutletContext } from "react-router-dom";
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { tokens } from "../../theme";
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const ResetPassword = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigateTo = useNavigate();
    const [verified, setVerified] = React.useState(false);
    const [opt_input, setOPTInput] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');

    const otpForForgotPassword = useOutletContext().otpForForgotPassword;
    const emailForForgotPassword = useOutletContext().emailForForgotPassword;

    const handleVerify = () => {
        if (opt_input === null || opt_input === undefined || opt_input === "") {
            alert("OTP is required");
            return;
        }

        if (opt_input.length !== 6) {
            alert("OTP must be 6 characters");
            return;
        }

        if (opt_input !== otpForForgotPassword) {
            alert("OTP is incorrect");
            return;
        }

        setVerified(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password.length < 8) {
            alert("Password must be at least 8 characters long");
            return;
        } else if (password.length > 30) {
            alert("Password must be less than 30 characters");
            return;
        } else if (password !== confirmPassword) {
            alert("Passwords do not match!");
            return;
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/reset-password`, {
            email: emailForForgotPassword,
            password: password,
        }).then((response) => {
            alert(response.data.message);
            navigateTo('/signin');
        }).catch((error) => {
            alert(error.response.data.message);
        });
    };

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div></div>
            <div>
                <Typography variant="h2" style={{ textAlign: 'center' }}>
                    Reset Password
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '24vw', padding: '1rem' }}>
                    {verified ? (<>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Confirm Password"
                            type="password"
                            id="confirmPassword"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                        />
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1, mb: 2 }}
                            color='secondary'
                            onClick={handleSubmit}
                        >
                            <Typography color={colors.primary[400]}>Reset Password</Typography>
                        </Button>
                    </>) : (<>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="opt"
                            label="OTP"
                            helperText="Check your email for the OTP"
                            autoComplete="opt"
                            onChange={(e) => setOPTInput(e.target.value)}
                            value={opt_input}
                        />
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1, mb: 2 }}
                            color='secondary'
                            onClick={handleVerify}
                        >
                            <Typography color={colors.primary[400]}>Verify OTP</Typography>
                        </Button>
                    </>)}
                    <Grid container>
                        <Grid item>
                            <Link href="/signin" variant="body2">
                                {"Go Back to Sign In"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div style={{display: "flex", padding: "2rem", gap: "1rem"}}>
                <img
                    alt="ETT logo"
                    width="160px"
                    height="60px"
                    src={`../../imgs/ETT_logo_tran.png`}
                    style={{margin: "auto"}}
                />
                <CloseIcon fontSize='large' sx={{ m: "auto" }} />
                <img
                    alt="solple logo"
                    width="200px"
                    height="64px"
                    src={`../../imgs/aone-logo.png`}
                    style={{margin: "auto"}}
                />
            </div>
        </Box>
    )
}

export default ResetPassword