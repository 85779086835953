import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ScoreModal = ({ 
    hw_answer,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
    setBackdropOpen,
    setUpdatedHWAnswers,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [feedbackOption, setFeedbackOption] = React.useState("");
    const [feedback, setFeedback] = React.useState(hw_answer.feedback ? hw_answer.feedback : "");
    const [score, setScore] = React.useState(hw_answer.given_score ? hw_answer.given_score : "");
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (score === undefined || score === null || score === "") {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please enter a score.");
            return;
        }

        let _feedback = null;

        if (feedbackOption === 1) {
            _feedback = "I will go over this in class!";
        } else if (feedbackOption === 2) {
            if (feedback === "") {
                setErrorAlertOpen(true);
                setErrorAlertMessage("Please enter feedback.");
                return;
            }

            _feedback = feedback;
        }

        setBackdropOpen(true);
        const total_score = 100;

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/hw-answers/${hw_answer.id}/grade`, {
            total_score: total_score,
            given_score: score,
            feedback: _feedback,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            }
        }).then((response) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message);
            setBackdropOpen(false);
            setUpdatedHWAnswers(true);
            handleClose();
        }).catch((error) => {
            console.log(error.reponse.data.messagse);
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.message);
            setBackdropOpen(false);
        });
    };

    return (
        <div>
            <Button
                type="submit"
                variant="contained"
                style={{
                    backgroundColor: hw_answer.given_score ? colors.primary[300] : 'info',
                    color: "white",
                    width: "7rem",
                    marginTop: "1rem",
                }}
                onClick={handleOpen}
            >
                {hw_answer.given_score ? "Edit" : "Set"} Grade
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={2}
                            component="form"
                            onSubmit={(e) => handleSubmit(e)}
                        >
                            <FormControl sx={{ width: 200 }} >
                                <InputLabel id="demo-simple-select-label">Score (%)</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={score}
                                    label="Score"
                                    onChange={(e) => setScore(e.target.value)}
                                >
                                    <MenuItem value={0}>0%</MenuItem>
                                    <MenuItem value={10}>10%</MenuItem>
                                    <MenuItem value={20}>20%</MenuItem>
                                    <MenuItem value={30}>30%</MenuItem>
                                    <MenuItem value={40}>40%</MenuItem>
                                    <MenuItem value={50}>50%</MenuItem>
                                    <MenuItem value={60}>60%</MenuItem>
                                    <MenuItem value={70}>70%</MenuItem>
                                    <MenuItem value={80}>80%</MenuItem>
                                    <MenuItem value={90}>90%</MenuItem>
                                    <MenuItem value={100}>100%</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl sx={{ width: 200 }} >
                                <InputLabel id="demo-simple-select-label">Feedback Options</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={feedbackOption}
                                    label="Feedback"
                                    onChange={(e) => setFeedbackOption(e.target.value)}
                                >
                                    <MenuItem value={0}>None</MenuItem>
                                    <MenuItem value={1}>
                                        "I will go over this in class!"
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        Type in feedback below
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <TextField 
                                disabled={feedbackOption !== 2}
                                multiline
                                rows={10}
                                variant="outlined"
                                type="text"
                                label="Feedback"
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                                sx={{ width: '100%' }}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                style={{
                                    backgroundColor: colors.primary[300],
                                    color: "white",
                                    marginTop: "0.5rem",
                                    width: "14rem",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                }}
                            >
                                Set Score & Feedback
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default ScoreModal