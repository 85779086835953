import React from 'react'
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { Box, useTheme, Typography, InputBase, IconButton } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { tokens } from "../../theme";
import Axios from 'axios';
import Backdrop from '../../components/Backdrop';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { TextField } from "@mui/material";
import dayjs from 'dayjs';

const AddStudentModal = ({ 
    setAnchorEl, 
    potentialCourse,
    fetchPotentialCourses, 
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    const userName = localStorage.getItem("userName") ? localStorage.getItem("userName") : "";

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); setAnchorEl(null); };

    const [search, setSearch] = React.useState(''); // search by consultation number
    const [emailSearch, setEmailSearch] = React.useState(''); // search by parent email
    const [studentEmailSearch, setStudentEmailSearch] = React.useState(''); // search by student email

    const [students, setStudents] = React.useState([]);
    // const [date, setDate] = React.useState(dayjs());    

    React.useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Enter') {
                // find the element with the focus
                const focusedElement = document.activeElement;
                if (focusedElement.id === 'consultation_number') {
                    fetchStudents('enter', 'consultation_number', focusedElement.value);
                } else if (focusedElement.id === 'parent_email') {
                    fetchStudents('enter', 'parent_email', focusedElement.value);
                } else if (focusedElement.id === 'student_email') {
                    fetchStudents('enter', 'student_email', focusedElement.value);
                }
            }
        });

        return () => {
            document.removeEventListener('keydown', () => {});
        }
    }, []);

    async function fetchStudents(method, param, value) {
        if (method === 'enter' && (value === '' || value === undefined || value === null)) {
            setErrorAlertMessage("Please enter a search value");
            setErrorAlertOpen(true);
            return;
        }
        if (method === 'click' 
            && (search === '' || search === undefined || search === null) 
            && (emailSearch === '' || emailSearch === undefined || emailSearch === null) 
            && (studentEmailSearch === '' || studentEmailSearch === undefined || studentEmailSearch === null)) {
            setErrorAlertMessage("Please enter a search value");
            setErrorAlertOpen(true);
            return;
        }

        setBackdropOpen(true);

        let url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress`;
        if (param === 'consultation_number') {
            url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress?consultation_number=`;
            url += method === 'enter' ? value : search;
        } else if (param === 'parent_email') {
            url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress?parent_email=`;
            url += method === 'enter' ? value : emailSearch;
        } else if (param === 'student_email') {
            url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress?student_email=`;
            url += method === 'enter' ? value : studentEmailSearch;
        } else if (param === 'num_of_people') {
            url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress?num_of_people=${value}`;
        }

        await Axios.get(url, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setStudents(response.data);

            // set searches to empty
            emptySearch();
        }).catch((error) => {
            if (error.response.status === 404) {
                setStudents([]);
                setErrorAlertMessage("No student found");
                setErrorAlertOpen(true);
                setBackdropOpen(false);
                return;
            }
            setErrorAlertMessage(error.response.data.message || "Something went wrong");
            setErrorAlertOpen(true);
        });

        setBackdropOpen(false);
    }

    function emptySearch() {
        setSearch('');
        setEmailSearch('');
        setStudentEmailSearch('');

        const consultationNumElement = document.getElementById('consultation_number');
        const parentEmailElement = document.getElementById('parent_email');
        const studentEmailElement = document.getElementById('student_email');
        if (consultationNumElement) {
            consultationNumElement.value = '';
        }
        if (parentEmailElement) {
            parentEmailElement.value = '';
        }
        if (studentEmailElement) {
            studentEmailElement.value = '';
        }
    }

    const handleAddStudent = async (e, student) => {
        e.preventDefault();

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${potentialCourse.uuid}/students/${student.uuid}`, {
            email_sent_at: dayjs(),
            email_author: userName
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage(response.data.message);
            setSuccessAlertOpen(true);
            fetchPotentialCourses();
            // setTimeout(() => {
            //     // scroll to the updated potential course
            //     window.location.href = `/admin/potentialCourses?scrollTo=${potentialCourse.uuid}`;
            // }, 500);            
        }).catch((error) => {
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        height: 700,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Button 
                onClick={handleOpen} 
                variant="outlined"
                color="primary"
                sx={{ 
                    width: "100%", 
                    border: "1px solid transparent",
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                }}
            >
                Add Student
            </Button>
            <Modal
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                open={open}
                onClose={handleClose}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]}>
                            Add a student to {potentialCourse.course_title}
                        </Typography>
                        <Typography variant="body1" color={colors.redAccent[400]}>
                            (Adding a student using this modal will not trigger sending an email to the student's parent.)
                        </Typography>
                        <Box 
                            display="flex" 
                            justifyContent="left" 
                            gap={2} 
                            p={0} 
                            minHeight={40} 
                            my={2}
                        >
                            {/* SEARCH BAR */}
                            <Box
                                display="flex"
                                backgroundColor={colors.primary[600]}
                                borderRadius="3px"
                                boxShadow={10}
                                mb={2}
                                maxWidth={300}
                                minWidth={260}
                            >
                                <InputBase 
                                    sx={{ ml: 2, flex: 1 }} 
                                    onChange={(e) => setSearch(e.target.value)} 
                                    placeholder="Search by consultation number" 
                                    id='consultation_number'
                                />
                                <IconButton 
                                    type="button" 
                                    sx={{ p: 1 }}
                                    onClick={() => fetchStudents('click', 'consultation_number')}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Box>

                            <Box
                                display="flex"
                                backgroundColor={colors.primary[600]}
                                borderRadius="3px"
                                boxShadow={10}
                                mb={2}
                                maxWidth={300}
                                minWidth={260}
                            >
                                <InputBase 
                                    sx={{ ml: 2, flex: 1 }} 
                                    onChange={(e) => setEmailSearch(e.target.value)} 
                                    placeholder="Search by parent email" 
                                    id='parent_email'
                                />
                                <IconButton 
                                    type="button" 
                                    sx={{ p: 1 }}
                                    onClick={() => fetchStudents('click', 'parent_email')}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Box>
                            <Box
                                display="flex"
                                backgroundColor={colors.primary[600]}
                                borderRadius="3px"
                                boxShadow={10}
                                mb={2}
                                maxWidth={300}
                                minWidth={260}
                            >
                                <InputBase 
                                    sx={{ ml: 2, flex: 1 }} 
                                    onChange={(e) => setStudentEmailSearch(e.target.value)} 
                                    placeholder="Search by student email" 
                                    id='student_email'
                                />
                                <IconButton 
                                    type="button" 
                                    sx={{ p: 1 }}
                                    onClick={() => fetchStudents('click', 'student_email')}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Box>                        
                        </Box>
                        {/* Date, author 추가칸: 필요시 추가할 것 */}
                        {/* <Box 
                            display="flex" 
                            justifyContent="left" 
                            gap={2} 
                            p={0} 
                            minHeight={40} 
                            my={2}
                        >
                            <Box mt={-1} mb={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            label="Date"
                                            value={date}
                                            onChange={(newValue) => setDate(newValue)}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 2,
                                    alignItems: 'center',
                                }}
                            >
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Author"
                                    onChange={(e) => setAuthor(e.target.value)}
                                    value={author}
                                />
                            </Box>
                        </Box> */}
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: 450,
                                minWidth: "100%",
                                maxWidth: "100%",
                                overflowX: 'scroll',
                                border: `1px solid ${colors.primary[400]}`,
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'sticky',
                                    top: 0,
                                    left: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    borderRight: `1px solid ${colors.primary[400]}`,
                                    width: 120,
                                    minWidth: 120,
                                    zIndex: 1,
                                }}
                            >
                                <Box
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: "100%",
                                        height: 40,
                                        backgroundColor: colors.primary[900],
                                        borderBottom: `1px solid ${colors.primary[400]}`,
                                    }}
                                >
                                    <Typography variant="h6">
                                        안내 번호
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: "100%",
                                        height: 40,
                                        backgroundColor: colors.primary[900],
                                        borderBottom: `1px solid ${colors.primary[400]}`,
                                    }}
                                >
                                    <Typography variant="h6">
                                        학생 이름
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: "100%",
                                        height: 40,
                                        backgroundColor: colors.primary[900],
                                        borderBottom: `1px solid ${colors.primary[400]}`,
                                    }}
                                >
                                    <Typography variant="body1">
                                        학생 이메일
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: "100%",
                                        height: 40,
                                        backgroundColor: colors.primary[900],
                                        borderBottom: `1px solid ${colors.primary[400]}`,
                                    }}
                                >
                                    <Typography variant="body1">
                                        부모님 이름
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: "100%",
                                        height: 40,
                                        backgroundColor: colors.primary[900],
                                        borderBottom: `1px solid ${colors.primary[400]}`,
                                    }}
                                >
                                    <Typography variant="body1">
                                        부모님 이메일
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: "100%",
                                        height: 40,
                                        backgroundColor: colors.primary[900],
                                        borderBottom: `1px solid ${colors.primary[400]}`,
                                    }}
                                >
                                    <Typography variant="body1">
                                        부모님 전화번호
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: "100%",
                                        height: 40,
                                        backgroundColor: colors.primary[900],
                                    }}
                                >
                                    <Typography variant="body1"></Typography>
                                </Box>
                            </Box>
                            {students?.length > 0 && students.map((student, index) => {
                                return (
                                    <Box
                                        key={student.uuid}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: '1rem',
                                            borderRight: `1px solid ${colors.primary[400]}`,
                                            p: 2,
                                            width: 300,
                                            minWidth: 300,
                                        }}
                                    >
                                        <Typography variant="h4" flex={1} fontWeight={"bold"}>
                                            {student.consultation_number ? student.consultation_number : 'N/A'}
                                        </Typography>
                                        <Typography variant="h6" flex={1}>
                                            {student.first_name ? student.first_name : ''} {student.last_name ? student.last_name : ''}
                                        </Typography>
                                        <Typography variant="body1" flex={1}>
                                            {student.email ? student.student_email : 'N/A'}
                                        </Typography>
                                        <Typography variant="body1" flex={1}>
                                            {student.parent_name ? student.parent_name : 'N/A'}
                                        </Typography>
                                        <Typography variant="body1" flex={1}>
                                            {student.parent_email ? student.parent_email : 'N/A'}
                                        </Typography>
                                        <Typography variant="body1" flex={1}>
                                            {student.parent_phone ? student.parent_phone : 'N/A'}
                                        </Typography>
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            sx={{
                                                mt: "auto",
                                            }}
                                            onClick={(e) => handleAddStudent(e, student)}
                                        >
                                            Add
                                        </Button>
                                    </Box>
                                );
                            })}
                        </Box>
                        <Box mt={"auto"} display="flex" flexDirection="column" gap={3} width="100%" pt={2}>
                            <Button
                                type="button"
                                variant="contained"
                                color="error"
                                sx={{ mt: 2, width: "7rem", ml: "auto"}}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                        <Backdrop backdropOpen={backdropOpen} handleBackdropClose={handleBackdropClose} />
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default AddStudentModal