import React from 'react'
import { tokens } from "../../theme";
import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Axios from 'axios';
import { motion, AnimatePresence } from "framer-motion";
// import Dropdown from '../../components/dropdown/Dropdown';
import Loading from '../../components/Loading';
import Alert from '../../components/alert/Alert';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import TextbookCard from './TextbookCard';
import { InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';


const Textbooks = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [loading, setLoading] = React.useState(true);
    // const [search, setSearch] = React.useState('');
    const [textbooks, setTextbooks] = React.useState(null);
    const [search, setSearch] = React.useState('');

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Textbooks');

        async function fetchTextbooks () {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/textbooks`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(async (response) => {
                setTextbooks(response.data);
            }).catch((error) => {
                if (error.response.status === 404) {
                    setTextbooks([]);
                    setLoading(false);
                    return;
                } else if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message);
                setErrorAlertOpen(true);
            });
        }

        fetchTextbooks();
        setLoading(false);
    }, []);

    return (
        <Box m="20px" marginX={3} marginY={3} >
            <Header title="A ONE Library" subtitle="A ONE Institute" />
            { loading || textbooks === null ? 
                <Loading />
            : <>
                <Box display="flex" justifyContent="left" gap={2} p={0} minHeight={70} mb={2}>
                    {/* SEARCH BAR */}
                    <Box
                        display="flex"
                        backgroundColor={colors.primary[600]}
                        borderRadius="3px"
                        boxShadow={10}
                        mb={2}
                        maxWidth={300}
                        minWidth={220}
                    >
                        <InputBase sx={{ ml: 2, flex: 1 }} onChange={(e) => setSearch(e.target.value)} placeholder="Enter Textbook Title" />
                        <IconButton type="button" sx={{ p: 1 }}>
                            <SearchIcon />
                        </IconButton>
                    </Box>

                    {/* GRADE SELECT */}
                    {/* <Dropdown 
                        maxWidth={100} 
                        maxHeight={3} 
                        reactUseState={grade} 
                        setReactUseState={setGrade} 
                        inputLabel="Grade" 
                        data={grades} 
                    /> */}
                </Box>

                {/* TEXTBOOK CARDS */}
                {textbooks && textbooks.length === 0 ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="50vh"
                    >
                        <h1>No textbooks found</h1>
                    </Box>
                ) : (
                    <motion.div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                            rowGap: "1rem",
                            columnGap: "1rem",    
                        }}
                        layout
                    >
                        <AnimatePresence>
                            {textbooks
                                .filter((textbook) => {
                                    return search === '' 
                                        ? textbook 
                                        : textbook.title?.toLowerCase().includes(search.toLowerCase());
                                })
                                .map((textbook) => {
                                return (
                                    <TextbookCard 
                                        textbook={textbook} 
                                        key={textbook.id} 
                                        setErrorAlertMessage={setErrorAlertMessage}
                                        setErrorAlertOpen={setErrorAlertOpen}
                                        setSuccessAlertMessage={setSuccessAlertMessage}
                                        setSuccessAlertOpen={setSuccessAlertOpen}
                                    />
                                );
                            })}
                        </AnimatePresence>
                    </motion.div>
                )}
            </>}
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
        </Box>
    )
}

export default Textbooks