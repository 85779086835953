import * as React from "react";
import { Box, Button, TextField, Typography, ListItemText } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import Axios from 'axios';
import Alert from '../../components/alert/Alert';
import Autocomplete from "./Autocomplete";
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import IconButton from '@mui/material/IconButton';
import { FileUploadOutlined } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


const AddTeacher = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");
    const isNonMobile = useMediaQuery("(min-width:600px)");

    // For auto-filling address
    const [address, setAddress] = React.useState(null);
    const [zip, setZip] = React.useState("");
    const [street, setStreet] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [country, setCountry] = React.useState("");

    // For uploading resume
    const [resume, setResume] = React.useState(null);

    // for teacher type
    const [isPotentialTeacher, setIsPotentialTeacher] = React.useState(false);
    const handleTeacherTypeChange = (event) => {
        setIsPotentialTeacher(event.target.value);
    };

    // for addMemo checkbox
    const [addMemo, setAddMemo] = React.useState(false);
    const handleAddMemoChange = (event) => {
        setAddMemo(event.target.checked);
    };
    const [memoContent, setMemoContent] = React.useState("");

    // for subject availability
    const subjectForAvailability = [
        "English",
        "Math",
        "History",
        "Biology",
        "Chemistry",
        "Physics",
        "Economics",
    ];

    const [selectedSubjects, setSelectedSubjects] = React.useState([]);

    const handleSubjectAvailabilityChange = (event) => {
        const {
            target: { value },
        } = event;
        // On autofill we get a stringified value.
        setSelectedSubjects(
            typeof value === 'string' ? value.split(',') : value
        );
    };    

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Add Teacher');
    }, []);

    React.useEffect(() => {
        if (address) {
            async function getZip() {
                let zip = "";
                await geocodeByPlaceId(address.value.place_id)
                    .then(results => {
                        for (let i = 0; i < results[0].address_components.length; i++) {
                            if (results[0].address_components[i].types[0] === "postal_code") {
                                zip = results[0].address_components[i].long_name;
                                break;
                            }
                        }
                    }).catch(error => console.error(error));

                return zip;
            }
            getZip().then((res) => { setZip(res) });

            let addrSplit = address.label.split(", ");

            setStreet(addrSplit[0]);
            setCity(addrSplit[1]);
            setState(addrSplit[2]);
            setCountry(addrSplit[3]);
            setAddress(null);
        }
    }, [address, zip])

    const reinitialize = (values) => {
        values.first_name = "";
        values.last_name = "";
        values.phone = "";
        values.email = "";
        values.school = "";
        values.major = "";
        values.degree = "";
        values.zelle = "";
        values.venmo = "";
        values.personal_email = "";
        values.interview_eval = "";
        values.subject_in_charge = "";
        setSelectedSubjects([]);
        setStreet("");
        setCity("");
        setState("");
        setCountry("");
        setResume(null);
        setAddress(null);
        setZip("");
        setAddMemo(false);
        setMemoContent("");
        setIsPotentialTeacher(false);
    };

    const handleFormSubmit = async (values) => {
        let teacher_id = null;
        const formData = new FormData();

        if (resume !== null)
            formData.append('resume', resume);

        let first_name = values.first_name && values.first_name !== "" ? values.first_name.trim() : null;
        let last_name = values.last_name && values.last_name !== "" ? values.last_name.trim() : null;
        let phone = values.phone && values.phone !== "" ? values.phone.trim() : null;
        let email = values.email && values.email !== "" ? values.email.toLowerCase().trim() : null;

        if (first_name === null || last_name === null || phone === null) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("First name, last name, and phone are required.");
            return;
        }

        if (!isPotentialTeacher && email === null) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Email is required if the teacher is not a potential teacher.");
            return;
        }

        let errors = false;
        
        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/teachers`, {
            first_name: first_name,
            last_name: last_name,
            phone: phone,
            email: email,
            school: values.school && values.school !== "" ? values.school.trim() : null,
            major: values.major && values.major !== "" ? values.major.trim() : null,
            degree: values.degree && values.degree !== "" ? values.degree.trim() : null,
            subject_in_charge: values.subject_in_charge && values.subject_in_charge !== "" ? values.subject_in_charge.trim() : null,
            subjectForAvailability: selectedSubjects.join('|'),
            zelle: values.zelle && values.zelle !== "" ? values.zelle.trim() : null,
            venmo: values.venmo && values.venmo !== "" ? values.venmo.trim() : null,
            street: street && street !== "" ? street.trim() : null,
            city: city && city !== "" ? city.trim() : null,
            state: state && state !== "" ? state.trim() : null,
            zip: zip && zip !== "" ? zip.trim() : null,
            country: country && country !== "" ? country.trim() : null,
            personal_email: values.personal_email && values.personal_email !== "" ? values.personal_email.toLowerCase().trim() : null,
            interview_eval: isPotentialTeacher ? values.interview_eval : null,
            memo: addMemo ? memoContent : null,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            teacher_id = response.data.teacher_id;
        }).catch((error) => {
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.message);
            errors = true;
        });

        if (errors) return;

        if (resume === null) {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage("Teacher created successfully!");
            reinitialize(values);
            return;
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/teachers/${teacher_id}/resumes`, 
        formData, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message);
            reinitialize(values);
        }).catch((error) => {
            console.log(error.response.data.message);
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.message);
        });
    };

    return (
        <Box m="20px" marginX={3} marginY={3} maxWidth={"70rem"}>
            <Header title="Add Teacher" subtitle="A ONE Institute" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        padding={4}
                        boxShadow={3}
                        borderRadius={3}
                        m={1}
                        bgcolor={colors.primary[600]}
                    >
                        <Typography
                            variant="h4"
                            fontWeight="bold"
                            mb={3}
                        >
                            Teacher Information
                        </Typography>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="First Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.first_name}
                                name="first_name"
                                error={!!touched.first_name && !!errors.first_name}
                                helperText={touched.first_name && errors.first_name}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Last Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.last_name}
                                name="last_name"
                                error={!!touched.last_name && !!errors.last_name}
                                helperText={touched.last_name && errors.last_name}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Phone"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.phone}
                                name="phone"
                                error={!!touched.phone && !!errors.phone}
                                helperText={touched.phone && errors.phone}
                                sx={{ gridColumn: "span 2" }}
                            />

                            <FormControl variant="filled" sx={{ gridColumn: "span 1" }}>
                                <InputLabel id="demo-simple-select-filled-label">Teacher Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={isPotentialTeacher}
                                    onChange={handleTeacherTypeChange}
                                >
                                    <MenuItem value={false}>Teacher</MenuItem>
                                    <MenuItem value={true}>Potential Teacher</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                variant="filled"
                                type="text"
                                label="Subject"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.subject_in_charge}
                                name="subject_in_charge"
                                error={!!touched.subject_in_charge && !!errors.subject_in_charge}
                                helperText={touched.subject_in_charge && errors.subject_in_charge}
                                sx={{ width: "100%" }}
                            />

                            {isPotentialTeacher &&
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="number"
                                    inputProps={{ min: "1", max: "3" }}
                                    label="Interview Evaluation (1: X, 2: △, 3: O)"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.interview_eval}
                                    name="interview_eval"
                                    error={!!touched.interview_eval && !!errors.interview_eval}
                                    helperText={touched.interview_eval && errors.interview_eval}
                                    sx={{ gridColumn: "span 1" }}
                                />
                            }
                            <TextField
                                fullWidth
                                disabled={isPotentialTeacher}
                                variant="filled"
                                type="text"
                                label="Email (for login)"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                id="email"
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Personal Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.personal_email}
                                name="personal_email"
                                error={!!touched.personal_email && !!errors.personal_email}
                                helperText={touched.personal_email && errors.personal_email}
                                sx={{ gridColumn: "span 2" }}
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="School"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.school}
                                name="school"
                                error={!!touched.school && !!errors.school}
                                helperText={touched.school && errors.school}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Major"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.major}
                                name="major"
                                error={!!touched.major && !!errors.major}
                                helperText={touched.major && errors.major}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Degree"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.degree}
                                name="degree"
                                error={!!touched.degree && !!errors.degree}
                                helperText={touched.degree && errors.degree}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <FormControl variant="filled" sx={{ gridColumn: "span 1" }}>
                                <InputLabel id="subject-availability-label">Subjects for Availability</InputLabel>
                                <Select
                                    labelId="subject-availability-label"
                                    id="subject-availability"
                                    multiple
                                    value={selectedSubjects}
                                    onChange={handleSubjectAvailabilityChange}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {subjectForAvailability.map((subject, index) => (
                                        <MenuItem key={index} value={subject}>
                                            <Checkbox checked={selectedSubjects.indexOf(subject) > -1} />
                                            <ListItemText primary={subject} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControlLabel
                                control={<Checkbox checked={addMemo} onChange={handleAddMemoChange} />}
                                label="Want to add a memo?"
                                sx={{ gridColumn: "span 4" }}
                            />
                            {addMemo &&
                                <TextField
                                    id="filled-basic"
                                    label="Memo (optional)"
                                    variant="filled"
                                    name='content'
                                    value={memoContent}
                                    onChange={(e) => setMemoContent(e.target.value)}
                                    fullWidth={true}
                                    multiline={true}
                                    rows={8}
                                    sx={{
                                        gridColumn: "span 4",
                                        borderRadius: "5px",
                                    }}
                                />
                            }

                            <Box sx={{ gridColumn: "span 2", zIndex: "10" }}>
                                <Typography variant="h5" fontWeight="bold" mb={1}>Address Autocomplete</Typography>
                                <Autocomplete address={address} setAddress={setAddress} />
                            </Box>
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Street Address"
                                value={street}
                                onChange={(e) => setStreet(e.target.value)}
                                name="street"
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="City"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                name="city"
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="State"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                name="state"
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Country"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                name="country"
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Zip Code"
                                value={zip}
                                onChange={(e) => setZip(e.target.value)}
                                name="zip"
                                sx={{ gridColumn: "span 1" }}
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Zelle (optional)"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.zelle}
                                name="zelle"
                                error={!!touched.zelle && !!errors.zelle}
                                helperText={touched.zelle && errors.zelle}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Venmo (optional)"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.venmo}
                                name="venmo"
                                error={!!touched.venmo && !!errors.venmo}
                                helperText={touched.venmo && errors.venmo}
                                sx={{ gridColumn: "span 1" }}
                            />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            mt={2}
                        >
                            <Typography variant="h6" fontWeight="bold" color={colors.primary[450]}>
                                Upload Resume
                            </Typography>
                            <IconButton component="label">
                                <FileUploadOutlined />
                                <input
                                    multiple={false}
                                    styles={{display:"none"}}
                                    type="file"
                                    hidden
                                    onChange={(e) => setResume(e.target.files[0])}
                                    name="[licenseFile]"
                                />
                            </IconButton>
                            {resume !== null &&
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body1" color={colors.primary[450]}>
                                        {resume.name}
                                    </Typography>
                                    <IconButton onClick={() => setResume(null)}>
                                        <ClearIcon />
                                    </IconButton>
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="start" mt="20px">
                        <Button type="submit" color="secondary" variant="contained">
                            Create New Teacher
                        </Button>
                    </Box>
                </form>
                )}
            </Formik>
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
        </Box>
    );
};

const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
    first_name: yup.string().optional(),
    last_name: yup.string().optional(),
    phone: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .optional(),
    email: yup.string().email("invalid email").optional(),
    personal_email: yup.string().email("invalid email").optional(),
    interview_eval: yup.number().optional(),
    school: yup.string().optional(),
    major: yup.string().optional(),
    degree: yup.string().optional(),
    zelle: yup.string().optional(),
    venmo: yup.string().optional(),
});

const initialValues = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    personal_email: "",
    interview_eval: "",
    school: "",
    major: "",
    degree: "",
    zelle: "",
    venmo: "",
};

export default AddTeacher;