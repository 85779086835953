import React from 'react'
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import MoreVertIcon from "../../../components/MoreVertIcon"
// import UpdateModal from './UpdateModal';
import DeleteDialog from '../../../components/DeleteDialog';
import CampaignIcon from '@mui/icons-material/Campaign';
import Axios from 'axios';

var options = {
    year: "numeric",
    month: "short",
    day: "numeric"
};

const AnnouncementBox = ({
    courseId, 
    index, 
    userRole, 
    announcement, 
    setAnnouncementsUpdated,
    setBackdropOpen, 
    setSuccessAlertOpen, 
    setErrorAlertOpen, 
    setSuccessAlertMessage, 
    setErrorAlertMessage, 
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for moreVertIcon popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMoreVertIconClick = (event) => {
        if (userRole !== "admin")
            return;
        setAnchorEl(event.currentTarget);
    };
    const handlePopOverClose = () => {
        setAnchorEl(null);
    };
    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    // for delete event
    const handleDeleteAnnouncement = () => {
        setAnchorEl(null);
        setBackdropOpen(true);
        Axios.delete(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/announcements/${announcement.id}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((res) => {
            setSuccessAlertMessage(res.data.message);
            setSuccessAlertOpen(true);
            setBackdropOpen(false);
            setAnnouncementsUpdated(true);
        }).catch((err) => {
            console.log(err);
            setErrorAlertMessage(err.response.data.message);
            setErrorAlertOpen(true);
            setBackdropOpen(false);
        });
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: colors.primary[600],
                borderRadius: "5px",
                border: "0.1px solid #a3a3a3",
                mb: "1.8rem",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    borderBottom: "0.1px solid #a3a3a3",
                    py: "0.5rem",
                    px: "1rem",
                    alignItems: "center",
                    borderRadius: "5px 5px 0px 0px",
                    backgroundColor: colors.primary[750],
                }}
            >
                <Avatar sx={{ width: 30, height: 30 }} >
                    <CampaignIcon />
                </Avatar>
                <Typography variant="h5" sx={{my: "auto", ml: "1rem"}}>
                    Announcement {index}
                </Typography>
                <Box sx={{ml: "auto"}}>
                    <MoreVertIcon handleClick={handleMoreVertIconClick} />
                    <Popover
                        id={popoverId}
                        open={popoverOpen}
                        anchorEl={anchorEl}
                        onClose={handlePopOverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        {userRole === "admin" &&
                            <>
                            {/* <UpdateModal
                                courseId={courseId} 
                                announcement={announcement} 
                                authorDisabledForAdminActions={authorDisabledForAdminActions}
                                setBackdropOpen={setBackdropOpen}
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                setErrorAlertOpen={setErrorAlertOpen}
                                setSuccessAlertMessage={setSuccessAlertMessage}
                                setErrorAlertMessage={setErrorAlertMessage}
                            /> */}
                            <DeleteDialog
                                handleDelete={handleDeleteAnnouncement}
                                setAnchorEl={setAnchorEl}
                                dialogTitle={"Are you sure you want to delete the announcement?"} 
                            />
                            </>
                        }
                    </Popover>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    py: "2rem",
                    px: "1rem"
                }}
            >
                <Typography variant="h3" sx={{my: "auto", ml: "1rem"}}>
                    {announcement.title}
                </Typography>
                <Typography variant="h6" color={colors.blueAccent[600]} sx={{my: "auto", ml: "1rem"}}>
                    {announcement.author}
                </Typography>
                <Typography variant="h7" color={colors.grey[700]} sx={{my: "auto", ml: "1rem"}}>
                    Posted on: {new Date(announcement.createdAt).toLocaleDateString("en", options)}
                </Typography>
                <Typography variant='h7' sx={{mt: "1rem", ml: "1rem"}}>
                    {announcement.content?.split("\n").map((line, index) => {
                        return (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        )
                    })}
                </Typography>
            </Box>
        </Box>
    )
}

export default AnnouncementBox