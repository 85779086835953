import { Outlet } from 'react-router-dom';
import ClassesTopbar from '../classrooms/ClassesTopbar';
import { Navigate } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import React from 'react';
import getCookies from '../utils/getCookies';

const ClassesLayout = () => {
    const navigate = useNavigate();

    let currentUser = getCookies("_auth_state");

    const id = currentUser ? currentUser?.split(',')[0]?.split(':')[1]?.split('"')[1] : null;
    const who = currentUser ? currentUser?.split(',')[2]?.split(':')[1]?.split('"')[1] : null;

    const userRole = who;
    const userId = id;

    // console.log(userRole);
    // console.log(userId);

    React.useEffect(() => {
        if ((userRole === null || userRole === undefined) || (userId === null || userId === undefined)) {
            currentUser = null;
            console.log("No user found");
            if (document?.cookie) {
                const cookies = document.cookie?.split(";");
                for (let i = 0; i < cookies.length; i++) {
                    const cookie = cookies[i];
                    const eqPos = cookie.indexOf("=");
                    const name = eqPos > -1 ? cookie.substring(0, eqPos).trim() : cookie;
                    if (name !== "_auth" && name !== "_auth_storage" && name !== "_auth_type" && name !== "_auth_state")
                        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
            }
            navigate("/signin");
        }
    });

    // if (currentUser === undefined) {
    //     return null; // or loading indicator, etc...
    // }
    
    return currentUser ? (
        <>
            <main className="content">
                <ClassesTopbar userRole={userRole} userId={userId} />
                <Outlet />
            </main>
        </>
    ) : <Navigate to="/signin"/>;
};

export default ClassesLayout;