import React from 'react'
import { tokens } from "../../../theme";
import { Box, useTheme, Typography, TextField, Button } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useMediaQuery } from '@mui/material';
import Axios from 'axios';
import jwt_decode from "jwt-decode";
import getStudentName from '../../../utils/getStudentName';

const AdminActions = ({
    courseId,
    allTeachers,
    courseTeachers,
    courseStudents,
    otherStudents,
    setBackdropOpen,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
    sortSemesters
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const userRole = jwt_decode(document.cookie).who;

    // modal for students
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleModalOpen = () => setModalOpen(true);;
    const handleModalClose = () => {
        setModalOpen(false);
        setAddStudent(null);
        setStudentInputValue('');
        setStartDate(null);
        setEndDate(null);
    };

    // for teachers
    const [addTeacher, setAddTeacher] = React.useState(null);
    const [removeTeacher, setRemoveTeacher] = React.useState(null);
    // const [openAlertDialog, setOpenAlertDialog] = React.useState(false);

    // for students
    const [addStudent, setAddStudent] = React.useState(null);
    const [removeStudents, setRemoveStudents] = React.useState([]);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    // for autocomplete
    const [studentInputValue, setStudentInputValue] = React.useState('');

    // for media query
    const matches2 = useMediaQuery('(min-width:1200px)');
    const matches3 = useMediaQuery('(min-width:1000px)');


    /* others */
    const getTeachersWithoutCourseTeacher = () => {
        if (courseTeachers === null || courseTeachers.length === 0) {
            return allTeachers;
        }

        return allTeachers.filter((teacher) => teacher.id !== courseTeachers.includes(teacher.id));
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const requestSuccess = (response) => {
        setSuccessAlertMessage(response.data.message);
        setSuccessAlertOpen(true);
        setBackdropOpen(false);
        window.location.reload();
    };

    const requestError = (error) => {
        setErrorAlertMessage(error.response.data.message);
        setErrorAlertOpen(true);
        setBackdropOpen(false);
    };

    /*
     * Send requests to backend to add or remove teachers and students
     */
    async function handleAddStudent() {
        if (addStudent === null) {
            setErrorAlertMessage("Please select a student to add.");
            setErrorAlertOpen(true);
            return;
        }

        if (startDate === null) {
            setErrorAlertMessage("Please select a start date.");
            setErrorAlertOpen(true);
            return;
        }

        if (endDate === null) {
            setErrorAlertMessage("Please select an end date.");
            setErrorAlertOpen(true);
            return;
        }

        if (startDate.isAfter(endDate)) {
            setErrorAlertMessage("Start date cannot be after end date.");
            setErrorAlertOpen(true);
            return;
        }

        if (startDate.isBefore(dayjs(sortSemesters[0].semester.start).locale('en')) 
        || endDate.isAfter(dayjs(sortSemesters[sortSemesters.length - 1].semester.end).locale('en'))) {
            setErrorAlertMessage("Start and end date must be within course's start and end date.");
            setErrorAlertOpen(true);
            return;
        }

        // set end_date to the end of the day
        const end_date_modified = new Date(endDate);
        end_date_modified.setDate(end_date_modified.getDate() + 1);
        end_date_modified.setSeconds(end_date_modified.getSeconds() - 1);

        setBackdropOpen(true);
        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/students/${addStudent.uuid}`, {
            start_date: startDate,
            end_date: end_date_modified
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            requestSuccess(response);
            handleModalClose();
        }).catch((error) => {
            requestError(error);
        });
    }

    async function handleRemoveStudents() {
        setBackdropOpen(true);

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/students/deletion`, {
            students_in_course: removeStudents,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            requestSuccess(response);
        }).catch((error) => {
            requestError(error);
        });
    }

    async function handleAddTeacher() {
        setBackdropOpen(true);

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/teachers/${addTeacher.uuid}`, {
            
        },{
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            requestSuccess(response);
        }).catch((error) => {
            requestError(error);
        });
    }

    async function handleRemoveTeacher() {
        setBackdropOpen(true);

        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/teachers/${removeTeacher.uuid}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            requestSuccess(response);
        }).catch((error) => {
            requestError(error);
        });
    }

    return (
        <>{userRole === "admin" &&
            <Box
                minWidth="20rem"
                width="100%"
                minHeight="10rem"
                borderRadius="3px"
                padding={3}
                boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                backgroundColor={colors.primary[600]}
                display="flex"
                flexDirection="column"
                alignItems="left"
                gap={3}
                mb={3}
            >
                <Typography variant="h4" color={colors.primary[450]}>
                    Admin Actions
                </Typography>
                <Box 
                    display="flex" 
                    flexDirection="row" 
                    gap={3}
                    width="100%" 
                    sx={{
                        ...!matches3 && {
                            flexDirection: "column",
                        },
                    }}
                >
                    <Box>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo-1"
                            options={
                                getTeachersWithoutCourseTeacher().map((teacher) => ({
                                    id: teacher.id,
                                    label: teacher.first_name + " " + teacher.last_name,
                                    uuid: teacher.uuid
                                }))
                            }
                            sx={{ 
                                width: "18vw", 
                                ...!matches2 && {width: "20vw"}, 
                                ...!matches3 && {width: "34vw"},
                                transition: "width 0.1s" 
                            }}
                            renderInput={(params) => <TextField {...params} label="Add Teachers" />}
                            onChange={(event, value) => setAddTeacher(value)} 
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                        />
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 1, width: "7rem" }}
                            onClick={addTeacher !== null ? handleAddTeacher : null}
                        >
                            Add
                        </Button>
                    </Box>
                    <Box>
                        <Autocomplete
                            id="controllable-states-demo"
                            options={otherStudents !== null ? otherStudents : []}
                            getOptionLabel={(student) => (
                                getStudentName(student)
                            )}
                            renderInput={(params) => <TextField {...params} label="Add Students"/>}
                            sx={{ 
                                width: "18vw", 
                                ...!matches2 && {width: "20vw"}, 
                                ...!matches3 && {width: "34vw"},
                                transition: "width 0.1s" 
                            }}
                            onChange={(event, value) => setAddStudent(value)}
                            value={addStudent}
                            inputValue={studentInputValue}
                            onInputChange={(event, newInputValue) => {
                                setStudentInputValue(newInputValue);
                            }}
                        />
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 1, width: "7rem" }}
                            onClick={handleModalOpen}
                        >
                            Add
                        </Button>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={modalOpen}
                            onClose={handleModalClose}
                            closeAfterTransition
                            slots={{ backdrop: Backdrop }}
                            slotProps={{
                                backdrop: {
                                    timeout: 500,
                                },
                            }}
                        >
                            <Fade in={modalOpen}>
                                <Box sx={style}>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        gap={4}
                                    >
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            mb={-2}
                                            gap={2}
                                        >
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="From"
                                                    value={startDate}
                                                    onChange={(newValue) => setStartDate(newValue)}
                                                    sx={{ width: "100%" }}
                                                />
                                            </LocalizationProvider>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="To"
                                                    value={endDate}
                                                    onChange={(newValue) => setEndDate(newValue)}
                                                    sx={{ width: "100%" }}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            style={{
                                                backgroundColor: colors.primary[300],
                                                color: "white",
                                                marginTop: "0.5rem",
                                                width: "10rem",
                                            }}
                                            onClick={handleAddStudent}
                                        >
                                            Add Student
                                        </Button>
                                    </Box>
                                </Box>
                            </Fade>
                        </Modal>
                    </Box>
                </Box>
                <Box 
                    display="flex" 
                    flexDirection="row" 
                    gap={3}
                    width="100%" 
                    sx={{
                        ...!matches3 && {
                            flexDirection: "column",
                        },
                    }}
                >
                    <Box>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo-2"
                            options={
                                (courseTeachers !== null && courseTeachers.length > 0 ?
                                    courseTeachers.map((teacher) => ({
                                        uuid: teacher.uuid,
                                        label: teacher.first_name + " " + teacher.last_name
                                    }))
                                    : [])
                            }
                            sx={{ 
                                width: "18vw", 
                                ...!matches2 && {width: "20vw"}, 
                                ...!matches3 && {width: "34vw"},
                                transition: "width 0.1s" 
                            }}
                            renderInput={(params) => <TextField {...params} label="Remove Teachers" />}
                            onChange={(event, value) => setRemoveTeacher(value)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                        />
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 1, width: "7rem" }}
                            onClick={removeTeacher !== null ? handleRemoveTeacher : null}
                        >
                            Remove
                        </Button>
                    </Box>
                    <Box>
                        <Autocomplete
                            multiple
                            id="controllable-states"
                            options={courseStudents !== null ? courseStudents : []}
                            getOptionLabel={(data) => getStudentName(data.student)}
                            filterSelectedOptions
                            renderInput={(params) => <TextField {...params} label="Remove Students" />}
                            sx={{ 
                                width: "18vw", 
                                ...!matches2 && {width: "20vw"}, 
                                ...!matches3 && {width: "34vw"},
                                transition: "width 0.1s" 
                            }}
                            onChange={(event, value) => setRemoveStudents(value)}
                        />
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 1, width: "7rem" }}
                            onClick={removeStudents.length !== 0 ? handleRemoveStudents : null}
                        >
                            Remove
                        </Button>
                    </Box>
                </Box>
            </Box>
        }</>
    )
}

export default AdminActions