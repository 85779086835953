import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import MoreInfoModal from './moreInfo/MoreInfoModal';
import { motion } from "framer-motion";
import { useTheme, Box, Tooltip } from "@mui/material";
import { tokens } from "../../theme";
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import Axios from 'axios';
import Alert from '../../components/alert/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import PersonOffIcon from '@mui/icons-material/PersonOff';

export default function StudentCard({
    student, 
    setStudentUpdated,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    // for dialog
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleClickDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleInfoMailUpdate = async (event) => {
        event.preventDefault();

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/students/${student.uuid}/info-mails/${student.info_mail.id}`, {
            is_sent: true,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("Info mail status has been updated!");
            setSuccessAlertOpen(true);
            setStudentUpdated(true);
            handleDialogClose();
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    };

    return (
        <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            layout
        >
            <Card
                sx={{
                    minWidth: 200,
                    backgroundColor: colors.primary[600],
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                    border: `1px solid ${colors.primary[600]}`,
                    ":hover": {
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                        border: "1px solid grey",
                    }
                }}
                key={student.id}
            >
                {!student.is_potential_student ? (
                    <CardContent>
                        <Box sx={{display: "flex", justifyContent: "space-between"}}>
                            <Typography variant="h5">
                                {student.last_name === null || student.last_name === "" 
                                    ? null 
                                    : `${student.last_name}, `}{student.first_name} {student.korean_name && student.korean_name.length > 0 ? `${student.korean_name}` : null}
                                    {student.consultation_number === null ? null 
                                    : student.consultation_number && student.last_name?.length + student.first_name?.length + student.korean_name?.length > 22 ?
                                    "..." : ` (${student.consultation_number})`}
                            </Typography>
                            <Tooltip title="Blacklist">
                                { student.is_blacklist_student ? <PersonOffIcon/> : <></> }
                            </Tooltip>
                        </Box>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            {student.school && student.school.length > 28 
                                ? `${student.school.substring(0, 28)}...` 
                                : student.school === null || student.school === "" ? "N/A"
                                : student.school}
                        </Typography>
                        <Typography variant="body2">
                            {student.phone === null || student.phone === "" ? "N/A" : student.phone}
                            <br />
                            {student.email === null || student.email === "" ? "N/A" : student.email}
                            <br />
                            <br />
                            {student.parent_email === null || student.parent_email === "" ? "N/A" : student.parent_email}
                            <br />
                            {student.parent_phone === null || student.parent_phone === "" ? "N/A" : student.parent_phone}
                        </Typography>
                    </CardContent>
                ) : (
                    <CardContent>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", gap: 0.5, mb: 2 }}>
                                <Typography variant="h5">
                                    {student.consultation_number}
                                </Typography>
                                <Typography color="text.secondary">
                                    (Consultation Number)
                                </Typography>
                            </Box>

                            <Tooltip title="Blacklist">
                                { student.is_blacklist_student ? <PersonOffIcon/> : <></> }
                            </Tooltip>

                            {student.is_potential_student && student.info_mail && !student.info_mail.is_sent 
                                && <>
                                <CancelScheduleSendIcon fontSize='small' color='error' sx={{ cursor: "pointer" }} onClick={handleClickDialogOpen} />
                                <Dialog
                                    open={dialogOpen}
                                    onClose={handleDialogClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"Did you send an infomation mail to this potential student?"}
                                    </DialogTitle>
                                    <DialogActions>
                                        <Button onClick={(e) => handleInfoMailUpdate(e)} color="success" autoFocus>
                                            Yes
                                        </Button>
                                        <Button onClick={handleDialogClose}>No</Button>
                                    </DialogActions>
                                </Dialog>
                            </>}
                        </Box>
                        <br />
                        <Typography variant="body2">
                            Parent Phone: {student.parent_phone ? student.parent_phone : "N/A"}
                            <br />
                            Parent Email: {student.parent_email && student.parent_email.length > 20 
                                ? student.parent_email.substring(0, 17) + "..." 
                                : student.parent_email && student.parent_email.length <= 20
                                ? student.parent_email
                                : "N/A"}
                        </Typography>
                    </CardContent>
                )}
                
                <CardActions>
                    <MoreInfoModal 
                        student={student} 
                        setStudentUpdated={setStudentUpdated} 
                    />
                </CardActions>
            </Card>
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical='bottom'
                horizontal='left'
            />
        </motion.div>
    );
}