import React, { useEffect } from 'react'
import { tokens } from "../../theme";
import { Box, IconButton, useTheme } from "@mui/material";
import CourseCard from "./CourseCard";
import Header from "../../components/Header";
import Loading from '../../components/Loading';
import Axios from 'axios';
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { motion, AnimatePresence } from "framer-motion";
import Dropdown from '../../components/dropdown/Dropdown';
import { generateYears, subjects, semesters } from '../../components/dropdown/DropdownData';
import { getCourseYear, getCourseSemester } from '../../utils/CourseDuration';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";


const Courses = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }
    
    const [loading, setLoading] = React.useState(true);
    const [courses, setCourses] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [codeSearch, setCodeSearch] = React.useState('');
    const [courseUpdated, setCourseUpdated] = React.useState(false);
    const [maxYear, setMaxYear] = React.useState(0);
    const [minYear, setMinYear] = React.useState(0);
    const [year, setYear] = React.useState(0);
    const [semester, setSemester] = React.useState('all');
    const [subject, setSubject] = React.useState('all');
    const season = {'Spring': 1, 'Summer': 2, 'Fall': 3, 'Winter': 4};


    function fetchCourses() {
        Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then(async (response) => {
                setCourses(response.data);
                setLoading(false);
            }).catch((error) => {
                if (error.response !== null && error.response.status !== 404)
                    console.log(error.response.data.message);
                else if (error.response !== null && error.response.status === 401)
                    handleLogout();
                setLoading(false);
            });
        setCourseUpdated(false);
    };

    function fetchMaxMinYears() {
        Axios.get(`${process.env.REACT_APP_URL}/api/v1/semesters/max-min-years`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then(async (response) => {
                setMaxYear(response.data.max_year);
                setMinYear(response.data.min_year);
            }).catch((error) => {
                if (error.response !== null && error.response.status !== 404)
                    console.log(error.response.data.message);
                else if (error.response !== null && error.response.status === 401)
                    handleLogout();
            });
    }

    useEffect(() => {
        localStorage.setItem('selectedMenu', 'Courses');
        fetchMaxMinYears();
        fetchCourses();

    }, [courseUpdated]);

    return (
        <Box m="20px" marginX={3} marginY={3} >
            <Header title="Courses" subtitle="A ONE Institute" />
            { loading ? 
                <Loading />
            : <>
                <Box display="flex" justifyContent="left" gap={2} p={0} minHeight={70} mb={2}>
                    {/* SEARCH BAR */}
                    <Box
                        display="flex"
                        backgroundColor={colors.primary[600]}
                        borderRadius="3px"
                        boxShadow={10}
                        mb={2}
                        maxWidth={300}
                        minWidth={220}
                    >
                        <InputBase sx={{ ml: 2, flex: 1 }} onChange={(e) => setSearch(e.target.value)} placeholder="Search by course title" />
                        <IconButton type="button" sx={{ p: 1 }}>
                            <SearchIcon />
                        </IconButton>
                    </Box>

                    <Box
                        display="flex"
                        backgroundColor={colors.primary[600]}
                        borderRadius="3px"
                        boxShadow={10}
                        mb={2}
                        maxWidth={300}
                        minWidth={220}
                    >
                        <InputBase sx={{ ml: 2, flex: 1 }} onChange={(e) => setCodeSearch(e.target.value)} placeholder="Search by course code" />
                        <IconButton type="button" sx={{ p: 1 }}>
                            <SearchIcon />
                        </IconButton>
                    </Box>

                    {/* SUBJECT SELECT */}
                    <Dropdown 
                        maxWidth={100} 
                        maxHeight={3} 
                        reactUseState={subject} 
                        setReactUseState={setSubject} 
                        inputLabel="Subject" 
                        data={subjects} 
                    />

                    {/* YEAR SELECT */}
                    <Dropdown 
                        maxWidth={200} 
                        maxHeight={3} 
                        reactUseState={year} 
                        setReactUseState={setYear} 
                        inputLabel="Year" 
                        data={generateYears(minYear, maxYear)} 
                    />

                    {/* SEMESTER SELECT */}
                    <Dropdown 
                        maxWidth={200} 
                        maxHeight={3} 
                        reactUseState={semester} 
                        setReactUseState={setSemester} 
                        inputLabel="Semester" 
                        data={semesters} 
                    />
                </Box>

                {/* COURSE CARDS */}
                <motion.div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                        rowGap: "1rem",
                        columnGap: "1rem",    
                    }}
                    layout
                >
                    <AnimatePresence>
                        {courses
                            .filter((course) => {
                                return search === '' 
                                    ? course 
                                    : course.course_title.toLowerCase().includes(search.toLowerCase()) 
                                        || course.course_title.toLowerCase().includes(search.toLowerCase());
                            })
                            .filter((course) => {
                                return codeSearch === '' 
                                    ? course 
                                    : course.code?.toLowerCase().includes(codeSearch.toLowerCase());
                            })
                            .filter((course) => {
                                return subject === 'all'
                                    ? course
                                    : course.subject.includes(subject);
                            })
                            .filter((course) => {
                                return semester === 'all'
                                    ? course
                                    : getCourseSemester(course, year).includes(season[semester]);
                            })
                            .filter((course) => {
                                return year === 0
                                    ? course
                                    : getCourseYear(course).includes(year);
                            })
                        .map((course) => {
                            return (
                                <CourseCard course={course} key={course.id} setCourseUpdated={setCourseUpdated} />
                            );
                        })}
                    </AnimatePresence>
                </motion.div>
            </>}
        </Box>
    )
}

export default Courses