import React from 'react'
import { useOutletContext } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Header from "../../../../components/Header";
import { tokens } from "../../../../theme";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Axios from 'axios';
import FeedbackModal from './FeedbackModal';
import Alert from '../../../../components/alert/Alert';
import Backdrop from '@mui/material/Backdrop';
// import jwt_decode from 'jwt-decode';
import getCookies from '../../../../utils/getCookies';
import Loading from '../../../../components/Loading';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import ScoreModal from './ScoreModal';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckBox from './CheckBox';
import { Button } from '@mui/material';

const Grading = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const currentUser = getCookies("_auth_state");
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const matches = useMediaQuery('(min-width:1500px)');
    const matches2 = useMediaQuery('(min-width:1200px)');

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const courseData = useOutletContext().courseData;
    const [loading, setLoading] = React.useState(true);
    const [homeworkData, setHomeworkData] = React.useState(null);
    const [submissions, setSubmissions] = React.useState(null);
    const [studentNames, setStudentNames] = React.useState(null);
    const [associatedClassMaterial, setAssociatedClassMaterial] = React.useState(null);
    const [courseId, setCourseId] = React.useState(null);
    const [homeworkId, setHomeworkId] = React.useState(null);

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: colors.primary[300],
            color: theme.palette.common.white,
            fontSize: 14,
            fontWeight: "bold",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 13,
        },
        border: '1px solid rgba(224, 224, 224, 1)',
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: colors.primary[750],
        },
        '&:nth-of-type(even)': {
            backgroundColor: colors.primary[750],
        },
    }));

    React.useEffect(() => {
        const courseId = window.location.pathname.split('/')[2];
        const homeworkId = window.location.pathname.split('/')[4];
        setCourseId(courseId);
        setHomeworkId(homeworkId);

        async function fetchHomeworkData() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/homeworks/${homeworkId}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setHomeworkData(response.data.homework);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                console.log(error.response.data.message);
            });
        }

        async function fetchSubmissions() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/homeworks/${homeworkId}/submissions`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setSubmissions(response.data.submissionsByStudent);
                setStudentNames(response.data.studentNames);
                setAssociatedClassMaterial(response.data.associatedClassMaterial);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                console.log(error.response.data.message);
            });
        }

        if (userRole === "student")
            window.location.href = `/classrooms/${courseId}/homework/${homeworkId}`;

        fetchHomeworkData();
        fetchSubmissions();
        setLoading(false);
    }, []);

    function getPercentage(given_score, total_score) {
        const percentage = (given_score / total_score) * 100;
        return percentage.toFixed(0);
    }

    return (
        <Box m="20px" marginX={3} marginY={3} minHeight="90%">
            { loading || courseData === null || homeworkData === null || submissions === null || studentNames === null || associatedClassMaterial === null ? 
                <Loading />
                : 
                <><Box 
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        px: "18%",
                        ...!matches && {
                            px: "15%",
                        },
                        ...!matches2 && {
                            px: "4%",
                        },
                    }}
                >
                    <Header 
                        title={
                            courseData.code !== null 
                            ? courseData.code + ". " + courseData.course_title 
                            : courseData.course_title
                        } 
                        subtitle="A ONE Institute"
                    />
                    <Box
                        minWidth="20rem"
                        width="100%"
                        minHeight="80vh"
                        borderRadius="3px"
                        padding={3}
                        boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                        backgroundColor={colors.primary[600]}
                        display="flex"
                        flexDirection="column"
                        alignItems="left"
                        gap={3}
                        mb={3}
                    >
                        <Typography variant="h3" color={colors.primary[450]} px={2} pb={2} borderBottom="0.1px solid" mb={2}>
                                {homeworkData.title}
                        </Typography>
                        {submissions && Object.keys(submissions).length > 0 ? Object.keys(submissions).map((student_id) => {
                            if (!associatedClassMaterial) {
                                // if there is no associated class material with the homework (normal homework without class material)
                                return (
                                    <TableContainer key={student_id} component={Paper} sx={{ maxWidth: "100%" }}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell style={{ width: "16%" }}>Name</StyledTableCell>
                                                    <StyledTableCell style={{ width: "10%" }}>Attempt</StyledTableCell>
                                                    <StyledTableCell style={{ width: "30%" }}>Submission</StyledTableCell>
                                                    <StyledTableCell style={{ width: "26%" }}>Submitted At</StyledTableCell>
                                                    <StyledTableCell style={{ width: "8%" }}>score</StyledTableCell>
                                                    <StyledTableCell style={{ width: "10%" }}>Feedback</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {submissions[student_id].map((submission, i) => {
                                                    return (
                                                        <StyledTableRow key={i}>
                                                            {i === 0 ? (
                                                                <><StyledTableCell rowSpan={submissions[student_id].length} style={{ width: "16%" }}>
                                                                        {studentNames[student_id]}
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "10%" }}>
                                                                    Attempt #{submissions[student_id].length - i}
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "30%" }}>
                                                                    {submission.content && submission.content.split("\n").map((line, index) => {
                                                                        return (
                                                                            <span key={index}>
                                                                                {line}
                                                                                <br />
                                                                            </span>
                                                                        )
                                                                    })}
                                                                    {submission.hw_submission_files && submission.hw_submission_files.length > 0
                                                                    && submission.hw_submission_files.map((file, index) => {
                                                                        return (
                                                                            <Typography 
                                                                                key={index} 
                                                                                component='a' 
                                                                                href={file.file_url}
                                                                                sx={{
                                                                                    textDecoration: "underline",
                                                                                    color: colors.blueAccent[600]
                                                                                }}
                                                                            >
                                                                                <br />{index+1}.{file.name}
                                                                            </Typography>
                                                                        ) 
                                                                    })}
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "26%" }}>
                                                                    {new Date(submission.createdAt).toLocaleString("en-US")}
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "8%" }}>
                                                                    {submission.score !== null && submission.score !== undefined ? submission.score : "-"} / {submission.maxScore}
                                                                    <ScoreModal 
                                                                        submission={submission} 
                                                                        setSuccessAlertOpen={setSuccessAlertOpen}
                                                                        setSuccessAlertMessage={setSuccessAlertMessage}
                                                                        setErrorAlertOpen={setErrorAlertOpen}
                                                                        setErrorAlertMessage={setErrorAlertMessage}
                                                                        setBackdropOpen={setBackdropOpen}
                                                                    />
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "10%" }}>
                                                                    {submission.hw_feedbacks.length > 0 
                                                                    && submission.hw_feedbacks.map((feedback, index) => {
                                                                        return (
                                                                            <Box key={index} display='flex' gap={1} alignItems='center'>
                                                                                <CheckBox
                                                                                    colors={colors}
                                                                                    courseData={courseData}
                                                                                    homeworkData={homeworkData}
                                                                                    feedback={feedback}
                                                                                    setBackdropOpen={setBackdropOpen}
                                                                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                                                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                                                                    setErrorAlertOpen={setErrorAlertOpen}
                                                                                    setErrorAlertMessage={setErrorAlertMessage}
                                                                                    handleLogout={handleLogout}
                                                                                />
                                                                                {feedback.file_url !== null && (
                                                                                    <Typography 
                                                                                        component='a' 
                                                                                        variant='h7'
                                                                                        href={feedback.file_url}
                                                                                        sx={{
                                                                                            textDecoration: "underline",
                                                                                            color: colors.blueAccent[600]
                                                                                        }}
                                                                                    >
                                                                                        {feedback.name}
                                                                                    </Typography>
                                                                                )}
                                                                                {feedback.comment !== null && feedback.comment !== "" && (
                                                                                    <Tooltip 
                                                                                        TransitionComponent={Zoom} 
                                                                                        title={
                                                                                            <Typography variant="h6" color="white">
                                                                                                {feedback.comment.split("\n").map((line, index) => (
                                                                                                    <span key={index}>
                                                                                                        {line}
                                                                                                        <br />
                                                                                                    </span>
                                                                                                ))}
                                                                                            </Typography>
                                                                                        } 
                                                                                        arrow
                                                                                    >
                                                                                        <MoreHorizIcon sx={{color: colors.grey[700]}} />
                                                                                    </Tooltip>
                                                                                )}
                                                                            </Box>
                                                                        )
                                                                    })}
                                                                    <FeedbackModal 
                                                                        submission={submission} 
                                                                        setSuccessAlertOpen={setSuccessAlertOpen}
                                                                        setSuccessAlertMessage={setSuccessAlertMessage}
                                                                        setErrorAlertOpen={setErrorAlertOpen}
                                                                        setErrorAlertMessage={setErrorAlertMessage}
                                                                        setBackdropOpen={setBackdropOpen}
                                                                    />
                                                                </StyledTableCell></>
                                                            ) : (
                                                                <><StyledTableCell style={{ width: "10%" }}>
                                                                    Attempt #{submissions[student_id].length - i}
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "30%" }}>
                                                                    {submission.content}
                                                                    {submission.hw_submission_files.map((file, index) => {
                                                                        return (
                                                                            <Typography 
                                                                                key={index} 
                                                                                component='a' 
                                                                                href={file.file_url}
                                                                                sx={{
                                                                                    textDecoration: "underline",
                                                                                    color: colors.blueAccent[600]
                                                                                }}
                                                                            >
                                                                                <br />{index+1}.{file.name}
                                                                            </Typography>
                                                                        ) 
                                                                    })}
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "26%" }}>
                                                                    {new Date(submission.createdAt).toLocaleString("en-US")}
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "8%" }}>
                                                                    {submission.score !== null && submission.score !== undefined ? submission.score : "-"} / {submission.maxScore}
                                                                    <ScoreModal 
                                                                        submission={submission} 
                                                                        setSuccessAlertOpen={setSuccessAlertOpen}
                                                                        setSuccessAlertMessage={setSuccessAlertMessage}
                                                                        setErrorAlertOpen={setErrorAlertOpen}
                                                                        setErrorAlertMessage={setErrorAlertMessage}
                                                                        setBackdropOpen={setBackdropOpen}
                                                                    />
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "10%" }}>
                                                                    {submission.hw_feedbacks.length > 0 
                                                                    && submission.hw_feedbacks.map((feedback, index) => {
                                                                        return (
                                                                            <Box key={index}  display='flex' gap={1} alignItems='center'>
                                                                                <CheckBox
                                                                                    colors={colors}
                                                                                    courseData={courseData}
                                                                                    homeworkData={homeworkData}
                                                                                    feedback={feedback}
                                                                                    setBackdropOpen={setBackdropOpen}
                                                                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                                                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                                                                    setErrorAlertOpen={setErrorAlertOpen}
                                                                                    setErrorAlertMessage={setErrorAlertMessage}
                                                                                    handleLogout={handleLogout}
                                                                                />
                                                                                <Typography 
                                                                                    component='a' 
                                                                                    href={feedback.file_url}
                                                                                    sx={{
                                                                                        textDecoration: "underline",
                                                                                        color: colors.blueAccent[600]
                                                                                    }}
                                                                                >
                                                                                    {feedback.name}
                                                                                </Typography>
                                                                            </Box>
                                                                        )
                                                                    })}
                                                                    <FeedbackModal 
                                                                        submission={submission} 
                                                                        setSuccessAlertOpen={setSuccessAlertOpen}
                                                                        setSuccessAlertMessage={setSuccessAlertMessage}
                                                                        setErrorAlertOpen={setErrorAlertOpen}
                                                                        setErrorAlertMessage={setErrorAlertMessage}
                                                                        setBackdropOpen={setBackdropOpen}
                                                                    />
                                                                </StyledTableCell></>
                                                            )}
                                                        </StyledTableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )
                            } else {
                                // if there is associated class material with the homework
                                return (
                                    <TableContainer key={student_id} component={Paper} sx={{ maxWidth: "100%" }}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell style={{ width: "20%" }}>Name</StyledTableCell>
                                                    <StyledTableCell style={{ width: "30%" }}>Submitted At</StyledTableCell>
                                                    <StyledTableCell style={{ width: "20%" }}>Auto-graded Score</StyledTableCell>
                                                    <StyledTableCell style={{ width: "20%" }}>Non-auto-graded Score</StyledTableCell>
                                                    <StyledTableCell style={{ width: "10%" }}>Submission</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {submissions[student_id].map((submission, i) => {
                                                    return (
                                                        <StyledTableRow key={i}>
                                                            {i === 0 ? (
                                                                <><StyledTableCell rowSpan={submissions[student_id].length} style={{ width: "20%" }}>
                                                                        {studentNames[student_id]}
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "30%" }}>
                                                                    {new Date(submission.createdAt).toLocaleString("en-US")}
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "20%" }}>
                                                                    {submission.correct_auto_graded_answer_count} / {submission.total_auto_graded_answer_count}
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "20%" }}>
                                                                    {submission.total_auto_graded_answer_count === submission.hw_answers.length ? (
                                                                        <> No questions to grade </>
                                                                    ) : submission.total_non_auto_graded_answer_score !== null && submission.total_non_auto_graded_answer_score > 0 ? (
                                                                        <>{getPercentage(submission.given_non_auto_graded_answer_score, submission.total_non_auto_graded_answer_score)}% ({submission.given_non_auto_graded_answer_score} / {submission.total_non_auto_graded_answer_score})</>
                                                                    ) : (
                                                                        <>Not graded yet</>
                                                                    )}
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "10%" }}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        href={`/classrooms/${courseId}/homework/${homeworkId}/submissions/attempts?submission_id=${submission?.id}&student_name=${studentNames[student_id]}`}
                                                                        target="_self"
                                                                        rel="noreferrer"
                                                                    >
                                                                        View
                                                                    </Button>
                                                                </StyledTableCell></>
                                                            ) : (
                                                                <><StyledTableCell style={{ width: "30%" }}>
                                                                    {new Date(submission.createdAt).toLocaleString("en-US")}
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "20%" }}>

                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "20%" }}>

                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "10%" }}>

                                                                </StyledTableCell></>
                                                            )}
                                                        </StyledTableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )
                            }
                        }) : (
                            <Typography variant="h4" color={colors.primary[450]} sx={{ mx: 'auto', mt: '10rem' }} >
                                No submissions yet!
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Alert
                    successAlertOpen={successAlertOpen}
                    setSuccessAlertOpen={setSuccessAlertOpen}
                    errorAlertOpen={errorAlertOpen}
                    setErrorAlertOpen={setErrorAlertOpen}
                    successMsg={successAlertMessage}
                    errorMsg={errorAlertMessage}
                    vertical="bottom"
                    horizontal="left"
                />
                <Backdrop
                    sx={{ color: colors.primary[400], zIndex: 2147483646 }}
                    open={backdropOpen}
                    onClick={handleBackdropClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                </>
            }
        </Box>
    )
}

export default Grading