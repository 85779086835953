import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { Box, useTheme } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { tokens } from "../../../theme";
import UploadComp from '../../../components/UploadComp';
import dayjs from 'dayjs';
import Axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


export default function UpdateModal({ 
    courseId, 
    lectureModule, 
    userRole,
    setBackdropOpen, 
    setSuccessAlertOpen, 
    setErrorAlertOpen, 
    setSuccessAlertMessage,
    setErrorAlertMessage,
    setAnchorEl,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const [lectureDate, setLectureDate] = React.useState(dayjs(lectureModule.start));
    const [fromValue, setFromValue] = React.useState(dayjs(lectureModule.start));
    const [toValue, setToValue] = React.useState(dayjs(lectureModule.end));
    const [files, setFiles] = React.useState(lectureModule.class_notes?.length > 0 ? lectureModule.class_notes : []);
    const [isCanceled, setIsCanceled] = React.useState(lectureModule.is_canceled || false);

    const handleIsCanceledChange = (event) => {
      setIsCanceled(event.target.checked);
    };
  
    // for module
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {setOpen(false); setAnchorEl(null)};

    const handleSubmitTeacher = async (event) => {
        event.preventDefault();

        if (files === null || files.length === 0) {
            setErrorAlertMessage("Please upload at least one file!");
            setErrorAlertOpen(true);
            return;
        }

        setBackdropOpen(true);
        const formData = new FormData();

        // divide files into old and new and append to formData
        for (let i = 0; i < files.length; i++) {
            if (files[i] instanceof File)
                formData.append('file', files[i], files[i].name);
            else
                formData.append('old_files', files[i].name);
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/events/${lectureModule.id}/class-notes/update`, formData, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            setBackdropOpen(false);
            setSuccessAlertMessage("Class notes updated successfully!");
            setSuccessAlertOpen(true);
            window.location.reload();
        }).catch((err) => {
            setBackdropOpen(false);
            setErrorAlertMessage(err.response.data.message);
            setErrorAlertOpen(true);
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let isFilesChanged = false;
        if (lectureModule.class_notes?.length !== files.length) {
            isFilesChanged = true;
        } else if (lectureModule.class_notes?.length !== 0 && files.length !== 0) {
            for (let i = 0; i < files.length; i++) {
                console.log(files[i], lectureModule.class_notes[i]);
                if (files[i]?.name !== lectureModule.class_notes[i]?.name) {
                    isFilesChanged = true;
                    break;
                }
            }
        }

        if (lectureDate.isSame(dayjs(lectureModule.start))
            && fromValue.isSame(dayjs(lectureModule.start))
            && toValue.isSame(dayjs(lectureModule.end))
            && !isFilesChanged 
            && isCanceled === lectureModule.is_canceled) {
            setErrorAlertMessage("No changes detected!");
            setErrorAlertOpen(true);
            return;
        }

        // if (data.get('title') === "") {
        //     setErrorAlertMessage("Title cannot be empty!");
        //     setErrorAlertOpen(true);
        //     return;
        // }

        if (isCanceled === undefined || isCanceled === null) {
            setErrorAlertMessage("Please select if the class is cancelled or not!");
            setErrorAlertOpen(true);
            return;
        }

        if (fromValue.hour() > toValue.hour() 
            || (fromValue.hour() === toValue.hour() && fromValue.minute() > toValue.minute())
            || (fromValue.hour() === toValue.hour() && fromValue.minute() === toValue.minute())) {
            setErrorAlertMessage("\"From time\" cannot be greater than or equal to \"To time\"!");
            setErrorAlertOpen(true);
            return;
        }

        setBackdropOpen(true);

        let errorMsg = "";
        let doesRequestSucceed = true;
        const formData = new FormData();

        // divide files into old and new and append to formData
        for (let i = 0; i < files.length; i++) {
            if (files[i] instanceof File) {
                if (files[i].name !== undefined && files[i].name !== null && files[i].name !== "" && files[i].name.length > 150) {
                    setErrorAlertMessage("File name should be less than 150 characters");
                    setErrorAlertOpen(true);
                    setBackdropOpen(false);
                    return;
                }
                formData.append('file', files[i], files[i].name);
            } else
                formData.append('old_files', files[i].name);
        }

        const start_hour = fromValue.hour() < 10 ? "0" + fromValue.hour() : fromValue.hour();
        const start_minute = fromValue.minute() < 10 ? "0" + fromValue.minute() : fromValue.minute();
        const end_hour = toValue.hour() < 10 ? "0" + toValue.hour() : toValue.hour();
        const end_minute = toValue.minute() < 10 ? "0" + toValue.minute() : toValue.minute();

        const start_time = new Date(lectureDate.locale('en').format('YYYY-MM-DD') + "T" + start_hour + ":" + start_minute + ":00");
        const end_time = new Date(lectureDate.locale('en').format('YYYY-MM-DD') + "T" + end_hour + ":" + end_minute + ":00");
        // Update the module
        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/events/${lectureModule.id}`, {
            start: start_time,
            end: end_time,
            // title: data.get('title'),
            title: null,
            allDay: false,
            progress: null,
            is_canceled: isCanceled,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then(async (res) => {
            // Update the class notes (upload new files and delete old files
            await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/events/${lectureModule.id}/class-notes/update`, formData, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {

            }).catch((err) => {
                errorMsg = err.response.data.message;
                doesRequestSucceed = false;
            });
        }).catch((err) => {
            errorMsg = err.response.data.message;
            doesRequestSucceed = false;
        });

        if (doesRequestSucceed) {
            setBackdropOpen(false);
            setSuccessAlertMessage("Module updated successfully!");
            setSuccessAlertOpen(true);
            window.location.reload();
            return;
        }

        setBackdropOpen(false);
        setErrorAlertMessage(errorMsg);
        setErrorAlertOpen(true);
    };

    return (
        <div>
            <Button onClick={handleOpen} sx={{ p: 1, width: "100%" }}>
                {userRole === "admin" ? "Update" : "Upload Class Notes"}
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                                display: "flex",
                                flexDirection: "column",
                                mr: "1rem",
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={userRole === "admin" ? handleSubmit : handleSubmitTeacher}
                        >
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "1rem",
                                minWidth: "100%",
                            }}>
                                {userRole === "admin" && <>
                                    {/* <TextField
                                        id="filled-basic"
                                        label="Lecture Title"
                                        variant="filled"
                                        name='title'
                                        sx={{
                                            flex: "1",
                                            maxWidth: "50%",
                                            '& .MuiFilledInput-root': {
                                                backgroundColor: colors.primary[600],
                                                ":focus": {
                                                    backgroundColor: 'white',
                                                },
                                            },
                                        }}
                                        defaultValue={lectureModule.title}
                                    /> */}
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "1rem",
                                            minWidth: "100%",
                                        }}
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Class Date"
                                                value={lectureDate}
                                                onChange={(newValue) => setLectureDate(newValue)}
                                            />
                                            <TimePicker
                                                label="From"
                                                value={fromValue}
                                                onChange={(newValue) => setFromValue(newValue)}
                                                format="HH:mm"
                                            />
                                            <TimePicker
                                                label="To"
                                                value={toValue}
                                                onChange={(newValue) => setToValue(newValue)}
                                                format="HH:mm"
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "1rem",
                                            minWidth: "100%",
                                        }}
                                    >
                                        <FormControlLabel 
                                            control={
                                                <Checkbox
                                                    checked={isCanceled}
                                                    onChange={handleIsCanceledChange}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            } 
                                            label="Check if this class is canceled" 
                                        />
                                    </Box>
                                </>}
                                <UploadComp text="Upload Class Notes" files={files} setFiles={setFiles} />
                            </Box>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{
                                    backgroundColor: colors.primary[300],
                                    color: "white",
                                    float: "right",
                                    marginTop: "0.5rem",
                                    marginBottom: "1rem",
                                    width: "10rem",
                                }}                                                                                                                                                                                                                                      
                            >
                                {userRole === "admin" ? "Update" : "Upload"}
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}