import React from 'react'
import Chip from '@mui/material/Chip';
import InfoIcon from '@mui/icons-material/Info';
import InfoModal from './InfoModal';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import { tokens } from "../../theme";
import { Box, useTheme } from "@mui/material";
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

var options = {
    year: "numeric",
    month: "short",
    day: "numeric"
};

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 10,
      top: 3,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 2px',
    },
}));

const EachTableRow = ({ i, index, homeworksLen, homework, StyledTableCell, StyledTableRow, element }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <StyledTableRow>
            <StyledTableCell style={{ width: "10%" }}>
                {dayjs(homework.createdAt).locale('en').format('MMM DD, YYYY')}
            </StyledTableCell>
            {i === 0 && index === 3 ? (
                <StyledTableCell rowSpan={homeworksLen} style={{ backgroundColor: colors.greenAccent[500], width: "14%" }}>
                    <Box sx={{ display: 'flex', alignContent: 'center', gap: '0.5rem' }}>
                        <Box>
                            {new Date(element).toUTCString("en", options).substring(0, 16)}
                        </Box>
                        <Box>
                            <TodayOutlinedIcon />
                        </Box>
                    </Box>
                </StyledTableCell>
            ) : i === 0 && index !== 3 ? (
                <StyledTableCell rowSpan={homeworksLen} style={{ width: "14%" }}>
                    {new Date(element).toUTCString("en", options).substring(0, 16)}
                </StyledTableCell>
            ) : (null)}
            <StyledTableCell component="th" scope="special_note" style={{ width: "10%" }}>
                ({homework.course.code}) {homework.course.course_title}
            </StyledTableCell>
            <StyledTableCell style={{ width: "10%" }}>
                {homework.title}
            </StyledTableCell>
            <StyledTableCell style={{ width: "52%" }}>
                {homework.studentsWhoSubmitted.map((student, index) => {
                    let exclude_students = [];
                                            
                    if (homework.exclude_student !== null) {                            
                        const exclude_student = homework.exclude_student.split('|');
                        exclude_students = exclude_student;
                    }
                    if (exclude_students.includes(student.id.toString()))
                        return null
                    if (homework.feedback_map[student.id] !== undefined) {
                        return (                            
                            <StyledBadge key={index} color='primary' variant={homework.feedback_map[student.id].is_opened ? 'standard' : 'dot'}>
                                {homework.feedback_map[student.id].is_opened &&
                                    <CheckCircleIcon sx={{ position: "absolute", top: "-0.5rem", right: "0", backgroundColor: colors.primary[600], borderRadius: '50%' }} />
                                }
                                <Chip color='secondary' label={student.name} sx={{ mr: "0.3rem", mb: "0.3rem" }} />
                            </StyledBadge>
                        )
                    } else {
                        return (
                            <Chip key={index} color='secondary' label={student.name} sx={{ mr: "0.3rem", mb: "0.3rem" }} />
                        )
                    }
                })}
                {homework.lateSubmissionStudents.map((student, index) => {
                    let exclude_students = [];
                                            
                    if (homework.exclude_student !== null) {                            
                        const exclude_student = homework.exclude_student.split('|');
                        exclude_students = exclude_student;
                    }

                    if (exclude_students.includes(student.id.toString()))
                        return null
                                        
                    if (homework.feedback_map[student.id] !== undefined) {
                        return (
                            <StyledBadge key={index} color='primary' variant={homework.feedback_map[student.id].is_opened ? 'standard' : 'dot'}>
                                {homework.feedback_map[student.id].is_opened &&
                                    <CheckCircleIcon sx={{ position: "absolute", top: "-0.5rem", right: "0", backgroundColor: colors.primary[600], borderRadius: '50%' }} />
                                }
                                <Chip color='warning' label={student.name} sx={{ mr: "0.3rem", mb: "0.3rem" }} />
                            </StyledBadge>
                        )
                    } else {
                        return (
                            <Chip key={index} color='warning' label={student.name} sx={{ mr: "0.3rem", mb: "0.3rem" }} />                            
                        )
                    }
                })}
                {homework.studentsWhoDidNotSubmit.map((student, index) => {
                    let exclude_students = [];
                                                
                    if (homework.exclude_student !== null) {                            
                        const exclude_student = homework.exclude_student.split('|');
                        exclude_students = exclude_student;
                    }

                    if (exclude_students.includes(student.id.toString()))
                        return null
                    return <Chip key={index} label={student.name} sx={{ mr: "0.3rem", mb: "0.3rem" }} />
                })}
            </StyledTableCell>
            <StyledTableCell align='center' style={{ width: "4%" }}>
                <div>
                    <InfoIcon 
                        fontSize='medium' 
                        sx={{ cursor: "pointer" }} 
                        onClick={handleOpen}    
                    />
                    <InfoModal 
                        homework={homework} 
                        open={open} 
                        handleClose={handleClose} 
                        StyledTableCell={StyledTableCell}
                        StyledTableRow={StyledTableRow}
                    />
                </div>
            </StyledTableCell>
        </StyledTableRow>
    )
}

export default EachTableRow