import React from 'react'
import { tokens } from "../../theme";
import { Box, IconButton, useTheme } from "@mui/material";
import Loading from '../../components/Loading';
import TeacherCard from '../teachers/TeacherCard';
import Header from "../../components/Header";
import Axios from 'axios';
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { motion, AnimatePresence } from "framer-motion";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

const PotentialTeachers = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [loading, setLoading] = React.useState(true);
    const [potentialTeachers, setPotentialTeachers] = React.useState([]);
    const [search, setSearch] = React.useState(''); // search by name
    const [emailSearch, setEmailSearch] = React.useState(''); // search by personal email
    const [teacherUpdated, setTeacherUpdated] = React.useState(false);

    async function fetchPotentialTeachers() {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/teachers?potential_teachers=true`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setPotentialTeachers(response.data);
            setLoading(false);
        }).catch((error) => {
            if (error.response.status === 404) {
                setPotentialTeachers([]);
                setLoading(false);
                return;
            } else if (error.response.status === 401) {
                handleLogout();
                return;
            }
            alert(error.response.data.message);
        });
        setTeacherUpdated(false);
    };

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Potential Teachers');
        fetchPotentialTeachers();
    }, [teacherUpdated]);

    return (
        <Box m="20px" marginX={3} marginY={3} >
            <Header title="Potential Teachers" subtitle="A ONE Institute" />
            { loading ? 
                <Loading />
            : <>
                <Box display="flex" justifyContent="left" gap={2} p={0} minHeight={70} mb={2}>
                    {/* SEARCH BAR */}
                    <Box
                        display="flex"
                        backgroundColor={colors.primary[600]}
                        borderRadius="3px"
                        boxShadow={10}
                        mb={2}
                        maxWidth={300}
                        minWidth={220}
                    >
                        <InputBase 
                            sx={{ ml: 2, flex: 1 }} 
                            onChange={(e) => setSearch(e.target.value)} 
                            placeholder="Search by Name" 
                        />
                        <IconButton type="button" sx={{ p: 1 }}>
                            <SearchIcon />
                        </IconButton>
                    </Box>

                    <Box
                        display="flex"
                        backgroundColor={colors.primary[600]}
                        borderRadius="3px"
                        boxShadow={10}
                        mb={2}
                        maxWidth={300}
                        minWidth={220}
                    >
                        <InputBase 
                            sx={{ ml: 2, flex: 1 }} 
                            onChange={(e) => setEmailSearch(e.target.value)} 
                            placeholder="Search by Personal Email" 
                        />
                        <IconButton type="button" sx={{ p: 1 }}>
                            <SearchIcon />
                        </IconButton>
                    </Box>
                </Box>

                {/* TEACHER CARDS */}
                <motion.div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                        rowGap: "1rem",
                        columnGap: "1rem",    
                    }}
                    layout
                >
                    <AnimatePresence>
                        {potentialTeachers
                            .filter((teacher) => {
                                return search === '' 
                                    ? teacher 
                                    : teacher.first_name?.toLowerCase().includes(search.toLowerCase()) || teacher.last_name?.toLowerCase().includes(search.toLowerCase()); 
                            })
                            .filter((teacher) => {
                                return emailSearch === ''
                                    ? teacher
                                    : teacher.personal_email?.toLowerCase().includes(emailSearch.toLowerCase())
                                    || teacher.personal_email_2?.toLowerCase().includes(emailSearch.toLowerCase());
                            })
                            .map((teacher) => {
                                return (
                                    <TeacherCard teacher={teacher} key={teacher.id} setTeacherUpdated={setTeacherUpdated} />
                                );
                            })
                        }
                    </AnimatePresence>
                </motion.div>
            </>}
        </Box>
    )
}

export default PotentialTeachers