import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useMediaQuery from '@mui/material/useMediaQuery';
import Axios from 'axios';
import ErrorSnackbar from '../../../components/ErrorSnackbar';
import UploadComp from '../../../components/UploadComp';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
// import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function AdminActionAccordion({ 
    courseId,
    students,
    userRole,
    studentsStartEndDateMap, 
    authorDisabledForAdminActions, 
    lectureModules, 
    setBackdropOpen, 
    setSuccessAlertOpen, 
    setErrorAlertOpen, 
    setSuccessAlertMessage,
    setErrorAlertMessage,
    classMaterials,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [files, setFiles] = React.useState([]);
    const [dueDate, setDueDate] = React.useState(null);
    const [lecture, setLecture] = React.useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const matches = useMediaQuery('(min-width:90rem)');
    const [tinyLoading, setTinyLoading] = React.useState(false);

    const [materialChoice, setMaterialChoice] = React.useState('file upload'); // ['file upload', 'class material']
    const [classMaterial, setClassMaterial] = React.useState(null);
    const [pageFrom, setPageFrom] = React.useState(0);
    const [pageTo, setPageTo] = React.useState(0);
    const [hwQuestions, setHwQuestions] = React.useState([]);
    const [excludedQuestions, setExcludedQuestions] = React.useState([]);
    const [excludedQuestionLabels, setExcludedQuestionLabels] = React.useState([]);
    const [selectedStudents, setSelectedStudents] = React.useState([]);

    // for teachers and admins
    const [lectureStartdate, setLectureStartdate] = React.useState(dayjs());
    
    React.useEffect(() => {     
        lectureModules.forEach((module) => {
            if (module.id === lecture) {
                setLectureStartdate(module.start.split("T")[0]);
            }
        });
    }, [lecture]);

    let current_students = [];
    if (students !== null && students !== undefined) {
        for (let i = 0; i < students.length; i++) {
            const end_date = dayjs(studentsStartEndDateMap[students[i].id].end_date);
            const start_date = dayjs(studentsStartEndDateMap[students[i].id].start_date);
        
            if (dayjs(lectureStartdate).isAfter(end_date) || dayjs(lectureStartdate).isBefore(start_date)) {
                continue;
            }
            current_students.push(students[i]);
        }
    }
    

    const handleLectureChange = (event) => {
        event.preventDefault();
        setLecture(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (lecture === '') {
            setErrorSnackbarOpen(true);
            return;
        }

        if (dueDate === null) {
            setErrorSnackbarOpen(true);
            return;
        }

        if (materialChoice === 'class material' && (classMaterial === null || classMaterial === '')) {
            setErrorAlertMessage("Please select class material!");
            setErrorAlertOpen(true);
            return;
        }

        if (materialChoice === 'class material' && (pageFrom === 0 || pageTo === 0)) {
            setErrorAlertMessage("Please select page range!");
            setErrorAlertOpen(true);
            return;
        }

        if (materialChoice === 'class material' && pageTo < pageFrom) {
            setErrorAlertMessage("Invalid page range!");
            setErrorAlertOpen(true);
            return;
        }

        let selected_students = "";
        let unselected_students = "";

        // selected한 학생이 없으면 select에 있는 모든 학생에게 숙제 부여한다
        if (selectedStudents === null || selectedStudents.length === 0) {            
            for (let i = 0; i < current_students.length; i++) {
                selected_students += current_students[i].id;
                if (i !== current_students.length - 1) {
                    selected_students += "|";
                }
            }
        }
        // selected한 학생이 있으면 select에 있는 학생에게만 숙제 부여
        if (selectedStudents !== null && selectedStudents.length > 0) {
            for (let i = 0; i < selectedStudents.length; i++) {
                selected_students += selectedStudents[i].id;
                if (i !== selectedStudents.length - 1) {
                    selected_students += "|";
                }
            }
        }
        let students_list = [];
        for (let i = 0; i < current_students.length; i++) {
            students_list.push(current_students[i].id);
        }
        
        const selectedStudentsList = selected_students?.split("|")?.toString();
        const unselectedFilter = students_list.filter(item => !selectedStudentsList.includes(item));

        for (let i = 0; i < unselectedFilter.length; i++) {
            unselected_students += unselectedFilter[i];
            if (i !== unselectedFilter.length - 1) {
                unselected_students += "|";
            }
        }
                     
        setBackdropOpen(true);

        const data = new FormData(event.currentTarget);
        if (materialChoice !== 'class material') {
            // normal homework (file upload or only title and instructions)
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                if (files[i].name !== undefined 
                    && files[i].name !== null 
                    && files[i].name !== ""
                    && files[i].name.length > 150) {
                    setErrorAlertMessage("File name should be less than 150 characters!");
                    setErrorAlertOpen(true);
                    setBackdropOpen(false);
                    return;
                }
                formData.append('file', files[i], files[i].name);
            }

            formData.append('title', data.get('title'));
            formData.append('posted_by', data.get('author'));
            formData.append('content', data.get('instructions'));
            formData.append('due_date', dueDate);
            formData.append('exclude_student', unselected_students);
            formData.append('unexclude_student', selected_students);
        
            await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/events/${lecture}/homeworks`, formData, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
            ).then(async (response) => {
                setBackdropOpen(false);
                setSuccessAlertMessage("Homework created successfully!");
                setSuccessAlertOpen(true);                

                const homework_id = response.data.homework_id;
                if (homework_id) {
                    await Axios.post(`${process.env.REACT_APP_URL}/api/v1/mails/courses/${courseId}/homeworks/${homework_id}`, {
                        // no params but still need this bracket for headers
                        selected_students: selected_students,
                    }, {
                        headers: {
                            Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,                            
                        },
                    }).then((res) => {                        
                        // return;
                        window.location.reload();
                    }).catch((error) => {
                        console.log(error);
                        setErrorAlertMessage(error.response.data.message || error.response.data.error);
                        setErrorAlertOpen(true);
                    });
                } else {
                    window.location.reload();
                }
            }).catch((error) => {
                console.log(error);
                setBackdropOpen(false);
                setErrorAlertMessage(error.response.data.message || error.response.data.error);
                setErrorAlertOpen(true);
            });
        } else {
            const title = data.get('title');
            const posted_by = data.get('author');
            const content = data.get('instructions');
            const due_date = dueDate;
            const page_from = pageFrom;
            const page_to = pageTo;
            const exceptions = excludedQuestions.join('|');
            const exclude_student = unselected_students;
            const unexclude_student = selected_students;


            // class material homework
            await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/events/${lecture}/homeworks/class-materials/${classMaterial.id}`, {
                title,
                posted_by,
                content,
                due_date,
                page_from,
                page_to,
                exceptions,
                exclude_student,
                unexclude_student
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(async (response) => {
                setBackdropOpen(false);
                setSuccessAlertMessage("Homework created successfully!");
                setSuccessAlertOpen(true);

                const homework_id = response.data.homework_id;
                if (homework_id) {
                    await Axios.post(`${process.env.REACT_APP_URL}/api/v1/mails/courses/${courseId}/homeworks/${homework_id}`, {
                        // no params but still need this bracket for headers
                        selected_students: selected_students,
                    }, {
                        headers: {
                            Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                        },
                    }).then((res) => {
                        window.location.reload();
                    }).catch((error) => {
                        console.log(error);
                        setErrorAlertMessage(error.response.data.message || error.response.data.error);
                        setErrorAlertOpen(true);
                    });
                } else {
                    window.location.reload();
                }
            }).catch((error) => {
                console.log(error);
                setBackdropOpen(false);
                setErrorAlertMessage(error.response.data.message || error.response.data.error);
                setErrorAlertOpen(true);
            });
        }
    };

    const fetchHWQuestions = async (e, pageTo) => {
        e.preventDefault();

        if (classMaterial === null || classMaterial === '') {
            setErrorAlertMessage("Please select class material!");
            setErrorAlertOpen(true);
            return;
        }

        if (classMaterial.textbook_id === null || classMaterial.textbook_id === '') {
            setErrorAlertMessage("There are no questions for this class material in the database!");
            setErrorAlertOpen(true);
            return;
        }

        if (pageFrom === 0 || pageTo === 0) {
            setErrorAlertMessage("Please select page range!");
            setErrorAlertOpen(true);
            return;
        }

        if (pageTo < pageFrom) {
            setErrorAlertMessage("Invalid page range!");
            setErrorAlertOpen(true);
            return;
        }

        setTinyLoading(true);

        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/hw-questions`, {
            params: {
                textbook_id: classMaterial.textbook_id,
                page_from: pageFrom,
                page_to: pageTo,
            },
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            console.log(response.data);
            setHwQuestions(response.data);
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage(error.response.data.message || error.response.data.error);
            setErrorAlertOpen(true);
        });

        setTinyLoading(false);
    };

    const handleExcludedQuestionsChange = (event) => {
        const {
            target: { value },
        } = event;

        const currVal = value;
        const preVal = excludedQuestionLabels;
        let diff = null;
        if (currVal.length > preVal.length) {
            diff = currVal.filter((x) => !preVal.includes(x));
        } else {
            diff = preVal.filter((x) => !currVal.includes(x));
        }
        const question = hwQuestions.find((question) => question.question_label === diff[0]);

        const questionId = question.id;
        const excludedQuestionsList = [...excludedQuestions];
        if (excludedQuestionsList.includes(questionId)) {
            // remove
            const index = excludedQuestionsList.indexOf(questionId);
            if (index > -1) {
                excludedQuestionsList.splice(index, 1);
            }
        } else {
            excludedQuestionsList.push(questionId);
        }

        setExcludedQuestions(excludedQuestionsList);
        setExcludedQuestionLabels(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <div style={{
            width: "100%",
            marginBottom: "1rem",
        }}>
            <Accordion
                style={{
                    backgroundColor: colors.primary[750],
                }}
                component="div"
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h6'>Create Homework</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                            display: "flex",
                            flexDirection: "column",
                            mr: "1rem",
                        }}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <FormControl fullWidth sx={{ minWidth: "49%", backgroundColor: colors.primary[600] }}>
                            <InputLabel id="demo-simple-select-label">Select a module</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={lecture}
                                label="Select a module"
                                onChange={handleLectureChange}
                            >
                                {lectureModules.map((lectureModule) => {
                                    return (
                                        <MenuItem key={lectureModule.id} value={lectureModule.id}>
                                            {lectureModule.title}, {new Date(lectureModule.start).toDateString()}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                label="Due Date"
                                value={dueDate}
                                onChange={(newValue) => setDueDate(newValue)}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        backgroundColor: colors.primary[600],
                                        width: "16rem",
                                        ...!matches && {
                                            width: "100%",
                                        },
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1rem",
                            minWidth: "100%",
                        }}>
                            <TextField
                                id="filled-basic"
                                label="Title"
                                variant="filled"
                                name='title'
                                sx={{
                                    flex: "1",
                                    maxWidth: "50%",
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[600],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                            />
                            <TextField
                                id="filled-basic"
                                label="Author"
                                variant="filled"
                                name='author'
                                sx={{
                                    flex: "1",
                                    maxWidth: "50%",
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[600],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                                defaultValue={localStorage.getItem('userName')}
                                inputProps={{ readOnly: authorDisabledForAdminActions }}
                            />
                        </Box>
                        {userRole === 'admin' ? (
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "1rem",
                                minWidth: "100%",
                            }}>
                                {/* For admin */}
                                {/* a list of students*/}
                                <Autocomplete
                                    multiple
                                    limitTags={4}
                                    id="student-list"
                                    options={current_students !== null ? current_students : []}
                                    getOptionLabel={(current_students) => current_students.first_name + " " + current_students.last_name}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="숙제를 부여할 학생 (아무도 선택하지 않거나 모두 선택하면 모든 학생에게 숙제가 부여됩니다)"
                                            // label="Select students to exclude from this homework"
                                        />
                                    )}
                                    sx={{ width: "100%" }}                                
                                    onChange={(event, value) => {
                                        setSelectedStudents(value);
                                    }}                                
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                />
                            </Box>
                            ) : (
                            <></>
                            )
                        }
                        <TextField
                            id="filled-basic"
                            label="Instructions"
                            variant="filled"
                            name='instructions'
                            fullWidth={true}
                            multiline={true}
                            rows={10}
                            sx={{
                                minWidth: "100%",
                                borderRadius: "5px",
                                '& .MuiFilledInput-root': {
                                    backgroundColor: colors.primary[600],
                                    ":focus": {
                                        backgroundColor: 'white',
                                    },
                                },
                            }}
                        />
                        {/* <FormControl style={{ width: '100%', marginTop: '20px' }}>
                            <FormLabel id="demo-radio-buttons-group-label">Homework Type</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                value={materialChoice}
                                name="controlled-radio-buttons-group"
                                row
                                onChange={(e) => setMaterialChoice(e.target.value)}
                                style={{
                                    display: "flex",
                                }}
                            >
                                <FormControlLabel value="file upload" control={<Radio />} label="default" />
                                <FormControlLabel value="class material" control={<Radio />} label="Class Material" />
                            </RadioGroup>
                        </FormControl> */}
                        {materialChoice === 'class material' ? (<>
                            <Box
                                display="flex"
                                minWidth="100%"
                                justifyContent="flex-start"
                                sx={{
                                    gap: "1rem",
                                }}
                            >
                                <FormControl fullWidth sx={{ maxWidth: "49%", backgroundColor: colors.primary[600] }}>
                                    <InputLabel id="demo-simple-select-label">Class Material</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={classMaterial ? classMaterial : ''}
                                        label="Select a class material"
                                        onChange={(e) => {
                                            setClassMaterial(e.target.value);
                                            setPageFrom(0);
                                            setPageTo(0);
                                            setExcludedQuestions([]);
                                            setExcludedQuestionLabels([]);
                                        }}
                                    >
                                        <MenuItem value={''}>
                                            None
                                        </MenuItem>
                                        {classMaterials.map((classMaterial) => {
                                            return (
                                                <MenuItem key={classMaterial.id} value={classMaterial}>
                                                    {classMaterial.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            {classMaterial && classMaterial !== null ? (<>
                                <Box
                                    display="flex"
                                    minWidth="100%"
                                    justifyContent="flex-start"
                                    sx={{
                                        gap: "1rem",
                                    }}
                                >
                                    <FormControl fullWidth sx={{ maxWidth: "33%", backgroundColor: colors.primary[600] }}>
                                        <InputLabel id="demo-simple-select-label">From (page)</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={pageFrom}
                                            label="Select a page"
                                            onChange={(e) => {
                                                setPageFrom(e.target.value)
                                                setPageTo(0)
                                                setExcludedQuestions([]);
                                                setExcludedQuestionLabels([]);
                                            }}
                                        >
                                            <MenuItem value={0}>
                                                None
                                            </MenuItem>
                                            {Array.from({ length: classMaterial?.textbook?.pages }, (_, i) => i + 1).map((page) => {
                                                return (
                                                    <MenuItem key={page} value={Number(page).toFixed(0)}>
                                                        {page}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth sx={{ maxWidth: "33%", backgroundColor: colors.primary[600] }}>
                                        <InputLabel id="demo-simple-select-label">To (page)</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={pageTo}
                                            label="Select a page"
                                            onChange={(e) => {
                                                setPageTo(e.target.value);
                                                fetchHWQuestions(e, e.target.value);
                                                setExcludedQuestions([]);
                                                setExcludedQuestionLabels([]);
                                            }}
                                        >
                                            <MenuItem value={0}>
                                                None
                                            </MenuItem>
                                            {Array.from({ length: classMaterial?.textbook?.pages }, (_, i) => i + 1).map((page) => {
                                                if (page < pageFrom) return null;

                                                return (
                                                    <MenuItem key={page} value={Number(page).toFixed(0)}>
                                                        {page}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    minWidth="100%"
                                    justifyContent="flex-start"
                                    position="relative"
                                    sx={{
                                        gap: "1rem",
                                    }}
                                >
                                    <Typography variant="h6" color="text.secondary">
                                        Total Questions: {hwQuestions.length}
                                    </Typography>
                                    {tinyLoading ? (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                width: '20px',
                                                height: '20px',
                                                zIndex: 1000,
                                            }}
                                        >
                                            <CircularProgress color="inherit" size={20} />
                                        </Box>
                                    ) : null}
                                    {hwQuestions.length > 0 && !tinyLoading && (
                                        <FormControl sx={{ m: 1, width: 300 }}>
                                            <InputLabel id="demo-multiple-checkbox-label">
                                                Select Questions to Exclude
                                            </InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={excludedQuestionLabels}
                                                onChange={handleExcludedQuestionsChange}
                                                input={<OutlinedInput label="Excluded Questions" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {hwQuestions.map((question) => {
                                                    return (
                                                        <MenuItem key={question.id} value={question.question_label}>
                                                            <Checkbox checked={excludedQuestionLabels?.indexOf(question.question_label) > -1} />
                                                            <ListItemText primary={question.question_label} />
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Box>
                            </>) : null}
                        </>) : (
                            <Box
                                display="flex"
                                minWidth="100%"
                                flexDirection="column"
                                justifyContent="flex-start"
                                sx={{
                                    gap: "1rem",
                                    ...matches && {
                                        justifyContent: "space-between",
                                        flexDirection: "row",
                                    },
                                }}
                            >
                                <UploadComp files={files} setFiles={setFiles} />
                            </Box>
                        )}
                        <Button
                            type="submit"
                            variant="contained"
                            style={{
                                backgroundColor: colors.primary[300],
                                color: "white",
                                float: "right",
                                marginTop: "0.5rem",
                                marginBottom: "1rem",
                                width: "10rem",
                            }}
                        >
                            Create
                        </Button>
                    </Box>
                </AccordionDetails>
                <ErrorSnackbar errorMsg="Please select module and set due date!" open={errorSnackbarOpen} setOpen={setErrorSnackbarOpen} />
            </Accordion>
        </div>
    )
}