import React from 'react'
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import TextField from '@mui/material/TextField';
import UploadComp from '../../../components/UploadComp';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Axios from 'axios';

const ReplyBox = ({
    setReplyOpen,
    userRole,
    userId,
    courseId,
    questionId,
    setBackdropOpen,
    setSuccessAlertOpen,
    setErrorAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertMessage,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [files, setFiles] = React.useState([]);
    const matches = useMediaQuery('(min-width:90rem)');


    const handleReplySubmit = async ( event ) => {
        event.preventDefault();

        setBackdropOpen(true);

        const data = new FormData(event.currentTarget);

        const author = data.get('author') || localStorage.getItem('userName');

        if (author === "" || data.get('reply') === "") {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Author and Reply fields cannot be empty!");
            setBackdropOpen(false);
            return;
        }

        if (author.length > 255) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Author name is too long!");
            setBackdropOpen(false);
            return;
        }

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            if (files[i].name !== undefined
                && files[i].name !== null
                && files[i].name !== ""
                && files[i].name.length > 150) {
                setErrorAlertOpen(true);
                setErrorAlertMessage("File name should be less than 150 characters");
                setBackdropOpen(false);
                return;
            }
            formData.append('file', files[i], files[i].name);
        }

        formData.append('author', author);
        formData.append('reply', data.get('reply'));
        formData.append('author_role', userRole);
        formData.append('author_uuid', userId);

        if (userRole === "admin" || userRole === "teacher") {
            formData.append('answered', true);
        } else {
            // student's reply, so answered should be false
            formData.append('answered', false);
        }
        
        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/course-questions/${questionId}/replies`, formData, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                'Content-Type': 'multipart/form-data',
            },
        }).then((res) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(res.data.message);
            setBackdropOpen(false);
            window.location.reload();
        }).catch((err) => {
            setErrorAlertOpen(true);
            setErrorAlertMessage(err.response.data.message);
            setBackdropOpen(false);
        });
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                mx: "0.5rem",
                px: "0.5rem",
                pt: "0.5rem",
                gap: "0.5rem",
            }}
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleReplySubmit}
        >
            <Box 
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                }}
            >
                <TextField
                    id="filled-basic"
                    label="Author"
                    variant="filled"
                    name='author'
                    sx={{
                        flex: "1",
                        '& .MuiFilledInput-root': {
                            ":focus": {
                                backgroundColor: 'white',
                            },
                        },
                    }}
                    defaultValue={localStorage.getItem('userName')}
                    inputProps={{ readOnly: userRole !== "admin" ? true : false }}
                    disabled={userRole !== "admin" ? true : false}
                />
            </Box>
            <TextField
                id="filled-basic"
                label="Reply"
                variant="filled"
                name='reply'
                fullWidth={true}
                multiline={true}
                rows={5}
                sx={{
                    minWidth: "100%",
                    borderRadius: "5px",
                    '& .MuiFilledInput-root': {
                        ":focus": {
                            backgroundColor: 'white',
                        },
                    },
                }}
            />
            <Box
                display="flex"
                minWidth="100%"
                flexDirection="column"
                justifyContent="flex-start"
                sx={{
                    gap: "1rem",
                    ...matches && {
                        justifyContent: "space-between",
                        flexDirection: "row",
                    },
                }}
            >
                <UploadComp files={files} setFiles={setFiles} />
            </Box>
            <Box
                sx={{
                    ml: "auto",
                }}
            >
                <Button
                    onClick={() => setReplyOpen(false)}
                    variant="contained"
                    style={{
                        backgroundColor: colors.grey[800],
                        color: colors.primary[200],
                        marginTop: "0.5rem",
                        marginBottom: "1rem",
                        marginRight: "0.5rem",
                        width: "8rem",
                    }}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    style={{
                        backgroundColor: colors.primary[300],
                        color: "white",
                        marginTop: "0.5rem",
                        marginBottom: "1rem",
                        width: "8rem",
                    }}
                >
                    Post Reply
                </Button>
            </Box>
        </Box>
    )
}

export default ReplyBox