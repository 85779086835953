import React from 'react'
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import MoreVertIcon from "../../../components/MoreVertIcon"
import DeleteDialog from '../../../components/DeleteDialog';
import Axios from 'axios';
import UpdateReplyModal from './UpdateReplyModal';

var options_without_time = {
    year: "numeric",
    month: "short",
    day: "numeric"
};

const Reply = ({
    reply,
    userRole,
    userId,
    courseId,
    questionId,
    setBackdropOpen,
    setSuccessAlertOpen,
    setErrorAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertMessage,
    stringAvatar,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMoreVertIconClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopOverClose = () => {
        setAnchorEl(null);
    };
    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;
    
    const handleDeleteReply = async () => {
        setAnchorEl(null);

        setBackdropOpen(true);
        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/course-questions/${questionId}/replies/${reply.id}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((res) => {
            setBackdropOpen(false);
            setSuccessAlertMessage("Reply has been deleted successfully!");
            setSuccessAlertOpen(true);
            window.location.reload();
        }).catch((err) => {
            setBackdropOpen(false);
            setErrorAlertMessage(err.response.data.message || err.response.data.error || "Something went wrong!");
            setErrorAlertOpen(true);
            console.log(err);
        });
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                pl: "3rem",
                py: "1rem",
                borderBottom: "0.1px solid #a3a3a3",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Avatar {...stringAvatar(reply.author)} style={{ width: "2rem", height: "2rem" }} />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography variant="h7" sx={{ml: "1rem"}}>
                            {reply.author}
                        </Typography>
                        <Typography variant="h8" color={colors.grey[700]} sx={{ml: "1rem"}}>
                            Posted on: {new Date(reply.createdAt).toLocaleDateString("en", options_without_time)}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    {userId === reply.author_uuid && userRole === reply.author_role && 
                    <>
                        <MoreVertIcon handleClick={handleMoreVertIconClick} />
                        <Popover
                            id={popoverId}
                            open={popoverOpen}
                            anchorEl={anchorEl}
                            onClose={handlePopOverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <UpdateReplyModal
                                courseId={courseId} 
                                questionId={questionId}
                                userRole={userRole}
                                reply={reply} 
                                setBackdropOpen={setBackdropOpen}
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                setErrorAlertOpen={setErrorAlertOpen}
                                setSuccessAlertMessage={setSuccessAlertMessage}
                                setErrorAlertMessage={setErrorAlertMessage}
                            />
                            <DeleteDialog
                                handleDelete={handleDeleteReply}
                                setAnchorEl={setAnchorEl}
                                dialogTitle={"Are you sure you want to delete the reply?"} 
                            />
                        </Popover>
                    </>}
                </Box>
            </Box>
            <Typography variant='h7' sx={{mt: "1rem", ml: "1rem"}}>
                {reply.reply && reply.reply.split("\n").map((line, index) => {
                    return (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    )
                })}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    mt: "1rem",
                    mx: "1rem"
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h7" color={colors.grey[700]}>
                        {reply.question_reply_files?.length === 1 ? "Attached 1 file" : `Attached ${reply.question_reply_files?.length} files`}
                    </Typography>
                    {reply.question_reply_files?.map((file) => {
                        return (
                            <a key={file.id} href={file.file_url} style={{ color: colors.grey[700] }}>
                                {file.name}
                            </a>
                        )
                    })}
                </Box>
            </Box>
        </Box>
    )
}

export default Reply