import React from 'react'
import Modal from '@mui/material/Modal';
import { tokens } from "../../theme";
import { Box, useTheme, Typography, Divider } from "@mui/material";
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const InfoModal = ({ homework, open, handleClose, StyledTableCell, StyledTableRow }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: colors.primary[750],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };      

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <InfoOutlinedIcon fontSize='large' />
                <Typography id="modal-modal-title" variant="h3" fontWeight="bold">
                    Student Info
                </Typography>
            </Box>
            <Divider sx={{ mt: 2 }} />
            <Typography component="div" id="modal-modal-description" sx={{ mt: 2 }}>
                <TableContainer component={Paper} sx={{ maxWidth: "100%" }}>
                    <Table aria-label="simple table">
                        <TableHead><StyledTableRow>
                            <StyledTableCell style={{ width: "34%" }}>Name</StyledTableCell>
                            <StyledTableCell style={{ width: "33%" }}>Phone</StyledTableCell>
                            <StyledTableCell style={{ width: "33%" }}>Parent's Phone</StyledTableCell>
                        </StyledTableRow></TableHead>
                        <TableBody>
                            {homework.studentsWhoDidNotSubmit.map((student, index) => {
                                let exclude_students = [];
                                
                                if (homework.exclude_student !== null) {
                                    const exclude_student = homework.exclude_student.split('|');
                                    exclude_students = exclude_student;
                                }
                                if (exclude_students.includes(student.id.toString()))
                                    return null     

                                return (                                                                                                    
                                    <StyledTableRow key={index}>                                        
                                        <StyledTableCell style={{ width: "34%" }}>{student.name}</StyledTableCell>
                                        <StyledTableCell style={{ width: "33%" }}>{student.phone}</StyledTableCell>
                                        <StyledTableCell style={{ width: "33%" }}>{student.parent_phone}</StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Typography>
            </Box>
        </Modal>
    )
}

export default InfoModal