import * as React from "react";
import { Box, Button, TextField, Typography, Autocomplete } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import Axios from 'axios';
import Alert from '../../components/alert/Alert';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Backdrop from "../../components/Backdrop";
import Loading from "../../components/Loading";
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Document, Page, pdfjs } from "react-pdf";
import HiddenPDF from "./HiddenPDF";
import CircularProgress from '@mui/material/CircularProgress';
// import "//unpkg.com/mathlive";
import 'mathlive';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const HwQuestions = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [loading, setLoading] = React.useState(true);
    const [pdfLoading, setPdfLoading] = React.useState(false);

    const [textbooks, setTextbooks] = React.useState([]);
    const [numPages, setNumPages] = React.useState(null);

    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");
    const isNonMobile = useMediaQuery("(min-width:600px)");

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // question data
    const [hwQuestion, setHwQuestion] = React.useState({
        textbook: null,
        page: null,
        question_type: null,
        question_label: "",
        options: [],
        answers: [],
        answer_tool: "",
    });

    // for chosen data
    const [chosenTextbook, setChosenTextbook] = React.useState(null);
    const [questionLabel, setQuestionLabel] = React.useState("");
    const [chosenQuestionType, setChosenQuestionType] = React.useState(null);
    const [options, setOptions] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [answers, setAnswers] = React.useState([]);
    const [chosenTool, setChosenTool] = React.useState("");
    const [numAnswers, setNumAnswers] = React.useState(1);

    const questionValidation = (question, parentOrChild) => {
        if (question.textbook === null) {
            setErrorAlertMessage("Please choose a textbook.");
            setErrorAlertOpen(true);
            return false;
        }
        if (question.page === null) {
            setErrorAlertMessage("Please choose a page.");
            setErrorAlertOpen(true);
            return false;
        }
        if (question.type === null) {
            setErrorAlertMessage("Please choose a question type.");
            setErrorAlertOpen(true);
            return false;
        }
        if (question.question_label === "") {
            setErrorAlertMessage("Please type in the question label.");
            setErrorAlertOpen(true);
            return false;
        }
        if (question.type === "multiple_choice" && question.options.length === 0) {
            setErrorAlertMessage("Please add options.");
            setErrorAlertOpen(true);
            return false;
        }
        return true;
    };

    const initiateVars = () => {
        setChosenQuestionType(null);
        setOptions([]);
        setQuestionLabel("");
        setAnswers([]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setBackdropOpen(true);

        /*
         * hw_questions = {
         *     class_material_id,
         *     hw_question_id,
         *     page,
         *     question_label,
         *     passage,
         *     options,
         *     type,
         *     answers,
         * }
         */

        // build the questions from the hwQuestion state
        const hw_question = {
            textbook_id: hwQuestion.textbook.id,
            page: hwQuestion.page,
            question_label: hwQuestion.question_label.trim(),
            passage: null,
            options: hwQuestion.question_type === "multiple_choice" ? hwQuestion.options.join("|") : null,
            type: hwQuestion.question_type,
            answer: hwQuestion.answers.join("|"),
            answer_tool: chosenTool === "" ? null : chosenTool,
        };

        console.log(hw_question);

        if (!questionValidation(hw_question)) {
            handleBackdropClose();
            return;
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/hw-questions`, {
            ...hw_question,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then(async (response) => {
            setSuccessAlertMessage("Homework question created successfully.");
            setSuccessAlertOpen(true);
            handleBackdropClose();

            // refresh the page
            // setTimeout(() => {
            //     window.location.reload();
            // }, 1000);
            initiateVars();

        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage("Error occurs while creating homework question.");
            setErrorAlertOpen(true);
            handleBackdropClose();
        });
    };

    // initialize states
    function initializeStates() {
        setChosenTextbook(null);
        setPage(0);
        setNumPages(null);
        setChosenQuestionType(null);
        setOptions([]);
        setQuestionLabel("");
        setAnswers([]);
        setPdfLoading(false);
        setNumAnswers(1);

        setHwQuestion({
            textbook: null,
            page: null,
            question_type: null,
            question_label: "",
            options: [],
            answers: [],
            tool: "",
        });
    }

    React.useEffect(() => {
        setLoading(true);
        localStorage.setItem('selectedMenu', 'Add Textbook Qs');

        // fetch all classes
        async function fetchTextbooks() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/textbooks`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                const textbooks = response.data;
                for (let i = 0; i < textbooks.length; i++) {
                    textbooks[i].label = textbooks[i].title;
                    textbooks[i].value = textbooks[i].id;
                }
                setTextbooks(textbooks);
            }).catch((error) => {
                console.log(error);
            });

            setLoading(false);
        }

        initializeStates();
        fetchTextbooks();
    }, []);

    const questionTypes = [
        { label: "Multiple Choice", value: "multiple_choice" },
        // { label: "True/False", value: "true_false" },
        { label: "Short Answer", value: "short_answer" },
        { label: "Long Answer", value: "long_answer" },
        { label: "File Upload", value: "file_upload" },
        // { label: "Essay", value: "essay" },
    ];

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPdfLoading(false);
    }

    const documentRender = () => {
        return (
            <Document 
                file={chosenTextbook?.file_url} 
                onLoadSuccess={onDocumentLoadSuccess} 
                onContextMenu={(e) => e.preventDefault()}
                className="pdf-container"
            >
                <div>
                    <Page
                        pageNumber={page}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        className="react-pdf__Page__canvas" 
                    />
                </div>
            </Document>
        );
    };

    return (
        <Box sx={{ width: "100%" }}>
            { loading || textbooks === null ?
                <Loading />
            : <Box m="30px" marginX={3} marginY={3} position="relative">
                <Header title="Add Textbook Question" subtitle="A ONE Institute" />

                1. Choose a textbook < br/>
                2. Choose a page < br/>
                3. Type in the question label (number or letter) < br/>
                4. Choose a question type < br/>
                4.1. if it's a multiple choice question, add options < br/>
                4.2. if it's a short or long answer, select a answer tool < br/>
                5. Add an answer < br/>

                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "20px",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                        }}
                    >
                        <Box
                            sx={{
                                width: "46%",
                            }}
                            padding={4}
                            boxShadow={3}
                            borderRadius={3}
                            m={1}
                            bgcolor={colors.primary[600]}
                        >
                            <Typography
                                variant="h4"
                                fontWeight="bold"
                                mb={3}
                            >
                                Question Details
                            </Typography>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                sx={{
                                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                }}
                            >
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={textbooks}
                                    sx={{ gridColumn: "span 4" }}
                                    renderInput={(params) => <TextField {...params} label="1. Choose Textbook" />}
                                    onChange={(event, newValue) => {
                                        setChosenTextbook(newValue);
                                        hwQuestion.textbook = newValue;
                                    }}
                                />
                                <FormControl 
                                    disabled={chosenTextbook === null || pdfLoading}
                                    sx={{
                                        minWidth: 120,
                                        maxWidth: 300,
                                        gridColumn: "span 2",
                                        position: 'relative',
                                    }}
                                >
                                    {pdfLoading ? (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                width: '20px',
                                                height: '20px',
                                                zIndex: 1000,
                                            }}
                                        >
                                            <CircularProgress color="inherit" size={20} />
                                        </Box>
                                    ) : null}
                                    <InputLabel id="demo-simple-select-label">2. Choose page</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={page}
                                        label="Page"
                                        onChange={(event) => {
                                            setPage(event.target.value);
                                            hwQuestion.page = event.target.value;
                                        }}
                                    >
                                        <MenuItem value={0}>
                                            <em>None</em>
                                        </MenuItem>
                                        {Array(numPages).fill(0).map((_, index) => {
                                            return (
                                                <MenuItem key={index} value={index + 1}>{index + 1}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                                <TextField
                                    disabled={chosenTextbook === null || page === 0}
                                    variant="filled"
                                    type="text"
                                    label={"3. Type in the Question Label"}
                                    onChange={(e) => {
                                        setQuestionLabel(e.target.value);
                                        hwQuestion.question_label = e.target.value;
                                    }}
                                    name={"question-label"}
                                    value={questionLabel}
                                    sx={{ gridColumn: "span 2" }}
                                />
                                <Autocomplete
                                    disabled={chosenTextbook === null || page === 0}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={questionTypes}
                                    sx={{ gridColumn: "span 2" }}
                                    renderInput={(params) => <TextField {...params} label="4. Choose Question Type" />}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.value === value.value;
                                    }}
                                    onChange={(event, newValue) => {
                                        setAnswers([]);
                                        setOptions([]);
                                        setHwQuestion({
                                            ...hwQuestion,
                                            question_type: newValue?.value,
                                            answers: [],
                                            options: [],
                                        });
                                        setChosenQuestionType(newValue?.value);
                                        setNumAnswers(1);
                                    }}
                                />
                            </Box>
                            <Box mt={2} sx={{ display: "flex", gap: "20px", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column" }}>
                                {chosenQuestionType === "multiple_choice" && ( <>
                                    <Typography variant="h6" fontWeight="bold">
                                        Options (ex: A., B., C., D. or 1., 2., 3., 4., 1), 2), etc.)
                                    </Typography>
                                    <Box display='flex' justifyContent='start' alignItems='center' flexWrap='wrap' gap={2}>
                                        {options.map((option, index) => {
                                            return (
                                                <Box position="relative" key={index}>
                                                    <TextField
                                                        key={index}
                                                        variant="filled"
                                                        type="text"
                                                        label={`Option ${index + 1}`}
                                                        onChange={(e) => {
                                                            setOptions(options.map((opt, i) => i === index ? e.target.value : opt));
                                                            setHwQuestion({
                                                                ...hwQuestion,
                                                                options: options.map((opt, i) => i === index ? e.target.value : opt),
                                                                answers: [],
                                                            });
                                                            setAnswers([]);
                                                        }}
                                                        name={`option-${index}`}
                                                        value={option}
                                                        sx={{ maxWidth: "300px"}}
                                                    />
                                                    <CloseIcon 
                                                        sx={{
                                                            position: 'absolute',
                                                            right: 0,
                                                            top: 0,
                                                            color: colors.redAccent[400],
                                                            cursor: 'pointer',
                                                            zIndex: 1000,
                                                        }}
                                                        fontSize="small"
                                                        onClick={() => {
                                                            setOptions(options.filter((_, i) => i !== index));
                                                            setHwQuestion({
                                                                ...hwQuestion,
                                                                options: options.filter((_, i) => i !== index),
                                                                answers: [],
                                                            });
                                                            setAnswers([]);
                                                        }}
                                                    />
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    <Box display='flex' justifyContent='start' alignItems='center' flexDirection='column' gap={2}>
                                        <Button type="button" color="primary" variant="contained" onClick={() => {setOptions([...options, ''])}}>Add Option</Button>
                                    </Box>
                                </>)}
                            </Box>
                            {chosenQuestionType !== null && ( <>
                                <Box mt={2} sx={{ display: "flex", gap: "20px", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                    {chosenQuestionType === "multiple_choice" ? (
                                        <FormControl sx={{ minWidth: 300, maxWidth: 400 }}>
                                            <InputLabel id="demo-simple-select-label">Choose an answer</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={answers}
                                                label="Answer"
                                                onChange={(event) => {
                                                    setAnswers([
                                                        event.target.value
                                                    ]);
                                                    setHwQuestion({
                                                        ...hwQuestion,
                                                        answers: [event.target.value]
                                                    });
                                                }}
                                            >
                                                {options.map((option, index) => {
                                                    return (
                                                        <MenuItem key={index} value={option}>{option}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>                                
                                    ) : chosenQuestionType === "short_answer" || chosenQuestionType === "long_answer" ? (
                                        <>
                                            <FormControl sx={{ minWidth: 300, maxWidth: 400 }}>
                                                <InputLabel id="demo-simple-select-label">Choose a answer tool</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={chosenTool}
                                                    label="Answer"
                                                    onChange={(event) => {
                                                        setChosenTool(event.target.value);
                                                        setAnswers([]);
                                                        setHwQuestion({
                                                            ...hwQuestion,
                                                            answer_tool: event.target.value,
                                                        });
                                                        setNumAnswers(1);
                                                    }}
                                                >
                                                    <MenuItem value={""}>None</MenuItem>
                                                    <MenuItem value={"textfield"}>Text Field</MenuItem>
                                                    <MenuItem value={"mathlive"}>Mathlive</MenuItem>
                                                    <MenuItem value={"tinymce"}>TinyMCE</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <Box display="flex" justifyContent="start" alignItems="center" flexDirection="column" gap={2}>
                                                {chosenQuestionType === "short_answer" && chosenTool === "textfield" ? (
                                                    Array(numAnswers).fill(0).map((_, index) => {
                                                        return (
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    gap: "10px",
                                                                    alignItems: "flex-start",
                                                                    width: "100%",
                                                                    position: "relative",
                                                                }}
                                                            >    
                                                                <TextField
                                                                    key={index}
                                                                    disabled={chosenQuestionType === "long_answer" || chosenQuestionType === "essay"}
                                                                    variant="filled"
                                                                    type="text"
                                                                    label={"6. Add Answer"}
                                                                    onChange={(e) => {
                                                                        let temp = hwQuestion.answers;
                                                                        temp[index] = e.target.value;
                                                                        setAnswers(temp);
                                                                        setHwQuestion({
                                                                            ...hwQuestion,
                                                                            answers: temp,
                                                                        });
                                                                    }}
                                                                    name={"answer"}
                                                                    value={answers[index]}
                                                                    sx={{ minWidth: "300px", maxWidth: "400px" }}
                                                                />
                                                                <Box
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        top: '12px',
                                                                        right: -30,
                                                                        color: colors.redAccent[400],
                                                                        zIndex: 1000,
                                                                    }}
                                                                >
                                                                    <CloseIcon 
                                                                        color='error' 
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                            '&:hover': {
                                                                                color: colors.redAccent[600]
                                                                            }
                                                                        }} 
                                                                        onClick={() => {
                                                                            setAnswers(hwQuestion.answers.filter((_, i) => i !== index));
                                                                            setHwQuestion({
                                                                                ...hwQuestion,
                                                                                answers: hwQuestion.answers.filter((_, i) => i !== index),
                                                                            });
                                                                            setNumAnswers(numAnswers - 1);
                                                                        }} 
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        )
                                                    })
                                                ) : chosenQuestionType === "short_answer" && chosenTool === "mathlive" ? (
                                                    Array(numAnswers).fill(0).map((_, index) => {
                                                        return (
                                                            <Box 
                                                                sx={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    gap: "10px",
                                                                    alignItems: "flex-start",
                                                                    width: "100%",
                                                                    position: "relative",
                                                                }}
                                                                key={index}
                                                            >
                                                                <math-field 
                                                                    id="mathfield"
                                                                    onInput={evt => {
                                                                        let temp = hwQuestion.answers;
                                                                        temp[index] = evt.target.value;
                                                                        setAnswers(temp);
                                                                        setHwQuestion({
                                                                            ...hwQuestion,
                                                                            answers: temp,
                                                                        });
                                                                    }}
                                                                    style={{ width: "inherit", minWidth: "300px", maxWidth: "400px"}}
                                                                >
                                                                    {/* {answers[index] ? answers[index] : ""} */}
                                                                </math-field>
                                                                <p>Answer: {answers[index]}</p>
                                                                <Box
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        top: '12px',
                                                                        right: -30,
                                                                        color: colors.redAccent[400],
                                                                        zIndex: 1000,
                                                                    }}
                                                                >
                                                                    <CloseIcon 
                                                                        color='error' 
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                            '&:hover': {
                                                                                color: colors.redAccent[600]
                                                                            }
                                                                        }} 
                                                                        onClick={() => {
                                                                            setAnswers(hwQuestion.answers.filter((_, i) => i !== index));
                                                                            setHwQuestion({
                                                                                ...hwQuestion,
                                                                                answers: hwQuestion.answers.filter((_, i) => i !== index),
                                                                            });
                                                                            setNumAnswers(numAnswers - 1);

                                                                        }} 
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        )
                                                    })
                                                ) : chosenQuestionType === "short_answer" && chosenTool === "tinymce" ? (
                                                    <Box></Box>
                                                ) : null }
                                            </Box>
                                        </>
                                    ) : null }
                                </Box>
                                <Box mt={2} sx={{ display: "flex", gap: "20px", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                    {chosenQuestionType === "short_answer" && (chosenTool === "textfield" || chosenTool === "mathlive") &&
                                        <Button
                                            type="button"
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => {
                                                setNumAnswers(numAnswers + 1);
                                                setAnswers([...answers, '']);
                                                setHwQuestion({
                                                    ...hwQuestion,
                                                    answers: [...hwQuestion.answers, ''],
                                                });
                                            }}
                                        >
                                            Add Answer
                                        </Button>
                                    }
                                </Box></>
                            )}
                        </Box>
                        <Box
                            sx={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                                width: "50%",
                                height: "100%",
                            }}
                        >
                            <HiddenPDF documentRender={documentRender} isHidden={false} /> 
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="start" mt="40px" mb="200px">
                        <Button 
                            type="button" 
                            color="secondary" 
                            variant="contained" 
                            onClick={(e) => handleSubmit(e)}
                        >
                            Create New Homework Question
                        </Button>
                    </Box>
                </Box>
                <Alert
                    successAlertOpen={successAlertOpen}
                    setSuccessAlertOpen={setSuccessAlertOpen}
                    errorAlertOpen={errorAlertOpen}
                    setErrorAlertOpen={setErrorAlertOpen}
                    successMsg={successAlertMessage}
                    errorMsg={errorAlertMessage}
                    vertical="bottom"
                    horizontal="left"
                />
                <Backdrop backdropOpen={backdropOpen} handleBackdropClose={handleBackdropClose} />
            </Box>}
        </Box>
    )
}

export default HwQuestions