import React, { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VideoCallOutlinedIcon from '@mui/icons-material/VideoCallOutlined';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faChalkboardUser, 
    faChalkboard, 
    faSchoolFlag, 
    faPersonChalkboard, 
    faPersonCirclePlus 
} from '@fortawesome/free-solid-svg-icons';
import { history } from "../utils/History";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DraftsIcon from '@mui/icons-material/Drafts';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import QuizIcon from '@mui/icons-material/Quiz';
import Axios from "axios";


const Sidebar = ({ adminRestrictions }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState(localStorage.getItem('selectedMenu') || 'Dashboard');

    const [isMangementCollapsed, setIsMangementCollapsed] = useState(false);
    const [isAdditionMenuCollapsed, setIsAdditionMenuCollapsed] = useState(false);
    const [isClassroomCollapsed, setIsClassroomCollapsed] = useState(false);
    const [isOthersCollapsed, setIsOthersCollapsed] = useState(false);

    const [numOfUnansweredQuestions, setNumOfUnansweredQuestions] = useState(0);
    const [numOfUnansweredSchoolMaterials, setNumOfUnansweredSchoolMaterials] = useState(0);
    const [numOfDrafts, setNumOfDrafts] = useState(0);

    const countStyle = {
        marginLeft: "10px",
        fontSize: "12px",
        padding: "2px 5px",
        borderRadius: "50%",
        backgroundColor: colors.redAccent[500],
        color: colors.grey[100],
    };

    const Item = ({ title, to, icon, selected, setSelected }) => {
        if (adminRestrictions 
            && adminRestrictions.length > 0 
            && !adminRestrictions.includes(title.toLowerCase())
            && title !== "Dashboard") {
            return null;
        }

        return (
            <MenuItem
                active={selected === title}
                style={{
                    color: colors.grey[700],
                }}
                onClick={() => setSelected(title)}
                icon={icon}
            >
                <Typography>
                    {title}
                    {title === 'Questions' && numOfUnansweredQuestions > 0 ? 
                            <span style={countStyle}>{numOfUnansweredQuestions}</span> 
                        : title === 'School Materials' && numOfUnansweredSchoolMaterials > 0 ? 
                            <span style={countStyle}>{numOfUnansweredSchoolMaterials}</span> 
                        : title === 'Email Drafts' && numOfDrafts > 0 ?
                            <span style={countStyle}>{numOfDrafts}</span>
                        : null
                    }
                </Typography>
                <Link to={title === "A ONE Classroom" ? to : "/admin" + to} />
            </MenuItem>
        );
    };    

    // for selected menu
    React.useEffect(() => {
        async function selectedMenu() {
            const selectedMenu = window.location.pathname.split('/')[2];
            if (selectedMenu === '' || selectedMenu === null || selectedMenu === undefined || selectedMenu === 'home') {
                setSelected('Dashboard');
            } else if (selectedMenu === 'attendance') {
                setSelected('Attendance');
            } else if (selectedMenu === 'consultationProgress') {
                setSelected('상담 진행 상황');
            } else if (selectedMenu === 'potentialCourses') {
                setSelected('다음 학기 예상 스케줄');
            } else if (selectedMenu === 'addZoomRoom') {
                setSelected('Add Zoom Room');
            } else if (selectedMenu.match("add")) {
                setSelected(`Add ${selectedMenu.split('add')[1]}`);
            } else if (selectedMenu === 'zoom') {
                setSelected('Zoom Rooms');
            } else if (selectedMenu === 'potentialStudents') {
                setSelected('Potential Students');
            } else if (selectedMenu === 'potentialTeachers') {
                setSelected('Potential Teachers');
            } else if (selectedMenu === 'hwQuestions') {
                setSelected('Add Textbook Qs');
            } else if (selectedMenu === 'textbooks') {
                setSelected('A ONE Library');
            } else if (selectedMenu === 'tests') {
                setSelected('Test Library');
            } else if (selectedMenu === 'schoolMaterials') {
                setSelected('School Materials');
            } else if (selectedMenu === 'emailDrafts') {
                setSelected('Email Drafts');
            } else {
                setSelected(selectedMenu.charAt(0).toUpperCase() + selectedMenu.slice(1));
            }
        }

        selectedMenu();

        const listenBackEvent = () => {
            selectedMenu();
        };
    
        const unlistenHistoryEvent = history.listen(({ action }) => {
            if (action === "POP") {
                listenBackEvent();
            }
        });

        return unlistenHistoryEvent;
    }, [selected]);

    React.useEffect(() => {
        // fetch the number of unanswered questions and set it to the badge
        async function fetchUnansweredQuestions() {
            // fetch the number of unanswered questions
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/unanswered-questions`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                if (response.data) {
                    const unansweredQuestions = response.data.unanswered_questions_count;
                    if (unansweredQuestions > 0) {
                        setNumOfUnansweredQuestions(unansweredQuestions);
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        }

        // fetch the number of unanswered questions and set it to the badge
        async function fetchUnansweredSchoolMaterials() {
            // fetch the number of unanswered questions
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/unanswered-questions?type=school_material`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                if (response.data) {
                    const unansweredSchoolMaterials = response.data.unanswered_questions_count;
                    if (unansweredSchoolMaterials > 0) {
                        setNumOfUnansweredSchoolMaterials(unansweredSchoolMaterials);
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        }

        // fetch the number of drafts
        async function fetchDrafts() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/info-email-drafts?len=true`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                if (response.data) {
                    const draftsLen = response.data;

                    if (draftsLen > 0) {
                        setNumOfDrafts(draftsLen);
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        }

        fetchDrafts();
        fetchUnansweredQuestions();
        fetchUnansweredSchoolMaterials();

        // use setIntervel to update the badge every 30 minutes
        const THIRTY_MINUTES = 1000 * 60 * 30;
        const interval = setInterval(() => {
            // fetch the number of unanswered questions
            fetchUnansweredQuestions();
            fetchUnansweredSchoolMaterials();
            fetchDrafts();
        }, THIRTY_MINUTES);

        return () => clearInterval(interval);
    }, []);
    

    return (
        <Box
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[400]} !important`,
                },
                "& .pro-sidebar-layout": {
                    '::-webkit-scrollbar': {
                        width: '5px',
                    },
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 20px !important",
                },
                "& .pro-inner-item:hover": {
                    color: "#F5F3F3 !important",
                },
                "& .pro-menu-item.active": {
                    color: "#F5F3F3 !important",
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    {/* LOGO AND MENU ICON */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                        margin: "10px 0 20px 0",
                        color: colors.primary[700],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                ml="15px"
                            >
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)} style={{ color: colors.primary[700] }}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                                <Typography variant="h3" color={colors.primary[700]}>
                                    A ONE
                                </Typography>
                            </Box>
                        )}
                    </MenuItem>

                    {!isCollapsed && (
                        <Box mb="25px">
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <img
                                    alt="profile-user"
                                    width="100px"
                                    height="100px"
                                    src={'/imgs/profile-img-3.png'}
                                    style={{ cursor: "pointer", borderRadius: "50%" }}
                                />
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                    variant="h3"
                                    color={colors.primary[700]}
                                    fontWeight="bold"
                                    sx={{ m: "10px 0 0 0" }}
                                >
                                    {localStorage.getItem("userName")}
                                </Typography>
                                <Typography variant="h5" color={colors.greenAccent[500]}>
                                    {localStorage.getItem("who").charAt(0).toUpperCase() + localStorage.getItem("who").slice(1)}
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                        <Item
                            title="Dashboard"
                            to="/home"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Attendance"
                            to="/attendance"
                            icon={<ChecklistIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="상담 진행 상황"
                            to="/consultationProgress"
                            icon={<SummarizeIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="다음 학기 예상 스케줄"
                            to="/potentialCourses"
                            icon={<CalendarMonthIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Email Drafts"
                            to="/emailDrafts"
                            icon={<DraftsIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        {!isCollapsed ? (
                            <Box 
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "10px",
                                    m: "15px 30px 5px 20px",
                                    cursor: "pointer",
                                    "&:hover": {
                                        opacity: 0.8,
                                    }
                                }}
                                onClick={() => {
                                    // display none for the management menu
                                    setIsMangementCollapsed(!isMangementCollapsed);
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    color={colors.greenAccent[500]}
                                >
                                    Management
                                </Typography>
                                {isMangementCollapsed ? (
                                    <ArrowDropUpIcon color="secondary" />
                                ) : (
                                    <ArrowDropDownIcon color="secondary" />
                                )}
                            </Box>
                        ) : (
                            <Box mt="20px" />
                        )}
                        {!isMangementCollapsed && ( <>
                            <Item
                                title="Students"
                                to="/students"
                                icon={<PeopleOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Potential Students"
                                to="/potentialStudents"
                                icon={<Groups2OutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Teachers"
                                to="/teachers"
                                icon={<FontAwesomeIcon icon={faPersonChalkboard} />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Potential Teachers"
                                to="/potentialTeachers"
                                icon={<Groups2OutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Courses"
                                to="/courses"
                                icon={<FontAwesomeIcon icon={faChalkboard} />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Zoom Rooms"
                                to="/zoom"
                                icon={<VideocamOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="A ONE Library"
                                to="/textbooks"
                                icon={<LibraryBooksIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Test Library"
                                to="/tests"
                                icon={<QuizIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </>)}
                        {!isCollapsed ? (
                            <Box 
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "10px",
                                    m: "15px 30px 5px 20px",
                                    cursor: "pointer",
                                    "&:hover": {
                                        opacity: 0.8,
                                    }
                                }}
                                onClick={() => { setIsAdditionMenuCollapsed(!isAdditionMenuCollapsed); }}
                            >
                                <Typography variant="h6" color={colors.greenAccent[500]}>
                                    Additions
                                </Typography>
                                {isAdditionMenuCollapsed ? (
                                    <ArrowDropUpIcon color="secondary" />
                                ) : (
                                    <ArrowDropDownIcon color="secondary" />
                                )}
                            </Box>
                        ) : (
                            <Box mt="20px" />
                        )}
                        {!isAdditionMenuCollapsed && ( <>
                            <Item
                                title="Add Student"
                                to="/addStudent"
                                icon={<PersonAddAltOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Add Teacher"
                                to="/addTeacher"
                                icon={<FontAwesomeIcon icon={faPersonCirclePlus} />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Add Course"
                                to="/addCourse"
                                icon={<FontAwesomeIcon icon={faChalkboardUser} />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Add Semester"
                                to="/addSemester"
                                icon={<EditCalendarIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Add Zoom Room"
                                to="/addZoomRoom"
                                icon={<VideoCallOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Add Textbook"
                                to="/addTextbook"
                                icon={<MenuBookIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Add Textbook Qs"
                                to="/hwQuestions"
                                icon={<NoteAddIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Add Test"
                                to="/addTest"
                                icon={<HistoryEduIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </>)}
                        {!isCollapsed ? (
                            <Box 
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "10px",
                                    m: "15px 30px 5px 20px",
                                    cursor: "pointer",
                                    "&:hover": {
                                        opacity: 0.8,
                                    }
                                }}
                                onClick={() => { setIsClassroomCollapsed(!isClassroomCollapsed); }}
                            >
                                <Typography variant="h6" color={colors.greenAccent[500]}>
                                    Classroom
                                </Typography>
                                {isClassroomCollapsed ? (
                                    <ArrowDropUpIcon color="secondary" />
                                ) : (
                                    <ArrowDropDownIcon color="secondary" />
                                )}
                            </Box>
                        ) : (
                            <Box mt="20px" />
                        )}
                        {!isClassroomCollapsed && ( <>
                            <Item
                                title="A ONE Classroom"
                                to="/classrooms/classes"
                                icon={<FontAwesomeIcon icon={faSchoolFlag} size="lg" />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Homework"
                                to="/homework"
                                icon={<AssignmentIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Questions"
                                to="/questions"
                                icon={<HelpOutlineIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="School Materials"
                                to="/schoolMaterials"
                                icon={<CollectionsBookmarkIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </>)}
                        {!isCollapsed ? (
                            <Box 
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "10px",
                                    m: "15px 30px 5px 20px",
                                    cursor: "pointer",
                                    "&:hover": {
                                        opacity: 0.8,
                                    }
                                }}
                                onClick={() => { setIsOthersCollapsed(!isOthersCollapsed); }}
                            >
                                <Typography variant="h6" color={colors.greenAccent[500]}>
                                    Others
                                </Typography>
                                {isOthersCollapsed ? (
                                    <ArrowDropUpIcon color="secondary" />
                                ) : (
                                    <ArrowDropDownIcon color="secondary" />
                                )}
                            </Box>
                        ) : (
                            <Box mt="20px" />
                        )}
                        {!isOthersCollapsed && ( <>
                            <Item
                                title="Calendar"
                                to="/calendar"
                                icon={<CalendarTodayOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </>)}
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;