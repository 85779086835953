import React from 'react'
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import EachMemoModal from './EachMemoModal';

const EachMemo = ({ reminder, setNotificationRemoved }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for memo modal
    const [memoModalOpen, setMemoModalOpen] = React.useState(false);
    const handleMemoModalOpen = () => setMemoModalOpen(true);
    const handleMemoModalClose = () => setMemoModalOpen(false);
    
    const memoBoxStyle = {
        p :1,
        px: 1,
        display: "flex",
        backgroundColor: colors.primary[600],
        my: "0.5rem",
        borderRadius: "10px",
        "&:hover": {
            backgroundColor: colors.greenAccent[500],
            cursor: "pointer",
            transform: "scale(1.01)",
        },
    };

    return (<>
        {reminder.student.is_potential_student === null || !reminder.student.is_potential_student ? (<>
            <Box
                boxShadow={1}
                sx={memoBoxStyle}
                onClick={handleMemoModalOpen}
            >
                {theme.palette.mode === "light" ? (
                    <PriorityHighIcon fontSize="small" color="warning" /> 
                ) : (
                    <PriorityHighIcon fontSize="small" color="error" /> 
                )}
                <Typography variant="body2" sx={{ ml: 1, mr: 1 }}>
                    {reminder.student.last_name && reminder.student.first_name ? <>
                        {reminder.student.last_name}, {reminder.student.first_name} - {reminder.title}
                    </> : <> {reminder.student.consultation_number} - {reminder.title} </>
                    }
                </Typography>
            </Box>
            <EachMemoModal 
                open={memoModalOpen} 
                handleMemoModalClose={handleMemoModalClose} 
                reminder={reminder} 
                setNotificationRemoved={setNotificationRemoved} 
            />
        </>) : (<>
            <Box
                boxShadow={1}
                sx={memoBoxStyle}
                onClick={handleMemoModalOpen}
            >
                {theme.palette.mode === "light" ? (
                    <PriorityHighIcon fontSize="small" color="warning" /> 
                ) : (
                    <PriorityHighIcon fontSize="small" color="error" /> 
                )}
                <Typography variant="body2" sx={{ ml: 1, mr: 1 }}>
                    {reminder.student.consultation_number} - {reminder.title}
                </Typography>
            </Box>
            <EachMemoModal 
                open={memoModalOpen} 
                handleMemoModalClose={handleMemoModalClose} 
                reminder={reminder} 
                setNotificationRemoved={setNotificationRemoved} 
            />
        </>)}
    </>)
}

export default EachMemo