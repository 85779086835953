import React from 'react'
import { tokens } from "../../theme";
import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Axios from 'axios';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import Loading from '../../components/Loading';
import EachTableRow from './EachTableRow';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';

const Questions = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = React.useState(true);

    const sevenDaysArray = [0, 1, 2, 3, 4, 5, 6];

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [questions, setQuestions] = React.useState(null);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: colors.primary[300],
            color: theme.palette.common.white,
            fontSize: 14,
            fontWeight: "bold",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
        border: '1px solid rgba(224, 224, 224, 1)',
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: colors.primary[750],
        },
        '&:nth-of-type(even)': {
            backgroundColor: colors.primary[750],
        },
    }));


    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Questions');

        async function getQuestions() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/all-course-questions?type=general_question`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setQuestions(response.data.course_questions_by_date);
            }).catch((error) => {
                if (error.response !== null && error.response.status === 401) {
                    handleLogout();
                    return;
                }
                if (error.response !== null && error.response.status === 404) {
                    setQuestions([]);
                    return;
                }
                console.log(error.response.data.message || error.response.data.error || error.response.data);
            });
        }

        getQuestions();
        setLoading(false);
    }, []);

    return (
        <Box m="20px" marginX={3} marginY={3}>
            { loading || questions === null ? 
                <Loading />
                : 
                <><Box 
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Header title="QUESTIONS" subtitle="A ONE Institute" />
                    <TableContainer component={Paper} sx={{ maxWidth: "90%" }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{ width: "14%" }}>Updated On</StyledTableCell>
                                    <StyledTableCell style={{ width: "14%" }}>Course</StyledTableCell>
                                    <StyledTableCell style={{ width: "16%" }}>Student Name</StyledTableCell>
                                    <StyledTableCell style={{ width: "48%" }}>Question</StyledTableCell>
                                    <StyledTableCell style={{ width: "8%" }}>Answered</StyledTableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                    {questions && sevenDaysArray.map((index) => {
                        return (
                            <TableContainer key={index} component={Paper} sx={{ maxWidth: "90%" }}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {questions[index].length === 0 && (
                                            <StyledTableRow>
                                                {index === 0 ? (
                                                    <StyledTableCell rowSpan={questions[index].length} style={{ backgroundColor: colors.greenAccent[500], width: "14%" }}>
                                                        <Box sx={{ display: 'flex', alignContent: 'center', gap: '0.5rem' }}>
                                                            <Box>
                                                                {dayjs().format("DD MMM YYYY")} 
                                                            </Box>
                                                            <Box>
                                                                <TodayOutlinedIcon />
                                                            </Box>
                                                        </Box>
                                                    </StyledTableCell>
                                                ) : (
                                                    <StyledTableCell rowSpan={questions[index].length} style={{ width: "14%" }}>
                                                                {dayjs().subtract(index, 'day').format("DD MMM YYYY")}
                                                    </StyledTableCell>
                                                )}
                                                <StyledTableCell style={{ width: "14%" }}></StyledTableCell>
                                                <StyledTableCell style={{ width: "16%" }}></StyledTableCell>
                                                <StyledTableCell style={{ width: "48%" }}></StyledTableCell>
                                                <StyledTableCell style={{ width: "8%" }}></StyledTableCell>
                                            </StyledTableRow>
                                        )}
                                        {questions[index].map((question, i) => {
                                            return (
                                                <EachTableRow 
                                                    key={i}
                                                    i={i}
                                                    index={index}
                                                    questionsLen={questions[index].length} 
                                                    question={question}
                                                    StyledTableCell={StyledTableCell}
                                                    StyledTableRow={StyledTableRow}
                                                />
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                    })}
                </Box></>
            }
        </Box>
    )
}

export default Questions