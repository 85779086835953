import React from 'react'
import Popover from '@mui/material/Popover';
import MoreVertIcon from '../../components/MoreVertIcon';
import UpdatePotentialCourseModal from './UpdatePotentialCourseModal';
import DeletePotentialCourseDialog from './DeletePotentialCourseDialog';
import AddStudentModal from './AddStudentModal';
import RemoveStudentModal from './RemoveStudentModal';

/*
 * for each course, there is a vert icon 
 */
const EachPotentialCourseVertIcon = ({
    potentialSchedules,
    student_courses,
    potentialCourse,
    fetchPotentialCourses,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
    potentialCourseType,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMoreVertIconClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    return (
        <><MoreVertIcon handleClick={handleMoreVertIconClick} />
        <Popover
            id={popoverId}
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopOverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <AddStudentModal
                setAnchorEl={setAnchorEl}
                potentialCourse={potentialCourse}
                fetchPotentialCourses={fetchPotentialCourses}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setSuccessAlertMessage={setSuccessAlertMessage}
                setErrorAlertOpen={setErrorAlertOpen}
                setErrorAlertMessage={setErrorAlertMessage}
            />
            <RemoveStudentModal
                student_courses={student_courses}
                setAnchorEl={setAnchorEl}
                potentialCourse={potentialCourse}
                fetchPotentialCourses={fetchPotentialCourses}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setSuccessAlertMessage={setSuccessAlertMessage}
                setErrorAlertOpen={setErrorAlertOpen}
                setErrorAlertMessage={setErrorAlertMessage}
            />
            <UpdatePotentialCourseModal 
                potentialSchedules={potentialSchedules}
                setAnchorEl={setAnchorEl}
                potentialCourse={potentialCourse}
                fetchPotentialCourses={fetchPotentialCourses}
                setSuccessAlertMessage={setSuccessAlertMessage}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setErrorAlertMessage={setErrorAlertMessage}
                setErrorAlertOpen={setErrorAlertOpen}
                potentialCourseType={potentialCourseType}
            />
            <DeletePotentialCourseDialog
                setAnchorEl={setAnchorEl}
                potentialCourse={potentialCourse}
                fetchPotentialCourses={fetchPotentialCourses}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setSuccessAlertMessage={setSuccessAlertMessage}
                setErrorAlertOpen={setErrorAlertOpen}
                setErrorAlertMessage={setErrorAlertMessage}
            />
        </Popover></>
    )
}

export default EachPotentialCourseVertIcon