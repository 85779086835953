import React from 'react'
import Box from '@mui/material/Box';
import { Document, Page, pdfjs } from "react-pdf";
import './Viewer.css';
import { Button, Divider, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ClassMaterialViewer = ({ PDF }) => {  
    const [numPages, setNumPages] = React.useState(null);
    const [fromPage, setFromPage] = React.useState("1");
    const [fromInputValue, setFromInputValue] = React.useState('');
    const [toPage, setToPage] = React.useState("1");
    const [toInputValue, setToInputValue] = React.useState('');
    const [selectedPages, setSelectedPages] = React.useState([]);
    const [zoom, setZoom] = React.useState(8);

    const MAX_ZOOM = 15;
    const MIN_ZOOM = 1;

    function handleZoom(isPlus) {
        let curZoom = zoom;
        if (isPlus) {
            curZoom = curZoom + 1 <= MAX_ZOOM ? curZoom + 1 : MAX_ZOOM;
        } else {
            curZoom = curZoom - 1 >= MIN_ZOOM ? curZoom - 1 : MIN_ZOOM;
        }

        setZoom(curZoom);
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const documentRender = () => {
        return (
            <Document 
                file={PDF} 
                onLoadSuccess={onDocumentLoadSuccess} 
                onContextMenu={(e) => e.preventDefault()}
                className="pdf-container"
            >
                {selectedPages.map((el, index) => (
                    <div key={index}>
                        <Page
                            key={`page_${el}`}
                            pageNumber={el}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            className="react-pdf__Page__canvas" 
                        />
                        <Divider sx={{ my: "1rem" }} />
                    </div>
                ))}
            </Document>
        );
    };

    React.useEffect(() => {
        if (fromPage !== "" && toPage !== "") {
            const from = fromPage !== null ? parseInt(fromPage.label) : 1;
            const to = toPage !== null ? parseInt(toPage.label) : numPages[numPages.length - 1];
            if (from <= to) {
                setSelectedPages(Array.from(new Array(to - from + 1), (el, index) => {
                    return index + from;
                }));
            }
        }
    }, [fromPage, toPage]);

    React.useEffect(() => {
        if (numPages) {
            setFromPage({
                label: "1",
            });
            setToPage({
                label: numPages > 10 ? "10" : numPages.toString(),
            });
        }
    }, [numPages]);

    React.useEffect(() => {
        documentRender();
    }, [selectedPages]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: "1rem",
                    gap: "1rem"
                }}
            >
                <Autocomplete
                    value={fromPage}
                    onChange={(event, newValue) => {
                        setFromPage(newValue);
                    }}
                    inputValue={fromInputValue}
                        onInputChange={(event, newInputValue) => {
                            setFromInputValue(newInputValue);
                        }
                    }
                    id="controllable-states-demo"
                    options={numPages ? Array.from(new Array(numPages), (el, index) => {
                        return {
                            label: (index + 1).toString(),
                        }
                    }) : []}
                    sx={{ width: 200 }}
                    renderInput={
                        (params) => <TextField {...params} label="From" />
                    }
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                />
                <Typography color="primary"> - </Typography>
                <Autocomplete
                    value={toPage}
                    onChange={(event, newValue) => {
                        setToPage(newValue);
                    }}
                    inputValue={toInputValue}
                        onInputChange={(event, newInputValue) => {
                            setToInputValue(newInputValue);
                        }
                    }
                    id="controllable-states-demo"
                    options={numPages ? Array.from(new Array(numPages), (el, index) => {
                        return {
                            label: (index + 1).toString(),
                        }
                    }) : []}
                    sx={{ width: 200 }}
                    renderInput={
                        (params) => <TextField {...params} label="To" />
                    }
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                />
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        padding: "0.6rem 1rem",
                    }}
                >
                    <Typography color="primary" fontWeight={'bold'}>ZOOM</Typography>
                    <Button 
                        variant="contained" 
                        color="primary"
                        disabled={zoom === MAX_ZOOM}
                        onClick={() => handleZoom(true)}
                    >
                        +
                    </Button>
                    <Button 
                        variant="contained" 
                        color="primary"
                        disabled={zoom === MIN_ZOOM}
                        onClick={() => handleZoom(false)}
                    >
                        -
                    </Button>
                </Box>
            </Box>
            <Box
                display="flex"
                width="100%"
            >
                <Box flex={zoom} width="100%">
                    {documentRender()}
                </Box>
                <Box flex={MAX_ZOOM - zoom} width="100%">
                    {/* whiteboard */}
                </Box>
            </Box>
        </>
    )
}

export default ClassMaterialViewer