import React from 'react'
import { tokens } from "../../../theme";
import { Box, useTheme, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '../../../components/alert/Alert';
import Header from "../../../components/Header";
import AdminActionAccordion from './AdminActionAccordion';
import { useOutletContext } from 'react-router-dom';
import ModuleBox from './ModuleBox';
import getCookies from '../../../utils/getCookies';
import Loading from '../../../components/Loading';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

const Modules = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const matches = useMediaQuery('(min-width:1500px)');
    const matches2 = useMediaQuery('(min-width:1200px)');
    const matches3 = useMediaQuery('(min-width:1100px)');

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [loading, setLoading] = React.useState(true);
    const [courseId, setCourseId] = React.useState(null);
    const courseData = useOutletContext().courseData;
    const eventModules = useOutletContext().lectureModules;
    const currentUser = getCookies("_auth_state");
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const [zooms, setZooms] = React.useState(null);

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    // useEffect
    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Modules');
        setCourseId(window.location.pathname.split('/')[2]);

        setLoading(false);
    }, []);

    React.useEffect(() => {
        const fetchZooms = async () => {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/zooms`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setZooms(response.data);
            }).catch((error) => {
                if (error.response.status === 404) {
                    setZooms([]);
                    return;
                } else if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message);
                setErrorAlertOpen(true);
            });
        };

        fetchZooms();
    }, []);


    return (
        <Box m="20px" marginX={3} marginY={3} minHeight="90%">
            { loading || courseData === null || eventModules === null ? 
                <Loading />
                : <>
                    <Box 
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            px: "25%",
                            ...!matches && {
                                px: "15%",
                            },
                            ...!matches2 && {
                                px: "10%",
                            },
                            ...!matches3 && {
                                px: "0%",
                            },
                        }}
                    >
                        <Header 
                            title={
                                courseData.code !== null 
                                ? courseData.code + ". " + courseData.course_title 
                                : courseData.course_title
                            } 
                            subtitle="A ONE Institute"
                        />
                        {/* For Admins */}
                        {(userRole === "admin") && 
                            <Box
                                minWidth="20rem"
                                width="100%"
                                minHeight="10rem"
                                borderRadius="3px"
                                padding={3}
                                boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                                backgroundColor={colors.primary[600]}
                                display="flex"
                                flexDirection="column"
                                alignItems="left"
                                gap={3}
                                mb={3}
                            >
                                <Typography variant="h4" color={colors.primary[450]}>
                                    Admin Actions
                                </Typography>
                                <AdminActionAccordion 
                                    courseId={courseId} 
                                    courseData={courseData}
                                    setBackdropOpen={setBackdropOpen}
                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                    setErrorAlertOpen={setErrorAlertOpen}
                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                    setErrorAlertMessage={setErrorAlertMessage}
                                />
                            </Box>
                        }

                        {/* Lecture Modules */}
                        <Box
                            minWidth="20rem"
                            width="100%"
                            minHeight="32rem"
                            borderRadius="3px"
                            padding={8}
                            boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                            backgroundColor={colors.primary[600]}
                            alignItems="center"
                        >
                            <Typography variant="h3" color={colors.primary[450]} borderBottom="0.1px solid" mb={2}>
                                    Modules
                            </Typography>
                            {eventModules === null || eventModules.length === 0 ? (
                                <Box 
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        minWidth: "20rem",
                                        minHeight: "16rem",
                                        gap: "0.4rem",
                                    }}
                                >
                                    <InfoOutlinedIcon sx={{ my: "auto" }} />
                                    <Typography variant="h4" color={colors.primary[450]} my="auto">
                                        No modules yet!
                                    </Typography>
                                </Box>
                            ) : (
                                <>
                                {eventModules.map((eventModule, index) => { 
                                    return (
                                        <ModuleBox 
                                            key={index}
                                            courseId={courseId}
                                            userRole={userRole}
                                            eventModule={eventModule} 
                                            eventModules_len={eventModules.length}
                                            zooms={zooms}
                                            index={index} 
                                            setBackdropOpen={setBackdropOpen}
                                            setSuccessAlertOpen={setSuccessAlertOpen}
                                            setErrorAlertOpen={setErrorAlertOpen}
                                            setSuccessAlertMessage={setSuccessAlertMessage}
                                            setErrorAlertMessage={setErrorAlertMessage}
                                        />
                                    )
                                })}</>
                            )}
                        </Box>
                    </Box>
                    <Alert
                        successAlertOpen={successAlertOpen}
                        setSuccessAlertOpen={setSuccessAlertOpen}
                        errorAlertOpen={errorAlertOpen}
                        setErrorAlertOpen={setErrorAlertOpen}
                        successMsg={successAlertMessage}
                        errorMsg={errorAlertMessage}
                        vertical="bottom"
                        horizontal="left"
                    />
                    <Backdrop
                        sx={{ color: colors.primary[400], zIndex: 2147483646 }}
                        open={backdropOpen}
                        onClick={handleBackdropClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                </>
            }
        </Box>
    )
}

export default Modules