import React from 'react'
import { tokens } from "../../../theme";
import { useTheme, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';
import { motion } from "framer-motion";



const ClassCard = ({ course }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const seasons = ['', 'Spring', 'Summer', 'Fall', 'Winter'];

    const sortSemesters = [...course.course_semesters].sort((a, b) => {
        return dayjs(a.semester.start) - dayjs(b.semester.start);
    });

    const start_date = sortSemesters[0].semester.start.split('T')[0];
    const end_date = sortSemesters[sortSemesters.length - 1].semester.end.split('T')[0];

    const handleClick = () => {
        localStorage.setItem('selectedMenu', 'Home');
    };

    const handleZoomClick = () => {
        const zoom = course.zoom;
        alert("Zoom Password: " + zoom.password);
        window.open(zoom.link, "_blank");
    };

    const cardBackground = () => {
        if (course.code === null)
            return colors.primary[800];

        if (course.code.charAt(0) === 'A')
            return "#E9FFAE";
        else if (course.code.charAt(0) === 'C')
            return "#DAE3F5";
        else if (course.code.charAt(0) === 'E')
            return "#FCECD4";
        else if (course.code.charAt(0) === 'H')
            return "#F8E7FE";
        else if (course.code.charAt(0) === 'M')
            return "#FFFFC3";
        else if (course.code.charAt(0) === 'O')
            return "#FFF0F1";
        else if (course.code.charAt(0) === 'R')
            return "#D0FFD2";
        else if (course.code.charAt(0) === 'S')
            return "#D6FFFD";
        else if (course.code.charAt(0) === 'T')
            return "#FFF0F1";
        else if (course.code.charAt(0) === 'W')
            return "#FCECD4";
        else
            return "#FFF0F1";
    }

    const cardHeaderImage = () => {
        if (course.code === null)
            return null;

        if (course.code.charAt(0) === 'A')
            return "/imgs/math.png";
        else if (course.code.charAt(0) === 'C' && course.course_title.includes("Computer Science"))
            return "/imgs/cs.jpg";
        else if (course.code.charAt(0) === 'C')
            return "/imgs/amc.jpg";
        else if (course.code.charAt(0) === 'E')
            return "/imgs/english.png";
        else if (course.code.charAt(0) === 'H')
            return "/imgs/social.png";
        else if (course.code.charAt(0) === 'M')
            return "/imgs/math.png";
        else if (course.code.charAt(0) === 'O') {
            if (course.course_title.includes("Olympiad"))
                return "/imgs/olympiad.png";
            else if (course.course_title.includes("History"))
                return "/imgs/social.png";
            else if (course.course_title.includes("Literature"))
                return "/imgs/english.png";
            else if (course.course_title.includes("Bio") || course.course_title.includes("Chem"))
                return "/imgs/science.png";
            else if (course.course_title.includes("Physics") || course.course_title.includes("Calculus"))
                return "/imgs/math.png";
            return "/imgs/competition.png";
        } else if (course.code.charAt(0) === 'R')
            return "/imgs/research.png";
        else if (course.code.charAt(0) === 'S')
            return "/imgs/science.png";
        else if (course.code.charAt(0) === 'T')
            return "/imgs/test-prep.png";
        else if (course.code.charAt(0) === 'W')
            return "/imgs/writing-competition.png";
        else
            return "/imgs/test-prep.png";
        return null;
    }

    return (
        <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            layout
        >
            <Card
                sx={{
                    justifyContent: "space-between",
                    width: "16.25rem",
                    height: "270px",
                    backgroundColor: cardBackground(),
                    borderRadius: "10px",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                    border: `1px solid ${colors.primary[600]}`,
                    ":hover": {
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                        border: "1px solid grey",
                    }
                }}
                key={course.id}
            >
                <Box
                    sx={{
                        height: "84%",
                    }}
                >
                    <CardMedia
                        image={cardHeaderImage()}
                        sx={{
                            height: 120,
                            zIndex: -1,
                        }}
                    />
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style={{ zIndex: 1, marginTop: -60, }}>
                        <defs>
                            <filter id="filter" x="0" y="0">
                                <feOffset result="offOut" in="SourceAlpha" dx="-1" dy="-1" />
                                <feGaussianBlur result="blurOut" in="offOut" stdDeviation="16" />
                                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                            </filter>
                        </defs>
                        <path 
                            style={{
                                filter: "url(#filter)",
                                border: "none",
                            }}
                            fill={cardBackground()} 
                            fillOpacity="1" 
                            d="M0,0L80,5.3C160,11,320,21,480,42.7C640,64,800,96,960,101.3C1120,107,1280,85,1360,74.7L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                        ></path>
                    </svg>
                    <Box
                        sx={{
                            width: "2.5rem",
                            height: "2.5rem",
                            zIndex: 1000,
                            marginTop: -16,
                            marginLeft: 2,
                            border: `1px solid white`,
                            borderRadius: "50%",
                            backgroundColor: "#42515A",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {course.code !== null ? 
                            <Typography variant='h6' fontWeight="bold" color="white">
                                {course.code}
                            </Typography> : null
                        }
                    </Box>
                    <CardContent sx={{ marginTop: 4.5, height: "100%" }}>
                        <Box
                            sx={{
                                height: "20%",
                            }}
                        >
                            <Typography 
                                variant={course.course_title.length > 30 ? "h5" : "h4"}
                                fontWeight="bold" 
                                component="div"
                                color="black"
                            >
                                {course.course_title}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h5" component="div" color="black" fontWeight="bold" sx={{ mt: 2 }}>
                                {course.course_semesters.length === 1 
                                        ? course.course_semesters[0].semester.year + " " + seasons[course.course_semesters[0].semester.season] 
                                        : sortSemesters[0].semester.year + " " + seasons[sortSemesters[0].semester.season]
                                            + " - " + sortSemesters[course.course_semesters.length - 1].semester.year 
                                            + " " + seasons[sortSemesters[course.course_semesters.length - 1].semester.season]
                                }
                            </Typography>
                            {course.start_date !== null && course.end_date !== null ? (
                                <Typography variant="body1" color="black">
                                    Session: {dayjs(course.start_date).format('YYYY-MM-DD')} - {dayjs(course.end_date).format('YYYY-MM-DD')}
                                </Typography>
                            ) : (
                                <Typography variant="body1" color="black">
                                    Session: {start_date} - {end_date}
                                </Typography>
                            )}
                        </Box>
                    </CardContent>
                </Box>
                <Box
                    sx={{
                        height: "16%",
                    }}
                >
                    <CardActions>
                        <Button
                            href={'/classrooms/' + course.uuid + '/home'}
                            size="small"
                            style={{
                                "backgroundColor": "#42515A",
                                "color": colors.primary[600],
                                "borderRadius": "50px",
                                "marginTop": -10,
                                "paddingLeft": 10,
                                "paddingRight": 10,
                                "paddingTop": 6,
                                "paddingBottom": 6,
                            }}
                            onClick={() => handleClick()}
                        >
                            <Typography variant="h8" color="white">
                                Go to Classroom
                            </Typography>
                        </Button>
                        {course.zoom !== null && course.zoom !== undefined ?
                            <Button
                                size="small"
                                style={{
                                    "backgroundColor": "#42515A",
                                    "color": colors.primary[600],
                                    "borderRadius": "50px",
                                    "marginTop": -10,
                                    "marginLeft": "auto",
                                    "paddingLeft": 10,
                                    "paddingRight": 10,
                                    "paddingTop": 6,
                                    "paddingBottom": 6,
                                }}
                                onClick={() => handleZoomClick()}
                            >
                                <OndemandVideoRoundedIcon style={{ marginRight: 5, fontSize: 15 }} />
                                <Typography variant="h8" color="white">
                                    Enter Zoom
                                </Typography>
                            </Button>
                            : null
                        }
                    </CardActions>
                </Box>
            </Card>
        </motion.div>
    )
}

export default ClassCard