import React from 'react'
import { tokens } from "../../theme";
import { Box, useTheme } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Header from "../../components/Header";
import Axios from 'axios';
import CourseInfoTabs from './CourseInfoTabs';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

const CourseInfo = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }
    
    const [loading, setLoading] = React.useState(true);
    const [courseData, setCourseData] = React.useState(null);
    const [courseStudents, setCourseStudents] = React.useState(null);
    const [courseUpdated, setCourseUpdated] = React.useState(false);

    React.useEffect(() => {
        async function getCourseInfo() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/${window.location.pathname.substring(7)}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setCourseData(response.data);
            }).catch((error) => {
                if (error.response !== null && error.response.status === 401) {
                    handleLogout();
                    return;
                }
                alert(error.response.data.message);
            });
        }

        getCourseInfo();

        async function getCourseStudents() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/${window.location.pathname.substring(7)}/students`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setCourseStudents(response.data);
                setLoading(false);
            }).catch((error) => {
                if (error.response !== null && error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setLoading(false);
            });
        }

        getCourseStudents();
    }, [courseUpdated]);

    return (
        <Box m="20px" marginX={3} marginY={3}>
            { loading || courseData === null ? 
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "55vh" }}>
                    <CircularProgress color="primary" />
                </div>
                : <>
                    <Header 
                        title={
                            courseData.code !== null ? courseData.code + '. ' + courseData.course_title : courseData.course_title
                        } 
                        subtitle={"A ONE Institute"} 
                    />
                    <Box
                        minWidth="70rem"
                        width="100%"
                        minHeight="80vh"
                        borderRadius="3px"
                        padding={3}
                        boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                        backgroundColor={colors.primary[600]}
                        alignItems="left"
                        gap={3}
                    >
                        <CourseInfoTabs courseData={courseData} courseStudents={courseStudents} setCourseUpdated={setCourseUpdated} />
                    </Box>
                </>
            }
        </Box>
    )
}

export default CourseInfo