import * as React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Axios from 'axios';
import Alert from '../../components/alert/Alert';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import dayjs from 'dayjs';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";


const AddSemester = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [semesters, setSemesters] = React.useState(null);
    const [semesterAdded, setSemesterAdded] = React.useState(false);

    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    // 1: Spring, 2: Summer, 3: Fall, 4: Winter
    const [season, setSeason] = React.useState('');
    const handleSelectChange = (event) => {
        setSeason(event.target.value);
    };

    const seasons = ['Spring', 'Summer', 'Fall', 'Winter'];

    const reInitiailize = (values) => {
        values.year = '';
        setStartDate(null);
        setEndDate(null);
        setSeason('');
    };
    
    const handleFormSubmit = async (values) => {
        if (startDate === null || endDate === null || (startDate !== null && endDate !== null && startDate.isAfter(endDate))) {
            setErrorAlertMessage("Unvalid date range!");
            setErrorAlertOpen(true);
            return;
        }

        if (season === '' || season === null || season === undefined || season === 4) {
            setErrorAlertMessage("Unvalid season!");
            setErrorAlertOpen(true);
            return;
        }

        const start_date = dayjs(startDate).startOf('day').locale('en');
        const end_date = dayjs(endDate).endOf('day').locale('en');

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/semesters`, {
            year: values.year,
            season: season,
            start: start_date,
            end: end_date
        },{
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message);
            reInitiailize(values);
            setSemesterAdded(true);
        }).catch((error) => {
            console.log(error.response.data.message);
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.message);
        });
    };

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Add Semester');

        async function fetchSemesters() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/semesters`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                    setSemesters(response.data);
                }).catch((error) => {
                    if (error.response !== null && error.response.status === 404) {
                        setSemesters([]);
                        return;
                    } else if (error.response !== null && error.response.status === 401) {
                        handleLogout();
                        return;
                    }
                    console.log(error.response.data.message);
                    setErrorAlertOpen(true);
                    setErrorAlertMessage(error.response.data.message);
                });
        };

        fetchSemesters();
        setSemesterAdded(false);
    }, [semesterAdded]);

    return (
        <Box m="20px" marginX={3} marginY={3} maxWidth={"70rem"}>
            <Header title="Add Semester" subtitle="A ONE Institute" />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "10vh",
                    boxShadow: 3,
                    padding: 4,
                    borderRadius: 3,
                    margin: 1,
                    backgroundColor: colors.primary[600],
                }}
            >
                <Typography variant="h4" fontWeight="bold">
                    The List of Semesters in the Database
                </Typography>
                {semesters === null ? (
                        <Typography variant="h6" fontWeight="bold">
                            No semesters yet
                        </Typography>
                    ) : (
                        <Stack direction="row" spacing={1} mt={1} flexWrap="wrap" rowGap={1.5}>
                        {semesters.map((semester) => (
                                <Chip 
                                    key={semester.id}
                                    label={
                                        semester.year + " " + seasons[semester.season - 1] + " (" 
                                        + dayjs(semester.start).format('MMM D, YYYY') +  " - " 
                                        + dayjs(semester.end).format('MMM D, YYYY') + ")"
                                    }
                                />
                            ))}
                        </Stack>
                    )
                }
            </Box>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        padding={4}
                        boxShadow={3}
                        borderRadius={3}
                        m={1}
                        bgcolor={colors.primary[600]}
                    >
                        <Typography
                            variant="h4"
                            fontWeight="bold"
                            mb={3}
                        >
                            Semester Information
                        </Typography>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Year"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.year}
                                name="year"
                                error={!!touched.year && !!errors.year}
                                helperText={touched.year && errors.year}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <FormControl fullWidth sx={{ gridColumn: "span 2" }}>
                                <InputLabel id="demo-simple-select-label">Season</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={season}
                                    label="Season"
                                    onChange={handleSelectChange}
                                >
                                    <MenuItem value={1}>Spring</MenuItem>
                                    <MenuItem value={2}>Summer</MenuItem>
                                    <MenuItem value={3}>Fall</MenuItem>
                                    <MenuItem value={4}>Winter</MenuItem>
                                </Select>
                            </FormControl>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="From"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                />
                                <DatePicker
                                    label="To"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                />
                            </LocalizationProvider>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="start" mt="20px">
                        <Button type="submit" color="secondary" variant="contained">
                            Create New Semester
                        </Button>
                    </Box>
                </form>
                )}
            </Formik>
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    year: yup.number().required("required"),
});

const initialValues = {
    year: "",
};

export default AddSemester