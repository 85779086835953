import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import Axios from 'axios';


const AdminActionAccordion = ({
    courseId, 
    setAnnouncementsUpdated,
    setSuccessAlertOpen,
    setErrorAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertMessage,
    setBackdropOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [expanded, setExpanded] = React.useState(false);
    const [title, setTitle] = React.useState("");
    const [author, setAuthor] = React.useState("");
    const [content, setContent] = React.useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();

        let title = event.target.title.value;
        let author = event.target.author.value;
        let content = event.target.content.value;

        if (title === "" || author === "" || content === "") {
            setErrorAlertMessage("Please fill in all fields.");
            setErrorAlertOpen(true);
            return;
        }

        setBackdropOpen(true);

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/announcements`, {
            title: title,
            author: author,
            content: content,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((res) => {
            setSuccessAlertMessage(res.data.message);
            setSuccessAlertOpen(true);
            setBackdropOpen(false);
            setAnnouncementsUpdated(true);
            setExpanded(!expanded);
            setTitle("");
            setAuthor("");
            setContent("");

            Axios.post(`${process.env.REACT_APP_URL}/api/v1/mails/courses/${courseId}/announcements`, {
                title: title,
                author: author,
                content: content,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).catch((err) => {
                setErrorAlertMessage(err.response.data.message || err.response.data.error);
                setErrorAlertOpen(true);
            });
        }).catch((err) => {
            setErrorAlertMessage(err.response.data.message || err.response.data.error);
            setErrorAlertOpen(true);
        });
    };

    return (
        <div style={{
            width: "100%",
            marginBottom: "1rem",
        }}>
            <Accordion
                style={{
                    backgroundColor: colors.primary[750],
                }}
                component="div"
                expanded={expanded}
                aria-expanded={expanded}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => setExpanded(!expanded)}
                >
                    <Typography variant='h6'>Create an announcement</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                            display: "flex",
                            flexDirection: "column",
                            mr: "1rem",
                        }}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1rem",
                            minWidth: "100%",
                        }}>
                            <TextField
                                id="filled-basic"
                                label="Title"
                                variant="filled"
                                name='title'
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                sx={{
                                    flex: "1",
                                    maxWidth: "50%",
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[600],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                            />
                            <TextField
                                id="filled-basic"
                                label="Author"
                                variant="filled"
                                name='author'
                                value={author}
                                onChange={(e) => setAuthor(e.target.value)}
                                sx={{
                                    flex: "1",
                                    maxWidth: "50%",
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[600],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                            />
                        </Box>
                        <TextField
                            id="filled-basic"
                            label="Content"
                            variant="filled"
                            name='content'
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            fullWidth={true}
                            multiline={true}
                            rows={20}
                            sx={{
                                minWidth: "100%",
                                borderRadius: "5px",
                                '& .MuiFilledInput-root': {
                                    backgroundColor: colors.primary[600],
                                    ":focus": {
                                        backgroundColor: 'white',
                                    },
                                },
                            }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            style={{
                                backgroundColor: colors.primary[300],
                                color: "white",
                                float: "right",
                                marginTop: "0.5rem",
                                marginBottom: "1rem",
                                width: "10rem",
                            }}
                        >
                            Create
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default AdminActionAccordion