import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import Axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';


const UpdateModal = ({
    eventModule, 
    special_notes, 
    courseStudents,
    setAnchorEl,
    setBackdropOpen, 
    setSuccessAlertOpen, 
    setErrorAlertOpen, 
    setSuccessAlertMessage,
    setErrorAlertMessage,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const matches = useMediaQuery('(min-width:1500px)');
    const matches2 = useMediaQuery('(min-width:1200px)');
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {setOpen(false); setAnchorEl(null)};

    const prevChapter = React.useState(eventModule.progress);
    const prevFrom = React.useState(eventModule.progress_from);
    const prevTo = React.useState(eventModule.progress_to);
    const prevChapter2 = React.useState(eventModule.progress_2);
    const prevFrom2 = React.useState(eventModule.progress_from_2);
    const prevTo2 = React.useState(eventModule.progress_to_2);
    const prevChapter3 = React.useState(eventModule.progress_3);
    const prevFrom3 = React.useState(eventModule.progress_from_3);
    const prevTo3 = React.useState(eventModule.progress_to_3);

    // for adding progress chapter
    const [isProgressAdded, setIsProgressAdded] = React.useState(
        prevChapter3[0] !== null ? 3 : prevChapter2[0] !== null ? 2 : 1
    );
    const firstProgressCount = React.useState(prevChapter3[0] !== null ? 3 : prevChapter2[0] !== null ? 2 : 1);
        

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "50%",
        height: "80%",
        overflow: 'scroll',
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        ...!matches && {
            width: "60%",
        },
        ...!matches2 && {
            width: "80%",
        },
    };

    const checkoutSchema = yup.object().shape({
        chapter_name: yup.string().required("required"),
        from: yup.number().required("required"),
        to: yup.number().required("required"),
        chapter_name_2: yup.string().optional(),
        from_2: yup.number().optional(),
        to_2: yup.number().optional(),
        chapter_name_3: yup.string().optional(),
        from_3: yup.number().optional(),
        to_3: yup.number().optional(),
    });
        
    const initialValues = {
        chapter_name: prevChapter[0] || "",
        from: prevFrom[0] || "",
        to: prevTo[0] || "",
        chapter_name_2: prevChapter2[0] || "",
        from_2: prevFrom2[0] || "",
        to_2: prevTo2[0] || "",
        chapter_name_3: prevChapter3[0] || "",
        from_3: prevFrom3[0] || "",
        to_3: prevTo3[0] || "",
    };

    const [firstRender, setFirstRender] = React.useState(true);

    // get students based on event
    const [studentsBasedOnEvent, setStudentsBasedOnEvent] = React.useState([]);

    if (firstRender) {
        const students = [];
        for (let i = 0; i < courseStudents.length; i++) {
            for (let j = 0; j < special_notes.length; j++) {
                if (courseStudents[i].student_id === special_notes[j].student_id) {
                    students.push({
                        ...courseStudents[i],
                        special_note_id: special_notes[j].id,
                        eval_1: special_notes[j].eval_1,
                        eval_2: special_notes[j].eval_2,
                    });
                    break;
                }
            }
        }
        
        setStudentsBasedOnEvent(students);
        setFirstRender(false);
    }

    // requirment check for course students evaluation
    const [courseStudentsEvaluation, setCourseStudentsEvaluation] = React.useState([]);

    React.useEffect(() => {
        setCourseStudentsEvaluation(
            studentsBasedOnEvent.length > 0 ? studentsBasedOnEvent.map((element) => {
                const eval_1 = element.eval_1
                const eval_2 = element.eval_2
                const special_note_id = element.special_note_id
    
                return {
                    id: element.student_id,
                    first_name: element.student.first_name,
                    last_name: element.student.last_name,
                    eval_1: eval_1 === undefined || eval_1 === null || eval_1 === "" ? false : true,
                    eval_1_data: eval_1,
                    prev_eval_1: eval_1,
                    eval_2: eval_2 === undefined || eval_2 === null || eval_2 === "" ? false : true,
                    eval_2_data: eval_2,
                    prev_eval_2: eval_2,
                    special_note_id: special_note_id,
                }
            }) : []    
        )
    }, [studentsBasedOnEvent]);

    const handleEvaluationChange = (event) => {
        setCourseStudentsEvaluation(courseStudentsEvaluation.map((element) => {
            if (element.id === parseInt(event.target.name.split('-')[0])) {
                if (event.target.name.split('-')[1] === "eval_1")
                    return {
                        ...element,
                        eval_1: event.target.value !== "" ? true : false,
                        eval_1_data: event.target.value
                    }
                else if (event.target.name.split('-')[1] === "eval_2")
                    return {
                        ...element,
                        eval_2: event.target.value !== "" ? true : false,
                        eval_2_data: event.target.value
                    }
            }
            return element;
        }));
    };

    const progressErrorCheck = (values) => {
        if (values.chapter_name === "" || values.chapter_name === null) {
            setErrorAlertMessage("Progress cannot be empty.");
            setErrorAlertOpen(true);
            return false;
        }

        if (values.chapter_name.length > 250) {
            setErrorAlertMessage("Progress is too long.");
            setErrorAlertOpen(true);
            return false;
        }

        if (isNaN(values.from) || isNaN(values.to)) {
            setErrorAlertMessage("Progress 'From' and 'To' must be a number.");
            setErrorAlertOpen(true);
            return false;
        }

        if (parseInt(values.from) > parseInt(values.to)) {
            setErrorAlertMessage("Progress 'From' cannot be greater than progress 'To'.");
            setErrorAlertOpen(true);
            return false;
        }

        if (values.from < 0 || values.to < 0) {
            setErrorAlertMessage("Progress 'From' and 'To' cannot be negative.");
            setErrorAlertOpen(true);
            return false;
        }

        if (isProgressAdded <= 1)
            return true;

        if (values.chapter_name_2 === "" || values.chapter_name_2 === null) {
            setErrorAlertMessage("Progress cannot be empty.");
            setErrorAlertOpen(true);
            return false;
        }

        if (values.chapter_name_2.length > 250) {
            setErrorAlertMessage("Progress is too long.");
            setErrorAlertOpen(true);
            return false;
        }

        if (isNaN(values.from_2) || isNaN(values.to_2)) {
            setErrorAlertMessage("Progress 'From' and 'To' must be a number.");
            setErrorAlertOpen(true);
            return false;
        }

        if (parseInt(values.from_2) > parseInt(values.to_2)) {
            setErrorAlertMessage("Progress 'From' cannot be greater than progress 'To'.");
            setErrorAlertOpen(true);
            return false;
        }

        if (values.from_2 < 0 || values.to_2 < 0) {
            setErrorAlertMessage("Progress 'From' and 'To' cannot be negative.");
            setErrorAlertOpen(true);
            return false;
        }

        if (isProgressAdded <= 2)
            return true;

        if (values.chapter_name_3 === "" || values.chapter_name_3 === null) {
            setErrorAlertMessage("Progress cannot be empty.");
            setErrorAlertOpen(true);
            return false;
        }

        if (values.chapter_name_3.length > 250) {
            setErrorAlertMessage("Progress is too long.");
            setErrorAlertOpen(true);
            return false;
        }

        if (isNaN(values.from_3) || isNaN(values.to_3)) {
            setErrorAlertMessage("Progress 'From' and 'To' must be a number.");
            setErrorAlertOpen(true);
            return false;
        }

        if (parseInt(values.from_3) > parseInt(values.to_3)) {
            setErrorAlertMessage("Progress 'From' cannot be greater than progress 'To'.");
            setErrorAlertOpen(true);
            return false;
        }

        if (values.from_3 < 0 || values.to_3 < 0) {
            setErrorAlertMessage("Progress 'From' and 'To' cannot be negative.");
            setErrorAlertOpen(true);
            return false;
        }

        return true;
    };

    const progressDirtyCheck = (values) => {
        if (firstProgressCount[0] !== isProgressAdded)
            return true;
        if (values.chapter_name !== prevChapter[0] || values.from !== prevFrom[0] || values.to !== prevTo[0])
            return true;
        if (isProgressAdded >= 2) {
            if (values.chapter_name_2 !== prevChapter2[0] || values.from_2 !== prevFrom2[0] || values.to_2 !== prevTo2[0])
                return true;
        }
        if (isProgressAdded >= 3) {
            if (values.chapter_name_3 !== prevChapter3[0] || values.from_3 !== prevFrom3[0] || values.to_3 !== prevTo3[0])
                return true;
        }
        return false;
    };

    const handleFormSubmit = async (values) => {
        setBackdropOpen(true);

        // check if all students have been evaluated
        let allEvaluated = true;
        courseStudentsEvaluation.forEach((element) => {
            if (!element.eval_1 || !element.eval_2) {
                allEvaluated = false;
            }
        });

        if (!allEvaluated) {
            setErrorAlertMessage("Please evaluate all students before submitting.");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        let doesRequestHaveError = false;
        let errorMsg = "";

        const progress = values.chapter_name;
        const progress_from = values.from;
        const progress_to = values.to;

        if (!progressErrorCheck(values)) {
            setBackdropOpen(false);
            return;
        }

        // update event module
        if (progressDirtyCheck(values)) {
            await Axios.put(`${process.env.REACT_APP_URL}/api/v1/events/${eventModule.id}/progress`, {
                progress,
                progress_from,
                progress_to,
                progress_2: isProgressAdded >= 2 ? values.chapter_name_2 : null,
                progress_from_2: isProgressAdded >= 2 ? values.from_2 : null,
                progress_to_2: isProgressAdded >= 2 ? values.to_2 : null,
                progress_3: isProgressAdded >= 3 ? values.chapter_name_3 : null,
                progress_from_3: isProgressAdded >= 3 ? values.from_3 : null,
                progress_to_3: isProgressAdded >= 3 ? values.to_3 : null,
                progress_count: isProgressAdded,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                console.log(response);
            }).catch((error) => {
                console.log(error);
                doesRequestHaveError = true;
                errorMsg = error.response.data.message;
            });
        }

        if (!doesRequestHaveError) {
            // update special notes
            const special_notes_data = [];
            for (let i = 0; i < courseStudentsEvaluation.length; i++) {
                if (courseStudentsEvaluation[i].eval_1_data !== courseStudentsEvaluation[i].prev_eval_1 
                    || courseStudentsEvaluation[i].eval_2_data !== courseStudentsEvaluation[i].prev_eval_2) {
                    special_notes_data.push({
                        id: courseStudentsEvaluation[i].special_note_id,
                        student_id: courseStudentsEvaluation[i].id,
                        eval_1: courseStudentsEvaluation[i].eval_1_data,
                        eval_2: courseStudentsEvaluation[i].eval_2_data,
                    });
                }
            }

            if (special_notes_data.length > 0) {
                await Axios.post(`${process.env.REACT_APP_URL}/api/v1/events/${eventModule.id}/special-notes/update`, {
                    all_special_notes: special_notes_data,
                }, {
                    headers: {
                        Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    },
                }).catch((error) => {
                    doesRequestHaveError = true;
                    errorMsg = error.response.data.message || "Something went wrong.";
                });
            }
        }

        if (doesRequestHaveError) {
            setErrorAlertMessage(errorMsg);
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        setBackdropOpen(false);
        setSuccessAlertOpen(true);
        setSuccessAlertMessage("Successfully updated the class log.");
        window.location.reload();
    };

    return (
        <div>
            <Button onClick={handleOpen} sx={{ p: 1, width: "100%" }}>Update</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                sx={{
                    height: "100%",
                    overflow: "scroll",
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Formik
                            onSubmit={handleFormSubmit}
                            initialValues={initialValues}
                            validationSchema={checkoutSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                            }) => (
                            <form onSubmit={handleSubmit}>
                                <Box
                                    padding={4}
                                    boxShadow={3}
                                    borderRadius={3}
                                    m={1}
                                    bgcolor={colors.primary[600]}
                                >
                                    <Typography
                                        variant="h4"
                                        fontWeight="bold"
                                        mb={3}
                                    >
                                        Update {eventModule.title}'s log
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        mb={1}
                                    >
                                        Progress
                                    </Typography>
                                    <Box
                                        display="grid"
                                        gap="30px"
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        mb={1}
                                        sx={{
                                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Name of Chapter"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.chapter_name}
                                            name="chapter_name"
                                            error={!!touched.chapter_name && !!errors.chapter_name}
                                            helperText={touched.chapter_name && errors.chapter_name}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="From (Page)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.from}
                                            name="from"
                                            error={!!touched.from && !!errors.from}
                                            helperText={touched.from && errors.from}
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="To"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.to}
                                            name="to"
                                            error={!!touched.to && !!errors.to}
                                            helperText={touched.to && errors.to}
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                    </Box>
                                    {isProgressAdded >= 2 && (
                                        <Box
                                            display="grid"
                                            gap="30px"
                                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                            mb={1}
                                            position="relative"
                                            sx={{
                                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                            }}
                                        >
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label="Name of Chapter"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.chapter_name_2}
                                                name="chapter_name_2"
                                                error={!!touched.chapter_name_2 && !!errors.chapter_name_2}
                                                helperText={touched.chapter_name_2 && errors.chapter_name_2}
                                                sx={{ gridColumn: "span 2" }}
                                            />
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label="From (Page)"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.from_2}
                                                name="from_2"
                                                error={!!touched.from_2 && !!errors.from_2}
                                                helperText={touched.from_2 && errors.from_2}
                                                sx={{ gridColumn: "span 1" }}
                                            />
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label="To"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.to_2}
                                                name="to_2"
                                                error={!!touched.to_2 && !!errors.to_2}
                                                helperText={touched.to_2 && errors.to_2}
                                                sx={{ gridColumn: "span 1" }}
                                            />
                                            <CloseIcon 
                                                color='error' 
                                                onClick={() => setIsProgressAdded(isProgressAdded - 1)} 
                                                sx={{ 
                                                    cursor: "pointer",
                                                    position: "absolute",
                                                    top: "0",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    right: "-20px",
                                                    zIndex: "100",
                                                }} 
                                            />
                                        </Box>
                                    )}
                                    {isProgressAdded >= 3 && (
                                        <Box
                                            display="grid"
                                            gap="30px"
                                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                            position="relative"
                                            sx={{
                                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                            }}
                                        >
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label="Name of Chapter"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.chapter_name_3}
                                                name="chapter_name_3"
                                                error={!!touched.chapter_name_3 && !!errors.chapter_name_3}
                                                helperText={touched.chapter_name_3 && errors.chapter_name_3}
                                                sx={{ gridColumn: "span 2" }}
                                            />
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label="From (Page)"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.from_3}
                                                name="from_3"
                                                error={!!touched.from_3 && !!errors.from_3}
                                                helperText={touched.from_3 && errors.from_3}
                                                sx={{ gridColumn: "span 1" }}
                                            />
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label="To"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.to_3}
                                                name="to_3"
                                                error={!!touched.to_3 && !!errors.to_3}
                                                helperText={touched.to_3 && errors.to_3}
                                                sx={{ gridColumn: "span 1" }}
                                            />
                                            <CloseIcon 
                                                color='error' 
                                                onClick={() => setIsProgressAdded(isProgressAdded - 1)} 
                                                sx={{ 
                                                    cursor: "pointer",
                                                    position: "absolute",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    right: "-20px",
                                                    zIndex: "100",
                                                }} 
                                            />
                                        </Box>
                                    )}
                                    {isProgressAdded < 3 && (
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: colors.blueAccent[600],
                                                color: "white",
                                                width: "12rem",
                                                marginTop: "20px",
                                            }}
                                            onClick={() => {
                                                setIsProgressAdded(isProgressAdded + 1);
                                            }}
                                        >
                                            Add Chapter
                                        </Button>
                                    )}

                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        mb={1}
                                        mt={2}
                                    >
                                        Student Evaluations
                                    </Typography>
                                    {courseStudentsEvaluation && courseStudentsEvaluation.map((student, index) => (
                                        <div 
                                            key={index}
                                            style={{
                                                width: "100%",
                                                marginBottom: "1rem",
                                            }}
                                        >
                                            <Accordion
                                                key={index}
                                                style={{
                                                    backgroundColor: colors.primary[750],
                                                }}
                                                component="div"
                                                defaultExpanded={index === 0 ? true : false}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography variant='h6' mr={1}>
                                                        {student.first_name + " " + student.last_name}
                                                    </Typography>
                                                    {student.eval_1 && student.eval_2 ? 
                                                        <CheckSharpIcon color='success' /> : <ClearSharpIcon color='error' />
                                                    }
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Box 
                                                        display="flex"
                                                        gap={1}
                                                    >
                                                        <TextField
                                                            id="filled-basic"
                                                            label="Evaluation: Student's good at this..."
                                                            variant="filled"
                                                            name={student.id + "-eval_1"}
                                                            fullWidth={true}
                                                            multiline={true}
                                                            rows={5}
                                                            sx={{
                                                                minWidth: "40%",
                                                                borderRadius: "5px",
                                                                '& .MuiFilledInput-root': {
                                                                    ":focus": {
                                                                        backgroundColor: 'white',
                                                                    },
                                                                },
                                                            }}
                                                            defaultValue={student.eval_1_data}
                                                            onChange={handleEvaluationChange}
                                                        />
                                                        <TextField
                                                            id="filled-basic"
                                                            label="Evaluation: Student needs to improve on this..."
                                                            variant="filled"
                                                            name={student.id + "-eval_2"}
                                                            fullWidth={true}
                                                            multiline={true}
                                                            rows={5}
                                                            sx={{
                                                                minWidth: "40%",
                                                                borderRadius: "5px",
                                                                '& .MuiFilledInput-root': {
                                                                    ":focus": {
                                                                        backgroundColor: 'white',
                                                                    },
                                                                },
                                                            }}
                                                            defaultValue={student.eval_2_data}
                                                            onChange={handleEvaluationChange}
                                                        />
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    ))}
                                    <Box display="flex" justifyContent="center" mt="20px">
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            style={{
                                                backgroundColor: colors.primary[300],
                                                color: "white",
                                                width: "10rem",
                                            }}
                                        >
                                            Update
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default UpdateModal