import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import Axios from 'axios';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(course, potentialCourse2, theme) {
    return {
        fontWeight:
            potentialCourse2.indexOf(course) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}  

const CombineCoursesModal = ({ 
    combineCoursesModalOpen,
    handleCombineCoursesCoursesModalClose,
    potentialCourses,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 400,
        maxWidth: 600,
        maxHeight: 800,
        overflowY: 'auto',
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
    };

    const [potentialCourse1, setPotentialCourse1] = React.useState(null);
    const [potentialCourse2, setPotentialCourse2] = React.useState([]);
    const [firstCourseSelectOpen, setFirstCourseSelectOpen] = React.useState(false);
    const [secondCourseSelectOpen, setSecondCourseSelectOpen] = React.useState(false);

    const handleFirstCourseSelectChange = (event) => {
        setPotentialCourse1(event.target.value);
        if (event.target.value.course_potential_schedule?.combined_with !== undefined
            && event.target.value.course_potential_schedule?.combined_with !== null) {
            const potentialCourse2 = [];
            for (let i = 0; i < event.target.value.course_potential_schedule.combined_with.length; i++) {
                for (let j = 0; j < potentialCourses.length; j++) {
                    if (potentialCourses[j].id === event.target.value.course_potential_schedule.combined_with[i]) {
                        potentialCourse2.push(potentialCourses[j]);
                    }
                }
            }

            setPotentialCourse2(potentialCourse2);
        }
    };
  
    const handleFirstCourseSelectClose = () => {
        setFirstCourseSelectOpen(false);
    };
  
    const handleFirstCourseSelectOpen = () => {
        setFirstCourseSelectOpen(true);
    };  

    const handleSecondCourseSelectChange = (event) => {
        const {
            target: { value },
        } = event;

        // if value is potentialCourse1, return
        for (let i = 0; i < value.length; i++) {
            if (value[i] === potentialCourse1) {
                return;
            }
        }

        if (value === potentialCourse1) {
            return;
        }

        setPotentialCourse2(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleSecondCourseSelectClose = () => {
        setSecondCourseSelectOpen(false);
    }

    const handleSecondCourseSelectOpen = () => {
        setSecondCourseSelectOpen(true);
    }

    async function combineCourses() {
        if (potentialCourse1 === null || potentialCourse2 === null || potentialCourse2.length === 0) {
            setErrorAlertMessage('Please select courses.');
            setErrorAlertOpen(true);
            return;
        }

        if (potentialCourse1.course_potential_schedule.id === undefined || potentialCourse1.course_potential_schedule.id === null) {
            setErrorAlertMessage('Error combining courses.');
            setErrorAlertOpen(true);
            return;
        }

        const otherCoursesIds = [];
        for (let i = 0; i < potentialCourse2.length; i++) {
            otherCoursesIds.push(potentialCourse2[i].id);
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/potential-courses/combine-for-template`, {
            course_potential_schedule_id: potentialCourse1.course_potential_schedule.id,
            first_course: potentialCourse1.id,
            other_courses: otherCoursesIds,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage('Courses combined successfully.');
            setSuccessAlertOpen(true);
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }).catch((error) => {
            setErrorAlertMessage('Error combining courses.');
            setErrorAlertOpen(true);
        });
    }

    return (
        <Modal
            open={combineCoursesModalOpen}
            onClose={handleCombineCoursesCoursesModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={combineCoursesModalOpen}>
                <Box sx={style}>
                    <Typography 
                        id="modal-modal-title" 
                        variant="h4" 
                        sx={{ 
                            color: colors.primary[100],
                            mb: '1rem',
                        }}
                    >
                        Combine Courses' Templates
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        variant="body1"
                        sx={{ 
                            color: 'darkgrey',
                            mb: '2rem',
                        }}
                    >
                        This will add a checkbox in the email generator 
                        (e.g. "SAT," which will function as a placeholder for the two courses 
                        (SAT Math and SAT English) and add only one email template in the email generator).
                    </Typography>                            
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '0.5rem' }}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-controlled-open-select-label">First Course (Use this course's email template)</InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    open={firstCourseSelectOpen}
                                    onClose={handleFirstCourseSelectClose}
                                    onOpen={handleFirstCourseSelectOpen}
                                    value={potentialCourse1}
                                    label="Course"
                                    onChange={handleFirstCourseSelectChange}
                                >
                                    {potentialCourses.map((course, index) => {
                                        if (course.course_potential_schedule !== undefined && course.course_potential_schedule !== null) {
                                            return (
                                                <MenuItem key={index} value={course}>{course.course_title + " (" + course.course_potential_schedule.time + " " + course.course_potential_schedule.days + ")"}</MenuItem>
                                            );
                                        }

                                        return (
                                            <MenuItem key={index} value={course}>{course.course_title}</MenuItem>
                                        );
                                    })}
                                </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-controlled-open-select-label">Other Courses</InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    open={secondCourseSelectOpen}
                                    onClose={handleSecondCourseSelectClose}
                                    onOpen={handleSecondCourseSelectOpen}
                                    multiple
                                    value={potentialCourse2}
                                    label="Course"
                                    onChange={handleSecondCourseSelectChange}
                                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => {
                                                if (value.course_potential_schedule !== undefined && value.course_potential_schedule !== null) {
                                                    return (
                                                        <Chip key={value.uuid} label={value.course_title + " (" + value.course_potential_schedule.time + " " + value.course_potential_schedule.days + ")"} />
                                                    );
                                                }

                                                return (
                                                    <Chip key={value.uuid} label={value.course_title} />
                                                );
                                            })}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {potentialCourses.map((course, index) => {
                                        if (course.course_potential_schedule !== undefined && course.course_potential_schedule !== null) {
                                            return (
                                                <MenuItem key={index} value={course}>{course.course_title + " (" + course.course_potential_schedule.time + " " + course.course_potential_schedule.days + ")"}</MenuItem>
                                            );
                                        }
                                        
                                        return (
                                            <MenuItem 
                                                key={index} 
                                                value={course}
                                                style={getStyles(course, potentialCourse2, theme)}
                                            >
                                                {course.course_title}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                        </FormControl>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: '1rem',
                            mt: '2rem',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={combineCourses}
                        >
                            Combine
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleCombineCoursesCoursesModalClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default CombineCoursesModal