import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, useTheme } from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { tokens } from "../../../theme";
import moment from 'moment';
import Axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';


export default function UpdateModal({ 
    courseId, 
    test,
    students,
    userRole,
    studentsStartEndDateMap,
    authorDisabledForAdminActions, 
    setBackdropOpen, 
    setSuccessAlertOpen, 
    setErrorAlertOpen, 
    setSuccessAlertMessage,
    setErrorAlertMessage,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    let current_students = [];

    for (let i = 0; i < students.length; i++) {
        const end_date = dayjs(studentsStartEndDateMap[students[i].id].end_date);
        const start_date = dayjs(studentsStartEndDateMap[students[i].id].start_date);        

        if (dayjs().isAfter(end_date) || dayjs().isBefore(start_date)) {
            // student was not in the class when the homework was posted
            continue;
        }
        current_students.push(students[i]);        
    }

    // 여기서 students를 가지고 homework에 있는 exclude_student와 unexclude_student를 처리해야 함
    let exclude_students = [];
    let unexclude_students = [];
    if (test.exclude_student !== null) {
        const exclude_student = test.exclude_student.split('|');
        exclude_students = exclude_student;
        unexclude_students = test.unexclude_student.split('|');
    }
    
    let exclude_candidates = [];
    let unexclude_candidates = [];
    let current_students_id = current_students.map(student => student.id);

    for (let i = 0; i < students.length; i++) {
        if (!current_students_id.includes(students[i].id))
            continue;

        if (!exclude_students.includes(students[i].id.toString()) && !unexclude_students.includes(students[i].id.toString())) {
            unexclude_candidates.push(students[i]);
            continue;
        }

        if (!exclude_students.includes(students[i].id.toString())) {
            exclude_candidates.push(students[i]);
        }
        else if (exclude_students.includes(students[i].id.toString())) {
            unexclude_candidates.push(students[i]);
        }
    }

    // for homework
    const [dueDate, setDueDate] = React.useState(test.due_date ? moment(test.due_date) : null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [newExcludeStudents, setnewExcludeStudents] = React.useState([]);
    const [newUnexcludeStudents, setNewUnexcludeStudents] = React.useState([]);

    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setBackdropOpen(true);

        // Join the selected_exclude_students and selected_unexclude_students arrays into strings
        let selected_exclude_students = newExcludeStudents.map(student => student.id).join('|');
        let selected_unexclude_students = newUnexcludeStudents.map(student => student.id).join('|');

        const testId = test.test_id;
        const data = new FormData(event.currentTarget);

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/tests/${testId}`, {
            title: data.get('title'),
            posted_by: data.get('author'),
            content: data.get('instructions'),
            due_date: dueDate,
            exclude_student: selected_exclude_students,
            unexclude_student: selected_unexclude_students,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("Test updated successfully!");
            setSuccessAlertOpen(true);
            setBackdropOpen(false);
            window.location.reload();
        }).catch((error) => {
            console.log(error);
            setBackdropOpen(false);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    };

    return (
        <div>
            <Button onClick={handleOpen} sx={{ p: 1, width: "100%" }}>Update</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                                display: "flex",
                                flexDirection: "column",
                                mr: "1rem",
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                        >
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "1rem",
                                minWidth: "100%",
                            }}>
                                <TextField
                                    id="filled-basic"
                                    label="Title"
                                    variant="filled"
                                    name='title'
                                    sx={{
                                        flex: "1",
                                        maxWidth: "50%",
                                        '& .MuiFilledInput-root': {
                                            backgroundColor: colors.primary[650],
                                            ":focus": {
                                                backgroundColor: 'white',
                                            },
                                        },
                                    }}
                                    defaultValue={test.title}
                                />
                                <TextField
                                    id="filled-basic"
                                    label="Author"
                                    variant="filled"
                                    name='author'
                                    sx={{
                                        flex: "1",
                                        maxWidth: "50%",
                                        '& .MuiFilledInput-root': {
                                            backgroundColor: colors.primary[650],
                                            ":focus": {
                                                backgroundColor: 'white',
                                            },
                                        },
                                    }}
                                    defaultValue={localStorage.getItem('userName')}
                                    disabled={authorDisabledForAdminActions}
                                />
                            </Box>
                            
                        {userRole === 'admin' ? 
                            (
                            <>
                                <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "1rem",
                                minWidth: "100%",
                                }}>
                                    {/* a list of students*/}                                
                                    <Autocomplete
                                        multiple
                                        limitTags={4}
                                        id="student-list"
                                        options={exclude_candidates ? exclude_candidates : []}
                                        getOptionLabel={(exclude_candidates) => exclude_candidates.first_name + " " + exclude_candidates.last_name}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select additional students to exclude from the test."
                                            />
                                        )}
                                        sx={{ width: "100%" }}                                
                                        onChange={(event, value) => {
                                            setnewExcludeStudents(value);
                                        }}                                
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                    />
                                </Box>
                                <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "1rem",
                                minWidth: "100%",
                                }}>
                                    {/* a list of students*/}
                                    <Autocomplete
                                        multiple
                                        limitTags={4}
                                        id="student-list"
                                        options={unexclude_candidates ? unexclude_candidates : []}
                                        getOptionLabel={(unexclude_candidates) => unexclude_candidates.first_name + " " + unexclude_candidates.last_name}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select additional test takers."                                                
                                            />
                                        )}
                                        sx={{ width: "100%" }}                                
                                        onChange={(event, value) => {
                                            setNewUnexcludeStudents(value);
                                        }}                                
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                    />
                                </Box>
                                </>
                            ) : (
                            <></>
                            )
                        }
                            <TextField
                                id="filled-basic"
                                label="Instructions"
                                variant="filled"
                                name='instructions'
                                fullWidth={true}
                                multiline={true}
                                rows={20}
                                sx={{
                                    minWidth: "100%",
                                    borderRadius: "5px",
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[650],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                                defaultValue={test.content}
                            />
                            <Box
                                display="flex"
                                minWidth="100%"
                                flexDirection="row"
                                justifyContent="space-between"
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        style={{
                                            backgroundColor: colors.primary[300],
                                            color: "white",
                                            float: "right",
                                            marginTop: "0.5rem",
                                            marginBottom: "1rem",
                                            width: "10rem",
                                        }}
                                    >
                                        Update
                                    </Button>                                
                                </Box>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DateTimePicker
                                        label="Due Date"
                                        value={dueDate}
                                        onChange={(newValue) => setDueDate(newValue)}
                                    />
                                </LocalizationProvider>
                            </Box>                     
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}