// import React, { useEffect, useState } from 'react';
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Signup from './adminDashboard/signup/Signup';
import Signin from './adminDashboard/signin/Signin';
import Home from "./adminDashboard/attendance/Home";
import Dashboard from "./adminDashboard/dashboard/Dashboard";
import Students from "./adminDashboard/students/Students";
import Teachers from "./adminDashboard/teachers/Teachers";
import Courses from "./adminDashboard/courses/Courses";
import CourseInfo from "./adminDashboard/courses/CourseInfo";
import AddStudent from "./adminDashboard/forms/AddStudent";
import AddCourse from "./adminDashboard/forms/AddCourse";
import AdminDashboardLayout from "./components/AdminDashboardLayout";
import SignInUpLayout from "./components/SignInUpLayout";
import ClassRoomLayout from "./components/ClassRoomLayout";
import Calendar from "./adminDashboard/calendar/Calendar";
import ClassRoomHome from "./classrooms/pages/home/ClassRoomHome";
import ClassRoomPeople from "./classrooms/pages/people/ClassRoomPeople";
import ClassesLayout from "./components/ClassesLayout";
import Classes from "./classrooms/pages/classes/Classes";
import Homework from "./classrooms/pages/homework/Homework";
import AddTeacher from "./adminDashboard/forms/AddTeacher";
import Modules from "./classrooms/pages/modules/Modules";
import SubmitPage from "./classrooms/pages/homework/SubmitPage";
import ClassLogs from "./classrooms/pages/classLogs/ClassLogs";
import AdminHomework from "./adminDashboard/homework/Homework";
import AdminClassLogs from "./adminDashboard/students/classLogs/ClassLogs";
import HwGrading from "./classrooms/pages/homework/grading/Grading";
import ClassMaterials from "./classrooms/pages/classMaterials/ClassMaterials";
import ZoomRooms from "./adminDashboard/zoomRooms/ZoomRooms";
import AddZoomRoom from "./adminDashboard/forms/AddZoomRoom";
import Announcements from "./classrooms/pages/announcements/Announcements";
import AddSemester from "./adminDashboard/forms/AddSemester";
import PotentialStudents from "./adminDashboard/potentialStudents/PotentialStudents";
import EmailGenerator from "./adminDashboard/consultationProgress/EmailGenerator";
import ZoomRecordings from "./classrooms/pages/zoomRecordings/ZoomRecordings";
import ForgotPassword from "./adminDashboard/signin/ForgotPassword";
import ResetPassword from "./adminDashboard/signin/ResetPassword";
import Questions from "./classrooms/pages/questions/Questions";
import AdminQuestions from "./adminDashboard/questions/Questions";
import AdminSchoolMaterials from "./adminDashboard/schoolMaterials/SchoolMaterials";
// import ZoomAssignPage from "./adminDashboard/zoomRooms/ZoomAssignPage";
import HwQuestions from "./adminDashboard/hwQuestions/HwQuestions";
import AddTextbook from "./adminDashboard/forms/AddTextbook";
import Textbooks from "./adminDashboard/textbooks/Textbooks";
import TextbookExercises from "./adminDashboard/textbooks/TextbookExercises";
import HWQuestionsAttempts from "./classrooms/pages/homework/hwQuestionsAttempts/HWQuestionsAttempts";
import PotentialTeachers from "./adminDashboard/potentialTeachers/PotentialTeachers";
import ConsultationProgress from "./adminDashboard/consultationProgress/ConsultationProgress";
import PotentialCourses from "./adminDashboard/potentialCourses/PotentialCourses";
import EmailDrafts from "./adminDashboard/emailDrafts/EmailDrafts";
import AddTest from "./adminDashboard/forms/AddTest";
import Test from "./classrooms/pages/tests/Test";
import Tests from "./adminDashboard/tests/Tests";
import ViewTest from "./adminDashboard/tests/ViewTest";
import EditTest from "./adminDashboard/tests/EditTest";
import TestSubmitPage from "./classrooms/pages/tests/TestSubmitPage";
import TestResult from "./classrooms/pages/tests/TestResult";
import StudentResult from "./classrooms/pages/tests/StudentResult";

function App() {
    const [theme, colorMode] = useMode();

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="app">
                    <Routes>
                        <Route element={<AdminDashboardLayout/>}>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/admin/home" element={<Dashboard />} />
                            <Route path="/admin/attendance" element={<Home />} />
                            <Route path="/admin/potentialCourses" element={<PotentialCourses />} />
                            <Route path="/admin/students" element={<Students />} />
                            <Route path="/admin/students/:id/class-logs" element={<AdminClassLogs />} />
                            <Route path="/admin/potentialStudents" element={<PotentialStudents />} />
                            <Route path="/admin/consultationProgress" element={<ConsultationProgress />} />
                            <Route path="/admin/consultationProgress/:id/emailGenerator" element={<EmailGenerator />} />
                            <Route path="/admin/emailDrafts" element={<EmailDrafts />} />
                            <Route path="/admin/teachers" element={<Teachers />} />
                            <Route path="/admin/potentialTeachers" element={<PotentialTeachers />} />
                            <Route path="/admin/courses" element={<Courses />} />
                            <Route path="/admin/courses/:id" element={<CourseInfo />} />
                            <Route path="/admin/zoom" element={<ZoomRooms />} />
                            <Route path="/admin/textbooks" element={<Textbooks />} />
                            <Route path="/admin/textbooks/:id" element={<TextbookExercises />} />
                            <Route path="/admin/tests" element={<Tests />} />
                            <Route path="/admin/tests/:id" element={<ViewTest />} />
                            <Route path="/admin/tests/:id/editTest" element={<EditTest />} />
                            {/* <Route path="/admin/zoom/assign" element={<ZoomAssignPage />} /> */}
                            <Route path="/admin/addStudent" element={<AddStudent />} />
                            <Route path="/admin/addTeacher" element={<AddTeacher />} />
                            <Route path="/admin/addCourse" element={<AddCourse />} />
                            <Route path="/admin/addSemester" element={<AddSemester />} />
                            <Route path="/admin/addZoomRoom" element={<AddZoomRoom />} />
                            <Route path="/admin/addTextbook" element={<AddTextbook />} />
                            <Route path="/admin/hwQuestions" element={<HwQuestions />} />
                            <Route path="/admin/addTest" element={<AddTest />} />
                            <Route path="/admin/homework" element={<AdminHomework />} />
                            <Route path="/admin/questions" element={<AdminQuestions />} />
                            <Route path="/admin/schoolMaterials" element={<AdminSchoolMaterials />} />
                            <Route path="/admin/calendar" element={<Calendar />} />
                        </Route>
                        <Route element={<SignInUpLayout/>}>
                            <Route path="/signin" element={<Signin />} />
                            <Route path="/signup" element={<Signup />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                        </Route>
                        <Route element={<ClassesLayout />}>
                            <Route path="/classrooms/classes" element={<Classes />} />
                        </Route>
                        <Route element={<ClassRoomLayout />}>
                            <Route path="/classrooms/:id/home" element={<ClassRoomHome />} />
                            <Route path="/classrooms/:id/announcements" element={<Announcements />} />
                            <Route path="/classrooms/:id/people" element={<ClassRoomPeople />} />
                            <Route path="/classrooms/:id/homework" element={<Homework />} />
                            <Route path="/classrooms/:id/homework/:hid" element={<Homework />} />
                            <Route path="/classrooms/:id/homework/:hid/grading" element={<HwGrading />} />
                            <Route path="/classrooms/:id/homework/:hid/submissions" element={<SubmitPage />} />
                            <Route path="/classrooms/:id/homework/:hid/submissions/attempts" element={<HWQuestionsAttempts />} />
                            <Route path="/classrooms/:id/modules" element={<Modules />} />
                            <Route path="/classrooms/:id/class-logs" element={<ClassLogs />} />
                            <Route path="/classrooms/:id/class-materials" element={<ClassMaterials />} />
                            <Route path="/classrooms/:id/tests" element={<Test />} />
                            <Route path="/classrooms/:id/tests/:tid/grading" element={<TestResult />} />
                            <Route path="/classrooms/:id/test/:tid/submissions" element={<TestSubmitPage />} />
                            <Route path="/classrooms/:id/test/:tid/student/:sid/submission" element={<StudentResult />} />
                            <Route path="/classrooms/:id/zoom-recordings" element={<ZoomRecordings />} />
                            <Route path="/classrooms/:id/questions" element={<Questions />} />
                        </Route>
                    </Routes>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default App;