import * as React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import Axios from 'axios';
import Alert from '../../components/alert/Alert';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Backdrop from "../../components/Backdrop";

const AddZoomRoom = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");
    const isNonMobile = useMediaQuery("(min-width:600px)");

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    const handleFormSubmit = (values) => {
        setBackdropOpen(true);
        
        Axios.post(`${process.env.REACT_APP_URL}/api/v1/zooms`, {
            room_number: values.room_number,
            meeting_id: values.meeting_id,
            link: values.link,
            password: values.password,
            note: values.note,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage(response.data.message);
            setSuccessAlertOpen(true);
            handleBackdropClose();
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
            handleBackdropClose();
        });
    };

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Add Zoom Room');
    }, []);

    return (
        <Box m="20px" marginX={3} marginY={3} maxWidth={"70rem"}>
            <Header title="Add Zoom Room" subtitle="A ONE Institute" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        padding={4}
                        boxShadow={3}
                        borderRadius={3}
                        m={1}
                        bgcolor={colors.primary[600]}
                    >
                        <Typography
                            variant="h4"
                            fontWeight="bold"
                            mb={3}
                        >
                            Zoom Room Information
                        </Typography>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Room Number"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.room_number}
                                name="room_number"
                                error={!!touched.room_number && !!errors.room_number}
                                helperText={touched.room_number && errors.room_number}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Meeting ID"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.meeting_id}
                                name="meeting_id"
                                error={!!touched.meeting_id && !!errors.meeting_id}
                                helperText={touched.meeting_id && errors.meeting_id}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Link"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.link}
                                name="link"
                                error={!!touched.link && !!errors.link}
                                helperText={touched.link && errors.link}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Room Password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                                name="password"
                                error={!!touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Note"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.note}
                                name="note"
                                error={!!touched.note && !!errors.note}
                                helperText={touched.note && errors.note}
                                sx={{ gridColumn: "span 2" }}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="start" mt="20px">
                        <Button type="submit" color="secondary" variant="contained">
                            Create New Zoom Room
                        </Button>
                    </Box>
                </form>
                )}
            </Formik>
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
            <Backdrop backdropOpen={backdropOpen} handleBackdropClose={handleBackdropClose} />
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    room_number: yup.number().required("required"),
    meeting_id: yup.string().required("required"),
    link: yup.string().required("required"),
    password: yup.string().required("required"),
    note: yup.string().required("required"),
});

const initialValues = {
    room_number: "",
    meeting_id: "",
    link: "",
    password: "",
    note: "",
};

export default AddZoomRoom