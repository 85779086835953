import React, { useState } from 'react'
import Header from '../../../components/Header'
import { tokens } from "../../../theme";
import { useTheme, useMediaQuery, Box, Divider, Backdrop, Select, InputLabel, MenuItem, FormControl } from "@mui/material";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import { FileUploadOutlined } from '@mui/icons-material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TextField from '@mui/material/TextField';
import Alert from '../../../components/alert/Alert';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Axios from 'axios';
import getCookies from '../../../utils/getCookies';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import SendIcon from '@mui/icons-material/Send';


const SubmitPage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const matches = useMediaQuery('(min-width:1500px)');
    const matches2 = useMediaQuery('(min-width:1200px)');

    const courseId = window.location.pathname.split("/")[2]; // uuid
    const homeworkId = window.location.pathname.split("/")[4]; // uuid
    const currentUser = getCookies("_auth_state");
    const userId = currentUser ? currentUser.split(',')[0]?.split(':')[1]?.split('"')[1] : null;
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const minWidth90Rem = useMediaQuery('(min-width:90rem)');
    const minWidth1300px = useMediaQuery('(min-width:1300px)');

    // for loading
    const [loading, setLoading] = React.useState(true)

    // for homework and submissions
    const [homework, setHomework] = React.useState([]);
    const [submissions, setSubmissions] = React.useState(null);
    const [onGoingSubmission, setOnGoingSubmission] = React.useState(null);

    // for students' submission by Admin
    const [studentList, setStudentList] = React.useState([]);

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    // for modal open 
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isSelectionMade, setIsSelectionMade] = useState(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    // for file upload
    const [files, setFiles] = React.useState([]);
    const handleFileRemove = (event, index) => {
        event.preventDefault();
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };
    const handleUpload = (event) => {
        setFiles([...files, ...Array.from(event.target.files)]);
    };

    const [studentUuid, setStudentUuid] = React.useState("");
    

    const handleSelect = (event) => {
        setStudentUuid(event.target.value);
        setIsSelectionMade(true);
    };

    const handleAdminSubmit = async (event) => {
        event.preventDefault();

        if (studentUuid === "") { // no uuid which means no student is selected.
            setErrorAlertMessage("Please select a student!");
            setErrorAlertOpen(true);
            return;
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/homeworks/${homeworkId}/students/${studentUuid}/admin/submissions`, {
            is_admin: userRole === "admin" ? true : false
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((res) => {
            console.log(res.data);
            setSuccessAlertMessage("Homework submitted successfully!");
            setSuccessAlertOpen(true);
            window.location.reload();
        }
        ).catch((err) => {
            setErrorAlertMessage(err.response.data.message);
            setErrorAlertOpen(true);
        });                  
    }

    // for form
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (userRole === "admin") {
            handleOpen();
            return;
        }

        if (userRole !== "student") {
            setErrorAlertMessage("Only students can submit homework!");
            setErrorAlertOpen(true);
            return;
        }

        if (files.length === 0 && event.target.comment.value === "") {
            setErrorAlertMessage("Please upload a file or write something to submit!");
            setErrorAlertOpen(true);
            return;
        }

        setBackdropOpen(true);
        /*
        * request body:
        * - student_id
        * - comment (content in the hw_submission table)
        * - files (for the hw_submission_files table)
        */
        const formData = new FormData();
        formData.append("student_id", userId);
        formData.append("content", event.target.comment.value);
        for (let i = 0; i < files.length; i++) {
            // only pdf is allowed
            if (files[i].type !== "application/pdf") {
                setErrorAlertMessage("Only PDF files are allowed!");
                setErrorAlertOpen(true);
                setBackdropOpen(false);
                return;
            }

            if (files[i].name !== undefined 
                && files[i].name !== null 
                && files[i].name !== ""
                && files[i].name.length > 150) {
                setErrorAlertMessage("File name should be less than 150 characters!");
                setErrorAlertOpen(true);
                setBackdropOpen(false);
                return;
            }

            formData.append('file', files[i], files[i].name);
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/events/${homework.event_id}/homeworks/${homework.id}/submissions`,
            formData, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
        }).then((res) => {
            setBackdropOpen(false);
            setSuccessAlertMessage("Homework submitted successfully!");
            setSuccessAlertOpen(true);
            window.location.reload();
        }).catch((err) => {
            setBackdropOpen(false);
            setErrorAlertMessage(err.response.data.message || err.response.data.error || "Failed to submit homework! Please try again.");
            setErrorAlertOpen(true);
        });
    };
    
    React.useEffect(() => {
        async function getHomework() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/homeworks/${homeworkId}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                setHomework(res.data.homework);
                // console.log(res.data.homework);
            }).catch((err) => {
                console.log(err);
            });
        }

        async function getSubmissions() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/homeworks/${homeworkId}/students/${userId}/submissions`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                setSubmissions(res.data.submissions);

                for (let i = 0; i < res.data.submissions.length; i++) {
                    if (res.data.submissions[i].is_submitted === false) {
                        setOnGoingSubmission(res.data.submissions[i]);
                        break;
                    }
                }
            }).catch((err) => {
                console.log(err);
                if (err.response.status === 404) {
                    setSubmissions([]);
                }
            });
        }

        async function getStudents(){
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/students`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },                
            }).then((studentRes) => {
                const student_list = [];

                Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/homeworks/${homeworkId}`, {
                    headers: {
                        Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    },
                }).then((homeworkRes) => {
                    setHomework(homeworkRes.data.homework);
                      
                    if (homeworkRes.data.homework?.unexclude_student !== null) {
                        for (let i = 0; i < studentRes.data.length; i++) {
                            student_list.push(studentRes.data[i].student);
                        }
                        const unexclude_student_list = homeworkRes.data.homework.unexclude_student.split("|");                     
                        const unexclude_list = student_list.filter(student => unexclude_student_list.includes(student.id.toString()));
                        setStudentList(unexclude_list);
                    }
                }).catch((err) => {
                console.log(err);
                if (err.response.status === 404) {
                    setStudentList([]);
                }
            })
        }).catch((err) => {
            console.log(err);
        });
        }

        getHomework();

        if (userRole === "admin")
            getStudents();
        else
            setStudentList([]);


        if (userRole === "student")
            getSubmissions();
        else
            setSubmissions([]);

        setLoading(false);
    }, [])


    function getHWStartString() {
        if (onGoingSubmission !== null) {
            return 'Continue Homework';
        }

        if (submissions !== null && submissions.length > 0) {
            return 'Already Submitted';
        }

        if (userRole !== "student") {
            return 'View Homework Details';
        }
        
        return 'Start Homework';
    }

    function getPercentage(given_score, total_score) {
        const percentage = (given_score / total_score) * 100;
        return percentage.toFixed(0);
    }

    function handleMouseOver(e, id) {
        const tooltip = document.getElementById('tooltip-' + id);
        tooltip.style.display = 'block';
    }

    function handleMouseLeave(e, id) {
        const tooltip = document.getElementById('tooltip-' + id);
        tooltip.style.display = 'none';
    }

    function handleFeedBackClick(e, feedbackId) {
        // send request to server to make 'is_opened' true
        Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/homeworks/${homeworkId}/feedbacks/${feedbackId}/opened`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).catch((err) => {
            console.log(err.response.data.message);
        });
    }

    return (
        <Box m="20px" marginX={3} marginY={3}>
            { loading || homework === null || submissions === null ? 
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60vh" }}>
                    <CircularProgress color="primary" />
                </div>
                : <>
                    <Box 
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            px: "25%",
                            ...!matches && {
                                px: "15%",
                            },
                            ...!matches2 && {
                                px: "10%",
                            },
                        }}
                    >
                        <Header title={homework.title} subtitle="A ONE Institute"/>
                        {/* For Admin */}
                        {userRole !== "student" && 
                            <Box></Box>
                        }

                        {/* For Students and teachers */}
                        <Box
                            minWidth="20rem"
                            width="100%"
                            minHeight="32rem"
                            borderRadius="3px"
                            padding={8}
                            boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                            backgroundColor={colors.primary[600]}
                            alignItems="center"
                        >
                            <Box
                                sx={{
                                    minWidth: "100%",
                                    pb: "2rem",
                                }}
                            >
                                <Typography variant="h4" fontWeight="bold" color={colors.primary[450]}>
                                    Your Previous Submissions
                                </Typography>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    mt={1}
                                    gap={1}
                                >
                                    {submissions !== null && submissions.length > 0 ? (
                                        submissions.map((submission, index) => {

                                            if (submission === null || submission === undefined)
                                                    return null;
                                            if (submission.is_submitted === false)
                                                    return null;
                                            

                                            if (homework.class_material_id === null) {
                                                // for homework that is not associated with class material and hw_questions (submitting only comment or files)
                                                return (
                                                    <Box key={index}>
                                                        <Box
                                                            key={submission.id}
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                minWidth: "100%",
                                                                flexWrap: "wrap",
                                                                ...minWidth1300px && {
                                                                    gap: "0.5rem",
                                                                },
                                                            }}
                                                        >
                                                            <Typography variant="h6" fontWeight="bold" color={colors.primary[450]}>
                                                                Attempt #{index + 1}
                                                            </Typography>
                                                            <Typography variant="h6" color={colors.grey[700]}>
                                                                (submitted on {new Date(submission.createdAt).toLocaleString()})
                                                            </Typography>
                                                            <Tooltip 
                                                                TransitionComponent={Zoom} 
                                                                title={
                                                                    <Box>
                                                                        <Typography variant="h6" color="white">
                                                                            {submission.content}
                                                                        </Typography>
                                                                        {submission.files.length > 0 ? 
                                                                            submission.files.map((file) => (
                                                                                <Typography key={file.id} variant="body1" color="white">
                                                                                    <a 
                                                                                        href={file.file_url} 
                                                                                        target="_blank" 
                                                                                        rel="noreferrer"
                                                                                        style={{
                                                                                            textDecoration: "underline",
                                                                                            color: colors.greenAccent[500],
                                                                                        }}
                                                                                    >
                                                                                        - {file.name}
                                                                                    </a>
                                                                                </Typography>
                                                                            ))
                                                                        : (
                                                                            <Typography variant="body1" color="white">
                                                                                - No files submitted
                                                                            </Typography>
                                                                        )}
                                                                    </Box>
                                                                } 
                                                                arrow
                                                            >
                                                                <MoreHorizIcon sx={{color: colors.grey[700]}} />
                                                            </Tooltip>
                                                        </Box>
                                                        {submission.score !== null && (
                                                            <Typography variant="h6" fontWeight="bold" ml={2}>
                                                                - Score: {submission.score} / {submission.maxScore}
                                                            </Typography>
                                                        )}
                                                        {submission.hw_feedbacks !== null && submission.hw_feedbacks.length > 0 
                                                        && submission.hw_feedbacks.map((feedback, index) => {
                                                            return (
                                                                <Box
                                                                    key={index}
                                                                    sx={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        alignItems: "center",
                                                                        gap: "0.5rem",
                                                                    }}
                                                                >
                                                                    <Typography variant="h6" fontWeight="bold" ml={2}>
                                                                        - Feedback #{index + 1}
                                                                    </Typography>
                                                                    {feedback.file_url !== null && (
                                                                        <Button
                                                                            type="button"
                                                                            variant="text"
                                                                            href={feedback.file_url}
                                                                            target='_blank'
                                                                            sx={{
                                                                                border: 'none',
                                                                                backgroundColor: 'transparent',
                                                                                color: colors.blueAccent[300],
                                                                                fontSize: "12px",
                                                                                textDecoration: "underline",
                                                                                textTransform: "none",
                                                                                padding: 0,
                                                                                '&:hover': {
                                                                                    backgroundColor: 'transparent',
                                                                                },
                                                                            }}
                                                                            onClick={(e) => handleFeedBackClick(e, feedback.id)}
                                                                        >
                                                                            {feedback.name}
                                                                        </Button>
                                                                    )}
                                                                    {feedback.comment !== null && feedback.comment !== "" && (
                                                                        <Tooltip 
                                                                            TransitionComponent={Zoom} 
                                                                            title={
                                                                                <Typography variant="h6" color="white">
                                                                                    {feedback.comment.split("\n").map((line, index) => (
                                                                                        <span key={index}>
                                                                                            {line}
                                                                                            <br />
                                                                                        </span>
                                                                                    ))}
                                                                                </Typography>
                                                                            } 
                                                                            arrow
                                                                        >
                                                                            <Typography variant="h6" color={colors.grey[700]}>
                                                                                (mouse over to see comment)
                                                                            </Typography>
                                                                        </Tooltip>
                                                                    )}
                                                                </Box>
                                                            )
                                                        })}
                                                    </Box>
                                                );
                                            } else {
                                                // for homework that is associated with class material and hw_questions
                                                return (
                                                    <Box key={index}>
                                                        <Box
                                                            key={submission.id}
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                minWidth: "100%",
                                                                flexWrap: "wrap",
                                                                ...minWidth1300px && {
                                                                    gap: "0.5rem",
                                                                },
                                                            }}
                                                        >
                                                            <Typography variant="h6" fontWeight="bold" color={colors.primary[450]}>
                                                                Homework Submitted
                                                            </Typography>
                                                            <Typography variant="h6" color={colors.grey[700]}>
                                                                (on {new Date(submission.createdAt).toLocaleString()})
                                                            </Typography>
                                                            
                                                            {/* navigate to result page */}
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                href={`/classrooms/${courseId}/homework/${homeworkId}/submissions/attempts?submission_id=${submission.id}`}
                                                                sx={{
                                                                    backgroundColor: colors.primary[300],
                                                                    color: "white",
                                                                    width: "8rem",
                                                                    alignSelf: "center",
                                                                    height: "1.5rem",
                                                                    fontSize: "12px",
                                                                    marginLeft: "1rem",
                                                                }}
                                                            >
                                                                View Result
                                                            </Button>
                                                        </Box>
                                                        {submission.correct_answer_count !== null && submission.hw_question_count && (
                                                            <Typography variant="h6" fontWeight="bold" ml={2} sx={{ display: 'inline-flex', alignItems: 'center', position: 'relative' }}>
                                                                - Score: {submission.correct_answer_count} / {submission.hw_question_count} 
                                                                <span style={{ color: colors.grey[700], fontWeight: 'normal' }}>(score for auto-graded questions)</span> 
                                                                <ContactSupportIcon sx={{ marginLeft: '0.5rem' }} onMouseOver={(e) => handleMouseOver(e, 1)} onMouseLeave={(e) => handleMouseLeave(e, 1)} />
                                                                <Box
                                                                    id="tooltip-1"
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        top: '-2.5rem',
                                                                        right: '0',
                                                                        backgroundColor: colors.grey[900],
                                                                        padding: '0.5rem',
                                                                        borderRadius: '5px',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        gap: '0.5rem',
                                                                        border: `2px solid ${colors.primary[100]}`,
                                                                        zIndex: 2147483647,
                                                                        display: 'none',
                                                                    }}
                                                                >
                                                                    <Typography variant="h6">
                                                                        correct answers / total questions
                                                                    </Typography>
                                                                </Box>
                                                            </Typography>
                                                        )}
                                                        {submission.total_score_not_autograded === -1 ? (
                                                            <Typography variant="h6" ml={2}>
                                                                No non-auto-graded questions in this homework
                                                            </Typography>
                                                        ) : submission.total_score_not_autograded === 0 ? (
                                                            <Typography variant="h6" ml={2}>
                                                                - Instructors have not graded non-auto-graded questions yet
                                                            </Typography>
                                                        ) : (
                                                            <Typography variant="h6" fontWeight="bold" ml={2} sx={{ display: 'inline-flex', alignItems: 'center', position: 'relative' }}>
                                                                - Score: {getPercentage(submission.given_score_from_instructor, submission.total_score_not_autograded)}% 
                                                                <span style={{ color: colors.grey[700], fontWeight: 'normal' }}>(score for non-auto-graded questions)</span>
                                                                <ContactSupportIcon sx={{ marginLeft: '0.5rem' }} onMouseOver={(e) => handleMouseOver(e, 2)} onMouseLeave={(e) => handleMouseLeave(e, 2)} />
                                                                <Box
                                                                    id="tooltip-2"
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        top: '-2.5rem',
                                                                        right: '0',
                                                                        backgroundColor: colors.grey[900],
                                                                        padding: '0.5rem',
                                                                        borderRadius: '5px',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        gap: '0.5rem',
                                                                        border: `2px solid ${colors.primary[100]}`,
                                                                        zIndex: 2147483647,
                                                                        //display: 'none',
                                                                    }}
                                                                >
                                                                    <Typography variant="h6">
                                                                        Your instructor might have left feedback
                                                                    </Typography>
                                                                </Box>
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                );
                                            }
                                        })
                                    ) : (
                                        <Typography variant="h6" color={colors.primary[450]}>
                                            No submissions yet
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            <Divider sx={{ minWidth: "100%" }} />
                            {homework.class_material_id === null ? (
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '25ch' },
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    open={open}
                                    onSubmit={handleSubmit}
                                >
                                    <Box 
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: "0.5rem",
                                            minWidth: "100%",
                                            pt: "1rem",
                                        }}
                                    >
                                        <AssignmentIcon />
                                        <Typography variant="h4" fontWeight="bold" color={colors.primary[450]}>
                                            {homework.title}
                                        </Typography>
                                    </Box>
                                    {userRole === "admin" ? <Box></Box> :
                                        <TextField
                                            id="filled-basic"
                                            label="Comment"
                                            variant="filled"
                                            name='comment'
                                            fullWidth={true}
                                            multiline={true}
                                            rows={10}
                                            sx={{
                                                minWidth: "100%",
                                                borderRadius: "5px",
                                                '& .MuiFilledInput-root': {
                                                    ":focus": {
                                                        backgroundColor: 'white',
                                                    },
                                                },
                                            }}
                                        /> 
                                    }                                    
                                    {userRole === "admin" ? <Box></Box> :
                                        <Box
                                            display="flex"
                                            minWidth="100%"
                                            flexDirection="column"
                                            justifyContent="flex-start"
                                            sx={{
                                                gap: "1rem",
                                                ...minWidth90Rem && {
                                                    justifyContent: "space-between",
                                                    flexDirection: "row",
                                                },
                                            }}
                                        >
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                gap={2}
                                            >
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    mb={-2}
                                                >
                                                    <Typography variant="h6" fontWeight="bold" color={colors.primary[450]}>
                                                        Upload Files
                                                    </Typography>
                                                    <IconButton component="label">
                                                        <FileUploadOutlined />
                                                        <input
                                                            multiple={true}
                                                            styles={{display:"none"}}
                                                            type="file"
                                                            hidden
                                                            onChange={handleUpload}
                                                            name="[licenseFile]"
                                                        />
                                                    </IconButton>
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    minWidth="100%"
                                                    flexDirection="column"
                                                >
                                                    {files.length > 0 && files.map((file, index) => {
                                                        return (
                                                            <Box key={index} display="flex" alignItems="center">
                                                                <Typography variant="body1" color={colors.primary[450]}>
                                                                    {file.name}
                                                                </Typography>
                                                                <IconButton onClick={(e) => handleFileRemove(e, index)}>
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </Box>
                                                        )
                                                    })}
                                                </Box>
                                            </Box>
                                        </Box>
                                    }
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        style={{
                                            backgroundColor: colors.primary[300],
                                            color: "white",
                                            float: "right",
                                            marginTop: "0.5rem",
                                            marginBottom: "1rem",
                                            width: userRole === "admin" ? "14rem" : "10rem",
                                            alignSelf: "center",
                                        }}
                                    >
                                        {userRole === "admin" ? "Submit for Student" : "Submit"}
                                    </Button>
                                    
                                    <Modal
                                        aria-labelledby="student"
                                        aria-describedby="student-list"
                                        open={open}
                                        onClose={handleClose}
                                        closeAfterTransition
                                        slots={{ backdrop: Backdrop }}
                                        slotProps={{
                                            backdrop: {
                                                timeout: 500,
                                            },
                                        }}
                                    >                                                                                
                                        <Fade in={open}>
                                            <Box 
                                                sx={style}
                                                display="flex"
                                                alignItems="center"
                                                flexDirection="column"
                                            >
                                            {studentList !== null && studentList.length > 0 && 
                                                <FormControl fullWidth>
                                                    <InputLabel id="student-select">Choose a student</InputLabel>
                                                    <Select
                                                        labelId="student-select"
                                                        id="student-select"
                                                        label="Choose a student"
                                                        onChange={handleSelect}
                                                        value={studentUuid}                                                    
                                                    >
                                                        {studentList.map((student, index) => {
                                                            if (student === null || student === undefined)
                                                                return null;

                                                            return (
                                                                <MenuItem key={index} value={student.uuid}>
                                                                    {student.first_name} {student.last_name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                    {isSelectionMade ? 
                                                        <Typography variant="contained" sx={{marginTop: '10px'}} color="red">
                                                            After submission, provide feedback for the selected student.
                                                        </Typography>
                                                        : <></> 
                                                    }
                                                    <Button variant="contained" sx={{marginTop: '15px'}} endIcon={<SendIcon />} onClick={handleAdminSubmit}>Submit</Button>                                               
                                                </FormControl>                                                   
                                            }
                                            
                                            </Box>
                                        </Fade>
                                    </Modal>
                                </Box>
                               ) : (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    height="30vh"
                                >
                                    <Button
                                        disabled={submissions !== null && submissions.length > 0 && onGoingSubmission === null}
                                        type="button"
                                        variant="contained"
                                        href={`/classrooms/${courseId}/homework/${homeworkId}/submissions/attempts?submission_id=${onGoingSubmission?.id || ""}`}
                                        sx={{
                                            backgroundColor: colors.primary[300],
                                            color: "white",
                                            float: "right",
                                            marginTop: "0.5rem",
                                            marginBottom: "1rem",
                                            width: "20rem",
                                            height: "3rem",
                                            alignSelf: "center",
                                        }}
                                    >
                                        {getHWStartString()}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                        <Alert
                            successAlertOpen={successAlertOpen}
                            setSuccessAlertOpen={setSuccessAlertOpen}
                            errorAlertOpen={errorAlertOpen}
                            setErrorAlertOpen={setErrorAlertOpen}
                            successMsg={successAlertMessage}
                            errorMsg={errorAlertMessage}
                            vert="bottom"
                            horiz="left"
                        />
                        <Backdrop
                            sx={{ color: colors.primary[400], zIndex: 2147483646 }}
                            open={backdropOpen}
                            onClick={handleBackdropClose}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </Box>
                </>
            }
        </Box>
    )
}

export default SubmitPage