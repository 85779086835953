import React from 'react'
import { tokens } from "../../../theme";
import { Box, useTheme, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '../../../components/alert/Alert';
import Header from "../../../components/Header";
import Axios from 'axios';
import getCookies from '../../../utils/getCookies';
import Loading from '../../../components/Loading';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CreateQuestionsAccordion from './CreateQuestionsAccordion';
import QuestionBox from './QuestionBox';

const Questions = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const matches = useMediaQuery('(min-width:1500px)');
    const matches2 = useMediaQuery('(min-width:1200px)');

    const [loading, setLoading] = React.useState(true);
    const [courseId, setCourseId] = React.useState(null);
    const courseData = useOutletContext().courseData;
    const lectureModules = useOutletContext().lectureModules;
    const currentUser = getCookies("_auth_state");
    const userId = currentUser ? currentUser.split(',')[0]?.split(':')[1]?.split('"')[1] : null;
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;

    const [questions, setQuestions] = React.useState(null);

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    async function getStudentQuestions() {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${window.location.pathname.split('/')[2]}/students/${userId}/course-questions`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            }
        }).then((res) => {
            setQuestions(res.data.questions);
        }).catch((err) => {
            if (err.response.status !== 401) {
                handleLogout();
                return;
            }
            console.log(err);
        });
    }

    async function getAdminTeacherQuestions() {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${window.location.pathname.split('/')[2]}/course-questions`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            }
        }).then((res) => {
            setQuestions(res.data.questions);
        }).catch((err) => {
            if (err.response.status !== 401) {
                handleLogout();
                return;
            }
            console.log(err);
        });
    }

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Questions');
        setCourseId(window.location.pathname.split('/')[2]);

        if (userRole === "student") {
            getStudentQuestions();
        } else if (userRole === "admin" || userRole === "teacher") {
            getAdminTeacherQuestions();
        }

        setLoading(false);
    }, []);
    

    return (
        <Box m="20px" marginX={3} marginY={3} minHeight="90%">
            { loading || courseData === null || lectureModules === null || questions === null ? 
                <Loading />
                : <>
                    <Box 
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            px: "25%",
                            ...!matches && {
                                px: "15%",
                            },
                            ...!matches2 && {
                                px: "10%",
                            },
                        }}
                    >
                        <Header 
                            title={
                                courseData.code !== null 
                                ? courseData.code + ". " + courseData.course_title 
                                : courseData.course_title
                            } 
                            subtitle="A ONE Institute"
                        />
                        {/* For creating questions */}
                        <Box
                            minWidth="20rem"
                            width="100%"
                            minHeight="10rem"
                            borderRadius="3px"
                            padding={3}
                            boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                            backgroundColor={colors.primary[600]}
                            display="flex"
                            flexDirection="column"
                            alignItems="left"
                            gap={3}
                            mb={3}
                        >
                            <Typography variant="h4" color={colors.primary[450]}>
                                Student Actions
                            </Typography>
                            <CreateQuestionsAccordion
                                courseId={courseId}
                                setBackdropOpen={setBackdropOpen}
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                setErrorAlertOpen={setErrorAlertOpen}
                                setSuccessAlertMessage={setSuccessAlertMessage}
                                setErrorAlertMessage={setErrorAlertMessage}
                            />
                        </Box>

                        {/* For questions */}
                        <Box
                            minWidth="20rem"
                            width="100%"
                            minHeight="100%"
                            borderRadius="3px"
                            padding={8}
                            boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                            backgroundColor={colors.primary[600]}
                            alignItems="center"
                        >
                            {questions !== null && questions.length === 0 ? (
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    minWidth: "20rem",
                                    minHeight: "32rem",
                                    gap: "0.4rem",
                                }}>
                                    <InfoOutlinedIcon sx={{ my: "auto" }} />
                                    <Typography variant="h4" color={colors.primary[450]} my="auto">
                                        No questions yet!
                                    </Typography>
                                </Box>
                            ) : (
                                questions.map((question, index) => {
                                    return (
                                        <QuestionBox 
                                            key={index}
                                            courseId={courseId}
                                            question={question}
                                            userId={userId}
                                            userRole={userRole}
                                            setBackdropOpen={setBackdropOpen}
                                            setSuccessAlertOpen={setSuccessAlertOpen}
                                            setErrorAlertOpen={setErrorAlertOpen}
                                            setSuccessAlertMessage={setSuccessAlertMessage}
                                            setErrorAlertMessage={setErrorAlertMessage}
                                        />
                                    )
                                })
                            )}
                        </Box>
                    </Box>
                    <Alert
                        successAlertOpen={successAlertOpen}
                        setSuccessAlertOpen={setSuccessAlertOpen}
                        errorAlertOpen={errorAlertOpen}
                        setErrorAlertOpen={setErrorAlertOpen}
                        successMsg={successAlertMessage}
                        errorMsg={errorAlertMessage}
                        vertical="bottom"
                        horizontal="left"
                    />
                    <Backdrop
                        sx={{ color: colors.primary[400], zIndex: 2147483646 }}
                        open={backdropOpen}
                        onClick={handleBackdropClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </>
            }
        </Box>
    )
}

export default Questions