import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import HomeIcon from '@mui/icons-material/Home';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { getCourseDuration } from '../../../utils/CourseDuration';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

export default function InfoTab({ teacher }) {
    const iconMargin = {
        marginTop: "5px"
    }

    return (<>
        <Grid container direction="row" alignItems="center" ml={1} mb={0.5}>
            <Grid item>
                <BubbleChartIcon sx={iconMargin} />
            </Grid>
            <Grid item>
                <Typography component='span' mb={1} ml={1} variant='h3'> Teacher</Typography>
            </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" ml={3}>
            <Grid item>
                <AlternateEmailIcon sx={iconMargin} />
            </Grid>
            {teacher.email &&
                <Grid item>
                    <Typography component='span' mb={1} ml={1} variant='h5'>{teacher.email} <span style={{ color: 'darkgrey' }}>(Email for Login)</span></Typography>
                </Grid>
            }
        </Grid>
        <Grid container direction="row" alignItems="center" ml={3}>
            <Grid item>
                <AlternateEmailIcon sx={iconMargin} />
            </Grid>
            {teacher.personal_email &&
                <Grid item>
                    <Typography component='span' mb={1} ml={1} variant='h5'>{teacher.personal_email} <span style={{ color: 'darkgrey' }}>(Personal Email)</span></Typography>
                </Grid>
            }
        </Grid>
        <Grid container direction="row" alignItems="center" ml={3}>
            <Grid item>
                <PhoneIphoneIcon sx={iconMargin} />
            </Grid>
            <Grid item>
                <Typography component='span' mb={1} ml={1} variant='h5'>{teacher.phone}</Typography>
            </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" ml={3}>
            <Grid item>
                <HomeIcon sx={iconMargin} />
            </Grid>
            <Grid item>
                <Typography component='span' mb={1} ml={1} variant='h5'>
                    {teacher.address.street}, {teacher.address.city}, {teacher.address.state} {teacher.address.zip}, {teacher.address.country}
                </Typography>
            </Grid>
        </Grid>
        {teacher.zelle &&
            <Grid container direction="row" alignItems="center" ml={3}>
                <Grid item>
                    <CreditCardIcon sx={iconMargin} />
                </Grid>
                <Grid item>
                    <Typography component='span' mb={1} ml={1} variant='h5'>{teacher.zelle} (zelle)</Typography>
                </Grid>
            </Grid>
        }
        {teacher.venmo &&
            <Grid container direction="row" alignItems="center" ml={3}>
                <Grid item>
                    <CreditCardIcon sx={iconMargin} />
                </Grid>
                <Grid item>
                    <Typography component='span' mb={1} ml={1} variant='h5'>{teacher.venmo} (venmo)</Typography>
                </Grid>
            </Grid>
        }
        {teacher.resumes.length > 0 &&
            <Grid container direction="row" alignItems="center" ml={3}>
                <Grid item>
                    <AssignmentIndIcon sx={iconMargin} />
                </Grid>
                <Grid item>
                    <Typography component='a' mb={1} ml={1} variant='h5' href={teacher.resumes[0].file_url} target='_blank'>
                        {teacher.resumes[0].name}
                    </Typography>
                </Grid>
            </Grid>
        }
        <br />
        <Grid container direction="row" alignItems="center" ml={1} mb={0.5}>
            <Grid item>
                <BubbleChartIcon sx={iconMargin} />
            </Grid>
            <Grid item>
                <Typography component='span' mb={1} ml={1} variant='h3'> Course</Typography>
            </Grid>
        </Grid>
        {teacher.courses.length > 0 ? teacher.courses.map((course, index) => {
            return (
                <Grid key={index} container direction="row" alignItems="center" ml={3}>
                    <Grid item>
                        <ArrowRightIcon sx={iconMargin} />
                    </Grid>
                    <Grid item>
                        <Typography component='span' mb={1} ml={1} variant='h5'> {course.course_title}, {getCourseDuration(course)}</Typography>
                    </Grid>
                </Grid>
            )
        }) : 
            <Grid container direction="row" alignItems="center" ml={3}>
                <Grid item sx={{ alignItems: "center" }}>
                    <ArrowRightIcon sx={iconMargin} />
                </Grid>
                <Grid item>
                    <Typography component='span' mb={1} ml={1} variant='h5'> No courses</Typography>
                </Grid>
            </Grid>
        }
        <br />
        <Grid container direction="row" alignItems="center" ml={1} mb={0.5}>
            <Grid item>
                <BubbleChartIcon sx={iconMargin} />
            </Grid>
            <Grid item>
                <Typography component='span' mb={1} ml={1} variant='h3'> Memo</Typography>
            </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" ml={3}>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    border: 1,
                    borderColor: 'grey.500',
                    p: 1,
                    m: 1,
                    width: 800,
                    minHeight: 100,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                }}
            >
                {teacher.memo &&
                    teacher.memo.split('\n').map((line, index) => {
                        return (
                            <Typography key={index} component='span' mb={1} ml={1} variant='h5'> {line}</Typography>
                        )
                    })
                }
            </Box>
        </Grid>
        <br />
    </>)
}