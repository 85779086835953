import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { FileUploadOutlined } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import Axios from 'axios';

const ClassMaterialModal = ({
    courseId,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
    setBackdropOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [files, setFiles] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);;
    const handleClose = () => setOpen(false);

    const handleUpload = (event) => {
        setFiles([...files, ...Array.from(event.target.files)]);
    };

    const handleFileRemove = (event, index) => {
        event.preventDefault();
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setBackdropOpen(true);

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            if (files[i].name !== undefined 
                && files[i].name !== null 
                && files[i].name !== ""
                && files[i].name.length > 150) {
                setErrorAlertOpen(true);
                setErrorAlertMessage("File name should be less than 150 characters");
                setBackdropOpen(false);
                return;
            }
            formData.append('file', files[i]);
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/class-materials`, 
            formData, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                setSuccessAlertOpen(true);
                setSuccessAlertMessage(response.data.message);
                setBackdropOpen(false);
                handleClose();
                window.location.reload();
            }).catch((error) => {
                setErrorAlertOpen(true);
                setErrorAlertMessage(error.response.data.message);
                setBackdropOpen(false);
            });
    };


    return (
        <div>
            <Button
                type="submit"
                variant="contained"
                style={{
                    backgroundColor: colors.primary[300],
                    color: "white",
                    width: "14rem",
                    marginTop: "0.5rem",
                    minHeight: "3rem",
                }}
                size='small'
                onClick={handleOpen}
            >
                Upload Class Materials
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                mb={-2}
                            >
                                <Typography variant="h6" fontWeight="bold" color={colors.primary[450]}>
                                    Upload Files
                                </Typography>
                                <IconButton component="label">
                                    <FileUploadOutlined />
                                    <input
                                        multiple={true}
                                        styles={{display:"none"}}
                                        type="file"
                                        hidden
                                        onChange={handleUpload}
                                        name="[licenseFile]"
                                    />
                                </IconButton>
                            </Box>
                            <Box
                                display="flex"
                                minWidth="100%"
                                flexDirection="column"
                            >
                                {files.length > 0 && files.map((file, index) => {
                                    return (
                                        <Box key={index} display="flex" alignItems="center">
                                            <Typography variant="body1" color={colors.primary[450]}>
                                                {file.name}
                                            </Typography>
                                            <IconButton onClick={(e) => handleFileRemove(e, index)}>
                                                <ClearIcon />
                                            </IconButton>
                                        </Box>
                                    )
                                })}
                            </Box>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{
                                    backgroundColor: colors.primary[300],
                                    color: "white",
                                    marginTop: "0.5rem",
                                    width: "10rem",
                                }}
                                onClick={handleSubmit}
                            >
                                Upload
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default ClassMaterialModal