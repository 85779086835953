import React from 'react'
import { Box, Typography } from "@mui/material";
import Header from "../../../components/Header";
import Axios from 'axios';
import LogsAccordion from './LogsAccordion';
import Loading from '../../../components/Loading';
import MemoTable from '../moreInfo/MemoTable';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

const ClassLogs = () => {
    const [loading, setLoading] = React.useState(true);
    const [studentId, setStudentId] = React.useState(null);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    // homeworks with course and students with no submissions
    const [coursesWithLogs, setCoursesWithLogs] = React.useState(null);

    // memos
    const [memos, setMemos] = React.useState(null);

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Students');
        const student_uuid = window.location.pathname.split('/')[3];
        setStudentId(student_uuid);

        async function getStudentCoursesWithLogs() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students/${student_uuid}/special-notes`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setCoursesWithLogs(response.data);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                }
                console.log(error.response.data.message);
            });
        }

        async function getStudentMemos() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students/${student_uuid}/memos`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setMemos(response.data);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                }
                console.log(error.response.data.message);
            });
        }

        getStudentCoursesWithLogs();
        getStudentMemos();
        setLoading(false);
    }, []);

    return (
        <Box m="20px" marginX={3} marginY={3}>
            { loading || coursesWithLogs === null || studentId === null ? 
                <Loading />
                : 
                <><Box 
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Header title="CLASS LOGS" subtitle="A ONE Institute" />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            height: "100%",
                            gap: "2rem",
                        }}
                    >
                        <Box sx={{ flex: "10", display: "flex", flexDirection: "column", gap: "2rem" }}>
                            {coursesWithLogs && coursesWithLogs.length > 0 
                                ? coursesWithLogs.map((courseWithLogs, index) => {
                                    return (
                                        <LogsAccordion key={index} courseWithLogs={courseWithLogs} index={index} />
                                    )
                                }) : (
                                    <Typography variant="h6">No class found for the student.</Typography>
                                )
                            }
                        </Box>
                        <Box sx={{ height: "78vh", flex: "9"}}>
                            <Box sx={{ position: "fixed", display: "sticky", height: "inherit", width: "inherit", overflowY: "scroll", mr: "1rem" }}>
                                <MemoTable memos={memos} setAllMemos={setMemos} student_id={studentId} />
                            </Box>
                        </Box>
                    </Box>
                </Box></>
            }
        </Box>
    )
}

export default ClassLogs