import { DateTime } from 'luxon';

// Summer Schedule
// export const generateWeekDates = (date) => {
    
//     const arrayOfDate = [];

//     // find the first day of the week
//     let firstDayOfWeek = date;
//     while (firstDayOfWeek.weekday !== 1) {
//         firstDayOfWeek = firstDayOfWeek.minus({ days: 1 });
//     }

//     arrayOfDate.push({
//         date: null,    
//         hours: [
//             "2pm", "", "2:30pm", "", "3pm", "", "3:30pm", "", "4pm", "", "4:30pm", "", 
//             "5pm", "", "5:30pm", "", "6pm", "", "6:30pm", "", "7pm", "", "7:30pm", "", 
//             "8pm", "", "8:30pm", "", "9pm", "", "9:30pm", "", "10pm", "", "10:30pm", "", 
//             "11pm", "", "11:30pm", ""
//         ]
//     });

//     for (let i = 0; i < 7; i++) {
//         const date = firstDayOfWeek.plus({ days: i });
//         date.set({ hour: 14, minute: 0, second: 0 });
//         const dateHourMap = [
//             "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",
//         ];
//         arrayOfDate.push(
//             {
//                 date: date,
//                 hours: dateHourMap
//             }
//         );
//     }

//     return arrayOfDate;
// };

// Spring, Fall Schedule
export const generateWeekDates = (date) => {
    
    const arrayOfDate = [];

    // find the first day of the week
    let firstDayOfWeek = date;
    while (firstDayOfWeek.weekday !== 1) {
        firstDayOfWeek = firstDayOfWeek.minus({ days: 1 });
    }

    arrayOfDate.push({
        date: null,    
        hours: [
            "9am", "", "9:30am", "", "10am", "", "10:30am", "", "11am", "", "11:30am", "",
            "12pm", "", "12:30pm", "", "1pm", "", "1:30pm", "", "2pm", "", "2:30pm", "",
            "3pm", "", "3:30pm", "", "4pm", "", "4:30pm", "", "5pm", "", "5:30pm", "",
            "6pm", "", "6:30pm", "", "7pm", "", "7:30pm", "", "8pm", "", "8:30pm", "",
            "9pm", "", "9:30pm", "", "10pm", "", "10:30pm", "", "11pm", "", "11:30pm", ""
        ]
    });

    for (let i = 0; i < 7; i++) {
        const date = firstDayOfWeek.plus({ days: i });
        date.set({ hour: 9, minute: 0, second: 0 });
        const dateHourMap = [
            "", "", "", "", "", "", "", "", "", "", "", "", 
            "", "", "", "", "", "", "", "", "", "", "", "",
            "", "", "", "", "", "", "", "", "", "", "", "",
            "", "", "", "", "", "", "", "", "", "", "", "",
            "", "", "", "", "", "", "", "", "", "", "", "",
        ];
        arrayOfDate.push(
            {
                date: date,
                hours: dateHourMap
            }
        );
    }

    return arrayOfDate;
};

export const checkifDateIsInThisWeek = (date) => {
    if (date === null) return false;

    const today = DateTime.now();
    let firstDayOfWeek = today;
    while (firstDayOfWeek.weekday !== 1) {
        firstDayOfWeek = firstDayOfWeek.minus({ days: 1 });
    }
    let lastDayOfWeek = firstDayOfWeek.plus({ days: 6 });
    // firstDayOfWeek = firstDayOfWeek.set({ hour: 14, minute: 0, second: 0 });
    firstDayOfWeek = firstDayOfWeek.set({ hour: 9, minute: 0, second: 0 });
    lastDayOfWeek = lastDayOfWeek.set({ hour: 23, minute: 59, second: 59 });
    return date >= firstDayOfWeek && date <= lastDayOfWeek;
};