import * as React from "react";
import { Box, Button, FormControl, FormControlLabel, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import Axios from 'axios';
import Alert from '../../components/alert/Alert';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Backdrop from "../../components/Backdrop";
import IconButton from '@mui/material/IconButton';
import { FileUploadOutlined } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import HiddenPDF from '../hwQuestions/HiddenPDF';
import { Document, Page, pdfjs } from "react-pdf";
import { Autocomplete } from "@mui/material";
import { getTestCategory } from "../../utils/TestCategory";
import QuestionPreviewForTest from "./QuestionPreviewForTest";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AddTest = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    const [file, setFile] = React.useState(null);
    // const [fileForTeacher, setFileForTeacher] = React.useState(null);

    const [previewCardToggle, setPreviewCardToggle] = React.useState(false);

    // const [options, setOptions] = React.useState([]);
    // const [answers, setAnswers] = React.useState([]);
    // const [numAnswers, setNumAnswers] = React.useState(1);
    const [optionName, setOptionName] = React.useState(['A', 'B', 'C', 'D']);
    const [numChoices, setNumChoices] = React.useState(4);

    const [subject, setSubject] = React.useState(null);
    const [isSection, setIsSection] = React.useState(null);
    const [sectionCreated, setSectionCreated] = React.useState(false);
    const [sampleCreated, setSampleCreated] = React.useState(false);

    const [numQuestions, setNumQuestions] = React.useState('');

    const [sections, setSections] = React.useState([]);
    const [sample, setSample] = React.useState([]);

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [sectionToDelete, setSectionToDelete] = React.useState(null);

    const handleClickDialogOpen = (index) => {
        setSectionToDelete(index);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDeleteSection = () => {
        const updatedSections = sections.filter((_, index) => index !== sectionToDelete);
        const reIndexedSections = updatedSections.map((section, index) => {
            return {
                ...section,
                sectionNumber: index + 1,
            };
        });
            setSections(reIndexedSections);
            handleDialogClose();

        if (reIndexedSections.length === 0) {
            setSectionCreated(false);
        }
    };

    const handleUpload = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFileRemove = (event) => {
        event.preventDefault();
        setFile(null);
    };

    // const handleTeacherFileUpload = (event) => {
    //     setFileForTeacher(event.target.files[0]);
    // };

    // const handleTeacherFileRemove = (event) => {
    //     event.preventDefault();
    //     setFileForTeacher(null);
    // };

    const documentRender = () => {
        return (
            <Document
                file={file}
                onContextMenu={(e) => e.preventDefault()}
                className="pdf-container"
            >
                <div>
                    <Page
                        pageNumber={1}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        className="react-pdf__Page__canvas"
                    />
                </div>
            </Document>
        );
    };

    const handleCreateSection = () => {

        if (!numQuestions || !numChoices || numQuestions === '0' || numChoices === '0') {
            setErrorAlertMessage("Please enter the valid number of questions and choices");
            setErrorAlertOpen(true);
            return;
        }

        if (numQuestions && numChoices) {
            const newSection = {
                sectionNumber: sections.length + 1,
                questions: [],
                numQuestions: numQuestions,
            };
        
            if (subject.value === 'ACT') {
                // if selected 4 choices section, then odd: A, B, C, D, even: F, G, H, J
                // if selected 5 choices section, then odd: A, B, C, D, E, even: F, G, H, J, K
                for (let i = 0; i < numQuestions; i++) {
                    if (numChoices === '4') {
                        const actOptions = i % 2 === 0 ? ['A', 'B', 'C', 'D'] : ['F', 'G', 'H', 'J'];
                        const newQuestion = {
                            questionNumber: i + 1,
                            choices: [],
                            answer: "",
                        };

                        for (let j = 0; j < numChoices; j++) {
                            newQuestion.choices.push({
                                choiceNumber: j + 1,
                                choice: actOptions[j],
                            });
                        }
                        newSection.questions.push(newQuestion);
                    
                    } else if (numChoices === '5') {
                        const actOptions = i % 2 === 0 ? ['A', 'B', 'C', 'D', 'E'] : ['F', 'G', 'H', 'J', 'K'];
                        const newQuestion = {
                            questionNumber: i + 1,
                            choices: [],
                            answer: "",
                        };

                        for (let j = 0; j < numChoices; j++) {
                            newQuestion.choices.push({
                                choiceNumber: j + 1,
                                choice: actOptions[j],
                            });
                        }
                        newSection.questions.push(newQuestion);
                    }
                }
            } else {
                for (let i = 0; i < numQuestions; i++) {
                    const newQuestion = {
                        questionNumber: i + 1,
                        choices: [],
                        answer: "",
                    };
    
                    for (let j = 0; j < numChoices; j++) {
                        newQuestion.choices.push({
                            choiceNumber: j + 1,
                            choice: optionName[j],
                        });
                    }
                    newSection.questions.push(newQuestion);
                }
            }
            setSections([...sections, newSection]);
            setSectionCreated(true);
        }
    };
    

    const handleCreateSample = () => {

        if (!numQuestions || !numChoices || numQuestions === '0' || numChoices === '0') {
            setErrorAlertMessage("Please enter the valid number of questions and choices");
            setErrorAlertOpen(true);
            return;
        }

        if (numQuestions && numChoices) {
            const newSample = {
                questions: [],
                numQuestions: numQuestions,
            };
    
            for (let i = 0; i < numQuestions; i++) {
                const newQuestion = {
                    questionNumber: i + 1,
                    choices: [],
                    answer: "",
                };
    
                for (let j = 0; j < numChoices; j++) {
                    newQuestion.choices.push({
                        choiceNumber: j + 1,
                        choice: optionName[j],
                    });
                }
    
                newSample.questions.push(newQuestion);
            }
            setSample(newSample);  // Stored as a single-element array
            setSampleCreated(true);
        }
    };

    const handleDeleteSample = () => {
        setSample([]);
        setSampleCreated(false);
    };

    const handleSectionAnswerChange = (sectionIndex, questionIndex, choice) => {
        const updatedSections = sections.map((section, sIndex) => {
            if (sIndex === sectionIndex) {
                const updatedQuestions = section.questions.map((question, qIndex) => {
                    if (qIndex === questionIndex) {
                        return { ...question, answer: choice };
                    }
                    return question;
                });
                return { ...section, questions: updatedQuestions };
            }
            return section;
        });
        setSections(updatedSections);
    };

    const handleSampleAnswerChange = (questionIndex, choice) => {
   
        const updatedQuestions = sample.questions.map((question, qIndex) => {
            if (qIndex === questionIndex) {
                return { ...question, answer: choice };
            }
            return question;
        });

        const updatedSample = {
            questions: updatedQuestions,
            numQuestions: numQuestions,
        };

        setSample(updatedSample);
    };

    const handleSectionNameChange = (event, index) => {
        const updatedSections = sections.map((section, sIndex) => {
            if (sIndex === index) {
                return { ...section, section_name: event.target.value };
            }
            return section;
        });
        setSections(updatedSections);
    };

    const handleFormSubmit = async (values) => {
        setBackdropOpen(true);
    
        if (isSection && isSection.value === "With Section" && !sectionCreated) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("At least one section is necessary");
            setBackdropOpen(false);
            return;
        }
    
        if (isSection && isSection.value === "No Section" && !sampleCreated) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("At least one sample is necessary");
            setBackdropOpen(false);
            return;
        }
    
        if (!file || file.type !== "application/pdf") {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please upload a pdf file");
            setBackdropOpen(false);
            return;
        }

        // if (!fileForTeacher || fileForTeacher.type !== "application/pdf") {
        //     setErrorAlertOpen(true);
        //     setErrorAlertMessage("Please upload a pdf file for teacher");
        //     setBackdropOpen(false);
        //     return;
        // }
    
        if (!subject) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please select subjects");
            setBackdropOpen(false);
            return;
        }
    
        if (!isSection) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please select with section or no section");
            setBackdropOpen(false);
            return;
        }
    
        if (!numQuestions || !numChoices) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please enter the number of questions and choices");
            setBackdropOpen(false);
            return;
        }
    
        const formData = new FormData();
        formData.append('file', file);
        // formData.append('fileForTeacher', fileForTeacher);
        formData.append('subject', subject.value);
        formData.append('isSection', isSection ? isSection.value : '');
    
        if (isSection.value === "With Section") {
            formData.append('sections', JSON.stringify(sections)); // Send sections as JSON string
        } else {
            formData.append('sample', JSON.stringify(sample)); // Send sample as JSON string
        }
    
        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/tests`, formData, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            setSuccessAlertMessage(response.data.message);
            setSuccessAlertOpen(true);
            handleBackdropClose();
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
            handleBackdropClose();
        });
    };

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Add Test');
    }, []);

    const tests = getTestCategory();

    return (
        <Box m="20px" marginX={3} marginY={3}>
            <Header title="Add Test" subtitle="A ONE Institute" />
            <Box 
                sx={{ 
                    flexBasis: "50%", 
                    display: "flex", 
                    flexDirection: "column", 
                    gap: "10px", 
                    typography: 'subtitle2',
                    mb: 5
                }}
            >
                <Typography variant="h3" fontWeight="bold">Instructions:</Typography>
                <Typography>1. Fill in the subject, section, number of questions, and choices. You will then see the sample form of a question.</Typography>
                <Typography>2. Write the name of the choice you want. The default values are A, B, C, D.</Typography>
                <Typography>3. Check the sample answer form at the bottom. This will be the question format for your section or test.</Typography>
                <Typography>4-1. If you select "With Section," create the number of sections you need, then fill in the answers for each section.</Typography>
                <Typography>4-2. If you select "No Section," fill in the answers for the sample.</Typography>
                <Typography>5. Create a test. You can find your test in the "Test Library" on the sidebar.</Typography>
            </Box> 
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        padding={4}
                        boxShadow={3}
                        borderRadius={3}
                        m={1}
                        bgcolor={colors.primary[600]}
                    >
                        <Typography
                            variant="h4"
                            fontWeight="bold"
                            mb={3}
                        >
                            Test Information
                        </Typography>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <Box
                                display="flex"
                                sx={{ gridColumn: "span 4" }}
                            >
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h6" fontWeight="bold" color={colors.primary[450]}>
                                        Upload Files
                                    </Typography>
                                    <IconButton component="label">
                                        <FileUploadOutlined />
                                        <input
                                            multiple={false}
                                            styles={{display:"none"}}
                                            type="file"
                                            hidden
                                            onChange={handleUpload}
                                            name="[licenseFile]"
                                        />
                                    </IconButton>
                                </Box>
                                {file && (
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="body1" color={colors.primary[450]}>
                                                {file.name}
                                            </Typography>
                                            <IconButton onClick={(e) => handleFileRemove(e)}>
                                                <ClearIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                )}
                                {/* <Box display="flex" alignItems="center" sx={{ml: 2}}>
                                    <Typography variant="h6" fontWeight="bold" color={colors.primary[450]}>
                                        Upload Files (for teacher)
                                    </Typography>
                                    <IconButton component="label">
                                        <FileUploadOutlined />
                                        <input
                                            multiple={false}
                                            styles={{display:"none"}}
                                            type="file"
                                            hidden
                                            onChange={handleTeacherFileUpload}
                                            name="[licenseFile]"
                                        />
                                    </IconButton>
                                </Box>
                                {fileForTeacher && (
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="body1" color={colors.primary[450]}>
                                                {fileForTeacher.name}
                                            </Typography>
                                            <IconButton onClick={(e) => handleTeacherFileRemove(e)}>
                                                <ClearIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                )}
                                */}
                            </Box>
                            <Autocomplete
                                id="subject-select"
                                options={tests}
                                sx={{ gridColumn: "span 1" }}
                                renderInput={(params) => <TextField {...params} label="Subject" />}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                onChange={(event, newValue) => {
                                    if (newValue !== null && newValue !== undefined) {                                        
                                        setSubject(newValue);
                                    } else {
                                        setSubject(null);
                                        setIsSection(null); // Reset section when subject changes
                                        setSections([]); // Reset sections when subject changes
                                    }
                                }}
                            />
                            {subject !== null && subject.section !== undefined && (
                                <Autocomplete
                                    id="free-solo-demo"
                                    options={subject.section}
                                    sx={{ gridColumn: "span 1" }}
                                    renderInput={(params) => <TextField {...params} label="Section" />}
                                    isOptionEqualToValue={(option, value) => {
                                        return option?.value === value?.value;
                                    }}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null && newValue !== undefined) {
                                            setIsSection(newValue);
                                        } else {
                                            setIsSection(null);
                                        }
                                    }}
                                />
                            )}
                            {subject !== null && subject.value === 'ACT' && subject.section !== null && isSection?.value === 'With Section' && (
                                <Autocomplete
                                    id="free-solo-demo"
                                    options={subject.section[0].numChoices}
                                    sx={{ gridColumn: "span 1" }}
                                    renderInput={(params) => <TextField {...params} label="Number of Choices For the Section" />}
                                    isOptionEqualToValue={(option, value) => {
                                        return option?.value === value?.value;
                                    }}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null && newValue !== undefined) {
                                            setNumChoices(newValue.value);
                                        } else {
                                            setNumChoices(null);
                                        }
                                    }}
                                />
                            )}
                            {(isSection && isSection.value) && (
                                <Box sx={{ gridColumn: "span 4", display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <TextField
                                        sx={{ width: "80%"}}
                                        // fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Number of Questions"
                                        onBlur={handleBlur}
                                        onChange={(e) => setNumQuestions(e.target.value)}
                                        InputProps={{
                                            inputProps: { min: 0 }
                                          }}
                                        value={numQuestions}
                                        name="numQuestions"
                                        error={!!touched.questions && !!errors.questions}
                                        helperText={touched.questions && errors.questions}
                                    />
                                    <TextField
                                        sx={{ width: "80%"}}
                                        // fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Number of Choices"
                                        onBlur={handleBlur}
                                        onChange={(e) => setNumChoices(e.target.value)}
                                        InputProps={{
                                            inputProps: { min: 0 }
                                          }}
                                        value={numChoices}
                                        name="numChoices"
                                        error={!!touched.choices && !!errors.choices}
                                        helperText={touched.choices && errors.choices}
                                        disabled={isSection?.value === "With Section" && subject?.value === "ACT"}
                                    />
                            
                            {isSection.value === "With Section" ? (
                                <Button
                                    sx={{ width: "80%" }}
                                    color="secondary"
                                    variant="contained"
                                    onClick={handleCreateSection}
                                >
                                    Create Section
                                </Button>
                            ) : (
                                !sampleCreated ? (
                                    <Button
                                        sx={{ width: "80%" }}
                                        color="secondary"
                                        variant="contained"
                                        onClick={handleCreateSample}
                                    >
                                        Create Sample
                                    </Button>
                                ) : (
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", mt: 2, p: 2, bgcolor: colors.primary[600], borderRadius: 2, boxShadow: 3 }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h6">Sample</Typography>
                                            <ClearIcon
                                                fontSize='small'
                                                color='error'
                                                sx={{ cursor: "pointer", alignSelf: "flex-start" }}
                                                onClick={handleDeleteSample}
                                            />
                                        </Box>
                                        <ul>
                                            {sample.questions.map((question, qIndex) => (
                                                <li key={qIndex}>
                                                    <Typography variant="body1">Q{question.questionNumber}</Typography>
                                                    <ul>
                                                        <RadioGroup
                                                            row
                                                            value={question.answer}
                                                            onChange={(e) => handleSampleAnswerChange(qIndex, e.target.value)}
                                                        >
                                                            {question.choices.map((choice, cIndex) => (
                                                                <FormControlLabel key={cIndex} value={choice.choice} control={<Radio />} label={choice.choice} />
                                                            ))}
                                                        </RadioGroup>
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                    </Box>
                                )
                            )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                    {!(subject !== null && subject.value === 'ACT' && subject.section !== null && isSection?.value === 'With Section') && numQuestions && numChoices && (<> 
                        <Box
                            sx={{
                                position: 'fixed',
                                top: '15%', 
                                // right: !consultationCardToggle ? 10 : -320,
                                right: !previewCardToggle ? 40 : -200,
                                transform: 'translateY(-50%)',
                                zIndex: 101,
                                transition: 'right 0.5s',
                                minWidth: 400,
                                maxWidth: 550,
                                // minWidth: 700, 
                                // maxWidth: 800,
                            }}
                        >
                            <IconButton
                                onClick={() => setPreviewCardToggle(!previewCardToggle)}
                            >
                                {previewCardToggle ?
                                <h4>Show Preview</h4> : <h4>Hide</h4>
                                }
                                {previewCardToggle ?
                                <KeyboardDoubleArrowLeftIcon fontSize='large' /> :
                                <KeyboardDoubleArrowRightIcon fontSize='large' />}
                            </IconButton>
                        </Box>
                        {/* need to send two: number of choices, choice label by user */}
                        <Box
                            sx={{
                                position: 'fixed',
                                top: '42%',
                                right: !previewCardToggle ? 40 : -350,
                                // right: 40,
                                transform: 'translateY(-50%)',
                                backgroundColor: colors.primary[600],
                                border: `5px solid ${colors.grey[800]}`,
                                boxShadow: 10,
                                zIndex: 100,
                                padding: 2,
                                transition: 'right 0.5s',
                                height: "50%",
                                // height: "45%",
                                // maxHeight: "70%",
                                // minWidth: 400,
                                // maxWidth: 700,
                                width: 400,
                                overflowX: 'hidden',
                                overflowY: 'auto',
                            }}
                        >
                            <QuestionPreviewForTest numChoices={numChoices} setOptionName={setOptionName} optionName={optionName}/>
                        </Box>
                    </>)}
                 
                    <Box
                        sx={{ 
                            display: "flex", 
                            flexDirection: "row", 
                            gap: "10px", 
                            mt: 2, 
                            p: 2, 
                            bgcolor: colors.primary[600], 
                            borderRadius: 2, 
                            boxShadow: 3,
                        }}
                    >
                        <FormControl>
                            <Box 
                                sx={{ 
                                    display: "flex", 
                                    flexDirection: "row",
                                    flexWrap: "wrap", 
                                    gap: 2,
                                }}
                            >
                                {isSection?.value === "With Section" && sections && sections.length > 0 && sections.map((section, sectionIndex) => (
                                    <Box 
                                        key={sectionIndex} 
                                        sx={{ 
                                            minWidth: "400px", 
                                            maxWidth: "400px", 
                                            flex: 1, 
                                            display: "flex", 
                                            flexDirection: "column", 
                                            justifyContent: "space-between", 
                                            alignItems: "flex-start", 
                                            p: 2, 
                                            bgcolor: colors.primary[700], 
                                            borderRadius: 2, 
                                            width: "100%", 
                                            boxShadow: 3 
                                        }}
                                    >
                                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <Box>
                                                <TextField 
                                                    label="Section Name"
                                                    variant="outlined"
                                                    value={section.section_name ? section.section_name : ``}
                                                    onChange={(e) => handleSectionNameChange(e, sectionIndex)}
                                                />
                                                <Typography variant="body1">{section.numQuestions} questions</Typography>
                                            </Box>
                                            <ClearIcon
                                                fontSize='small'
                                                color='error'
                                                sx={{ cursor: "pointer" }}
                                                onClick={() => handleClickDialogOpen(sectionIndex)}
                                            />
                                        </Box>
                                        <Box
                                            sx={{height: "100%"}}    
                                        >
                                            <ul>
                                                {section.questions.map((question, qIndex) => (
                                                    <li key={qIndex}>
                                                        <Typography variant="body1">Q{question.questionNumber}</Typography>
                                                        <ul>
                                                            <RadioGroup
                                                                row
                                                                value={question.answer}
                                                                onChange={(e) => handleSectionAnswerChange(sectionIndex, qIndex, e.target.value)}
                                                            >
                                                                {question.choices.map((choice, cIndex) => (
                                                                    <FormControlLabel key={cIndex} value={choice.choice} control={<Radio />} label={choice.choice} />
                                                                ))}
                                                            </RadioGroup>
                                                        </ul>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </FormControl>
                        
                        <Dialog
                            open={dialogOpen}
                            onClose={handleDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Do you want to delete this section?"}
                            </DialogTitle>
                            <DialogActions>
                                <Button onClick={handleDeleteSection} color="warning" autoFocus>
                                    Yes
                                </Button>
                                <Button onClick={handleDialogClose}>No</Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                    
                    <Box display="flex" justifyContent="start" mt="20px">
                        <Button type="submit" color="secondary" variant="contained">
                            Create New Test
                        </Button>
                    </Box>
                </form>
                )}
            </Formik>
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
            <Backdrop backdropOpen={backdropOpen} handleBackdropClose={handleBackdropClose} />
            <HiddenPDF documentRender={documentRender} /> 
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    pages: yup.number().required("required"),
    questions: yup.number().required("required"),
    choices: yup.number().required("required"),
});

const initialValues = {
    pages: 0,
    questions: 0,
    choices: 0,
};

export default AddTest