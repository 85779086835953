import { Editor } from '@tinymce/tinymce-react';
import React from 'react';

const options = {
    height: 300,
    menubar: false,
    // Add wiris plugin
    external_plugins: {
        'tiny_mce_wiris' : `${window.location.href}/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`
    },
    plugins: [
        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
        'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
    ],
    toolbar: [
        ' undo redo | blocks | bold italic backcolor |' +
        'alignleft aligncenter alignright alignjustify | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry |' +
        'bullist numlist outdent indent | removeformat | help'

        
    ],
    htmlAllowedTags:  ['.*'],
    htmlAllowedAttrs: ['.*'],
    extended_valid_elements: '*[.*]',
};

/* Create a component to be rendered later.
This is important to remove complexity from the reactDom.render
and to be able to add other functionality. */

export const EditorTiny = ({ value, setHWAnswers, hw_question_id }) => {

    return (
        <Editor
            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
            init={options}
            value={value}
            onEditorChange={
                (newValue, editor) => {
                    if (setHWAnswers === null) {
                        return;
                    }
                    
                    setHWAnswers((prev) => ({
                        ...prev,
                        [hw_question_id]: {
                            ...prev[hw_question_id],
                            answer: newValue,
                        },
                    }))
                }
            }
        />
    );
}