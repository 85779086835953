import React from 'react'
import { tokens } from "../../theme";
import { Box, IconButton, useTheme } from "@mui/material";
import Loading from '../../components/Loading';
import StudentCard from "../students/StudentCard";
import Header from "../../components/Header";
import Axios from 'axios';
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { motion, AnimatePresence } from "framer-motion";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import Dropdown from '../../components/dropdown/Dropdown';
import { potentialStudentsFilter } from '../../components/dropdown/DropdownData';

const PotentialStudents = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [loading, setLoading] = React.useState(true);
    const [potentialStudents, setPotentialStudents] = React.useState([]);
    const [search, setSearch] = React.useState(''); // search by consultation number
    const [emailSearch, setEmailSearch] = React.useState(''); // search by parent email
    const [studentUpdated, setStudentUpdated] = React.useState(false);
    const [studentStatus, setStudentStatus] = React.useState('all');

    async function fetchPotentialStudents() {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students/potential`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setPotentialStudents(response.data);
            setLoading(false);
        }).catch((error) => {
            if (error.response.status === 404) {
                setPotentialStudents([]);
                setLoading(false);
                return;
            } else if (error.response.status === 401) {
                handleLogout();
                return;
            }
            alert(error.response.data.message);
        });
        setStudentUpdated(false);
    };

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Potential Students');
        fetchPotentialStudents();
    }, [studentUpdated]);

    return (
        <Box 
            m="20px" 
            marginX={3} 
            marginY={3} 
            sx={{
                minHeight: "88%",
            }}
        >
            <Header title="Potential Students" subtitle="A ONE Institute" />
            { loading ? 
                <Loading />
            : <>
                <Box display="flex" justifyContent="left" gap={2} p={0} minHeight={70} mb={2}>
                    {/* SEARCH BAR */}
                    <Box
                        display="flex"
                        backgroundColor={colors.primary[600]}
                        borderRadius="3px"
                        boxShadow={10}
                        mb={2}
                        maxWidth={300}
                        minWidth={220}
                    >
                        <InputBase 
                            sx={{ ml: 2, flex: 1 }} 
                            onChange={(e) => setSearch(e.target.value)} 
                            placeholder="Search by consultation number" 
                        />
                        <IconButton type="button" sx={{ p: 1 }}>
                            <SearchIcon />
                        </IconButton>
                    </Box>

                    <Box
                        display="flex"
                        backgroundColor={colors.primary[600]}
                        borderRadius="3px"
                        boxShadow={10}
                        mb={2}
                        maxWidth={300}
                        minWidth={220}
                    >
                        <InputBase 
                            sx={{ ml: 2, flex: 1 }} 
                            onChange={(e) => setEmailSearch(e.target.value)} 
                            placeholder="Search by email" 
                        />
                        <IconButton type="button" sx={{ p: 1 }}>
                            <SearchIcon />
                        </IconButton>
                    </Box>

                    <Dropdown 
                        maxWidth={200} 
                        maxHeight={3} 
                        reactUseState={studentStatus} 
                        setReactUseState={setStudentStatus} 
                        inputLabel="Status" 
                        data={potentialStudentsFilter} 
                    />
                </Box>

                <motion.div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                        rowGap: "1rem",
                        columnGap: "1rem",
                    }}
                    layout
                >
                    <AnimatePresence>
                        {potentialStudents
                            .filter((student) => {
                                return search === '' 
                                    ? student 
                                    : student.consultation_number === parseInt(search);
                            })
                            .filter((student) => {
                                return emailSearch === ''
                                    ? student
                                    : student.parent_email?.toLowerCase().includes(emailSearch.toLowerCase())
                                    || student.parent_email_2?.toLowerCase().includes(emailSearch.toLowerCase());
                            })
                            .filter((student) => {
                                return studentStatus === 'all' 
                                    ? student
                                    : studentStatus === 'blacklist'
                                    ? student.is_blacklist_student
                                : student
                            })
                            .filter((student) => {
                                return studentStatus === 'all' 
                                    ? student
                                    : studentStatus === 'blacklist'
                                    ? student.is_blacklist_student
                                : student
                            })
                            .map((student) => {
                                return (
                                    <StudentCard student={student} key={student.id} setStudentUpdated={setStudentUpdated} />
                                );
                            })
                        }
                    </AnimatePresence>
                </motion.div>
            </>}
        </Box>
    )
}

export default PotentialStudents