import React from 'react'
import { tokens } from "../../../theme";
import { Box, useTheme, Typography } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';

var options = {
    year: "numeric",
    month: "short",
    day: "numeric"
};

const LogsAccordion = ({ courseWithLogs, index }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const eventModules = courseWithLogs.events.map(event => event);
    const season = {1: 'Spring', 2: 'Summer', 3: 'Fall', 4: 'Winter'};

    const sortSemesters = [...courseWithLogs.course_semesters].sort((a, b) => {
        return dayjs(a.semester.start) - dayjs(b.semester.start);
    });

    const courseDuration = () => {
        const start_year = sortSemesters[0].semester.year;
        const start_season = season[sortSemesters[0].semester.season];
        const end_year = sortSemesters[sortSemesters.length - 1].semester.year;
        const end_season = season[sortSemesters[sortSemesters.length - 1].semester.season];

        if (sortSemesters.length === 1)
            return `${start_year} ${start_season}`;
        else
            return `${start_year} ${start_season} - ${end_year} ${end_season}`;
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: colors.primary[300],
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
        border: '1px solid rgba(224, 224, 224, 1)',
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: colors.primary[750],
        },
        '&:nth-of-type(even)': {
            backgroundColor: colors.primary[750],
        },
    }));

    return (
        <Box>
            <Accordion
                sx={{
                    backgroundColor: colors.primary[750],
                    boxShadow: "0px 0px 1px 2px rgba(0, 0, 0, 0.30)",
                }}
                defaultExpanded={index === 0 ? true : false}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                        <Typography variant="h6">({courseWithLogs.code ?? 'n/a'}) {courseWithLogs.course_title}, {courseDuration()}</Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails sx={{ backgroundColor: colors.primary[600] }}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Module</StyledTableCell>
                                    <StyledTableCell>Date</StyledTableCell>
                                    <StyledTableCell>Evaluation 1</StyledTableCell>
                                    <StyledTableCell>Evaluation 2</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {eventModules && eventModules.length > 0 && eventModules.map((event, index) => {
                                    return (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="special_note" style={{ width: "14%" }}>
                                                {event.title}
                                            </StyledTableCell>
                                            <StyledTableCell style={{ width: "10%" }}>
                                                {new Date(event.start).toLocaleDateString("en", options)}
                                            </StyledTableCell>
                                            {event.special_notes && event.special_notes.length > 0 && <>
                                                <StyledTableCell style={{ width: "38%" }}>
                                                    {event.special_notes[0].eval_1}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ width: "38%" }}>
                                                    {event.special_notes[0].eval_2}
                                                </StyledTableCell>
                                            </>}
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default LogsAccordion