import * as React from 'react';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import ModalTabs from '../teachers/moreInfo/ModalTabs';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';

const TeacherCardModal = ({ setModalOpen, openModal, teacher, setInfoUpdated }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        height: 800,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
    };
    
    const handleModalClose = () => setModalOpen(false);

    return (
        <>
        {teacher !== null &&
            <Modal
                open={openModal}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                    timeout: 500,
                    },
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h1" ml={1} borderBottom={1}>
                            {teacher.last_name}, {teacher.first_name}
                        </Typography>
                        <Typography id="modal-modal-description" variant="h6" mt={1} mb={1} ml={1} color={"grey"}>
                            <SchoolRoundedIcon style={{marginBottom: "-5px", marginRight: "5px"}} /> {teacher.school} ({teacher.degree} in {teacher.major})
                        </Typography>
                        <ModalTabs teacher={teacher} setTeacherUpdated={setInfoUpdated} />
                    </Box>
                </Fade>
            </Modal>
        }
        </>
    )
}

export default TeacherCardModal