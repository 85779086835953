import * as React from "react";
import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CopyCourseForm from "./CopyCourseForm";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 214,
    },
});

const CopyCourseModal = ({ 
    potentialCourseType,
    potentialCourse,
    student_courses,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 940,
        height: 820,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (<>
        <CustomWidthTooltip 
            title={ potentialCourseType === 0 
                ? "This will copy this course settings and create a new group course (not potential) with confirmed students!"
                : potentialCourseType === 1
                    ? "This will copy this course settings and create a new private course (not potential) with a selected student!"
                    : ""
            }
            placement="top" 
            arrow
        >
            <Button
                variant="outlined"
                color="primary"
                sx={{ 
                    width: "100%", 
                    marginTop: "auto",
                    '&:hover': {
                        backgroundColor: colors.primary[900],
                    },
                }}
                onClick={() => handleOpen()}
            >
                Copy it to a New Course
            </Button>
        </CustomWidthTooltip>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={open}>
                <Box sx={style}>
                    <CopyCourseForm 
                        potentialCourseType={potentialCourseType}
                        courseData={potentialCourse} 
                        student_courses={student_courses} 
                        handleClose={handleClose} 
                    />
                </Box>
            </Fade>
        </Modal>
    </>);
};

export default CopyCourseModal