import * as React from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alerts = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Alert = ({ 
    successAlertOpen, 
    setSuccessAlertOpen, 
    errorAlertOpen, 
    setErrorAlertOpen, 
    successMsg, 
    errorMsg,
    vert, 
    horiz
}) => {

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSuccessAlertOpen(false);
        setErrorAlertOpen(false);
    };

    return (
        <>
            <Snackbar open={successAlertOpen} autoHideDuration={4000} onClose={handleCloseAlert}>
                <Alerts
                    onClose={handleCloseAlert}
                    severity="success"
                    sx={{ width: '100%', alignItems: "center" }}
                    anchororigin={{ vertical: vert, horizontal: horiz }}
                    key={vert + horiz}
                >
                    {successMsg}
                </Alerts>
            </Snackbar>
            <Snackbar open={errorAlertOpen} autoHideDuration={4000} onClose={handleCloseAlert}>
                <Alerts
                    onClose={handleCloseAlert}
                    severity="error"
                    sx={{ width: '100%', alignItems: "center" }}
                    anchororigin={{ vertical: vert, horizontal: horiz }}
                    key={vert + horiz}
                >
                    {errorMsg}
                </Alerts>
            </Snackbar>
        </>
    );
}

export default Alert;