import * as React from 'react';
import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import MoreInfoModal from './moreInfo/MoreInfoModal';
import { motion } from "framer-motion";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import CloseIcon from '@mui/icons-material/Close';
import ScheduleModal from './moreInfo/ScheduleModal';

export default function TeacherCard({teacher, setTeacherUpdated}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            layout
        >
            <Card
                sx={{
                    minWidth: 200,
                    backgroundColor: colors.primary[600],
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                    border: `1px solid ${colors.primary[600]}`,
                    ":hover": {
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                        border: "1px solid grey",
                    }
                }}
                key={teacher.id}
            >
                <CardContent
                    sx={{
                        minHeight: 160,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                    >
                        <Typography variant="h5">
                            {teacher.last_name && teacher.last_name !== "" ? teacher.last_name + ", " : null}{teacher.first_name}
                        </Typography>
                        {teacher.email === null && teacher.interview_eval !== null && teacher.interview_eval === 3 ? (
                            <RadioButtonUncheckedIcon sx={{ color: "green" }} />
                        ) : teacher.email === null && teacher.interview_eval !== null && teacher.interview_eval === 2 ? (
                            <ChangeHistoryIcon sx={{ color: "orange" }} />
                        ) : teacher.email === null && teacher.interview_eval !== null && teacher.interview_eval === 1 ? (
                            <CloseIcon sx={{ color: "red" }} />
                        ) : null}
                    </Box>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {teacher.school && teacher.school.length > 28 
                            ? `${teacher.school.substring(0, 28)}...` 
                        : teacher.school === null || teacher.school === "" 
                            ? "N/A" 
                            : teacher.school
                        }
                    </Typography>
                    {/* subject in charge */}
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {teacher.subject_in_charge && teacher.subject_in_charge.length > 35
                            ? `${teacher.subject_in_charge.substring(0, 35)}...`
                        : teacher.subject_in_charge === null || teacher.subject_in_charge === ""
                            ? "N/A" 
                            : teacher.subject_in_charge
                        }
                    </Typography>
                    <Typography variant="body2">
                        {teacher.phone === null || teacher.phone === "" ? "N/A" : teacher.phone}
                        <br />
                        {teacher.email === null || teacher.email === "" ? (
                            <span>N/A <span style={{ color: 'darkgray' }}>(Email for Login)</span></span>
                            ) : teacher.email 
                        }
                        <br />
                        {teacher.personal_email === null || teacher.personal_email === "" ? (
                            <span>N/A <span style={{ color: 'darkgray' }}>(Personal Email)</span></span>
                        ) : (
                            <span>{teacher.personal_email} <span style={{ color: 'darkgray' }}>(Personal Email)</span></span>
                        )}
                    </Typography>
                </CardContent>
                <CardActions
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <MoreInfoModal teacher={teacher} setTeacherUpdated={setTeacherUpdated} />
                    <ScheduleModal teacher={teacher} userId={teacher.uuid} />
                </CardActions>
            </Card>
        </motion.div>
    );
}