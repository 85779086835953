import React from 'react'
import { Box, Button, TextField } from "@mui/material";
import Axios from 'axios';
import dayjs from 'dayjs';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const NoteButton = ({
    course,
    student_course,
    date,
    attendance_map,
    setInfoUpdated,
    findEventId,
    setErrorAlertOpen,
    setErrorAlertMessage,
    handlePopperClose,
}) => {
    const [note, setNote] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    // handle attendance box click to change attendance status
    const handleChangeButtonClick = async (student_id, event_id, date, status) => {
        if (note === "") {
            setErrorAlertMessage("Note is required!");
            setErrorAlertOpen(true);
            return;
        }

        // if (date.format('YYYY-MM-DD') > dayjs().format('YYYY-MM-DD'))
        //     return;
        if ((student_course.start_date !== null && student_course.end_date !== null)
        && (date.format('YYYY-MM-DD') < dayjs(student_course.start_date).format('YYYY-MM-DD')
        || date.format('YYYY-MM-DD') > dayjs(student_course.end_date).format('YYYY-MM-DD')))
            return;

        if (attendance_map[student_id] === undefined 
        || attendance_map[student_id] === null
        || attendance_map[student_id][course.id] === undefined 
        || attendance_map[student_id][course.id] === null
        || attendance_map[student_id][course.id][event_id] === undefined 
        || attendance_map[student_id][course.id][event_id] === null
        || attendance_map[student_id][course.id][event_id].id === undefined
        || attendance_map[student_id][course.id][event_id].id === null) {
            // create attendance
            await Axios.post(`${process.env.REACT_APP_URL}/api/v1/attendance/notes`, {
                student_id: student_id,
                event_id: event_id,
                note: note,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                setInfoUpdated(true);
            }).catch((err) => {
                console.log("err: ", err);
                setErrorAlertMessage(err.response.data.message);
                setErrorAlertOpen(true);
            });
        } else {
            const id = attendance_map[student_id][course.id][event_id].id;

            if (id === undefined || id === null || id === "") {
                setErrorAlertMessage("Please refresh the page and try again!");
                setErrorAlertOpen(true);
                return;
            }

            // update attendance
            await Axios.put(`${process.env.REACT_APP_URL}/api/v1/attendance/${id}/notes`, {
                note: note,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                setInfoUpdated(true);
            }).catch((err) => {
                console.log("err: ", err);
                setErrorAlertMessage(err.response.data.message);
                setErrorAlertOpen(true);
            });
        }
        handleClose();
        handlePopperClose();
    };


    return (
        <Box sx={{ width: '100%' }}>
            <Button
                onClick={handleOpen}
                sx={{ color: 'info.main', textTransform: 'none', width: '100%' }}
            >
                NOTE
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box
                    sx={style}
                >
                    <TextField
                        id="note"
                        label="Note"
                        multiline
                        rows={8}
                        onChange={(e) => setNote(e.target.value)}
                        variant="outlined"
                        sx={{ width: '100%' }}
                    />
                    <Button
                        color="secondary" 
                        variant="contained"
                        onClick={() => handleChangeButtonClick(student_course.student.id, findEventId(course, date), date, 0)}
                        sx={{
                            width: '100%',
                            marginTop: '10px',
                        }}
                    >
                        SUBMIT
                    </Button>
                </Box>
            </Modal>
        </Box>
    )
}

export default NoteButton