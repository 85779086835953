import React from 'react'
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { tokens } from "../../../../theme";
import TextbookAndQuestions from './TextbookAndQuestions';
import Alert from '../../../../components/alert/Alert';
import Axios from 'axios';
import Loading from '../../../../components/Loading';
import BackdropComp from '../../../../components/Backdrop';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import getCookies from '../../../../utils/getCookies';
import { Typography } from '@mui/material';


const HWQuestionsAttempts = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const currentUser = getCookies("_auth_state");
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const userId = currentUser ? currentUser.split(',')[0]?.split(':')[1]?.split('"')[1] : null;
    const course_id = window.location.pathname.split("/")[window.location.pathname.split("/").length - 5];
    const homework_id = window.location.pathname.split("/")[window.location.pathname.split("/").length - 3];

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [searchParams] = useSearchParams();
    const courseData = useOutletContext().courseData;
    const [loading, setLoading] = React.useState(true);

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");
    
    // for class materials
    const [classMaterial, setClassMaterial] = React.useState(null);
    const [hwQuestions, setHWQuestions] = React.useState([]);
    const [hwAnswers, setHWAnswers] = React.useState({});
    const [pageFrom, setPageFrom] = React.useState(0);
    const [pageTo, setPageTo] = React.useState(0);
    const [submissionId, setSubmissionId] = React.useState(null);
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [hwAnswerFiles, setHWAnswerFiles] = React.useState({});
    const [updatedHWAnswers, setUpdatedHWAnswers] = React.useState(false);
    const [studentName, setStudentName] = React.useState("");

    let first_render = true;
  
    React.useEffect(() => {
        setLoading(true);
        const submission_id = searchParams.get("submission_id") || submissionId || null;
        const studentName = searchParams.get("student_name") || null;
        if (studentName) {
            setStudentName(studentName);
        }

        async function fetchClassMaterialAndHWQuestions() {
            // for student
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${course_id}/homework/${homework_id}/class-materials/hw-questions?submission_id=${submission_id}&user_uuid=${userId}&role=${userRole}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setClassMaterial(response.data.class_material);
                setHWQuestions(response.data.hw_questions);
                setPageFrom(response.data.page_from);
                setPageTo(response.data.page_to);
                setSubmissionId(response.data.submission_id);
                setIsSubmitted(response.data.is_submitted);
                for (let i = 0; i < response.data.hw_questions.length; i++) {
                    let hw_answer = response.data.hw_questions[i].hw_answers[0] || null;
                    if (response.data.hw_questions[i].type === "file_upload" && hw_answer) {
                        setHWAnswerFiles((prev) => ({
                            ...prev,
                            [response.data.hw_questions[i].id]: {
                                file: null,
                                prev_file: hw_answer.hw_answer_file ? hw_answer.hw_answer_file : null,
                            },
                        }));
                    }

                    setHWAnswers((prev) => ({
                        ...prev,
                        [response.data.hw_questions[i].id]: hw_answer,
                    }));
                }

                // console.log(response.data.class_material);
                // console.log(response.data.hw_questions);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message);
                setErrorAlertOpen(true);
            });
        }

        async function fetchClassMaterialAndHWQuestionsForTeacherAndAdmin() {
            // for teacher and admin
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${course_id}/homework/${homework_id}/class-materials/hw-questions/for-instructors?user_uuid=${userId}&role=${userRole}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setClassMaterial(response.data.class_material);
                setHWQuestions(response.data.hw_questions);
                setPageFrom(response.data.page_from);
                setPageTo(response.data.page_to);
                setSubmissionId(response.data.submission_id);
                
                for (let i = 0; i < response.data.hw_questions.length; i++) {
                    let hw_answer = response.data.hw_questions[i].hw_answers[0] || null;
                    if (response.data.hw_questions[i].type === "file_upload" && hw_answer) {
                        setHWAnswerFiles((prev) => ({
                            ...prev,
                            [response.data.hw_questions[i].id]: {
                                file: null,
                                prev_file: hw_answer.hw_answer_file ? hw_answer.hw_answer_file : null,
                            },
                        }));
                    }

                    setHWAnswers((prev) => ({
                        ...prev,
                        [response.data.hw_questions[i].id]: hw_answer,
                    }));
                }

                // console.log(response.data.class_material);
                // console.log(response.data.hw_questions);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message);
                setErrorAlertOpen(true);
            });
        }

        if (first_render) {
            if (userRole === "student" || submission_id) {
                fetchClassMaterialAndHWQuestions();
            } else {
                fetchClassMaterialAndHWQuestionsForTeacherAndAdmin();
            }
            first_render = false;
        }
        setUpdatedHWAnswers(false);
        setLoading(false);
    }, [courseData, updatedHWAnswers]);

    return (
        <Box m="20px" marginX={3} marginY={3} minHeight="90%">
            { loading || courseData === null || classMaterial === null || submissionId === null ? 
                <Loading />
                : 
                <Box 
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        position: "relative",
                    }}
                >
                    <Box
                        position="absolute"
                        top="-50px"
                        left="0"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography variant="h4" sx={{fontWeight: "bold"}}>
                            HOMEWORK PROBLEMS {studentName && `FOR ${studentName.toUpperCase()}`}
                        </Typography>
                    </Box>
                    <Box
                        minWidth="20rem"
                        width="100%"
                        minHeight="70vh"
                        borderRadius="3px"
                        padding={3}
                        boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                        backgroundColor={colors.primary[600]}
                        display="flex"
                        flexDirection="column"
                        alignItems="left"
                        gap={3}
                        mb={3}
                    >
                        {classMaterial && (
                            <TextbookAndQuestions 
                                PDF={classMaterial.file_url} 
                                pageFrom={pageFrom} 
                                pageTo={pageTo} 
                                hwQuestions={hwQuestions} 
                                setHWAnswers={setHWAnswers}
                                hwAnswers={hwAnswers}
                                userId={userId}
                                userRole={userRole}
                                course_id={course_id}
                                homework_id={homework_id}
                                colors={colors}
                                submissionId={submissionId}
                                isSubmitted={isSubmitted}
                                setHWAnswerFiles={setHWAnswerFiles}
                                hwAnswerFiles={hwAnswerFiles}
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                setSuccessAlertMessage={setSuccessAlertMessage}
                                setErrorAlertOpen={setErrorAlertOpen}
                                setErrorAlertMessage={setErrorAlertMessage}
                                setBackdropOpen={setBackdropOpen}
                                handleLogout={handleLogout}
                                setUpdatedHWAnswers={setUpdatedHWAnswers}
                            />
                        )}
                    </Box>
                    <Alert
                        successAlertOpen={successAlertOpen}
                        setSuccessAlertOpen={setSuccessAlertOpen}
                        errorAlertOpen={errorAlertOpen}
                        setErrorAlertOpen={setErrorAlertOpen}
                        successMsg={successAlertMessage}
                        errorMsg={errorAlertMessage}
                        vertical="bottom"
                        horizontal="left"
                    />
                    <BackdropComp backdropOpen={backdropOpen} handleBackdropClose={handleBackdropClose} />
                </Box>
            }
        </Box>
    )
}

export default HWQuestionsAttempts