import React from 'react'
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../theme";
import Axios from 'axios';
import Backdrop from '../../components/Backdrop';

const RemoveStudentModal = ({ 
    setAnchorEl, 
    student_courses,
    potentialCourse,
    fetchPotentialCourses,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); setAnchorEl(null); };

    const [studentsToRemove, setStudentsToRemove] = React.useState([]);

    const handleRemoveStudent = async (e) => {
        e.preventDefault();

        if (studentsToRemove.length === 0) {
            setErrorAlertMessage("Please select students to remove.");
            setErrorAlertOpen(true);
            return;
        }

        console.log("studentsToRemove: ", studentsToRemove);

        const promises = studentsToRemove.map((student_id) => {
            return Axios.delete(`${process.env.REACT_APP_URL}/api/v1/courses/${potentialCourse.uuid}/students/${student_id}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            });
        });

        Promise.all(promises).then((res) => {
            console.log("res: ", res);
            setSuccessAlertMessage("Students removed successfully!");
            setSuccessAlertOpen(true);
            fetchPotentialCourses();
            // setTimeout(() => {
            //     window.location.href = `/admin/potentialCourses?scrollTo=${potentialCourse.uuid}`;
            // }, 500);
        }).catch((err) => {
            console.log("err: ", err);
            setErrorAlertMessage("Failed to remove students.");
            setErrorAlertOpen(true);
        });
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        height: 700,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Button 
                onClick={handleOpen} 
                variant="outlined"
                color="error"
                sx={{ 
                    width: "100%", 
                    border: "1px solid transparent",
                    borderRadius: 0,
                }}
            >
                Remove Student
            </Button>
            <Modal
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                open={open}
                onClose={handleClose}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]}>
                            Remove Student
                        </Typography>
                        <Box
                            sx={{
                                mt: 2,
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: 500,
                                minWidth: "100%",
                                maxWidth: "100%",
                                overflowX: 'scroll',
                                border: `1px solid ${colors.primary[400]}`,
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'sticky',
                                    top: 0,
                                    left: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    borderRight: `1px solid ${colors.primary[400]}`,
                                    width: 120,
                                    minWidth: 120,
                                    zIndex: 1,
                                }}
                            >
                                <Box
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: "100%",
                                        height: 40,
                                        backgroundColor: colors.primary[900],
                                        borderBottom: `1px solid ${colors.primary[400]}`,
                                    }}
                                >
                                    <Typography variant="h6">
                                        안내 번호
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: "100%",
                                        height: 40,
                                        backgroundColor: colors.primary[900],
                                        borderBottom: `1px solid ${colors.primary[400]}`,
                                    }}
                                >
                                    <Typography variant="h6">
                                        학생 이름
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: "100%",
                                        height: 40,
                                        backgroundColor: colors.primary[900],
                                        borderBottom: `1px solid ${colors.primary[400]}`,
                                    }}
                                >
                                    <Typography variant="body1">
                                        학생 이메일
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: "100%",
                                        height: 40,
                                        backgroundColor: colors.primary[900],
                                        borderBottom: `1px solid ${colors.primary[400]}`,
                                    }}
                                >
                                    <Typography variant="body1">
                                        부모님 이름
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: "100%",
                                        height: 40,
                                        backgroundColor: colors.primary[900],
                                        borderBottom: `1px solid ${colors.primary[400]}`,
                                    }}
                                >
                                    <Typography variant="body1">
                                        부모님 이메일
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: "100%",
                                        height: 40,
                                        backgroundColor: colors.primary[900],
                                        borderBottom: `1px solid ${colors.primary[400]}`,
                                    }}
                                >
                                    <Typography variant="body1">
                                        부모님 전화번호
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: "100%",
                                        height: 40,
                                        backgroundColor: colors.primary[900],
                                    }}
                                >
                                    <Typography variant="body1"></Typography>
                                </Box>
                            </Box>
                            {student_courses?.length > 0 && student_courses.map((student_course, index) => {
                                const student = student_course.student;

                                return (
                                    <Box
                                        key={student.uuid}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: '1rem',
                                            borderRight: `1px solid ${colors.primary[400]}`,
                                            p: 2,
                                            width: 300,
                                            minWidth: 300,
                                        }}
                                    >
                                        <Typography variant="h4" flex={1} fontWeight={"bold"}>
                                            {student.consultation_number ? student.consultation_number : 'N/A'}
                                        </Typography>
                                        <Typography variant="h6" flex={1}>
                                            {student.first_name ? student.first_name : ''} {student.last_name ? student.last_name : ''}
                                        </Typography>
                                        <Typography variant="body1" flex={1}>
                                            {student.email ? student.student_email : 'N/A'}
                                        </Typography>
                                        <Typography variant="body1" flex={1}>
                                            {student.parent_name ? student.parent_name : 'N/A'}
                                        </Typography>
                                        <Typography variant="body1" flex={1}>
                                            {student.parent_email ? student.parent_email : 'N/A'}
                                        </Typography>
                                        <Typography variant="body1" flex={1}>
                                            {student.parent_phone ? student.parent_phone : 'N/A'}
                                        </Typography>
                                        <Button 
                                            variant="contained" 
                                            color={studentsToRemove.includes(student.uuid) ? "error" : "primary"}
                                            sx={{
                                                mt: "auto",
                                            }}
                                            onClick={() => {
                                                if (studentsToRemove.includes(student.uuid)) {
                                                    setStudentsToRemove(studentsToRemove.filter((uuid) => uuid !== student.uuid));
                                                } else {
                                                    setStudentsToRemove([...studentsToRemove, student.uuid]);
                                                }
                                            }}
                                        >
                                            {studentsToRemove.includes(student.uuid) ? "Selected" : "Select"}
                                        </Button>
                                    </Box>
                                );
                            })}
                        </Box>
                        <Box mt={"auto"} display="flex" flexDirection="row" gap={3} width="100%" pt={2}>
                            <Button
                                type="button"
                                variant="contained"
                                color="error"
                                sx={{ mt: 2, width: "14rem", ml: "auto"}}
                                onClick={(e) => handleRemoveStudent(e) }
                            >
                                Remove ({studentsToRemove.length}) Students
                            </Button>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2, width: "7rem"}}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default RemoveStudentModal