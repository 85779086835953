import React from 'react';
import { Outlet } from 'react-router-dom';
import { useMode } from "../theme";
import { tokens } from '../theme';
import { Navigate } from 'react-router-dom';
import getCookies from '../utils/getCookies';

const SignInUpLayout = () => {
    const [theme] = useMode();
    const colors = tokens(theme.palette.mode);

    const currentUser = getCookies("_auth_state");

    const who = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const userRole = who;

    const [otpForForgotPassword, setOptForForgotPassword] = React.useState('');
    const [emailForForgotPassword, setEmailForForgotPassword] = React.useState('');

    const redirectTo = () => {
        if (userRole === "admin")
            return <Navigate to="/"/>
        else
            return <Navigate to="/classrooms/classes"/>
    }
    
    return currentUser ? redirectTo() : (
        <>
            <main className="content" style={{backgroundColor: colors.primary[750]}}>
                <Outlet context={{
                    otpForForgotPassword: otpForForgotPassword,
                    setOptForForgotPassword: setOptForForgotPassword,
                    emailForForgotPassword: emailForForgotPassword,
                    setEmailForForgotPassword: setEmailForForgotPassword,
                }}/>
            </main>
        </>
    );
};

export default SignInUpLayout;