import React from 'react'
import { tokens } from "../../theme";
import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Axios from 'axios';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import Loading from '../../components/Loading';
import EachTableRow from './EachTableRow';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

var options = {
    year: "numeric",
    month: "short",
    day: "numeric"
};

const Homework = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = React.useState(true);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    // homeworks with course and students with no submissions
    const [homeworks, setHomeworks] = React.useState(null);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: colors.primary[300],
            color: theme.palette.common.white,
            fontSize: 14,
            fontWeight: "bold",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
        border: '1px solid rgba(224, 224, 224, 1)',
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: colors.primary[750],
        },
        '&:nth-of-type(even)': {
            backgroundColor: colors.primary[750],
        },
    }));


    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Homework');

        async function getHomeworks() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/homeworks/students/no-submissions`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },                
            }).then((response) => {               
                setHomeworks(response.data.homeworksByDueDate);
            }).catch((error) => {
                if (error.response !== null && error.response.status === 401) {
                    handleLogout();
                    return;
                }
                console.log(error.response.data.message);
            });
        }

        getHomeworks();
        setLoading(false);
    }, []);

    return (
        <Box m="20px" marginX={3} marginY={3}>
            { loading || homeworks === null ? 
                <Loading />
                : 
                <><Box 
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Header title="HOMEWORK" subtitle="A ONE Institute" />
                    <TableContainer component={Paper} sx={{ maxWidth: "90%" }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{ width: "10%" }}>Created On</StyledTableCell>
                                    <StyledTableCell style={{ width: "14%" }}>Due Date</StyledTableCell>
                                    <StyledTableCell style={{ width: "10%" }}>Course</StyledTableCell>
                                    <StyledTableCell style={{ width: "10%" }}>Homework</StyledTableCell>
                                    <StyledTableCell style={{ width: "52%" }}>Students</StyledTableCell>
                                    <StyledTableCell style={{ width: "4%" }}>Info</StyledTableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                    {homeworks && Object.keys(homeworks).map((element, index) => {
                        return (
                            <TableContainer key={index} component={Paper} sx={{ maxWidth: "90%" }}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {homeworks[element].length === 0 && (
                                            <StyledTableRow>
                                                <StyledTableCell style={{ width: "10%" }}></StyledTableCell>
                                                {index === 3 ? (
                                                    <StyledTableCell rowSpan={homeworks[element].length} style={{ backgroundColor: colors.greenAccent[500], width: "14%" }}>
                                                        <Box sx={{ display: 'flex', alignContent: 'center', gap: '0.5rem' }}>
                                                            <Box>
                                                                {new Date(element).toUTCString("en", options).substring(0, 16)} 
                                                            </Box>
                                                            <Box>
                                                                <TodayOutlinedIcon />
                                                            </Box>
                                                        </Box>
                                                    </StyledTableCell>
                                                    ) : (
                                                    <StyledTableCell rowSpan={homeworks[element].length} style={{ width: "14%" }}>
                                                                {new Date(element).toUTCString("en", options).substring(0, 16)} 
                                                    </StyledTableCell>
                                                )}
                                                <StyledTableCell component="th" scope="special_note" style={{ width: "10%" }}></StyledTableCell>
                                                <StyledTableCell style={{ width: "10%" }}></StyledTableCell>
                                                <StyledTableCell style={{ width: "52%" }}></StyledTableCell>
                                                <StyledTableCell style={{ width: "4%" }}></StyledTableCell>
                                            </StyledTableRow>
                                        )}
                                        {homeworks[element].map((homework, i) => {
                                            return (
                                                <EachTableRow 
                                                    key={i}
                                                    i={i}
                                                    index={index}
                                                    homeworksLen={homeworks[element].length} 
                                                    homework={homework}
                                                    StyledTableCell={StyledTableCell}
                                                    StyledTableRow={StyledTableRow}
                                                    element={element}
                                                />
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        )
                    })}
                </Box></>
            }
        </Box>
    )
}

export default Homework