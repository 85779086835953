import React from 'react'
import { useOutletContext } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Header from "../../../components/Header";
import { tokens } from "../../../theme";
// import jwt_decode from 'jwt-decode';
import Dropdown from '../../../components/dropdown/Dropdown';
import ClassMaterialModal from './ClassMaterialModal';
import ClassMaterialViewer from './ClassMaterialViewer';
import Alert from '../../../components/alert/Alert';
import DownloadIcon from '@mui/icons-material/Download';
import Axios from 'axios';
import Loading from '../../../components/Loading';
import BackdropComp from '../../../components/Backdrop';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ClassMaterialDeleteModal from './ClassMaterialDeleteModal';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import getCookies from '../../../utils/getCookies';
import ClassMaterialAssignModal from './ClassMaterialAssignModal';


const ClassMaterials = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const currentUser = getCookies("_auth_state");
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const courseData = useOutletContext().courseData;
    const eventModules = useOutletContext().lectureModules;
    const [loading, setLoading] = React.useState(true);

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");
    
    // for class materials
    const [classMaterials, setClassMaterials] = React.useState(null);
    const [classMaterial, setClassMaterial] = React.useState("");
  
    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Class Materials');
        // const courseId = window.location.pathname.split('/')[2];

        setLoading(false);
    }, []);

    React.useEffect(() => {
        if (courseData !== null) {
            setBackdropOpen(true);
            Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${courseData.uuid}/class-materials`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                // setClassMaterials(response.data);

                // temporary fix for class materials
                // later we will change the backend to send class materials based on user role
                const classMaterials_from_server = response.data;
                if (classMaterials_from_server !== null && classMaterials_from_server.length > 0) {
                    let classMaterial_list = [];
                    classMaterials_from_server.map((item) => {
                        if (userRole === "student") {
                            if (item.name !== null && !item.name.includes("share") && !item.name.includes("answer")) {
                                classMaterial_list.push(item);
                            }
                        } else if (userRole === "teacher") {
                            if (item.name !== null && (item.name.includes("share") || item.name.includes("answer"))) {
                                classMaterial_list.push(item);
                            }
                        } else {
                            // admin
                            classMaterial_list.push(item);
                        }
                    });

                    setClassMaterials(classMaterial_list);
                } else {
                    setClassMaterials([]);
                }
                setClassMaterial("");
                setBackdropOpen(false);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                
                setErrorAlertOpen(true);
                setErrorAlertMessage(error.response.data.message);
                setBackdropOpen(false);
            });
        }
    }, [courseData]);

    return (
        <Box m="20px" marginX={3} marginY={3} minHeight="90%">
            { loading || courseData === null || eventModules === null ? 
                <Loading />
                : 
                <Box 
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <Header 
                        title={
                            courseData.code !== null 
                            ? courseData.code + ". " + courseData.course_title 
                            : courseData.course_title
                        } 
                        subtitle="A ONE Institute"
                    />
                    <Box
                        minWidth="20rem"
                        width="100%"
                        minHeight="80vh"
                        borderRadius="3px"
                        padding={3}
                        boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                        backgroundColor={colors.primary[600]}
                        display="flex"
                        flexDirection="column"
                        alignItems="left"
                        gap={3}
                        mb={3}
                    >
                        <Box display="flex" flexDirection="row" gap={2} flexWrap="wrap">
                            <Box flex={1}>
                                {userRole !== "student" &&
                                <Dropdown 
                                    reactUseState={classMaterial}
                                    setReactUseState={setClassMaterial}
                                    inputLabel="Select a Class Material"
                                    data={classMaterials !== null ? 
                                        classMaterials.map((item) => {
                                            return {
                                                value: item.file_url,
                                                label: item.name
                                            }
                                        }) : []
                                    }
                                />
                                }
                            </Box>
                            <Box flex={1}></Box>
                            <Box flex={1}></Box>
                        </Box>
                        {userRole === "admin" && 
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "1rem",
                                }}
                            >
                                <ClassMaterialAssignModal
                                    courseId={courseData.uuid}
                                    classMaterials={classMaterials}
                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                    setErrorAlertOpen={setErrorAlertOpen}
                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                    setErrorAlertMessage={setErrorAlertMessage}
                                    setBackdropOpen={setBackdropOpen}
                                />
                                <ClassMaterialModal
                                    courseId={courseData.uuid}
                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                    setErrorAlertOpen={setErrorAlertOpen}
                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                    setErrorAlertMessage={setErrorAlertMessage}
                                    setBackdropOpen={setBackdropOpen}
                                />
                                <ClassMaterialDeleteModal
                                    courseId={courseData.uuid}
                                    classMaterials={classMaterials}
                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                    setErrorAlertOpen={setErrorAlertOpen}
                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                    setErrorAlertMessage={setErrorAlertMessage}
                                    setBackdropOpen={setBackdropOpen}
                                />
                            </Box>
                        }
                        {userRole === "teacher" ? (
                            <>{courseData !== null && classMaterials && classMaterial && classMaterial !== "" ? (
                                <ClassMaterialViewer PDF={classMaterial} />
                                )
                                : courseData !== null && (!classMaterials || classMaterials.length === 0) ? (
                                    <Box sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        minWidth: "20rem",
                                        minHeight: "32rem",
                                        gap: "0.4rem",
                                    }}>
                                        <InfoOutlinedIcon sx={{ my: "auto" }} />
                                        <Typography variant="h4" color={colors.primary[450]} my="auto">
                                            No class materials found!
                                        </Typography>
                                    </Box>
                                ) : (null)
                            }</>
                        ) : userRole === "student" ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                {courseData !== null && classMaterials && classMaterials.length > 0 ? (
                                    <> <Box sx={{ display: "flex", gap: ".5rem", alignItems: "center" }} >
                                        <DownloadIcon fontSize='large' />
                                        <Typography variant="h4" color={colors.primary[450]}>
                                            Click to Download:
                                        </Typography>
                                    </Box>
                                    {classMaterials.map((item, index) => {
                                        return (
                                            <Typography 
                                                key={index}
                                                component="a" 
                                                href={item.file_url} 
                                                variant="h4"
                                                color={colors.blueAccent[600]} 
                                                mt={2}
                                                width="fit-content"
                                            >
                                                {item.name}
                                            </Typography>
                                        )
                                    })}
                                    </>
                                    )
                                    : courseData !== null && (!classMaterials || classMaterials.length === 0) ? (
                                        <Box sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            minWidth: "20rem",
                                            minHeight: "32rem",
                                            gap: "0.4rem",
                                        }}>
                                            <InfoOutlinedIcon sx={{ my: "auto" }} />
                                            <Typography variant="h4" color={colors.primary[450]} my="auto">
                                                No class materials found!
                                            </Typography>
                                        </Box>
                                        ) : (null)
                                }
                            </Box>
                        ) : (
                            <>{courseData !== null && classMaterials && classMaterials.length > 0 ? (
                                <>
                                    {classMaterials.map((item, index) => {
                                        return (
                                            <Typography 
                                                key={index}
                                                component="a" 
                                                href={item.file_url} 
                                                variant="h4" 
                                                color={colors.blueAccent[600]} 
                                                width="fit-content"
                                            >
                                                {item.name}
                                            </Typography>
                                        )
                                    })}
                                    {classMaterial && classMaterial !== "" &&
                                        <ClassMaterialViewer PDF={classMaterial} />
                                    }
                                </>
                                ) : courseData !== null && (!classMaterials || classMaterials.length === 0) ? (
                                    <Box sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        minWidth: "20rem",
                                        minHeight: "32rem",
                                        gap: "0.4rem",
                                    }}>
                                        <InfoOutlinedIcon sx={{ my: "auto" }} />
                                        <Typography variant="h4" color={colors.primary[450]} my="auto">
                                            No class materials found!
                                        </Typography>
                                    </Box>
                                ) : (null)
                            }</>
                        )}
                    </Box>
                    <Alert
                        successAlertOpen={successAlertOpen}
                        setSuccessAlertOpen={setSuccessAlertOpen}
                        errorAlertOpen={errorAlertOpen}
                        setErrorAlertOpen={setErrorAlertOpen}
                        successMsg={successAlertMessage}
                        errorMsg={errorAlertMessage}
                        vertical="bottom"
                        horizontal="left"
                    />
                    <BackdropComp backdropOpen={backdropOpen} handleBackdropClose={handleBackdropClose} />
                </Box>
            }
        </Box>
    )
}

export default ClassMaterials