import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const ErrorSnackbar = ({ errorMsg, open, setOpen, vertical, horizontal }) => {
    const state = {
        vertical: vertical === undefined ? 'bottom' : vertical,
        horizontal: horizontal === undefined ? 'left' : horizontal,
    }
    const handleClose = () => {
        setOpen(false);
    };  

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={state}>
            <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%', alignItems: "center" }}>
                    {errorMsg}
            </Alert>
        </Snackbar>
    )
}

export default ErrorSnackbar