import React from 'react'
import { tokens } from "../../../theme";
import { Box, useTheme, Typography, IconButton } from "@mui/material";
import ClassCard from './ClassCard';
import Axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Loading from '../../../components/Loading';
// import jwt_decode from 'jwt-decode';
// import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';
import { useSignOut } from "react-auth-kit";
import getCookies from '../../../utils/getCookies';
import { motion, AnimatePresence } from "framer-motion";
import Dropdown from '../../../components/dropdown/Dropdown';
import { generateYears, semesters } from '../../../components/dropdown/DropdownData';
import { getCourseYear, getCourseSemester } from '../../../utils/CourseDuration';
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const Classes = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const matches1 = useMediaQuery('(max-width:750px)');
    const season = {'Spring': 1, 'Summer': 2, 'Fall': 3, 'Winter': 4};

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [loading, setLoading] = React.useState(true);
    const [courses, setCourses] = React.useState(null);
    
    const [maxYear, setMaxYear] = React.useState(0);
    const [minYear, setMinYear] = React.useState(0);
    const [year, setYear] = React.useState(0); // current year
    const [semester, setSemester] = React.useState('all');

    const [search, setSearch] = React.useState('');
    const [codeSearch, setCodeSearch] = React.useState('');

    let currentUser = getCookies("_auth_state");

    const id = currentUser ? currentUser.split(',')[0]?.split(':')[1]?.split('"')[1] : null;
    const who = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;

    const userRole = who;
    const userUUID = id;

    // const userRole = jwt_decode(document.cookie).who;
    // const userUUID = jwt_decode(document.cookie).id;

    // const [currentZoomClass, setCurrentZoomClass] = React.useState(null);

    const handleAdminHome = () => {
        localStorage.setItem('selectedMenu', 'Dashboard');
        navigate("/");
    }

    React.useEffect(() => {
        function fetchMaxMinYears() {
            Axios.get(`${process.env.REACT_APP_URL}/api/v1/semesters/max-min-years`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(async (response) => {
                    setMaxYear(response.data.max_year);
                    setMinYear(response.data.min_year);
                }).catch((error) => {
                    if (error.response !== null && error.response.status !== 404)
                        console.log(error.response.data.message);
                    else if (error.response !== null && error.response.status === 401)
                        handleLogout();
                });
        }
    
        async function getCourseInfo() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setCourses(response.data);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                } else if (error.response.status === 404) {
                    setCourses([]);
                    return;
                } else {
                    alert(error.response.data.message || error.response.data.error);
                }
            });
        }
    
        async function getCoursesByTeacherId() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/teachers/${userUUID}/courses`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setCourses(response.data);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                } else if (error.response.status === 404) {
                    setCourses([]);
                    return;
                } else {
                    alert(error.response.data.message || error.response.data.error);
                }
            });
        }
    // here, when we get courses with student's id, we need to filter out the courses that the student is_confirmed as false
        async function getCoursesByStudentId() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students/${userUUID}/courses`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setCourses(response.data);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                } else if (error.response.status === 404) {
                    setCourses([]);
                    return;
                } else {
                    alert(error.response.data.message || error.response.data.error);
                }
            });
        }

        fetchMaxMinYears();
        if (userRole === "admin") {
            getCourseInfo();
            // setCurrentZoomClass({ zoom_link: null, course_title: null});
        } else if (userRole === "teacher") {
            getCoursesByTeacherId();
            // getZoomTeacher();
        } else if (userRole === "student") {
            getCoursesByStudentId();
            // getZoomStudent();
        }

        setYear(new Date().getFullYear());
        setLoading(false);

        let intervalId;
        if (userRole === "admin") {
            intervalId = setInterval(getCourseInfo, 60000 * 60); // 1 hour
        } else if (userRole === "teacher" || userRole === "student") {
            intervalId = setInterval(() => {
                if (userRole === "teacher") {
                    getCoursesByTeacherId();
                } else if (userRole === "student") {
                    getCoursesByStudentId();
                }
            }, 60000 * 15); // 15 minutes
        }
        
        return () => clearInterval(intervalId);

    }, [userRole, userUUID]);

    // focus on search code input field if user is admin
    React.useEffect(() => {
        if (userRole === "admin" && !loading) {
            const input = document.getElementById('searchByCode');
            if (input) {
                input.focus();
            }
        }
    }, [loading, courses]);

    return (
        <Box sx={{ height: "100%", width: "100%" }}>
            { loading || courses === null ?
                <Loading />
            : <>
                <Box
                    sx={{ 
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: colors.primary[600],
                        maxWidth: "1200px",
                        m: "0 auto",
                        minHeight: "100%",
                        boxShadow: "0px 0px 10px 10px rgba(0, 0, 0, 0.10)",
                    }}
                >
                    <Box sx={{ height: "4rem", width: "100%" }}></Box>
                    {userRole === "admin" ? (
                        <Button
                            fullWidth
                            variant="contained"
                            color='secondary'
                            onClick={handleAdminHome}
                            sx={{
                                mt: "2rem",
                                ml: "auto",
                                mr: "3rem",
                                width: "14rem",
                            }}
                        >
                            <Typography color={colors.primary[400]}>Go to Admin Dashboard</Typography>
                        </Button>
                    ) : null}
                    {courses.length === 0 ? 
                        <>
                            <Box 
                                sx={{ 
                                    display: "flex", 
                                    justifyContent: "center", 
                                    height: "100%", 
                                    width: "100%", 
                                    fontWeight: "1", 
                                    mt: "15rem" 
                                }}
                            >
                                <ErrorOutlineIcon fontSize="large" sx={{ my: "auto", mr: "1rem" }} />
                                {userRole === "student" ? (
                                    <Typography variant='h2' >
                                        You are not enrolled in any classes.
                                    </Typography>
                                ) : userRole === "teacher" ? (
                                    <Typography variant='h2'>
                                        You are not teaching any classes.
                                    </Typography>
                                ) : userRole === "admin" ? (
                                    <Typography variant='h2' >
                                        There are no classes.
                                    </Typography>
                                ) : null}
                            </Box>
                            <Box
                                sx={{ 
                                    display: "flex", 
                                    justifyContent: "center", 
                                    height: "100%", 
                                    width: "100%", 
                                    fontWeight: "1", 
                                    mt: "1rem" 
                                }}
                            >
                                {(userRole === "student" || userRole === "teacher") ? (
                                    <Typography variant='h5' color='grey' >
                                        Please contact an administrator if your classes are not visible to you.
                                    </Typography>
                                ) : userRole === "admin" ? (
                                    <Typography variant='h5' color='grey' >
                                        Please contact a developer if you don't see any classes.
                                    </Typography>
                                ) : null}
                            </Box>
                        </> : <>
                            <Box
                                sx={{
                                    width: "100%", 
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingX: "2rem",
                                    mt: "2rem",
                                }}
                            >
                                <Typography
                                    variant='h2'
                                    sx={{ 
                                        textAlign: "center",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <span style={{ color: colors.blueAccent[600], marginRight: "0.5rem" }}>
                                        A ONE 
                                    </span>
                                    Classrooms
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "1rem",
                                        mt: "2rem",
                                        minHeight: 68,
                                        ...matches1 && {
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "1rem",
                                            ...matches1 && {
                                                flexDirection: "column",
                                            },
                                        }}
                                    >
                                        {/* SEARCH BAR */}
                                        <Box
                                            display="flex"
                                            backgroundColor={colors.primary[600]}
                                            borderRadius="3px"
                                            boxShadow={10}
                                            mb={2}
                                            maxWidth={200}
                                            minWidth={200}
                                            sx={{
                                                ...matches1 && {
                                                    mb: 0,
                                                },
                                            }}
                                        >
                                            <InputBase sx={{ ml: 2, flex: 1 }} onChange={(e) => setSearch(e.target.value)} placeholder="Search by course title" />
                                            <IconButton type="button" sx={{ p: 1 }}>
                                                <SearchIcon />
                                            </IconButton>
                                        </Box>

                                        <Box
                                            display="flex"
                                            backgroundColor={colors.primary[600]}
                                            borderRadius="3px"
                                            boxShadow={10}
                                            mb={2}
                                            maxWidth={200}
                                            minWidth={200}
                                        >
                                            <InputBase 
                                                sx={{ ml: 2, flex: 1 }} 
                                                onChange={(e) => setCodeSearch(e.target.value)} 
                                                placeholder="Search by course code" 
                                                id='searchByCode'
                                            />
                                            <IconButton type="button" sx={{ p: 1 }}>
                                                <SearchIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "1rem",
                                            height: '50px',
                                            ...matches1 && {
                                                mb: "2rem",
                                            },
                                        }}
                                    >
                                        {/* YEAR SELECT */}
                                        <Dropdown 
                                            maxWidth={200} 
                                            maxHeight={3} 
                                            reactUseState={year} 
                                            setReactUseState={setYear} 
                                            inputLabel="Year" 
                                            data={generateYears(minYear, maxYear)} 
                                        />
                                        {/* SEMESTER SELECT */}
                                        <Dropdown 
                                            maxWidth={200} 
                                            maxHeight={3} 
                                            reactUseState={semester} 
                                            setReactUseState={setSemester} 
                                            inputLabel="Semester" 
                                            data={semesters} 
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            {/* <Box
                                sx={{
                                    backgroundColor: colors.primary[600],
                                    display: "flex",
                                    padding: "2rem",
                                    flexWrap: "wrap",
                                    gap: "2rem",
                                    mb: "16vh",
                                    height: "10%",
                                    justifyContent: "flex-start",
                                    ...!matches1 && {
                                        justifyContent: "center",
                                    },
                                }}
                            > */}
                            <motion.div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                                    rowGap: "1rem",
                                    columnGap: "1rem",
                                    paddingRight: "2rem",
                                    paddingLeft: "2rem",
                                    paddingBottom: "2rem",
                                    justifyItems: "center",
                                    alignItems: "center",
                                }}
                                layout
                            >
                                <AnimatePresence>
                                    {courses
                                        .filter((course) => {
                                            return search === '' 
                                                ? course 
                                                : course.course_title.toLowerCase().includes(search.toLowerCase()) 
                                                    || course.course_title.toLowerCase().includes(search.toLowerCase());
                                        })
                                        .filter((course) => {
                                            return codeSearch === '' 
                                                ? course 
                                                : course.code?.toLowerCase().includes(codeSearch.toLowerCase());
                                        })
                                        .filter((course) => {
                                            return semester === 'all'
                                                ? course
                                                : getCourseSemester(course, year).includes(season[semester]);
                                        })
                                        .filter((course) => {
                                            return year === 0
                                                ? course
                                                : getCourseYear(course).includes(year);
                                        })                                   
                                        .map((course) => {
                                            return (
                                                <ClassCard key={course.id} course={course} />
                                            )
                                        })
                                    }
                                </AnimatePresence>
                            </motion.div>
                            {/* </Box> */}
                        </>
                    }
                </Box>
            </>}
        </Box>
    )
}

export default Classes