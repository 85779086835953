import dayjs from 'dayjs';

export const generateDate = (
    { year, start, end }
) => {
    if (year === null 
        || year === undefined 
        || start === null
        || start === undefined
        || end === null
        || end === undefined) {
        return [];
    }

    // const startDate = dayjs(year + "-" + start_end_dates["start_date"]);
    // let endDate = dayjs(year + "-" + start_end_dates["end_date"]);

    const startDate = dayjs(start);
    const endDate = dayjs(end);

    const arrayOfDate = [];

    if (startDate.month() < endDate.month()) {
        for (let i = startDate.month(); i <= endDate.month(); i++) {
            const month = dayjs().month(i);

            const firstDateOfMonth = startDate.month() !== i ? month.startOf('month') : startDate;
            const lastDateOfMonth = endDate.month() !== i ? month.endOf('month') : endDate;
            for (let j = firstDateOfMonth.date(); j <= lastDateOfMonth.date(); j++) {
                const date = dayjs().year(year).month(i).date(j);
                arrayOfDate.push(date);
            }
        }
    } else {
        for (let i = startDate.month(); i <= 11; i++) {
            const month = dayjs().month(i);
            const firstDateOfMonth = startDate.month() !== i ? month.startOf('month') : startDate;
            const lastDateOfMonth = endDate.month() !== i ? month.endOf('month') : endDate;
            for (let j = firstDateOfMonth.date(); j <= lastDateOfMonth.date(); j++) {
                const date = dayjs().year(year).month(i).date(j);
                arrayOfDate.push(date);
            }
        }

        for (let i = 0; i <= endDate.month(); i++) {
            const month = dayjs().month(i);
            const firstDateOfMonth = month.startOf('month');
            const lastDateOfMonth = endDate.month() !== i ? month.endOf('month') : endDate;
            for (let j = firstDateOfMonth.date(); j <= lastDateOfMonth.date(); j++) {
                const date = dayjs().year(year + 1).month(i).date(j);
                arrayOfDate.push(date);
            }
        }
    }

    // const lastDate = arrayOfDate[arrayOfDate.length - 1];
    // // monday - thursday
    // if (lastDate.day() !== 0 && lastDate.day() < 5) {
    //     while (true) {
    //         const date = arrayOfDate[arrayOfDate.length - 1].add(1, 'day');
    //         if (date.day() === 6) {
    //             // if Saturday, then break
    //             break;
    //         }
    //         arrayOfDate.push(date);
    //     }
    // }

    return arrayOfDate;
};