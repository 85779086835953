import * as React from "react";
import { Box, Button, FormControl, FormControlLabel, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import Axios from 'axios';
import Alert from '../../components/alert/Alert';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Backdrop from "../../components/Backdrop";
import IconButton from '@mui/material/IconButton';
import { FileUploadOutlined } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import HiddenPDF from '../hwQuestions/HiddenPDF';
import { Document, Page, pdfjs } from "react-pdf";
import { Autocomplete } from "@mui/material";
import { getTestCategory } from "../../utils/TestCategory";
import QuestionPreviewForTest from "../forms/QuestionPreviewForTest";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const EditTest = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    const [file, setFile] = React.useState(null);
    // const [fileForTeacher, setFileForTeacher] = React.useState(null);

    const [previewCardToggle, setPreviewCardToggle] = React.useState(false);

    const [optionName, setOptionName] = React.useState(['A', 'B', 'C', 'D']);
    const [numChoices, setNumChoices] = React.useState(4);

    const [subject, setSubject] = React.useState(null);
    const [isSection, setIsSection] = React.useState(null);

    const [numQuestions, setNumQuestions] = React.useState('');

    const [sections, setSections] = React.useState([]);
    const [sample, setSample] = React.useState([]);

    const [testUpdated, setTestUpdated] = React.useState(false);

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [sectionToDelete, setSectionToDelete] = React.useState(null);

    const testId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];


    React.useEffect(() => {
        async function fetchTest() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/tests/${testId}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                const test = response.data;
                setSubject(test.subject);
                setIsSection({ value: test.has_section ? "With Section" : "No Section" });
                
                if (test.has_section === true) {
                    // Update sections to include numQuestions
                    const updatedSections = test.test_sections.map(section => ({
                        ...section,
                        numQuestions: section.test_questions.length
                    }));
                    setSections(updatedSections);
                } else {
                    setSample(test.test_questions);
                }
    
                // Set initial file state
                setFile({
                    file: null,
                    name: test.title,
                    url: test.file_url,
                    type: "application/pdf",
                    changed: false
                });
            }).catch((error) => {
                console.log(error);
            });
        }
        fetchTest();
    }, [testUpdated]);
    

    const handleClickDialogOpen = (index) => {
        setSectionToDelete(index);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDeleteSection = async () => {    
        const updatedSections = sections.filter((_, index) => index !== sectionToDelete);
        const reIndexedSections = updatedSections.map((section, index) => {
            return {
                ...section,
                sectionNumber: index + 1,
            };
        });

        setSections(reIndexedSections);
        handleDialogClose();
    };
    

    const handleUpload = (event) => {
        setFile({
            file: event.target.files[0],
            name: event.target.files[0].name,
            url: URL.createObjectURL(event.target.files[0]),
            type: event.target.files[0].type,
            changed: true
        });
    };
    

    const handleFileRemove = (event) => {
        event.preventDefault();
        setFile(null);
    };

    // const handleTeacherFileUpload = (event) => {
    //     setFileForTeacher({
    //         file: event.target.files[0],
    //         name: event.target.files[0].name,
    //         url: URL.createObjectURL(event.target.files[0]),
    //         type: event.target.files[0].type,
    //         changed: true
    //     });
    // };

    // const handleTeacherFileRemove = (event) => {
    //     event.preventDefault();
    //     setFileForTeacher(null);
    // };

    const handleSectionNameChange = (event, index) => {
        const updatedSections = sections.map((section, sIndex) => {
            if (sIndex === index) {
                return { ...section, section_name: event.target.value };
            }
            return section;
        });
        setSections(updatedSections);
    };

    const documentRender = () => {
        return (
            <Document
                file={file}
                onContextMenu={(e) => e.preventDefault()}
                className="pdf-container"
            >
                <div>
                    <Page
                        pageNumber={1}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        className="react-pdf__Page__canvas"
                    />
                </div>
            </Document>
        );
    };

    const handleCreateSection = () => {

        if (!numQuestions || !numChoices || numQuestions === '0' || numChoices === '0') {
            setErrorAlertMessage("Please enter the valid number of questions and choices");
            setErrorAlertOpen(true);
            return;
        }

        if (numQuestions && numChoices) {
            const newSection = {
                section_number: sections.length + 1,
                questions: [],
                numQuestions: numQuestions,
            };

            if (subject.value === 'ACT') {
                const createdQuestions = [];                
                // if selected 4 choices section, then odd: A, B, C, D, even: F, G, H, J
                // if selected 5 choices section, then odd: A, B, C, D, E, even: F, G, H, J, K
                for (let i = 0; i < numQuestions; i++) {
                    if (numChoices === '4') {
                        const actOptions = i % 2 === 0 ? ['A', 'B', 'C', 'D'] : ['F', 'G', 'H', 'J'];
                        const newQuestion = {
                            question_number: i + 1,
                            question_options: [],
                            answer: "",
                        };

                        for (let j = 0; j < numChoices; j++) {
                            newQuestion.question_options.push({
                                option: actOptions[j],
                            });
                        }
                        createdQuestions.push(newQuestion);
                    
                    } else if (numChoices === '5') {
                        const actOptions = i % 2 === 0 ? ['A', 'B', 'C', 'D', 'E'] : ['F', 'G', 'H', 'J', 'K'];
                        const newQuestion = {
                            question_number: i + 1,
                            question_options: [],
                            answer: "",
                        };

                        for (let j = 0; j < numChoices; j++) {
                            newQuestion.question_options.push({
                                option: actOptions[j],
                            });
                        }
                        createdQuestions.push(newQuestion);

                    }
                    newSection.test_questions = createdQuestions;
                    newSection.numQuestions = numQuestions;        
                }
            } else {
                const createdQuestions = [];
                for (let i = 0; i < numQuestions; i++) {
                    const newQuestion = {
                        question_number: i + 1,
                        question_options: [],
                        answer: "",
                    };
                    for (let j = 0; j < numChoices; j++) {
                        newQuestion.question_options.push({
                            option: optionName[j],
                        });
                    }
                    createdQuestions.push(newQuestion);
                }
                
                newSection.test_questions = createdQuestions;
                newSection.numQuestions = numQuestions;
            }
            setSections([...sections, newSection]);
        }
    };

    const handleCreateSample = () => {

        if (!numQuestions || !numChoices || numQuestions === '0' || numChoices === '0') {
            setErrorAlertMessage("Please enter the valid number of questions and choices");
            setErrorAlertOpen(true);
            return;
        }

        if (numQuestions && numChoices) {
            const newSample = {
                questions: [],
                numQuestions: numQuestions,
            };
    
            for (let i = 0; i < numQuestions; i++) {
                const newQuestion = {
                    question_number: i + 1,
                    question_options: [],
                    answer: "",
                };
    
                for (let j = 0; j < numChoices; j++) {
                    newQuestion.question_options.push({
                        option: optionName[j],
                    });
                }
    
                newSample.questions.push(newQuestion);
            }
            setSample(newSample.questions);  // Stored as an array of questions
        }
    };

    const handleDeleteSample = () => {
        setSample([]);
    };

    const handleSectionAnswerChange = (sectionIndex, questionIndex, choice) => {
        const updatedSections = sections.map((section, sIndex) => {
            if (sIndex === sectionIndex) {
                const updatedQuestions = section.test_questions.map((question, qIndex) => {
                    if (qIndex === questionIndex) {
                        return { ...question, answer: choice };
                    }
                    return question;
                });
                return { ...section, test_questions: updatedQuestions };
            }
            return section;
        });
        setSections(updatedSections);
    };

    const handleSampleAnswerChange = (questionIndex, choice) => {
        const updatedQuestions = sample.map((question, qIndex) => {
            if (qIndex === questionIndex) {
                return { ...question, answer: choice };
            }
            return question;
        });

        setSample(updatedQuestions);
    };

    const handleAddQuestion = async (sectionIndex, questionIndex, qOptions) => {

        const optionNames = qOptions.map(option => option.option);

        const newQuestion = {
            question_number: questionIndex + 1,
            question_options: optionNames.map(option => ({ option })),
            answer: ""
        };

        const updatedSections = sections.map((section, sIndex) => {
            if (sIndex === sectionIndex) {
                const updatedQuestions = [...section.test_questions];
                updatedQuestions.splice(questionIndex + 1, 0, newQuestion);

                // Reorder question numbers
                const reorderedQuestions = updatedQuestions.map((question, index) => ({
                    ...question,
                    question_number: index + 1
                }));

                return { ...section, test_questions: reorderedQuestions };
            }
            return section;
        });
        setSections(updatedSections);
    }
    

    const handleDeleteQuestion = async (sectionIndex, questionIndex) => {
        const updatedSections = sections.map((section, sIndex) => {
            if (sIndex === sectionIndex) {
                if (section.test_questions.length <= 1) {
                    setErrorAlertMessage("At least one problem is needed in the section.");
                    setErrorAlertOpen(true);
                    return section;
                }
                const updatedQuestions = section.test_questions.filter((_, qIndex) => qIndex !== questionIndex);
    
                // Reorder question numbers
                const reorderedQuestions = updatedQuestions.map((question, index) => ({
                    ...question,
                    question_number: index + 1
                }));
    
                return { ...section, test_questions: reorderedQuestions };
            }
            return section;
        });
        setSections(updatedSections);
    };
    
    
    const handleAddQuestionForNoSection = async (questionIndex, qOptions) => {

        const optionNames = qOptions.map(option => option.option);

        const newQuestion = {
            question_number: questionIndex + 1,
            question_options: optionNames.map(option => ({ option })),
            answer: ""
        };

        const updatedQuestions = [...sample];
        updatedQuestions.splice(questionIndex + 1, 0, newQuestion);

        // Reorder question numbers
        const reorderedQuestions = updatedQuestions.map((question, index) => ({
            ...question,
            question_number: index + 1
        }));
        setSample(reorderedQuestions);
   
    };
    
    const handleDeleteQuestionForNoSection = async (questionIndex) => {
        if (sample.length <= 1) {
            setErrorAlertMessage("At least one problem is needed in the test.");
            setErrorAlertOpen(true);
            return;
        }
        const updatedQuestions = sample.filter((_, qIndex) => qIndex !== questionIndex);
        // Reorder question numbers
        const reorderedQuestions = updatedQuestions.map((question, index) => ({
            ...question,
            question_number: index + 1
        }));
        setSample(reorderedQuestions);
    };
    

    const handleFormSubmit = async (values) => {
        setBackdropOpen(true);
    
        if (isSection && isSection.value === "With Section" && sections.length === 0) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("At least one section is necessary");
            setBackdropOpen(false);
            return;
        }        
    
        if (isSection && isSection.value === "No Section" && sample.length === 0) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("At least one sample is necessary");
            setBackdropOpen(false);
            return;
        }
    
        if (!file || (file.changed && file.type !== "application/pdf")) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please upload a valid pdf file");
            setBackdropOpen(false);
            return;
        }

        // if (!fileForTeacher || fileForTeacher.type !== "application/pdf") {
        //     setErrorAlertOpen(true);
        //     setErrorAlertMessage("Please upload a pdf file for teacher");
        //     setBackdropOpen(false);
        //     return;
        // }
    
        if (!subject) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please select subjects");
            setBackdropOpen(false);
            return;
        }
    
        if (!isSection) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please select with section or no section");
            setBackdropOpen(false);
            return;
        }
    
        const formData = new FormData();

        if (file && file.changed) {
            formData.append('file', file.file);
        }
        
        // if subject has been changed, send the subject.value
        if (subject && subject.value) {
            formData.append('subject', subject.value);
        }
        else {
            formData.append('subject', subject);
        }
        formData.append('isSection', isSection ? isSection.value : '');
    
        if (isSection.value === "With Section") {
            formData.append('sections', JSON.stringify(sections)); // Send sections as JSON string
        } else {
            formData.append('sample', JSON.stringify(sample)); // Send sample as JSON string
        }
    
        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/tests/${testId}/editTest`, formData, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            setSuccessAlertMessage(response.data.message);
            setSuccessAlertOpen(true);
            handleBackdropClose();
            setTestUpdated(true);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
            handleBackdropClose();
        });
    };

    const handleDragEnd = async (result) => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const currentSections = Array.from(sections);
        const [removed] = currentSections.splice(source.index, 1);
        currentSections.splice(destination.index, 0, removed);

        const reorderedSections = currentSections.map((section, index) => ({
            ...section,
            section_number: index + 1
        }));

        setSections(reorderedSections);
    };

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Edit Test');
    }, []);

    const tests = getTestCategory();

    return (
        <Box m="20px" marginX={3} marginY={3}>
            <Header title="Edit Test" subtitle="A ONE Institute" />
            <Box 
                sx={{ 
                    flexBasis: "50%", 
                    display: "flex", 
                    flexDirection: "column", 
                    gap: "10px", 
                    typography: 'subtitle2',
                    mb: 5
                }}
            >
                <Typography variant="h3" fontWeight="bold">Instructions:</Typography>
                <Typography>1. You would see the structure of the test you selected when it was created.</Typography>
                <Typography>2. You can change the answer, add a section, delete a section, add a question, delete a question, change the file, and modify the category freely.</Typography>
                <Typography>3. If you click the '+' button next to the question number, it will create a new question right next to the current question.</Typography>
                <Typography>4. If you click the '-' button next to the question number, it will remove the selected question.</Typography>
                <Typography>5. If you edit a test with sections, you can also change the order of the sections by dragging and dropping.</Typography>
                <Typography>6. Make sure to click 'Update the Test' at the bottom when you are done; otherwise, your changes will not be saved.</Typography>  
            </Box>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        padding={4}
                        boxShadow={3}
                        borderRadius={3}
                        m={1}
                        bgcolor={colors.primary[600]}
                    >
                        <Typography
                            variant="h4"
                            fontWeight="bold"
                            mb={3}
                        >
                            Test Information
                        </Typography>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <Box
                                display="flex"
                                sx={{ gridColumn: "span 4" }}
                            >
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h6" fontWeight="bold" color={colors.primary[450]}>
                                        Upload Files
                                    </Typography>
                                    <IconButton component="label">
                                        <FileUploadOutlined />
                                        <input
                                            multiple={false}
                                            style={{display:"none"}}
                                            type="file"
                                            hidden
                                            onChange={handleUpload}
                                            name="[licenseFile]"
                                        />
                                    </IconButton>
                                </Box>
                                {file && (
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="body1" color={colors.primary[450]}>
                                                {file.name}
                                            </Typography>
                                            {/* <a href={file.url} target="_blank" rel="noopener noreferrer">View File</a> */}
                                            <IconButton onClick={(e) => handleFileRemove(e)}>
                                                <ClearIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                )}
                                {/* <Box display="flex" alignItems="center" sx={{ml: 2}}>
                                    <Typography variant="h6" fontWeight="bold" color={colors.primary[450]}>
                                        Upload Files (for teacher)
                                    </Typography>
                                    <IconButton component="label">
                                        <FileUploadOutlined />
                                        <input
                                            multiple={false}
                                            styles={{display:"none"}}
                                            type="file"
                                            hidden
                                            onChange={handleTeacherFileUpload}
                                            name="[licenseFile]"
                                        />
                                    </IconButton>
                                </Box>
                                {fileForTeacher && (
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="body1" color={colors.primary[450]}>
                                                {fileForTeacher.name}
                                            </Typography>
                                            <IconButton onClick={(e) => handleTeacherFileRemove(e)}>
                                                <ClearIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                )} */}
                            </Box>                            
                            <Autocomplete
                                id="free-solo-demo"
                                options={tests}
                                sx={{ gridColumn: "span 1" }}
                                value={subject ? subject : null} // Set the default value
                                renderInput={(params) => <TextField {...params} label="Subject" />}
                                isOptionEqualToValue={(option, value) => {
                                    return option.value === value.value;
                                }}
                                onChange={(event, newValue) => {
                                    if (newValue !== null && newValue !== undefined) {
                                        setSubject(newValue);                                                                                
                                    } else {
                                        setSubject(null);
                                        setIsSection(null);
                                        setSections([]); // Reset sections when subject changes
                                        setFile(null); // Reset file when subject changes
                                    }
                                }}
                            />
                            {subject !== null && subject.section !== undefined && (
                                <Autocomplete
                                    id="free-solo-demo"
                                    options={subject.section}
                                    sx={{ gridColumn: "span 1" }}
                                    value={isSection.value ? isSection.value : null} // Set the default value
                                    renderInput={(params) => <TextField {...params} label="Section" />}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.value === value.value;
                                    }}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null && newValue !== undefined) {
                                            setIsSection(newValue);
                                        } else {
                                            setIsSection(null);
                                        }
                                    }}
                                />
                            )}
                            {subject !== null && subject.value === 'ACT' && subject.section !== null && isSection?.value === 'With Section' && (
                                <Autocomplete
                                    id="free-solo-demo"
                                    options={subject.section[0].numChoices}
                                    sx={{ gridColumn: "span 1" }}
                                    renderInput={(params) => <TextField {...params} label="Number of Choices For the Section" />}
                                    isOptionEqualToValue={(option, value) => {
                                        return option?.value === value?.value;
                                    }}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null && newValue !== undefined) {
                                            setNumChoices(newValue.value);
                                        } else {
                                            setNumChoices(null);
                                        }
                                    }}
                                />
                            )}
                            {(isSection && isSection.value) && (
                                <Box sx={{ gridColumn: "span 4", display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <TextField
                                        sx={{ width: "80%"}}
                                        variant="filled"
                                        type="number"
                                        label="Number of Questions"
                                        onBlur={handleBlur}
                                        onChange={(e) => setNumQuestions(e.target.value)}
                                        InputProps={{
                                            inputProps: { min: 0 }
                                        }}
                                        value={numQuestions}
                                        name="numQuestions"
                                        error={!!touched.questions && !!errors.questions}
                                        helperText={touched.questions && errors.questions}
                                    />
                                    <TextField
                                        sx={{ width: "80%"}}
                                        variant="filled"
                                        type="number"
                                        label="Number of Choices"
                                        onBlur={handleBlur}
                                        onChange={(e) => setNumChoices(e.target.value)}
                                        InputProps={{
                                            inputProps: { min: 0 }
                                        }}
                                        value={numChoices}
                                        name="numChoices"
                                        error={!!touched.choices && !!errors.choices}
                                        helperText={touched.choices && errors.choices}
                                        disabled={isSection?.value === "With Section" && subject?.value === "ACT"}
                                    />
                            
                            {isSection.value === "With Section" ? (
                                <Button
                                    sx={{ width: "80%" }}
                                    color="secondary"
                                    variant="contained"
                                    onClick={handleCreateSection}
                                >
                                    Create Section
                                </Button>
                            ) : (
                                sample.length === 0 ? (
                                    <Button
                                        sx={{ width: "80%" }}
                                        color="secondary"
                                        variant="contained"
                                        onClick={handleCreateSample}
                                    >
                                        Create Sample
                                    </Button>
                                ) : (
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", mt: 2, p: 2, bgcolor: colors.primary[600], borderRadius: 2, boxShadow: 3 }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h6">Sample</Typography>
                                            <ClearIcon
                                                fontSize='small'
                                                color='error'
                                                sx={{ cursor: "pointer", alignSelf: "flex-start" }}
                                                onClick={handleDeleteSample}
                                            />
                                        </Box>
                                        <ul>
                                            {sample.map((question, qIndex) => (
                                                <li key={qIndex}>
                                                    <Box sx={{ display: "flex"}}>
                                                        <Typography variant="body1">Q{question.question_number}</Typography>
                                                        <AddIcon
                                                            fontSize='small'
                                                            color='primary'
                                                            sx={{ cursor: "pointer", ml: 1, mr: 1 }}
                                                            onClick={() => handleAddQuestionForNoSection(qIndex, question.question_options)}
                                                        />
                                                        <RemoveIcon
                                                            fontSize='small'
                                                            color='primary'
                                                            sx={{ cursor: "pointer" }}
                                                            onClick={() => handleDeleteQuestionForNoSection(qIndex)}
                                                        />
                                                    </Box>
                                                    <ul>
                                                        <RadioGroup
                                                            row
                                                            value={question.answer ? question.answer : ""}
                                                            onChange={(e) => handleSampleAnswerChange(qIndex, e.target.value)}
                                                            sx={{mb: 2}}
                                                        >
                                                            {question?.question_options?.map((choice, cIndex) => (
                                                                <FormControlLabel key={cIndex} value={choice.option} control={<Radio />} label={choice.option} />
                                                            ))}
                                                        </RadioGroup>
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                    </Box>
                                )
                            )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                    
                    {!(subject !== null && subject.value === 'ACT' && subject.section !== null && isSection?.value === 'With Section') && numQuestions && numChoices && (<> 
                        <Box
                            sx={{
                                position: 'fixed',
                                top: '15%', 
                                right: !previewCardToggle ? 40 : -200,
                                transform: 'translateY(-50%)',
                                zIndex: 101,
                                transition: 'right 0.5s',
                                minWidth: 400,
                                maxWidth: 550,
                            }}
                        >
                            <IconButton
                                onClick={() => setPreviewCardToggle(!previewCardToggle)}
                            >
                                {previewCardToggle ?
                                <h4>Show Preview</h4> : <h4>Hide</h4>
                                }
                                {previewCardToggle ?
                                <KeyboardDoubleArrowLeftIcon fontSize='large' /> :
                                <KeyboardDoubleArrowRightIcon fontSize='large' />}
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                position: 'fixed',
                                top: '42%',
                                right: !previewCardToggle ? 40 : -350,
                                transform: 'translateY(-50%)',
                                backgroundColor: colors.primary[600],
                                border: `5px solid ${colors.grey[800]}`,
                                boxShadow: 10,
                                zIndex: 100,
                                padding: 2,
                                transition: 'right 0.5s',
                                height: "50%",
                                width: 400,
                                overflowX: 'hidden',
                                overflowY: 'auto',
                            }}
                        >
                            <QuestionPreviewForTest numChoices={numChoices} setOptionName={setOptionName} optionName={optionName}/>
                        </Box>
                    </>)}
                 
                <Box
                    sx={{ 
                        display: "flex", 
                        flexDirection: "row", 
                        gap: "10px", 
                        mt: 2, 
                        p: 2, 
                        bgcolor: colors.primary[600], 
                        borderRadius: 2, 
                        boxShadow: 3 
                    }}
                >
                    <FormControl>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="sections" direction="horizontal" type="SECTION">
                                {(provided, snapshot) => (
                                    <Box
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        sx={{
                                            display: "flex", 
                                            // flexDirection: "row",
                                            flexWrap: "wrap", 
                                            gap: 2,
                                        }}
                                    >
                                        {isSection?.value === "With Section" && sections && sections.length > 0 && sections.map((section, sectionIndex) => (
                                            <Draggable key={sectionIndex} draggableId={`section-${sectionIndex}`} index={sectionIndex}>
                                                {(provided, snapshot) => (
                                                    <Box 
                                                        key={sectionIndex} 
                                                        sx={{ 
                                                            minWidth: "400px", 
                                                            maxWidth: "400px",
                                                            flex: '1 0 21%', // This ensures four items per row
                                                            // flex: 1, 
                                                            display: "flex", 
                                                            flexDirection: "column", 
                                                            justifyContent: "space-between", 
                                                            alignItems: "flex-start", 
                                                            p: 2, 
                                                            bgcolor: colors.primary[700], 
                                                            borderRadius: 2, 
                                                            width: "100%", 
                                                            boxShadow: 3,
                                                            mb: 2
                                                        }}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                            <Box>                                                                
                                                                <TextField 
                                                                    label="Section Name"
                                                                    variant="outlined"
                                                                    value={section.section_name ? section.section_name : ``}
                                                                    onChange={(e) => handleSectionNameChange(e, sectionIndex)}
                                                                />
                                                                <Typography variant="body1">{section.numQuestions} questions</Typography>
                                                            </Box>                                                            
                                                            <ClearIcon
                                                                fontSize='small'
                                                                color='error'
                                                                sx={{ cursor: "pointer" }}
                                                                onClick={() => handleClickDialogOpen(sectionIndex)}
                                                            /> 
                                                        </Box>
                                                        <Box sx={{height: "100%"}}>
                                                            <ul>
                                                                {section?.test_questions?.map((question, qIndex) => (
                                                                    <li key={qIndex}>
                                                                        <Box sx={{ display: "flex", flexDirection: "column"}}>
                                                                            <Typography variant="body1">Q{question.question_number}</Typography>
                                                                            <Box>
                                                                                <AddIcon
                                                                                    fontSize='small'
                                                                                    color='primary'
                                                                                    sx={{ cursor: "pointer", mr: 1 }}
                                                                                    onClick={() => handleAddQuestion(sectionIndex, qIndex, question.question_options)}
                                                                                />
                                                                                <RemoveIcon
                                                                                    fontSize='small'
                                                                                    color='primary'
                                                                                    sx={{ cursor: "pointer" }}
                                                                                    onClick={() => handleDeleteQuestion(sectionIndex, qIndex)}
                                                                                    />
                                                                            </Box>
                                                                        </Box>
                                                                        <ul>
                                                                            <RadioGroup
                                                                                row
                                                                                value={question.answer}
                                                                                defaultValue={question.answer}
                                                                                onChange={(e) => handleSectionAnswerChange(sectionIndex, qIndex, e.target.value)}
                                                                                sx={{mb: 2}}
                                                                            >
                                                                                {question.question_options?.map((choice, cIndex) => (
                                                                                    <FormControlLabel key={cIndex} value={choice.option} control={<Radio />} label={choice.option} />
                                                                                ))}
                                                                            </RadioGroup>
                                                                        </ul>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </FormControl>
                    
                    <Dialog
                        open={dialogOpen}
                        onClose={handleDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Do you want to delete this section?"}
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleDeleteSection} color="warning" autoFocus>
                                Yes
                            </Button>
                            <Button onClick={handleDialogClose}>No</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
                    
                    <Box display="flex" justifyContent="start" mt="20px">
                        <Button type="submit" color="secondary" variant="contained">
                            Update Test
                        </Button>
                    </Box>
                </form>
                )}
            </Formik>
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
            <Backdrop backdropOpen={backdropOpen} handleBackdropClose={handleBackdropClose} />
            <HiddenPDF documentRender={documentRender} /> 
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    questions: yup.number().required("required"),
    choices: yup.number().required("required"),
});

const initialValues = {
    questions: 0,
    choices: 0,
};

export default EditTest;
