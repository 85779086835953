import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import Axios from 'axios';


export default function AdminActionAccordion({ 
    courseId, 
    courseIntro, 
    setSuccessAlertOpen,
    setErrorAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertMessage,
    setBackdropOpen,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleSubmit = (event) => {
        event.preventDefault();
        setBackdropOpen(true);
        const data = new FormData(event.currentTarget);

        if (courseIntro) {
            Axios.put(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/course-intros`, {
                title: data.get('title'),
                content: data.get('content'),
                author: data.get('author'),
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                console.log(response.data);
                setSuccessAlertMessage("Class Intro Updated Successfully!");
                setSuccessAlertOpen(true);
                window.location.reload();
            }).catch((error) => {
                console.log(error.response.data.message);
                setErrorAlertMessage(error.response.data.message);
                setErrorAlertOpen(true);
                setBackdropOpen(false);
            });
        } else {
            Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/course-intros`, {
                title: data.get('title'),
                content: data.get('content'),
                author: data.get('author'),
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setSuccessAlertMessage("Class Intro Created Successfully!");
                setSuccessAlertOpen(true);
                window.location.reload();
            }).catch((error) => {
                console.log(error.response.data.message);
                setErrorAlertMessage(error.response.data.message);
                setErrorAlertOpen(true);
                setBackdropOpen(false);
            });
        }

    };

    return (
        <div style={{
            width: "100%",
            marginBottom: "1rem",
        }}>
            <Accordion
                style={{
                    backgroundColor: colors.primary[750],
                }}
                component="div"
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {courseIntro 
                        ? <Typography variant='h6'>Update the Class Intro</Typography>
                        : <Typography variant='h6'>Create a Class Intro</Typography>
                    }
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                            display: "flex",
                            flexDirection: "column",
                            mr: "1rem",
                        }}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1rem",
                            minWidth: "100%",
                        }}>
                            <TextField
                                id="filled-basic"
                                label="Title"
                                variant="filled"
                                name='title'
                                sx={{
                                    flex: "1",
                                    maxWidth: "50%",
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[600],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                                defaultValue={courseIntro ? courseIntro.title : null}
                            />
                            <TextField
                                id="filled-basic"
                                label="Author"
                                variant="filled"
                                name='author'
                                sx={{
                                    flex: "1",
                                    maxWidth: "50%",
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[600],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                                defaultValue={courseIntro ? courseIntro.author : null}
                            />
                        </Box>
                        <TextField
                            id="filled-basic"
                            label="Content"
                            variant="filled"
                            name='content'
                            fullWidth={true}
                            multiline={true}
                            rows={20}
                            sx={{
                                minWidth: "100%",
                                borderRadius: "5px",
                                '& .MuiFilledInput-root': {
                                    backgroundColor: colors.primary[600],
                                    ":focus": {
                                        backgroundColor: 'white',
                                    },
                                },
                            }}
                            defaultValue={courseIntro ? courseIntro.content : null}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            style={{
                                backgroundColor: colors.primary[300],
                                color: "white",
                                float: "right",
                                marginTop: "0.5rem",
                                marginBottom: "1rem",
                                width: "10rem",
                            }}
                        >
                            {courseIntro ? "Update" : "Create"}
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}