import React from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import EditNoteSharpIcon from '@mui/icons-material/EditNoteSharp';
import VideocamIcon from '@mui/icons-material/Videocam';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import ViewModuleOutlinedIcon from '@mui/icons-material/ViewModuleOutlined';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardUser, faSchoolFlag } from '@fortawesome/free-solid-svg-icons';
// import jwt_decode from 'jwt-decode';
import { history } from '../utils/History';
import dayjs from 'dayjs';
import getCookies from "../utils/getCookies";

const Item = ({ title, to, icon, selected, setSelected, courseId }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const link = courseId !== null ? '/classrooms/' + courseId + to : to;

    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[700],
            }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            {title === 'Questions & School Material Upload' ? (<>
                    <Typography>Questions &</Typography>
                    <Typography>School Material Upload</Typography>
            </>) : (
                <Typography>{title}</Typography>
            )}
            <Link to={link} />
        </MenuItem>
    );
};

const ClassRoomSidebar = ({ courseData }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = React.useState(false);
    const [selected, setSelected] = React.useState(localStorage.getItem('selectedMenu') || 'Home');
    const [courseId, setCourseId] = React.useState(null);
    const currentUser = getCookies("_auth_state");
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const [courseEndDate, setCourseEndDate] = React.useState(null);

    React.useEffect(() => {
        setCourseId(window.location.pathname.split('/')[2]);
    }, [courseId]);

    React.useEffect(() => {
        let courseEndDate = null;
        if (courseData !== null) {
            courseData.course_semesters.map((course_semester) => {
                if (courseEndDate === null || dayjs(courseEndDate).isBefore(course_semester.semester.end))
                    courseEndDate = course_semester.semester.end;
            });
            setCourseEndDate(courseEndDate);
        }
    }, [courseData]);

    // for selected menu
    React.useEffect(() => {
        async function selectedMenu() {
            const selectedMenu = window.location.pathname.split('/')[3];

            if (selectedMenu === '') {
                setSelected('Home');
            } else if (selectedMenu === 'class-materials' || selectedMenu === 'class-logs') {
                const split1 = selectedMenu.slice(1).split('-')[0];
                const split2 = selectedMenu.slice(1).split('-')[1];
                setSelected(selectedMenu.charAt(0).toUpperCase() + split1 + ' ' + split2.charAt(0).toUpperCase() + split2.slice(1));
            } else if (selectedMenu === 'zoom-recordings') {
                setSelected('Zoom Recordings');
            } else if (selectedMenu === 'questions') {
                setSelected('Questions & School Material Upload');
            } else if (selectedMenu === 'test') {
                setSelected('Tests');
            } else {
                setSelected(selectedMenu.charAt(0).toUpperCase() + selectedMenu.slice(1));
            }
        }

        selectedMenu();

        const listenBackEvent = () => {
            selectedMenu();
        };
    
        const unlistenHistoryEvent = history.listen(({ action }) => {
            if (action === "POP") {
                listenBackEvent();
            }
        });

        return unlistenHistoryEvent;
        }, [selected]);
    

    return (
        <Box
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[400]} !important`,
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 20px !important",
                },
                "& .pro-inner-item:hover": {
                    color: "#F5F3F3 !important",
                },
                "& .pro-menu-item.active": {
                    color: "#F5F3F3 !important",
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    {/* LOGO AND MENU ICON */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                        margin: "10px 0 20px 0",
                        color: colors.primary[700],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                ml="15px"
                            >
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)} style={{ color: colors.primary[700] }}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                                <Typography variant="h3" color={colors.primary[700]}>
                                    A ONE
                                </Typography>
                            </Box>
                        )}
                    </MenuItem>

                    {!isCollapsed && (
                        <Box mb="25px">
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <img
                                    alt="profile-user"
                                    width="100px"
                                    height="100px"
                                    src={'/imgs/profile-img-3.png'}
                                    style={{ cursor: "pointer", borderRadius: "50%" }}
                                />
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                    variant="h3"
                                    color={colors.primary[700]}
                                    fontWeight="bold"
                                    sx={{ m: "10px 0 0 0" }}
                                >
                                    {localStorage.getItem("userName")}
                                </Typography>
                                <Typography variant="h5" color={colors.greenAccent[500]}>
                                    {localStorage.getItem("who").charAt(0).toUpperCase() + localStorage.getItem("who").slice(1)}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                        {!isCollapsed ? (
                            <Typography
                                variant="h6"
                                color={colors.greenAccent[500]}
                                sx={{ m: "15px 0 5px 20px" }}
                            >
                                A ONE
                            </Typography>
                        ) : (
                            <Box mt="20px" />
                        )}
                        <Item
                            title="Classes"
                            to="/classrooms/classes"
                            icon={<FontAwesomeIcon icon={faSchoolFlag} size="lg" />}
                            selected={selected}
                            setSelected={setSelected}
                            courseId={null}
                        />
                        {!isCollapsed ? (
                            <Typography
                                variant="h6"
                                color={colors.greenAccent[500]}
                                sx={{ m: "15px 0 5px 20px" }}
                            >
                                Classroom
                            </Typography>
                        ) : (
                            <Box mt="20px" />
                        )}
                        <Item
                            title="Home"
                            to="/home"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            courseId={courseId}
                        />
                        <Item
                            title="Announcements"
                            to="/announcements"
                            icon={<AnnouncementOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            courseId={courseId}
                        />
                        { userRole !== 'student' &&
                            <Item
                                title="People"
                                to="/people"
                                icon={<PeopleOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                courseId={courseId}
                            />
                        }
                        { (userRole === 'admin' || (userRole !== 'admin' && dayjs(courseEndDate).add(14, 'day').isAfter(dayjs()))) && 
                        <>
                            <Item
                                title="Modules"
                                to="/modules"
                                icon={<ViewModuleOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                courseId={courseId}
                            />
                            <Item
                                title="Class Materials"
                                to="/class-materials"
                                icon={<StickyNote2OutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                courseId={courseId}
                            />
                            <Item
                                title="Homework"
                                to="/homework"
                                icon={<FontAwesomeIcon icon={faChalkboardUser} />}
                                selected={selected}
                                setSelected={setSelected}
                                courseId={courseId}
                            />
                            <Item
                                title="Tests"
                                to="/tests"
                                icon={<HistoryEduIcon fontSize="medium" />}
                                selected={selected}
                                setSelected={setSelected}
                                courseId={courseId}
                            />
                            {userRole !== 'teacher' &&
                                <Item
                                    title="Zoom Recordings"
                                    to="/zoom-recordings"
                                    icon={<VideocamIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                    courseId={courseId}
                                />
                            }
                            <Item
                                title={
                                    "Questions & School Material Upload"
                                }
                                to="/questions"
                                icon={<HelpOutlineIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                courseId={courseId}
                            />
                            { userRole !== 'student' &&
                                <>
                                {!isCollapsed ? (
                                    <Typography
                                        variant="h6"
                                        color={colors.greenAccent[500]}
                                        sx={{ m: "15px 0 5px 20px" }}
                                    >
                                        Teacher
                                    </Typography>
                                ) : (
                                    <Box mt="20px" />
                                )}
                                <Item
                                    title="Class Logs"
                                    to="/class-logs"
                                    icon={<EditNoteSharpIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                    courseId={courseId}
                                /></>
                            }
                        </>}
                        { userRole === 'admin' &&
                            <>
                            {!isCollapsed ? (
                                <Typography
                                    variant="h6"
                                    color={colors.greenAccent[500]}
                                    sx={{ m: "15px 0 5px 20px" }}
                                >
                                    Admin
                                </Typography>
                            ) : (
                                <Box mt="20px" />
                            )}
                            <Item
                                title="Dashboard"
                                to="/"
                                icon={<ExitToAppIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                courseId={null} 
                            /></>
                        }
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default ClassRoomSidebar;