import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTheme, Box, Tooltip } from "@mui/material";
import { tokens } from "../../theme";
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import PersonOffIcon from '@mui/icons-material/PersonOff';

export default function ConsultationCardReadOnly({ student }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [scroll, setScroll] = React.useState(0);

    // more and add memo button toggle
    const [moreMemos, setMoreMemos] = React.useState(false);

    const [modifiedMemo, setModifiedMemo] = React.useState(new Map());

    function memoMap(memos) {
        const memoMap = new Map();
        if (!memos) return memoMap;
        memos?.forEach(memo => {
            memoMap.set(memo.id, memo);
        });

        return memoMap;
    };
    
    // function reverseMemos(memos) {
    //     const reversedMemos = [];
    //     if (!memos) return reversedMemos;
    //     memos.forEach(memo => {
    //         reversedMemos.unshift(memo);
    //     });

    //     return reversedMemos;
    // };

    React.useEffect(() => {
        const consultationCard = document.getElementById("consultation-card");
        const consultationNumber = document.getElementById("consultation-number");
        const numberBlock = document.getElementById("number-block");

        if (scroll > consultationCard?.offsetTop + consultationNumber?.offsetTop + consultationNumber?.clientHeight) {
            numberBlock.style.display = "flex";
            numberBlock.style.zIndex = 3;
        }
        else if (scroll < consultationCard?.offsetTop + consultationNumber?.offsetTop + consultationNumber?.clientHeight) {
            numberBlock.style.display = "none";
            numberBlock.style.zIndex = -1;
        }

    }, [scroll]);

    React.useEffect(() => {
        setModifiedMemo(student.memos ? memoMap(student.memos) : new Map());
    }, [student]);

    return (
        <Card
            id="consultation-card"
            sx={{
                position: "relative",
                minWidth: "100%",
                minHeight: "100%",
                maxHeight: "100%",
                backgroundColor: colors.primary[600],
                border: `1px solid ${colors.primary[600]}`,
                overflowX: 'hidden',
                overflowY: 'auto',
            }}
            onScroll={(e) => {
                setScroll(e.target.scrollTop);
            }}
            key={student.id}
        >
            <CardContent sx={{ paddingX: 0 }}>
                <Box
                    id="number-block"
                    sx={{
                        position: "sticky",
                        width: "100%",
                        height: "40px",
                        top: "0",
                        backgroundColor: colors.primary[100],
                        color: "white",
                        display: "none",
                        zIndex: "-1",
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 0.5, 
                        mb: 2 ,
                        paddingX: '10px',
                    }}                
                >
                    <Typography variant="h3">
                        {student.consultation_number ?? "N/A"}
                    </Typography>                
                </Box>

                <Box 
                    id="consultation-number"
                    sx={{ display: "flex", justifyContent: "space-between", paddingX: '10px' }}>
                    <Box 
                        sx={{ 
                            flex: 1,
                            display: "flex", 
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 0.5, 
                            mb: 2 
                        }}
                    >
                        <Typography variant="h3">
                            {student.consultation_number ?? "N/A"}
                        </Typography>
                    </Box>
                    {student.is_potential_student && student.info_mail && !student.info_mail.is_sent 
                        && <>
                        <CancelScheduleSendIcon fontSize='small' color='error' sx={{ cursor: "pointer" }} />
                    </>}
                    <Tooltip title="Blacklist" sx={{ marginLeft: 2 }}>
                        { student.is_blacklist_student ? <PersonOffIcon/> : <></> }
                    </Tooltip>
                </Box>
                <br />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {/* Consultation Date */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: '100%',
                            minHeight: 50,
                            borderTop: `1px solid ${colors.grey[800]}`,
                            borderBottom: `1px solid ${colors.grey[800]}`,
                            textAlign: "center",
                        }}
                    >
                        <Typography 
                            variant="h6" 
                            color={student.consultation_date ? "primary" : colors.grey[700]}
                            sx={{ 
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%", 
                                flex: 1, 
                            }}
                        >
                            {student.consultation_date !== "" ? student.consultation_date : "N/A"}
                        </Typography>
                    </Box>
                    {/* if they will register */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: '100%',
                            minHeight: 50,
                            borderBottom: `1px solid ${colors.grey[800]}`,
                            textAlign: "center",
                        }}
                    >
                    </Box>
                    {/* Date email sent */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: '100%',
                            minHeight: 70,
                            borderBottom: `1px solid ${colors.grey[800]}`,
                            textAlign: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography 
                            variant="h6" 
                            color={student.info_mail?.date ? "primary" : colors.grey[700]}
                            sx={{ 
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%", 
                                flex: 1, 
                            }}
                        >
                            {student.info_mail !== undefined && student.info_mail !== null && student.info_mail.date !== null  && student.info_mail.date !== ""
                                    ? dayjs(student.info_mail.date).format("YYYY-MM-DD") : "N/A"
                            }
                        </Typography>
                    </Box>
                    {/* Parent Name */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: '100%',
                            minHeight: 50,
                            borderBottom: `1px solid ${colors.grey[800]}`,
                            textAlign: "center",
                        }}
                    >
                        <Typography 
                            variant="h6" 
                            color={student.parent_name ? "primary" : colors.grey[700]}
                            sx={{ 
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%", 
                                flex: 1, 
                            }}
                        >
                            {student.parent_name !== "" ? student.parent_name : "N/A"}
                        </Typography>
                    </Box>
                    {/* Parent Phone */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: '100%',
                            minHeight: 50,
                            borderBottom: `1px solid ${colors.grey[800]}`,
                            textAlign: "center",
                        }}
                    >
                        <Typography 
                            variant="h6" 
                            color={student.parent_phone ? "primary" : colors.grey[700]}
                            sx={{ 
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%", 
                                flex: 1, 
                            }}
                        >
                            {student.parent_phone !== "" ? student.parent_phone : "N/A"}
                        </Typography>
                    </Box>
                    {/* Parent Email */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: '100%',
                            minHeight: 50,
                            borderBottom: `1px solid ${colors.grey[800]}`,
                            textAlign: "center",
                        }}
                    >
                        <Typography 
                            variant="h6" 
                            color={student.parent_email ? "primary" : colors.grey[700]}
                            sx={{ 
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%", 
                                flex: 1, 
                            }}
                        >
                            {student.parent_email !== "" ? student.parent_email : "N/A"}
                        </Typography>
                    </Box>
                    {/* Consultation Subjects (It is Memos of the potential student data) */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: '100%',
                            minHeight: '100%',
                            textAlign: "center",
                            padding: "0 20px",
                        }}
                    >
                        {student.memos?.sort(
                                (a, b) => {
                                    if (a.order === null && b.order === null) {
                                        return a.createdAt > b.createdAt ? -1 : 1;
                                    }
                                    if (a.order === null) {
                                        return -1;
                                    }
                                    if (b.order === null) {
                                        return 1;
                                    }
                                    return a.order < b.order ? -1 : 1;
                                }
                        ).map((memo, index) => {
                            if (memo === undefined || memo === null) return;

                            if (index === 0) {
                                return (
                                    <Box
                                        key={memo.id}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 1,
                                            width: '100%',
                                            minHeight: "400px",
                                            textAlign: "center",
                                            padding: "20px 0 40px 0",
                                        }}
                                    >
                                        <Box 
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 1,
                                                width: '100%',
                                                textAlign: "center",
                                            }}
                                        >
                                            <Typography variant="h6" color="primary" sx={{padding: "10px 0"}}>
                                                {modifiedMemo.get(memo.id)?.title} {modifiedMemo.get(memo.id)?.date ? `(${modifiedMemo.get(memo.id)?.date})` : ""}
                                            </Typography>
                                        </Box>
                                        <Box 
                                            sx={{
                                                flex: 1,
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 1,
                                                width: '100%',
                                                height: "100%",
                                                textAlign: "center",
                                            }}
                                        >
                                            {modifiedMemo.get(memo.id)?.content?.split('\n').map((line, index) => {
                                                return (
                                                    <Typography key={index}>
                                                        {line}
                                                    </Typography>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                );
                            }

                            return (
                                moreMemos &&
                                <Box
                                    key={memo.id}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 1,
                                        width: '100%',
                                        minHeight: "400px",
                                        borderTop: `1px solid ${colors.grey[800]}`,
                                        textAlign: "center",
                                        padding: "20px 0",
                                        marginBottom: index === student.memos.length - 1 ? "20px" : "0",
                                    }}
                                >
                                    <Box 
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 1,
                                            width: '100%',
                                            textAlign: "center",
                                        }}
                                    >
                                        <Typography variant="h6" color="primary" sx={{padding: "10px 0"}}>
                                            {modifiedMemo.get(memo.id)?.title} {modifiedMemo.get(memo.id)?.date ? `(${modifiedMemo.get(memo.id)?.date})` : ""}
                                        </Typography>
                                    </Box>
                                    <Box 
                                        sx={{
                                            flex: 1,
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 1,
                                            width: '100%',
                                            height: "100%",
                                            textAlign: "center",
                                        }}
                                    >
                                        {modifiedMemo.get(memo.id)?.content?.split('\n').map((line, index) => {
                                            return (
                                                <Typography key={index}>
                                                    {line}
                                                </Typography>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            );
                        })}
                        <Box
                            sx={{
                                position: "relative",
                                bottom: 0,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                display: "flex",
                                flexDirection: "row",
                                gap: 1,
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {student.memos && student.memos?.length > 1 && 
                                <Button
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "20px",
                                        textAlign: "center",
                                        padding: "20px",
                                        marginBottom: "10px",
                                        backgroundColor: colors.greenAccent[500],
                                    }}
                                    onClick={() => setMoreMemos(!moreMemos)}
                                >
                                    <Typography variant="h6" color={colors.primary[400]}>
                                        {moreMemos ? "Less" : "More"}
                                    </Typography>
                                </Button>
                            }
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}