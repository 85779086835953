import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Header from '../../../components/Header';
import { tokens } from '../../../theme';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Axios from 'axios';
import Alert from '../../../components/alert/Alert';
import Backdrop from '@mui/material/Backdrop';
import getCookies from '../../../utils/getCookies';
import Loading from '../../../components/Loading';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const TestResult = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const currentUser = getCookies("_auth_state");
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const matches = useMediaQuery('(min-width:1500px)');
    const matches2 = useMediaQuery('(min-width:1200px)');
    
    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const courseData = useOutletContext().courseData;
    const [loading, setLoading] = React.useState(true);
    const [submissions, setSubmissions] = React.useState(null);
    const [studentNames, setStudentNames] = React.useState({});

    const [testInfo, setTestInfo] = React.useState(null);
    const [fullPoints, setFullPoints] = React.useState(0);

    const courseId = window.location.pathname.split('/')[2];
    const testId = window.location.pathname.split('/')[4];

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: colors.primary[300],
            color: theme.palette.common.white,
            fontSize: 14,
            fontWeight: "bold",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 13,
        },
        border: '1px solid rgba(224, 224, 224, 1)',
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: colors.primary[750],
        },
        '&:nth-of-type(even)': {
            backgroundColor: colors.primary[750],
        },
    }));

    React.useEffect(() => {

        async function fetchSubmissions() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/tests/${testId}/all-student-answers`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                const data = response.data;
                setSubmissions(data);

                const studentInfo = {};

                data.forEach((submission) => {
                    studentInfo[submission.student_id] = submission.student_name;
                });

                setStudentNames(studentInfo);
                setLoading(false);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                console.log(error.response.data.message);
            });
        }

        async function fetchTestInfo() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/tests/${testId}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {        
                setTestInfo(response.data);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                console.log(error.response.data.message);
            });            
        }

        fetchSubmissions();
        fetchTestInfo();
    }, []);

    React.useEffect(() => {
        if (testInfo && testInfo.test_sections) {
            let maxScore = 0;
            testInfo.test_sections.forEach(section => {
                maxScore += section.test_questions.length;
            });
            setFullPoints(maxScore);
        }
        else if (testInfo && !testInfo.test_sections && testInfo.test_questions) {
            setFullPoints(testInfo.test_questions.length);
        }
    }, [testInfo]);


    const parseStudentAnswers = (studentAnswers) => {
        if (testInfo && testInfo.test_sections) {
            return studentAnswers.split('@').map(section => section.split('|'));
        } else if (testInfo && !testInfo.test_sections && testInfo.test_questions) {
            return studentAnswers.split('|');
        }
    };

    const calculateSectionScore = (sectionIndex, studentAnswers) => {
        let score = 0;    
        if (testInfo.test_sections) {
            testInfo.test_sections[sectionIndex].test_questions.forEach((question, qIndex) => {
                if (question.answer === studentAnswers[sectionIndex][qIndex]) 
                    score++;
            });
        } else {
            testInfo.test_questions.forEach((question, qIndex) => {
                if (question.answer === studentAnswers[qIndex]) {
                    score++;
                }
            });
        };
        return score;
    };   
    
    return (
        <Box m="20px" marginX={3} marginY={3} minHeight="90%">
            {loading || courseData === null || submissions === null ? (
                <Loading />
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        px: "18%",
                        ...!matches && {
                            px: "15%",
                        },
                        ...!matches2 && {
                            px: "4%",
                        },
                    }}
                >
                    <Header
                        title={
                            courseData.code !== null
                                ? courseData.code + ". " + courseData.course_title
                                : courseData.course_title
                        }
                        subtitle="A ONE Institute"
                    />
                    <Box
                        minWidth="20rem"
                        width="100%"
                        minHeight="80vh"
                        borderRadius="3px"
                        padding={3}
                        boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                        backgroundColor={colors.primary[600]}
                        display="flex"
                        flexDirection="column"
                        alignItems="left"
                        gap={3}
                        mb={3}
                    >
                        <Typography variant="h3" color={colors.primary[450]} px={2} pb={2} borderBottom="0.1px solid" mb={2}>
                            Test Results
                        </Typography>
                        {submissions && Object.keys(submissions).length > 0 ? (
                            Object.keys(studentNames).map((studentId) => (
                                <TableContainer key={studentId} component={Paper} sx={{ maxWidth: "100%" }}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell style={{ width: "20%", textAlign: "center" }}>Name</StyledTableCell>
                                                <StyledTableCell style={{ width: "20%", textAlign: "center" }}>Total Score</StyledTableCell>
                                                {testInfo && testInfo.test_sections && (
                                                    <StyledTableCell style={{ width: "20%", textAlign: "center" }}>Section Scores</StyledTableCell>
                                                )}
                                                <StyledTableCell style={{ width: "20%", textAlign: "center" }}>Result</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {submissions.filter(submission => submission.student_id === parseInt(studentId)).map((submission, i) => {
                                                const studentAnswers = parseStudentAnswers(submission.student_answers);
                                                const isTestComplete = submission.score !== null && submission.score !== undefined;

                                                return (
                                                    <StyledTableRow key={i}>
                                                        <StyledTableCell style={{ width: "20%", fontSize: "20px", textAlign: "center" }}>
                                                            {studentNames[submission.student_id]}
                                                        </StyledTableCell>
                                                        {isTestComplete ? (
                                                            <>
                                                                <StyledTableCell style={{ width: "20%", fontSize: "40px", textAlign: "center" }}>
                                                                    {submission.score} / {fullPoints}
                                                                </StyledTableCell>
                                                                {testInfo && testInfo.test_sections && (
                                                                    <StyledTableCell style={{ width: "20%" }}>
                                                                        {testInfo.test_sections.map((section, sectionIndex) => (
                                                                            <Typography key={sectionIndex} variant="body1" sx={{ mb: 2, fontSize: "18px", textAlign: "center" }}>
                                                                                Section {section.section_number}: <span style={{ marginLeft: 5 }}> {calculateSectionScore(sectionIndex, studentAnswers)} / {section.test_questions.length} </span>
                                                                            </Typography>
                                                                        ))}
                                                                    </StyledTableCell>
                                                                )}
                                                                <StyledTableCell style={{ width: "20%", fontSize: "20px", textAlign: "center" }}>
                                                                    <Link to={`/classrooms/${courseId}/test/${testId}/student/${submission.student_uuid}/submission`} style={{ textDecoration: 'none' }}>
                                                                        <Button
                                                                            variant="contained"
                                                                            sx={{
                                                                                backgroundColor: colors.blueAccent[600],
                                                                                color: 'white',
                                                                                padding: '10px 20px',
                                                                                borderRadius: '5px',
                                                                                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                                                                                ':hover': {
                                                                                    backgroundColor: colors.blueAccent[700],
                                                                                },
                                                                            }}
                                                                        >
                                                                            See Result
                                                                        </Button>
                                                                    </Link>
                                                                </StyledTableCell>
                                                            </>
                                                        ) : (
                                                            <StyledTableCell colSpan={3} style={{ width: "60%", fontSize: "40px", textAlign: "center" }}>
                                                                The student is still taking the test.
                                                            </StyledTableCell>
                                                        )}
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ))
                        ) : (
                            <Typography variant="h4" color={colors.primary[450]} sx={{ mx: 'auto', mt: '10rem' }} >
                                No submissions yet!
                            </Typography>
                        )}
                    </Box>
                </Box>
            )}
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
            <Backdrop
                sx={{ color: colors.primary[400], zIndex: 2147483646 }}
                open={backdropOpen}
                onClick={handleBackdropClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default TestResult;
