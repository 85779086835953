import React from 'react'
import { tokens } from "../../../theme";
import { Box, useTheme, Typography } from "@mui/material";
import AdminActionAccordion from './AdminActionAccordion';
import Header from "../../../components/Header";
import { useOutletContext } from "react-router-dom";
import Loading from '../../../components/Loading';
// import jwt_decode from 'jwt-decode';
import Alert from '../../../components/alert/Alert';
import BackdropComp from '../../../components/Backdrop';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import getCookies from '../../../utils/getCookies';

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const ClassRoomHome = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = React.useState(true);
    const courseData = useOutletContext().courseData;
    const [courseId, setCourseId] = React.useState(null);
    const [courseIntro, setCourseIntro] = React.useState(null);
    const currentUser = getCookies("_auth_state");
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const matches = useMediaQuery('(min-width:1500px)');
    const matches2 = useMediaQuery('(min-width:1200px)');

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Home');
        setCourseId(window.location.pathname.split('/')[2]);
        if (courseData !== null)
            setCourseIntro(courseData.course_intros[0]);
        setLoading(false);
    }, [courseData]);

    return (
        <Box m="20px" marginX={3} marginY={3}>
            { loading || courseData === null || courseIntro === null ? 
                <Loading />
                : <>
                    <Box 
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            px: "25%",
                            ...!matches && {
                                px: "15%",
                            },
                            ...!matches2 && {
                                px: "10%",
                            },
                        }}
                    >
                        <Header 
                            title={
                                courseData.code !== null 
                                ? courseData.code + ". " + courseData.course_title 
                                : courseData.course_title
                            } 
                            subtitle="A ONE Institute"
                        />
                        {/* For Admin */}
                        {userRole === "admin" && 
                            <Box
                                minWidth="20rem"
                                width="100%"
                                minHeight="10rem"
                                borderRadius="3px"
                                padding={3}
                                boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                                backgroundColor={colors.primary[600]}
                                display="flex"
                                flexDirection="column"
                                alignItems="left"
                                gap={3}
                                mb={3}
                            >
                                <Typography variant="h4" color={colors.primary[450]}>
                                    Admin Actions
                                </Typography>
                                <AdminActionAccordion 
                                    courseId={courseId} 
                                    courseIntro={courseIntro} 
                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                    setErrorAlertOpen={setErrorAlertOpen}
                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                    setErrorAlertMessage={setErrorAlertMessage}
                                    setBackdropOpen={setBackdropOpen}
                                />
                            </Box>
                        }

                        {/* For Students and teachers */}
                        <Box
                            minWidth="20rem"
                            width="100%"
                            minHeight="32rem"
                            borderRadius="3px"
                            padding={3}
                            boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                            backgroundColor={colors.primary[600]}
                            // display="flex"
                            // flexDirection="column"
                            alignItems="center"
                            gap={3}
                        >
                            {!courseIntro ? (
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    minWidth: "20rem",
                                    minHeight: "32rem",
                                    gap: "0.4rem",
                                }}>
                                    <InfoOutlinedIcon sx={{ my: "auto" }} />
                                    <Typography variant="h4" color={colors.primary[450]} my="auto">
                                        No class introduction yet!
                                    </Typography>
                                </Box>
                            ) : (
                                <>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="left"
                                        m="20px"
                                    >
                                        <Typography variant="h4" color={colors.primary[450]}>
                                            {courseIntro.author}
                                        </Typography>
                                        <Typography variant="h6" color={colors.primary[450]}>
                                            {
                                                months[new Date(courseIntro.createdAt.split('T')[0]).getMonth()] 
                                                + " " + new Date(courseIntro.createdAt.split('T')[0]).getDate() 
                                                + ", " + new Date(courseIntro.createdAt.split('T')[0]).getFullYear()
                                            }
                                        </Typography>
                                    </Box>
                                    <Typography
                                        m="20px"
                                        variant="h4"
                                        color={colors.primary[450]}
                                        fontWeight="bold"
                                    >
                                        {courseIntro.title}
                                    </Typography>
                                    <Typography
                                        m="20px"
                                        variant="h6"
                                        color={colors.primary[450]}
                                    >
                                        {courseIntro.content.split('\n').map((item, key) => {
                                            return <span key={key}>{item}<br /></span>
                                        })}
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </Box>
                    <Alert
                        successAlertOpen={successAlertOpen}
                        setSuccessAlertOpen={setSuccessAlertOpen}
                        errorAlertOpen={errorAlertOpen}
                        setErrorAlertOpen={setErrorAlertOpen}
                        successMsg={successAlertMessage}
                        errorMsg={errorAlertMessage}
                        vertical="bottom"
                        horizontal="left"
                    />
                    <BackdropComp backdropOpen={backdropOpen} handleBackdropClose={handleBackdropClose} />
                </>
            }
        </Box>
    )
}

export default ClassRoomHome;