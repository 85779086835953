import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Axios from 'axios';

const ScoreModal = ({ 
    submission,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
    setBackdropOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 310,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [score, setScore] = React.useState("");
    const [maxScore, setMaxScore] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleScoreSubmit = async (event) => {
        event.preventDefault();

        if (score === "") {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please enter a score.");
            return;
        }

        if (maxScore === "") {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please enter a maximum score.");
            return;
        }

        if (score.includes(".") || score.includes("/") || score.includes("-") || score.includes("+") || score.includes("*") || score.includes("(") || score.includes(")")) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please enter a valid score. (no decimals)");
            return;
        }

        if (maxScore.includes(".") || maxScore.includes("/") || maxScore.includes("-") || maxScore.includes("+") || maxScore.includes("*") || maxScore.includes("(") || maxScore.includes(")")) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please enter a valid score. (no decimals)");
            return;
        }

        let scoreInt = null;
        let maxScoreInt = null;
        try {
            scoreInt = parseInt(score);
            maxScoreInt = parseInt(maxScore);
        } catch (error) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please enter a valid score.");
            return;
        }

        if (!Number.isInteger(scoreInt) || !Number.isInteger(maxScoreInt)) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please enter a valid score.");
            return;
        }

        if (scoreInt < 0 || maxScoreInt < 0) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please enter a valid score.");
            return;
        }
        else if (scoreInt > maxScoreInt) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("The score cannot be greater than the maximum score.");
            return;
        }

        setBackdropOpen(true);

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/courses/${window.location.pathname.split('/')[2]}/homeworks/${window.location.pathname.split('/')[4]}/submissions/${submission.id}/score`, {
            score,
            maxScore,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            }
        }).then((response) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message);
            setBackdropOpen(false);
            handleClose();
            window.location.reload();
        }).catch((error) => {
            console.log(error.reponse.data.messagse);
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.message);
            setBackdropOpen(false);
        });
    };

    return (
        <div>
            <Button
                type="submit"
                variant="contained"
                style={{
                    backgroundColor: colors.primary[300],
                    color: "white",
                    width: "4rem",
                    marginTop: "0.5rem",
                    fontSize: "0.4rem",
                }}
                size='small'
                onClick={handleOpen}
            >
                Set Score
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={2}
                            component="form"
                            onSubmit={(e) => handleScoreSubmit(e)}
                        >
                            <TextField
                                id="filled-basic"
                                label="The score"
                                variant="filled"
                                sx={{
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[750],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                                onChange={(e) => setScore(e.target.value)}
                            />
                            <TextField
                                id="filled-basic"
                                label="The maximum score"
                                variant="filled"
                                sx={{
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[750],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                                onChange={(e) => setMaxScore(e.target.value)}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                style={{
                                    backgroundColor: colors.primary[300],
                                    color: "white",
                                    marginTop: "0.5rem",
                                    width: "15rem",
                                }}
                            >
                                Set Scores
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default ScoreModal