import * as React from 'react';
import { tokens } from "../../../theme";
import { Button, useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';

const ClassLogsConfirmModal = ({ 
    open, 
    onClose,
    onSubmit,   
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1300,
        height: 500,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
    };
    
    return (
        <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
            >
            <Fade in={open}>
                <Box sx={style}>
                    <Box mt={2} display="flex" flexDirection="column" justifyContent="space-between" >
                        <Typography id="modal-modal-title" variant="h1" ml={1} borderBottom={1} color={"red"}>
                            Please check again to ensure you have done the following correctly:
                        </Typography>
                        <Typography id="modal-modal-description" variant="h2" mt={1} mb={1} ml={1} color={"black"}>
                            <ul>
                                <li>Selected the correct class module</li>
                                <li>Filled in the progress box with the name of the chapter and page numbers</li>
                                <li>Provided student evaluations for each student</li>
                            </ul>
                            Additionally, please pay particular attention to providing accurate page numbers, as they are crucial for tracking progress effectively.
                        </Typography>
                    </Box>
                    <Box mt={2} display="flex" justifyContent="space-between" width="100%">
                        <Button
                            type='submit'
                            onClick={onSubmit}
                            variant="contained"
                            style={{
                                backgroundColor: "green",
                                color: "white",
                                width: "45%",
                            }}
                        >
                            Confirm (Create Class Log)
                        </Button>
                        <Button 
                            variant="contained" 
                            color="error" 
                            onClick={onClose}
                            style={{width: "45%",}}
                        >
                            Go Back
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

export default ClassLogsConfirmModal;