import React from 'react'
import Chip from '@mui/material/Chip';
import TeacherCardModal from './TeacherCardModal';
import Axios from 'axios';

const TeacherChip = ({ teacher, setInfoUpdated }) => {
    const [openModal, setModalOpen] = React.useState(false);
    const [teacherInfo, setTeacherInfo] = React.useState(null);

    const handleModalOpen = async () => {
        Axios.get(`${process.env.REACT_APP_URL}/api/v1/teachers/${teacher.uuid}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then(res => {
            setTeacherInfo(res.data);
            setModalOpen(true);
        }).catch(err => {
            console.log(err);
        })
    };
    
    return (<>
        <Chip
            label={teacher.first_name + " " + teacher.last_name.substring(0, 1) + "."}
            onClick={handleModalOpen}
            sx={{ m: 1 }}
        />
        <TeacherCardModal openModal={openModal} setModalOpen={setModalOpen} teacher={teacherInfo} setInfoUpdated={setInfoUpdated} />
    </>)
}

export default TeacherChip