import React from 'react'
import { tokens } from "../../theme";
import { Box, useTheme, Typography, Button, IconButton } from "@mui/material";
import Loading from '../../components/Loading';
import Header from "../../components/Header";
import Axios from 'axios';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import Alert from '../../components/alert/Alert';
import dayjs from 'dayjs';
import CancelIcon from '@mui/icons-material/Cancel';

const EmailDrafts = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    const [loading, setLoading] = React.useState(true);

    const [emailDrafts, setEmailDrafts] = React.useState(null);

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Email Drafts');

        async function fetchInfoEmailDrafts() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/info-email-drafts`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                setEmailDrafts(res.data);
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        handleLogout();
                    } else {
                        setErrorAlertMessage("Error occurs while fetching email drafts!");
                        setErrorAlertOpen(true);
                    }
                } else {
                    setErrorAlertMessage("Error occurs while fetching email drafts!");
                    setErrorAlertOpen(true);
                }
            });
        }
        
        fetchInfoEmailDrafts();
        setLoading(false);
    }, []);

    function getStudentInfo(student) {
        let consultation_num = "";
        let parent_name = "";
        let student_name = "";

        if (student === null || student === undefined) {
            return "No student";
        }

        if (student.consultation_number !== null) {
            consultation_num = student.consultation_number;
        }
        if (student.parent_name !== null) {
            parent_name = student.parent_name;
        }
        if (student.first_name !== null) {
            student_name = student.first_name;
        }
        if (student.last_name !== null) {
            student_name += " " + student.last_name;
        }

        let returnString = "";
        if (consultation_num !== "") {
            returnString += "(" + consultation_num + ") ";
        }
        if (parent_name !== "") {
            returnString += parent_name;
        } else if (student_name !== "") {
            returnString += student_name;
        }

        return returnString;
    }

    async function deleteDraft(draft_id) {
        if (!window.confirm("Are you sure you want to delete this draft?")) {
            return;
        }

        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/info-email-drafts/${draft_id}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((res) => {
            setSuccessAlertMessage("Draft deleted successfully!");
            setSuccessAlertOpen(true);
            setTimeout(() => {
                window.location.reload();
            }, 300);
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    handleLogout();
                } else {
                    setErrorAlertMessage("Error occurs while deleting the draft!");
                    setErrorAlertOpen(true);
                }
            } else {
                setErrorAlertMessage("Error occurs while deleting the draft!");
                setErrorAlertOpen(true);
            }
        });
    }

    return (
        <Box 
            m="20px" 
            marginX={3} 
            marginY={3} 
            sx={{
                minHeight: "88%",
                position: 'relative',
            }}
        >
            <Header title="Email Drafts" subtitle="A ONE Institute" />
            { loading || !emailDrafts ? 
                <Loading />
            :
                <Box 
                    display="flex" 
                    flexDirection="column" 
                    justifyContent="left" 
                    gap={2} 
                    p={0} 
                    minHeight={70} 
                    mb={2}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            border: `1px solid ${colors.blueAccent[600]}`,
                            backgroundColor: colors.primary[600],
                            width: "100%",
                            minHeight: "80vh",
                        }}
                    >
                        <Box 
                            display="flex" 
                            flexDirection="row" 
                            sx={{
                                backgroundColor: colors.blueAccent[600],
                                padding: "20px",
                            }}
                        >
                            <Typography variant="h5" flex={1}>
                                Student
                            </Typography>
                            <Typography variant="h5" flex={1}>
                                Saved By
                            </Typography>
                            <Typography variant="h5" flex={1}>
                                Selected Course Titles
                            </Typography>
                            <Typography variant="h5" flex={1}>
                                Created At
                            </Typography>
                            <Typography variant="h5" flex={1}>
                                Updated At
                            </Typography>
                            <Typography variant="h5" minWidth={"150px"} maxWidth={"150px"}>

                            </Typography>
                            <Typography variant="h5" minWidth={"50px"} maxWidth={"50px"}>
                                Delete
                            </Typography>
                        </Box>
                        {emailDrafts.map((draft, index) => {
                            return (
                                <Box 
                                    key={index} 
                                    display="flex" 
                                    flexDirection="row" 
                                    sx={{
                                        padding: "20px",
                                        alignItems: "center",
                                        borderBottom: index !== emailDrafts.length - 1 ? `1px solid ${colors.blueAccent[600]}` : "none",
                                    }}
                                >
                                    <Typography variant="body1" flex={1}>
                                        {getStudentInfo(draft.student) ?? "N/A"}
                                    </Typography>
                                    <Typography variant="body1" flex={1}>
                                        {draft.email_author ?? "N/A"}
                                    </Typography>
                                    <Typography variant="body1" flex={1}>
                                        {draft.course_titles.join(", ")}
                                    </Typography>
                                    <Typography variant="body1" flex={1}>
                                        {dayjs(draft.createdAt).format("YYYY-MM-DD HH:mm") ?? "N/A"}
                                    </Typography>
                                    <Typography variant="body1" flex={1}>
                                        {dayjs(draft.updatedAt).format("YYYY-MM-DD HH:mm") ?? "N/A"}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            minWidth: "150px",
                                            maxWidth: "150px",
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                window.location.href = `/admin/consultationProgress/${draft.student_uuid}/emailGenerator?draft=${draft.id}`;
                                            }}
                                        >
                                            Email Generator
                                        </Button>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            minWidth: "50px",
                                            maxWidth: "50px",
                                        }}
                                    >
                                        <IconButton
                                            color="error"
                                            onClick={() => {deleteDraft(draft.id)}}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            }
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical='bottom'
                horizontal='left'
            />
        </Box>
    )
}

export default EmailDrafts;