import * as React from "react";
import { Box, Button, IconButton, Typography, TextField } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime, Settings } from 'luxon';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Tooltip from '@mui/material/Tooltip';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Autocomplete from '@mui/material/Autocomplete';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

Settings.defaultZone = "system";

const ScheduleConfirmModal = ({ 
    student_course_id,
    potentialCourse_uuid,
    student_uuid,
    private_potential_schedule,
    handlePrivateScheduleConfirm,
    from_combined_schedule = false,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 600,
        height: 500,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
    };    

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // for time schedule in each day of class session
    const [fromValue, setFromValue] = React.useState(private_potential_schedule === null ? DateTime.now() : DateTime.fromISO(from_combined_schedule ? private_potential_schedule.start_time_iso : private_potential_schedule.start_time));
    const [toValue, setToValue] = React.useState(private_potential_schedule === null ? DateTime.now() : DateTime.fromISO(from_combined_schedule ? private_potential_schedule.end_time_iso : private_potential_schedule.end_time));

    // for days of week for couese schedule
    const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    // const daysValue = {'Monday' : 1, 'Tuesday' : 2, 'Wednesday' : 3, 'Thursday' : 4, 'Friday' : 5, 'Saturday' : 6}
    const [days, setDays] = React.useState(private_potential_schedule === null ? [] : private_potential_schedule.days.split('|'));

    // for teacher name
    const [teacherName, setTeacherName] = React.useState(private_potential_schedule === null ? '' : private_potential_schedule.teacher_name);

    return (<>
        {private_potential_schedule === null ? (
            <Tooltip title="Set Schedule" placement="top" arrow>
                <IconButton 
                    classes={{ root: `confirm-student-${student_course_id}` }}
                    aria-label="Set Schedule" 
                    color='gray'
                    sx={{ 
                        '&:hover': {
                            color: colors.redAccent[400],
                        }
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOpen();
                    }}
                >
                    <EventAvailableIcon fontSize='small' />
                </IconButton>
            </Tooltip>
        ) : (
            <Tooltip title="Edit Schedule" placement="top" arrow>
                <IconButton 
                    classes={{ root: `confirm-student-${student_course_id}` }}
                    aria-label="Edit Schedule" 
                    color='gray'
                    sx={{ 
                        '&:hover': {
                            color: colors.redAccent[400],
                        }
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOpen();
                    }}
                >
                    <EditCalendarIcon fontSize='small' />
                </IconButton>
            </Tooltip>
        )}
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '1.5rem' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                            <Typography variant="h5" sx={{ fontWeight: 700 }}>
                                Please fill out the following information to set schedule
                            </Typography>
                            {/* <Typography variant="h6" sx={{ fontWeight: 500, color: colors.grey[600] }}>

                            </Typography> */}
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <TimePicker
                                label="From"
                                value={fromValue}
                                timezone="America/New_York"
                                onChange={(newValue) => setFromValue(newValue)}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TimePicker
                                label="To"
                                value={toValue}
                                timezone="America/New_York"
                                onChange={(newValue) => setToValue(newValue)}
                                sx={{ gridColumn: "span 2" }}
                            />
                        </LocalizationProvider>
                        <Autocomplete
                            multiple
                            id="tags-outlined-1"
                            options={daysOfWeek}
                            getOptionLabel={(day) => day}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Days (required)"
                                    placeholder="Select days"
                                />
                            )}
                            sx={{ gridColumn: "span 3" }}
                            onChange={(event, value) => setDays(value)}
                            value={days}
                        />
                        <TextField
                            id="teacher"
                            name="teacher"
                            label="Teacher name (optional)"
                            variant="filled"
                            value={teacherName}
                            onChange={(e) => setTeacherName(e.target.value)}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: 'auto',
                            }}
                        >
                            {private_potential_schedule === null ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        width: 100,
                                    }}
                                    onClick={(e) => handlePrivateScheduleConfirm(e, potentialCourse_uuid, student_uuid, fromValue, toValue, days, null, teacherName)}
                                >
                                    Confirm
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        width: 100,
                                    }}
                                    onClick={(e) => handlePrivateScheduleConfirm(e, potentialCourse_uuid, student_uuid, fromValue, toValue, days, null, teacherName, private_potential_schedule.id)}
                                >
                                    Update
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                color="error"
                                sx={{
                                    width: 100,
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                        {private_potential_schedule !== null && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '1rem',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    marginTop: 'auto',
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    color="error"
                                    sx={{
                                        width: 220,
                                    }}
                                    onClick={(e) => handlePrivateScheduleConfirm(e, potentialCourse_uuid, student_uuid, fromValue, toValue, days, null, teacherName, private_potential_schedule.id, true)}
                                >
                                    Delete This Information
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Fade>
        </Modal>
    </>);
};

export default ScheduleConfirmModal