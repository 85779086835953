import React from 'react'
import Popover from '@mui/material/Popover';
import AddTeacherModal from './AddTeacherModal';
import AddStudentModal from './AddStudentModal';
import MoreVertIcon from '../../../components/MoreVertIcon';
import RemoveStudentModal from './RemoveStudentModal';
import RemoveTeacherModal from './RemoveTeacherModal';
import UpdateCourseModal from './UpdateCourseModal';
import UpdateStudentModal from './UpdateStudentModal';
import GoToClassRoomButton from './GoToClassRoomButton';

/*
 * for each course, there is a vert icon 
 */
const EachCourseVertIcon = ({
    semestersForCreateCourseModal,
    course,
    setInfoUpdated, 
    setBackdropOpen,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMoreVertIconClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    return (
        <><MoreVertIcon handleClick={handleMoreVertIconClick} />
        <Popover
            id={popoverId}
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopOverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <GoToClassRoomButton course={course} />
            <UpdateCourseModal
                semestersForCreateCourseModal={semestersForCreateCourseModal}
                setAnchorEl={setAnchorEl}
                course={course}
                setInfoUpdated={setInfoUpdated}
                setBackdropOpen={setBackdropOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setSuccessAlertMessage={setSuccessAlertMessage}
                setErrorAlertOpen={setErrorAlertOpen}
                setErrorAlertMessage={setErrorAlertMessage}
            />
            <AddTeacherModal 
                setAnchorEl={setAnchorEl} 
                course={course} 
                setInfoUpdated={setInfoUpdated}
                setBackdropOpen={setBackdropOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setSuccessAlertMessage={setSuccessAlertMessage}
                setErrorAlertOpen={setErrorAlertOpen}
                setErrorAlertMessage={setErrorAlertMessage}
            />
            <AddStudentModal 
                setAnchorEl={setAnchorEl} 
                course={course} 
                setInfoUpdated={setInfoUpdated}
                setBackdropOpen={setBackdropOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setSuccessAlertMessage={setSuccessAlertMessage}
                setErrorAlertOpen={setErrorAlertOpen}
                setErrorAlertMessage={setErrorAlertMessage}
            />
            <UpdateStudentModal
                setAnchorEl={setAnchorEl}
                course={course}
                setInfoUpdated={setInfoUpdated}
                setBackdropOpen={setBackdropOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setSuccessAlertMessage={setSuccessAlertMessage}
                setErrorAlertOpen={setErrorAlertOpen}
                setErrorAlertMessage={setErrorAlertMessage}
            />
            <RemoveTeacherModal
                setAnchorEl={setAnchorEl}
                course={course}
                setInfoUpdated={setInfoUpdated}
                setBackdropOpen={setBackdropOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setSuccessAlertMessage={setSuccessAlertMessage}
                setErrorAlertOpen={setErrorAlertOpen}
                setErrorAlertMessage={setErrorAlertMessage}
            />
            <RemoveStudentModal
                setAnchorEl={setAnchorEl}
                course={course}
                setInfoUpdated={setInfoUpdated}
                setBackdropOpen={setBackdropOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setSuccessAlertMessage={setSuccessAlertMessage}
                setErrorAlertOpen={setErrorAlertOpen}
                setErrorAlertMessage={setErrorAlertMessage}
            />
        </Popover></>
    )
}

export default EachCourseVertIcon;