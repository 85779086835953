import React from 'react'
import { tokens } from "../../../theme";
import { Box, useTheme, Typography } from "@mui/material";
import AdminActionAccordion from './AdminActionAccordion';
import Header from "../../../components/Header";
import { useOutletContext } from "react-router-dom";
import Loading from '../../../components/Loading';
// import jwt_decode from 'jwt-decode';
import Alert from '../../../components/alert/Alert';
import BackdropComp from '../../../components/Backdrop';
import AnnouncementBox from './AnnouncementBox';
import Axios from 'axios';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import getCookies from '../../../utils/getCookies';


const Announcements = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const matches = useMediaQuery('(min-width:1500px)');
    const matches2 = useMediaQuery('(min-width:1200px)');

    const [loading, setLoading] = React.useState(true);
    const courseData = useOutletContext().courseData;
    const [courseId, setCourseId] = React.useState(null);
    const currentUser = getCookies("_auth_state");
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const [announcements, setAnnouncements] = React.useState(null);
    const [announcementsUpdated, setAnnouncementsUpdated] = React.useState(false);

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Announcements');
        setCourseId(window.location.pathname.split('/')[2]);
        setLoading(false);
    }, [courseData]);

    React.useEffect(() => {
        if (courseId === null)
            return;

        async function fetchAnnouncements() {
            setBackdropOpen(true);
            Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/announcements`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                setAnnouncements(res.data);
                setBackdropOpen(false);
            }).catch((err) => {
                if (err.response !== null && err.response.status === 401) {
                    handleLogout();
                    return;
                }
                console.log(err);
                setErrorAlertMessage(err.response.data.message);
                setErrorAlertOpen(true);
                setBackdropOpen(false);
            });
        }

        fetchAnnouncements();
        setAnnouncementsUpdated(false);
    }, [announcementsUpdated, courseId]);

    

    return (
        <Box m="20px" marginX={3} marginY={3}>
        { loading || courseData === null ? 
            <Loading />
            : <>
                <Box 
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        px: "25%",
                        ...!matches && {
                            px: "15%",
                        },
                        ...!matches2 && {
                            px: "10%",
                        },
                }}
                >
                    <Header 
                        title={
                            courseData.code !== null 
                            ? courseData.code + ". " + courseData.course_title 
                            : courseData.course_title
                        } 
                        subtitle="A ONE Institute"
                    />
                    {/* For Admin */}
                    {userRole === "admin" && 
                        <Box
                            minWidth="20rem"
                            width="100%"
                            minHeight="10rem"
                            borderRadius="3px"
                            padding={3}
                            boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                            backgroundColor={colors.primary[600]}
                            display="flex"
                            flexDirection="column"
                            alignItems="left"
                            gap={3}
                            mb={3}
                        >
                            <Typography variant="h4" color={colors.primary[450]}>
                                Admin Actions
                            </Typography>
                            <AdminActionAccordion 
                                courseId={courseId} 
                                setAnnouncementsUpdated={setAnnouncementsUpdated}
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                setErrorAlertOpen={setErrorAlertOpen}
                                setSuccessAlertMessage={setSuccessAlertMessage}
                                setErrorAlertMessage={setErrorAlertMessage}
                                setBackdropOpen={setBackdropOpen}
                            />
                        </Box>
                    }

                    {/* Announcements */}
                    <Box
                        minWidth="20rem"
                        width="100%"
                        minHeight="32rem"
                        borderRadius="3px"
                        padding={8}
                        boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                        backgroundColor={colors.primary[600]}
                        alignItems="center"
                    >
                        <Typography variant="h3" color={colors.primary[450]} borderBottom="0.1px solid" mb={2}>
                            Announcements
                        </Typography>
                        {announcements === null || announcements.length === 0 ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    my: "10rem",
                                    gap: "0.4rem",
                                }}
                            >
                                <InfoOutlinedIcon sx={{ my: 'auto' }} />
                                <Typography variant="h4" color={colors.primary[450]} my="auto">
                                    No announcements yet!
                                </Typography>
                            </Box>
                        ) : (
                            <>
                            {announcements.map((announcement, index) => { 
                                return (
                                    <AnnouncementBox
                                        key={index}
                                        courseId={courseId}
                                        index={announcements.length - index}
                                        userRole={userRole}
                                        announcement={announcement}
                                        setAnnouncementsUpdated={setAnnouncementsUpdated}
                                        setBackdropOpen={setBackdropOpen}
                                        setSuccessAlertOpen={setSuccessAlertOpen}
                                        setErrorAlertOpen={setErrorAlertOpen}
                                        setSuccessAlertMessage={setSuccessAlertMessage}
                                        setErrorAlertMessage={setErrorAlertMessage}
                                    />
                                )
                            })}</>
                        )}
                    </Box>
                </Box>
                <Alert
                    successAlertOpen={successAlertOpen}
                    setSuccessAlertOpen={setSuccessAlertOpen}
                    errorAlertOpen={errorAlertOpen}
                    setErrorAlertOpen={setErrorAlertOpen}
                    successMsg={successAlertMessage}
                    errorMsg={errorAlertMessage}
                    vertical="bottom"
                    horizontal="left"
                />
                <BackdropComp backdropOpen={backdropOpen} handleBackdropClose={handleBackdropClose} />
            </>
        }
        </Box>
    )
}

export default Announcements