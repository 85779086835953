import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTheme, Box, FormControl, TextField } from "@mui/material";
import { tokens } from "../../theme";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function QuestionPreviewForTest({ numChoices, setOptionName, optionName }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleOptionNameChange = (index, newName) => {
        const updatedOptions = [...optionName];
        updatedOptions[index] = newName;
        setOptionName(updatedOptions);
    };

    return (
        <Card
            sx={{
                position: "relative",
                minWidth: "100%",
                minHeight: "100%",
                backgroundColor: colors.primary[600],
                border: `1px solid ${colors.primary[600]}`,
            }}
        >
            <CardContent
                sx={{
                    paddingX: 0,
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", paddingX: '10px' }}>
                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 0.5,                        
                        }}
                    >
                        <Typography variant="h4">
                            {"A Question Preview"}
                        </Typography>
                    </Box>
                </Box>
                <br/>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", ml: 3, mr: 3}}>
                        {Array.from({ length: numChoices }).map((_, index) => (
                            <Box key={index} sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                <Typography>{index + 1}.</Typography>
                                <TextField
                                    variant="filled"
                                    placeholder='Option Name'
                                    value={optionName[index] || ''}
                                    onChange={(e) => handleOptionNameChange(index, e.target.value)}
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                        ))}
                    </Box>             
                <br/>
                <FormControl>
                    --------------------------------------------------------------------------------
                    <Typography sx={{mt: 2, ml: 2}} variant="h5">A sample answer form</Typography>
                    <br/>
                    <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        sx={{ display: "flex", gap: "10px", ml: 3 }}
                    >
                        {optionName.slice(0, numChoices).map((option, index) => (
                            <FormControlLabel
                                key={index}
                                value={option}
                                control={<Radio />}
                                label={option}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </CardContent>
        </Card>
    );
}
