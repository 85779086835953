import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { motion } from "framer-motion";
import Button from '@mui/material/Button';
import { tokens } from "../../theme";
import dayjs from 'dayjs';
import { useTheme } from "@mui/material";

export default function CourseCard({course}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const seasons = {1: 'Spring', 2: 'Summer', 3: 'Fall', 4: 'Winter'};
    // const teacher = course.teacher_id 
    //     ? "Instructor: "
    //     : "No teacher assigned";

    const handleOpen = () => {
        window.open("/admin/courses/" + course.uuid, "_self");
    };

    const sortSemesters = [...course.course_semesters].sort((a, b) => {
        return dayjs(a.semester.start) - dayjs(b.semester.start);
    });


    return (
        <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            layout
        >
            <Card
                sx={{
                    minWidth: 200,
                    backgroundColor: colors.primary[600],
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                    border: `1px solid ${colors.primary[600]}`,
                    ":hover": {
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                        border: "1px solid grey",
                    }
                }}
                key={course.id}
            >
                <CardContent>
                    <Typography variant="h5" component="div" mt={1}>
                        {course.code !== null && course.course_title.length > 20
                            ? course.code + ". " + course.course_title.substring(0, 20) + "..."
                        : course.code !== null && course.course_title.length <= 20
                            ? course.code + ". " + course.course_title
                        : course.code === null && course.course_title.length > 20
                            ? course.course_title.substring(0, 20) + "..."
                            : course.course_title
                        }
                    </Typography>
                    <Typography variant="h7" component="div">
                        {course.course_semesters.length === 1 
                            ? course.course_semesters[0].semester.year + " " + seasons[course.course_semesters[0].semester.season] 
                            : sortSemesters[0].semester.year + " " + seasons[sortSemesters[0].semester.season]
                                + " - " + sortSemesters[course.course_semesters.length - 1].semester.year 
                                + " " + seasons[sortSemesters[course.course_semesters.length - 1].semester.season]
                        }
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {course.subject}
                    </Typography>
                    <Typography variant="body1">
                        
                    </Typography>
                    {course.start_date !== null && course.end_date !== null ? (
                    <Typography variant="body2">
                        <br />
                        Start date: {dayjs(course.start_date).format('YYYY-MM-DD')} <br />
                        End date: {dayjs(course.end_date).format('YYYY-MM-DD')}
                    </Typography>
                    ): (
                        <Typography variant="body2">
                            <br />
                            Start date: {dayjs(sortSemesters[0].semester.start).format('YYYY-MM-DD')} <br />
                            End date: {dayjs(sortSemesters[course.course_semesters.length - 1].semester.end).format('YYYY-MM-DD')}
                        </Typography>
                    )}
                </CardContent>
                <CardActions>
                    <Button
                        size="small"
                        style={{
                            "backgroundColor": colors.primary[800],
                            "color": colors.primary[400]
                        }}
                        onClick={handleOpen}
                    >
                        More Info
                    </Button>
                    <Button
                        size="small"
                        style={{
                            "backgroundColor": colors.primary[800],
                            "color": colors.primary[400]
                        }}
                        onClick={() => window.open("/classrooms/" + course.uuid + "/home", "_self")}
                    >
                        Go to Classroom
                    </Button>

                </CardActions>
            </Card>
        </motion.div>
    );
}