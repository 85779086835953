import React from 'react';
import { tokens } from '../../../theme';
import { Box, Typography, useTheme, CircularProgress, Divider } from "@mui/material";
import Header from '../../../components/Header';
import Axios from 'axios';
import Loading from '../../../components/Loading';
import Alert from '../../../components/alert/Alert';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import './Viewer.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import getCookies from '../../../utils/getCookies';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import StudentAnswerConfirmModal from './StudentAnswerConfirmModal';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TestSubmitPage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const currentUser = getCookies("_auth_state");
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const testId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 2];
    const courseId = window.location.pathname.split("/")[2];
    const studentUuid = currentUser.split(',')[0]?.split(':')[1]?.split('"')[1];

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }
    const [loading, setLoading] = React.useState(true);

    const [pdfLoading, setPdfLoading] = React.useState(true);
    const [test, setTest] = React.useState(null);
    const [numPages, setNumPages] = React.useState(null);
    const [sections, setSections] = React.useState([]);

    const [testTakerInfo, setTestTakerInfo] = React.useState(null);

    // const [unansweredQuestions, setUnansweredQuestions] = React.useState([]);

    const [questionsForNoSectionTest, setQuestionsForNoSectionTest] = React.useState([]);

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");


    const [dividerPosition, setDividerPosition] = React.useState(7); // Initial flex ratio
    const rightPaneRef = React.useRef(null);

    const handleMouseDown = (e) => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        if (rightPaneRef.current) {
            const newDividerPosition = (e.clientX / window.innerWidth) * 10;
            setDividerPosition(newDividerPosition);
        }
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    const parseStudentAnswers = (studentAnswers, hasSection) => {
        if (hasSection) {
            return studentAnswers.split('@').map(section => section.split('|'));
        } else {
            return studentAnswers.split('|');
        }
    };
    
    // let parsedStudentAnswers = [];
    // if (testTakerInfo && testTakerInfo.student_answers && test) {
    //     parsedStudentAnswers = parseStudentAnswers(testTakerInfo.student_answers, test.has_section);
    // }

    // for modal
    const [modalOpen, setModalOpen] = React.useState(false);

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Tests');
    
        async function fetchTest() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/test-taking/${testId}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(async (response) => {
                setTest(response.data);
                if (response.data.test_sections) {
                    setSections(response.data.test_sections.map(section => ({
                        ...section,
                        test_questions: section.test_questions.map(question => ({
                            ...question,
                            student_pick: ''
                        }))
                    })));
                } else {
                    setQuestionsForNoSectionTest(response.data.test_questions.map(question => ({
                        ...question,
                        student_pick: ''
                    })));
                }
            }).catch((error) => {
                if (error.response.status === 404) {
                    setLoading(false);
                    setErrorAlertMessage('Test not found!');
                    setErrorAlertOpen(true);
                    return;
                } else if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message);
                setErrorAlertOpen(true);
            });
        }
        fetchTest();
        setLoading(false);
    }, []);
    

    React.useEffect(() => {
        const fetchStudentTestInfo = async (studentUuid) => {
            try {
                const response = await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/test-taking/${testId}/students/${studentUuid}/student-answers`, {
                    headers: {
                        Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    },
                });
    
                if (response && response.data) {
                    setTestTakerInfo(response.data);
                    if (response.data.student_answers) {
                        const parsedAnswers = parseStudentAnswers(response.data.student_answers, test.has_section);
                        if (test.has_section) {
                            setSections(prevSections => prevSections.map((section, sIndex) => ({
                                ...section,
                                test_questions: section.test_questions.map((question, qIndex) => ({
                                    ...question,
                                    student_pick: parsedAnswers[sIndex] && parsedAnswers[sIndex][qIndex] ? parsedAnswers[sIndex][qIndex] : ''
                                }))
                            })));
                        } else {
                            setQuestionsForNoSectionTest(prevQuestions => prevQuestions.map((question, qIndex) => ({
                                ...question,
                                student_pick: parsedAnswers[qIndex] ? parsedAnswers[qIndex] : ''
                            })));
                        }
                    }
                }
            } catch (error) {
                if (error.response.status === 401) {
                    handleLogout();
                } else if (error.response.status === 404) {
                    setTestTakerInfo(null);
                    console.log("Test not found");
                }
            }
        };
    
        if (userRole === "student" && test) {
            const studentUuid = currentUser.split(',')[0]?.split(':')[1]?.split('"')[1];
            if (studentUuid) {
                fetchStudentTestInfo(studentUuid);
            }
        }
    }, [test, userRole, currentUser, courseId, testId]);
    
    
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPdfLoading(false);
    };

    const documentRender = () => {
        return (
            <Document 
                file={test?.file_url}
                onLoadSuccess={onDocumentLoadSuccess}
                onContextMenu={(e) => e.preventDefault()}
                className="pdf-container"
            >
                {Array.from(new Array(numPages), (el, index) => (
                    <Page 
                        key={`page_${index + 1}`} 
                        pageNumber={index + 1} 
                        renderTextLayer={false} 
                        renderAnnotationLayer={false} 
                    />
                ))}
            </Document>
        );
    };

    const handleSectionAnswerChange = (sectionIndex, questionIndex, choice) => {
        const updatedSections = sections.map((section, sIndex) => {
            if (sIndex === sectionIndex) {
                const updatedQuestions = section.test_questions.map((question, qIndex) => {
                    if (qIndex === questionIndex) {
                        return { ...question, student_pick: choice };
                    }
                    return question;
                });
                return { ...section, test_questions: updatedQuestions };
            }
            return section;
        });
        setSections(updatedSections);
    };   
        
    const handleNoSectionAnswerChange = (questionIndex, choice) => {
        const updatedQuestions = questionsForNoSectionTest.map((question, qIndex) => {
            if (qIndex === questionIndex) {
                return { ...question, student_pick: choice };
            }
            return question;
        });
        setQuestionsForNoSectionTest(updatedQuestions);
    };

    const getScore = () => {
        let score = 0;
    
        if (test.has_section) {
            sections.forEach(section => {
                section.test_questions.forEach(question => {
                    if (question.answer === question.student_pick) {
                        score++;
                    }
                });
            });
        } else {
            questionsForNoSectionTest.forEach(question => {
                if (question.answer === question.student_pick) {
                    score++;
                }
            });
        }
        return score;
    };

    const formatAnswers = () => {
        let answers_text = '';
        if (test.has_section) {
            answers_text = sections.map(section => {
                return section.test_questions.map(question => question.student_pick || '').join('|');
            }).join('@');
        } else {
            answers_text = questionsForNoSectionTest.map(question => question.student_pick || '').join('|');
        }
        return answers_text;
    };
    
    const handleSaveAnswers = () => {
        if (userRole !== "student") return;
    
        const formattedAnswers = formatAnswers();
        handlePauseTest(formattedAnswers);
    };

    const handleSubmitAnswers = () => {

        if (userRole !== "student") {
            setErrorAlertMessage("Only students can submit answers!");
            setErrorAlertOpen(true);
            return;
        }

        // if (!checkAllQuestionsAnswered()) {
        //     setErrorAlertMessage("Please answer all the questions before submitting.");
        //     setErrorAlertOpen(true);
        //     return;
        // }
        
        const formattedAnswers = formatAnswers();
        const score = getScore();

        handleStudentAnswerSubmit(formattedAnswers, score);
    };

    const handlePauseTest = async (formattedAnswers) => {
        // For debugging
        // console.log("formattedAnswers: ", formattedAnswers);
        
        try {
            const response = await Axios.put(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/test-pause/${testId}/students/${studentUuid}/student-answers`, {
                student_answers: formattedAnswers,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            });
    
            if (response.status === 200) {
                setSuccessAlertMessage("Your answers saved successfully!");
                setSuccessAlertOpen(true);
            } else {
                setErrorAlertMessage("Error saving progress!");
                setErrorAlertOpen(true);
            }
        } catch (error) {
            console.error(error);
            if (error.response.status === 401) {
                handleLogout();
            } else {
                setErrorAlertMessage(error.response.data.message);
                setErrorAlertOpen(true);
            }
        }
    };
    

    const handleStudentAnswerSubmit = async (formattedAnswers, score) => {

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/test-taking/${testId}/students/${studentUuid}/student-answers`, {
            student_answers: formattedAnswers,
            score: score,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("Answer submitted successfully!");
            setSuccessAlertOpen(true);
            window.location.reload();
            // window.location.href = `/classrooms/${courseId}/tests`;
        }).catch((error) => {
            if (error.response.status === 401) {
                handleLogout();
                return;
            }
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    }
    
    return (
        <Box m="20px" marginX={3} marginY={3}>
            <Header title={test?.course_tests[0].title} subtitle="A ONE Institute" />
            {loading ? 
                <Loading />
            : <>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '20px',
                        marginBottom: '10px',
                        position: 'relative',
                        mt: 5,
                    }}
                >                 
                    {/* Open PDF */}
                    <a href={test?.file_url} target="_blank" style={{ textDecoration: 'none' }}>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                left: '0',
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center',
                                padding: '10px',
                                borderRadius: '5px',
                                backgroundColor: colors.greenAccent[500],
                                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                                color: colors.primary[400],
                            }}
                        >
                            Open Test file in PDF
                            <OpenInNewIcon fontSize='small' sx={{ cursor: 'pointer' }} />
                        </Typography>
                    </a>
                { userRole !== "student" &&
                    <Box sx={{ position: 'absolute', top: '50%', right: '0', transform: 'translateY(20%)' }}>
                        <Link to={`/classrooms/${courseId}/tests/${testId}/grading`} style={{ textDecoration: 'none' }}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: colors.blueAccent[600],
                                    color: 'white',
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                                    ':hover': {
                                        backgroundColor: colors.blueAccent[700],
                                    },
                                }}
                            >
                                See Results
                            </Button>
                        </Link>
                    </Box>
                }
                {/* 
                    1. Only students can save their progress 
                    2. If this is a student's first time taking the test, the button should be appeared
                    3. If the student has not completed (submitted) the test, the button should be appeared
                */}
                { userRole === "student" && (!testTakerInfo || (testTakerInfo && testTakerInfo.is_completed === false)) &&
                    <Box sx={{ display: "flex", transform: 'translateY(20%)' }}>
                        <Button
                            sx={{
                                backgroundColor: colors.blueAccent[600],
                                color: 'white',
                                padding: '10px 30px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                                ':hover': {
                                    backgroundColor: "#00308F",
                                },
                                mr: 1,
                            }}
                            onClick={handleSaveAnswers}
                        >
                            Save
                        </Button>
                        <Button
                            sx={{ 
                                backgroundColor: "#DDA0DD", 
                                color: 'white', 
                                padding: '10px 20px', 
                                borderRadius: '5px', 
                                cursor: 'pointer',
                                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                                ':hover': {
                                    backgroundColor: "#800080",
                                },
                            }}
                            // onClick={handleSubmitAnswers}
                            onClick={handleModalOpen}
                        >
                            Submit
                        </Button>
                        <StudentAnswerConfirmModal 
                            open={modalOpen}
                            onClose={handleModalClose}
                            onSubmit={handleSubmitAnswers}
                            courseId={courseId}
                            testId={testId}
                            studentUuid={studentUuid}
                        />
                    </Box>
                }
                </Box>
                <Box
                    display="flex"
                    width="100%"
                    bgcolor={colors.primary[600]}
                >
                    <Box flex={dividerPosition} width="100%" sx={{ overflowY: 'scroll', height: '80vh', left: 0 }}>
                        <Box
                            sx={{
                                position: "sticky",
                                top: 0,
                            }}
                        >
                            {documentRender()}
                        </Box>
                        {pdfLoading && (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            >
                                <CircularProgress color="inherit" size={50} />
                            </Box>
                        )}
                    </Box>
                    <Divider 
                        orientation="vertical" 
                        flexItem
                        onMouseDown={handleMouseDown}
                        sx={{ cursor: 'col-resize', width: '5px', bgcolor: "grey" }}
                    />
                    <Box
                        flex={10 - dividerPosition}
                        ref={rightPaneRef}  
                        width="100%" 
                        p={5}
                        display='flex'
                        flexDirection='column'
                        gap={3}
                        sx={{
                            overflowY: 'auto',
                            overscrollBehavior: 'contain',
                            height: '80vh',
                        }}
                    > 
                    {/* Wtih Section */}
                        {test ? test.has_section ? (
                            <>
                                {sections.map((section, sectionIndex) => (
                                    <Box key={sectionIndex}>
                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Box>
                                                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                                                    {section.section_name ? section.section_name : `Section ${section.section_number}`}
                                                </Typography>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                                    Number of Questions: {section.test_questions.length}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <ul>
                                            {section.test_questions.map((question, qIndex) => (
                                                <li key={qIndex}>
                                                    <Typography variant="body1">Q{question.question_number}</Typography>
                                                    <ul>
                                                    <RadioGroup
                                                        row
                                                        value={question.student_pick || ''}
                                                        onChange={(e) => handleSectionAnswerChange(sectionIndex, qIndex, e.target.value)}
                                                    >
                                                        {question.question_options.map((choice, cIndex) => (
                                                            <FormControlLabel
                                                                key={cIndex}
                                                                value={choice.option}
                                                                control={<Radio />}
                                                                label={choice.option}
                                                                disabled={userRole !== "student"}
                                                            />
                                                        ))}
                                                    </RadioGroup>
                                                    </ul>
                                                    {userRole !== "student" && (
                                                        <Typography variant="body1" sx={{ mt: 2, mb: 2, color: "green" }}>
                                                            Correct Answer: {question.answer}
                                                        </Typography>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>                                
                                    </Box>
                                ))}                              
                            </>
                        ) : (
                        // No Section
                        <>
                            <Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box>                                    
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                            Number of Questions: {test.test_questions.length}
                                        </Typography>
                                    </Box>
                                <Box/>
                                 
                            </Box>
                                <ul>
                                    {questionsForNoSectionTest.map((question, qIndex) => (
                                        <li key={qIndex}>
                                                <Typography variant="body1">Q{question.question_number}</Typography>
                                            <ul>
                                                <RadioGroup
                                                    row
                                                    value={question.student_pick || ''}
                                                    onChange={(e) => handleNoSectionAnswerChange(qIndex, e.target.value)}
                                                >
                                                    {question.question_options.map((choice, cIndex) => (
                                                        <FormControlLabel 
                                                            key={cIndex} 
                                                            value={choice.option}
                                                            control={<Radio />} 
                                                            label={choice.option} 
                                                            disabled={userRole !== "student"}
                                                            />
                                                    ))}
                                                </RadioGroup>
                                            </ul>
                                            {userRole !== "student" && (
                                                <Typography variant="body1" sx={{ mt: 2, mb: 2, color: "green" }}>
                                                    Correct Answer: {question.answer}
                                                </Typography>
                                            )}                  
                                        </li>
                                    ))}
                                </ul>
                            </Box>                 
                        </>                            
                        ) : (
                            <Typography variant="h5" sx={{ 'fontWeight': 'bold' }}>
                                No Question found in this test
                            </Typography>
                        )}
                        </Box>
                    </Box>
                </>
            }
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
        </Box>
    )
}

export default TestSubmitPage;
