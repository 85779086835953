import * as React from 'react';
import { tokens } from "../../theme";
import { Button, useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import RestoreIcon from '@mui/icons-material/Restore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const RestoreModal = ({ 
    student,
    setPrevActions,
    prevActions,
    modalOpen, 
    setModalOpen,
    updatePotentialStudentsInfo,
    updateInfoMail,
    updateMemo,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [page, setPage] = React.useState(1);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        height: 800,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
        overflowY: "auto",
    };
    
    const handleModalClose = () => setModalOpen(false);

    function restoreData() {
        const selected_prev_action = prevActions[page - 1] ?? null;

        if (!selected_prev_action) {
            console.log("Restore failed");
            alert("Restore failed! No action found in the list");
            return;
        }

        // consultation_date
        // info_mail_date -> info_mail_id
        // parent_name
        // parent_phone
        // parent_email
        // memo_content -> memo_id
        // memo_title -> memo_id

        if (selected_prev_action.action === "consultation_date" 
            || selected_prev_action.action === "parent_name" 
            || selected_prev_action.action === "parent_phone" 
            || selected_prev_action.action === "parent_email"
        ) {

            updatePotentialStudentsInfo(
                selected_prev_action.student_uuid,
                {
                    [selected_prev_action.action]: selected_prev_action.prev_data,
                }
            ); // student_uuid, updatedInfo for args

            // update the student info in the potentialStudents
            student[selected_prev_action.action] = selected_prev_action.prev_data;

        } else if (selected_prev_action.action === "info_mail_date") {

            updateInfoMail(
                selected_prev_action.student_uuid,
                selected_prev_action.memo_id_or_info_mail_id,
                {
                    "date": selected_prev_action.prev_data,
                }
            ); // student_uuid, memo_id_or_info_mail_id, updatedInfo for args

            // update the info_mail_date in the potentialStudents
            const info_mail = student.info_mails.find(info_mail => info_mail.id === selected_prev_action.memo_id_or_info_mail_id);
            info_mail.date = selected_prev_action.prev_data;

        } else if (selected_prev_action.action === "memo_content" 
            || selected_prev_action.action === "memo_title"
        ) {

            const action = selected_prev_action.action.split("_")[1];
            updateMemo(
                selected_prev_action.student_uuid,
                selected_prev_action.memo_id_or_info_mail_id,
                {
                    [action]: selected_prev_action.prev_data,
                }
            ); // student_uuid, memo_id_or_info_mail_id, updatedInfo for args

            // update the memo in the potentialStudents
            const memo = student.memos.find(memo => memo.id === selected_prev_action.memo_id_or_info_mail_id);
            memo[action] = selected_prev_action.prev_data;

        } else {
            console.log("Restore failed");
            alert("Restore failed! No action found");
            return;
        }

        // remove the action from prevActions
        const new_prev_actions = prevActions.filter((action, index) => index !== page - 1);
        setPrevActions(new_prev_actions);

        // close the modal
        handleModalClose();
    }

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={modalOpen}>
                <Box sx={style}>
                    <Typography variant="h3" ml={1} borderBottom={1}>
                        Restoration <span style={{ fontSize: "18px", color: colors.grey[600] }}>(Lastest to Oldest)</span>
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 2,
                            padding: "10px",
                            marginBottom: "20px",
                        }}
                    >
                        <Button
                            variant="text"
                            color="primary"
                            disabled={page === 1}
                            onClick={() => setPage(page - 1)}
                            sx={{
                                backgroundColor: colors.greenAccent[500],
                            }}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="text"
                            color="primary"
                            disabled={page === prevActions.length || prevActions.length === 0}
                            onClick={() => setPage(page + 1)}
                            sx={{
                                backgroundColor: colors.greenAccent[500],
                            }}
                        >
                            Next
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: "20px",
                            height: "600px",
                            width: "900px",
                        }}
                    >
                        <Box 
                            sx={{
                                display: 'flex', 
                                flexDirection: 'column',
                                alignItems: 'center', 
                                height: "100%",
                                width: "100px",
                                backgroundColor: colors.primary[200],
                            }}
                        >
                            <Box 
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    width: "100px",
                                    height: "50px",
                                    borderBottom: 1,
                                    borderColor: colors.primary[600],
                                    padding: "0 10px",
                                }}
                            >
                                <Typography variant="h6" color='white'>Consultation Number</Typography>
                            </Box>
                            <Box 
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: "100px",
                                    height: "50px",
                                    borderBottom: 1,
                                    borderColor: colors.primary[600],
                                    padding: "0 10px",
                                }}
                            >
                                <Typography variant="h6" color='white'>Action</Typography>
                            </Box>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: "100px",
                                    height: "100%",
                                    padding: "0 10px",
                                    borderBottom: 1,
                                    borderColor: colors.primary[600],
                                }}
                            >
                                <Typography variant="h6" color='white'>Data</Typography>
                                <Typography variant="h6" color='white'>(Old {'>'} New)</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: "100px",
                                    height: "50px",
                                    padding: "0 10px",
                                }}
                            >
                                <Typography variant="h6" color='white'>Restore</Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: "100%",
                                width: "800px",
                            }}
                        >
                            {prevActions.length === 0 && (
                                <Box 
                                    sx={{
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        padding: "50px 10px",
                                        width: "800px",
                                        border: 1,
                                    }}
                                >
                                    <Typography variant="h6">No actions to restore</Typography>
                                </Box>
                            
                            )}
                            {prevActions.map((action, index) => {
                                if (index === page - 1) {
                                    return (
                                        <Box 
                                            key={index}
                                            sx={{
                                                display: 'flex', 
                                                flexDirection: 'column',
                                                alignItems: 'center', 
                                                width: "800px",
                                                border: 1,
                                            }}
                                        >
                                            {/* <Typography variant="h6">{action?.student_id}</Typography>
                                            <Typography variant="h6">{action?.memo_id_or_info_mail_id}</Typography> */}
                                            <Box 
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    padding: "20px 10px",
                                                    width: "100%",
                                                    height: "50px",
                                                    borderBottom: 1,
                                                }}
                                            >
                                                <Typography variant="h6">{action?.consultation_number}</Typography>
                                            </Box>
                                            <Box 
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    padding: "20px 10px",
                                                    width: "100%",
                                                    height: "50px",
                                                    borderBottom: 1,
                                                }}
                                            >
                                                <Typography variant="h6">{action?.action}</Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    gap: 2,
                                                    padding: "20px 10px",
                                                    width: "100%",
                                                    borderBottom: 1,
                                                }}
                                            >
                                                <Box paddingX={5} width={350}>
                                                    <Typography variant="h6">{action?.prev_data}</Typography>
                                                </Box>
                                                <Box paddingX={1} minWidth={40} width={100}>
                                                    <Typography variant="h6"><ArrowForwardIcon /></Typography>
                                                </Box>
                                                <Box paddingX={5} width={350}>
                                                    <Typography variant="h6">{action?.new_data}</Typography>
                                                </Box>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: "100%",
                                                    height: "50px",
                                                    padding: "0 10px",
                                                }}
                                            >
                                                <Tooltip 
                                                    title={<h1>Old Data will be restored</h1>}
                                                    placement="top"
                                                >
                                                    <IconButton onClick={() => restoreData()}>
                                                        <RestoreIcon color='primary' />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    )
                                }

                                return null;
                            })}
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

export default RestoreModal