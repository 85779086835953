import React from "react";
import { Box, IconButton, Button, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import AccountUpdate from "./topBarComp/AccountUpdate";
import ScheduleModal from "../adminDashboard/teachers/moreInfo/ScheduleModal";


const ClassRoomTopbar = ({ userRole, userId }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    // auth
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            {/* SEARCH BAR */}
            <Box
                display="flex"
                backgroundColor={colors.primary[700]}
                borderRadius="3px"
                boxShadow={10}
            >

            </Box>

            {/* ICONS */}
            <Box display="flex" gap={1}>
                {/* Weekl Schedule */}
                {userRole === "teacher" ? (
                    <ScheduleModal iconColor={"default"} teacher={null} button={false} userId={userId} />
                ) : null}


                {/* Password update icon */}
                <AccountUpdate color="default" />

                {/* color mode change */}
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton>

                {/* Sign out button */}
                <Button
                    fullWidth
                    variant="contained"
                    color='secondary'
                    onClick={handleLogout}
                >
                    <Typography color={colors.primary[400]}>Sign Out</Typography>
                </Button>
            </Box>
        </Box>
    );
};

export default ClassRoomTopbar;