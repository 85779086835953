import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import MoreInfoModal from './moreInfo/MoreInfoModal';
import { motion } from "framer-motion";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";

const ZoomRoomCard = ({ zoomRoom, setZoomRoomUpdated }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            layout
        >
            <Card
                sx={{
                    minWidth: 200,
                    backgroundColor: colors.primary[600],
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                    border: `1px solid ${colors.primary[600]}`,
                    ":hover": {
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                        border: "1px solid grey",
                    }
                }}
                key={zoomRoom.id}
            >
                <CardContent>
                    <Typography variant="h5" sx={{ mb: 1.5 }}>
                        Zoom {zoomRoom.room_number}
                    </Typography>
                    <Typography color="text.secondary">
                        Meeting ID: {zoomRoom.meeting_id}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Link: {zoomRoom.link !== null && zoomRoom.link.length > 20 ? zoomRoom.link.substring(0, 20) + "..." : zoomRoom.link}
                    </Typography>
                    <Typography variant="body2">
                        Password: {zoomRoom.password}
                        <br />
                        Note: {zoomRoom.note}
                    </Typography>
                </CardContent>
                <CardActions>
                    <MoreInfoModal zoomRoom={zoomRoom} setZoomRoomUpdated={setZoomRoomUpdated} />
                </CardActions>
            </Card>
        </motion.div>
    )
}

export default ZoomRoomCard