import React from 'react'
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import ReplyIcon from '@mui/icons-material/Reply';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import MoreVertIcon from "../../../components/MoreVertIcon"
import DeleteDialog from '../../../components/DeleteDialog';
import Axios from 'axios';
import ReplyBox from './ReplyBox';
import Reply from './Reply';
import UpdateQuestionModal from './UpdateQuestionModal';

var options_without_time = {
    year: "numeric",
    month: "short",
    day: "numeric"
};

function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}
  
function stringAvatar(name) {
    if (name.split(' ').length === 1)
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name[0]}`,
        };

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const QuestionBox = ({
    courseId,
    question,
    setBackdropOpen, 
    setSuccessAlertOpen, 
    setErrorAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertMessage,
    userRole,
    userId,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [replyOpen, setReplyOpen] = React.useState(false);

    // for popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMoreVertIconClick = (event) => {
        if (userRole === "teacher")
            return;
        setAnchorEl(event.currentTarget);
    };
    const handlePopOverClose = () => {
        setAnchorEl(null);
    };
    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    const handleDeleteQuestion = async () => {
        setAnchorEl(null);
        setBackdropOpen(true);
        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/students/${userId}/course-questions/${question.id}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((res) => {
            setBackdropOpen(false);
            setSuccessAlertMessage("Question has been deleted successfully!");
            setSuccessAlertOpen(true);
            window.location.reload();
        }).catch((err) => {
            setBackdropOpen(false);
            setErrorAlertMessage(err.response.data.message || err.response.data.error || "Something went wrong!");
            setErrorAlertOpen(true);
            console.log(err);
        });
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: colors.primary[600],
                borderRadius: "5px",
                border: "0.1px solid #a3a3a3",
                mb: "1.8rem",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    borderBottom: "0.1px solid #a3a3a3",
                    py: "0.5rem",
                    px: "1rem",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                        }}
                    >
                        <Avatar {...stringAvatar(question.author)} />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Typography variant="h6" sx={{ml: "1rem"}}>
                                {question.author}
                            </Typography>
                            <Typography variant="h7" color={colors.grey[700]} sx={{ml: "1rem"}}>
                                Posted on: {new Date(question.createdAt).toLocaleDateString("en", options_without_time)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    {(userRole === "admin" || question.student.uuid === userId) &&
                    <>
                        <MoreVertIcon handleClick={handleMoreVertIconClick} />
                        <Popover
                            id={popoverId}
                            open={popoverOpen}
                            anchorEl={anchorEl}
                            onClose={handlePopOverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            {question.student.uuid === userId &&
                                <UpdateQuestionModal
                                    courseId={courseId} 
                                    userId={userId}
                                    question={question} 
                                    setBackdropOpen={setBackdropOpen}
                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                    setErrorAlertOpen={setErrorAlertOpen}
                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                    setErrorAlertMessage={setErrorAlertMessage}
                                />
                            }
                            <DeleteDialog
                                handleDelete={handleDeleteQuestion}
                                setAnchorEl={setAnchorEl}
                                dialogTitle={"Are you sure you want to delete the question?"} 
                            />
                        </Popover>
                    </>}
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    borderBottom: "0.1px solid #a3a3a3",
                    py: "1rem",
                    px: "0.3rem",
                }}
            >
                <Typography variant="h4" sx={{my: "auto", ml: "1rem"}}>
                    {question.title}
                </Typography>
                <Typography variant='h7' sx={{mt: "1rem", ml: "1rem"}}>
                    {question.content && question.content.split("\n").map((line, index) => {
                        return (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        )
                    })}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        mt: "1rem",
                        mx: "1rem"
                    }}
                >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="h7" color={colors.grey[700]}>
                            {question.course_question_files?.length === 1 ? "Attached 1 file" : `Attached ${question.course_question_files?.length} files`}
                        </Typography>
                        {question.course_question_files?.map((file) => {
                            return (
                                <a key={file.id} href={file.file_url} style={{ color: colors.grey[700] }}>
                                    {file.name}
                                </a>
                            )
                        })}
                    </Box>
                </Box>
            </Box>
            {question.course_question_replies && question.course_question_replies.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    {question.course_question_replies.map((reply, index) => {
                        return (
                            <Reply 
                                key={index}
                                reply={reply}
                                userRole={userRole}
                                userId={userId}
                                courseId={courseId}
                                questionId={question.id}
                                setBackdropOpen={setBackdropOpen}
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                setErrorAlertOpen={setErrorAlertOpen}
                                setSuccessAlertMessage={setSuccessAlertMessage}
                                setErrorAlertMessage={setErrorAlertMessage}
                                stringAvatar={stringAvatar}
                            />
                        )
                    })}
                </Box>
            )}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    borderBottom: "0.1px solid #a3a3a3",
                    py: "1rem",
                    px: "0.3rem",
                }}
            >
                {!replyOpen ? (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            border: "0.1px solid #a3a3a3",
                            mx: "0.5rem",
                            p: "0.5rem",
                            alignItems: "center",
                            gap: "0.5rem",
                            "&:hover": {
                                backgroundColor: colors.primary[700],
                                color: "black",
                                cursor: "text",
                            }
                        }}
                        onClick={() => setReplyOpen(true)}
                    >
                        <ReplyIcon />
                        <Typography variant="h7">
                            Reply
                        </Typography>
                    </Box>
                ) : (
                    <ReplyBox
                        setReplyOpen={setReplyOpen}
                        userRole={userRole}
                        userId={userId}
                        courseId={courseId}
                        questionId={question.id}
                        setBackdropOpen={setBackdropOpen}
                        setSuccessAlertOpen={setSuccessAlertOpen}
                        setErrorAlertOpen={setErrorAlertOpen}
                        setSuccessAlertMessage={setSuccessAlertMessage}
                        setErrorAlertMessage={setErrorAlertMessage}
                    />
                )}
            </Box>
        </Box>
    )
}

export default QuestionBox