import * as React from "react";
import { Box, Button } from "@mui/material";
import Axios from 'axios';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const AddTimeModal = ({ 
    open, 
    handleClose, 
    selected_semester_id,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 500,
        height: 200,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
    };    

    const [fromTime, setFromTime] = React.useState(dayjs());
    const [toTime, setToTime] = React.useState(dayjs());

    async function handleAddTime() {
        if (!fromTime || !toTime) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please fill all fields!");
            return;
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/potential-schedules`, {
            from_time: fromTime,
            to_time: toTime,
            semester_id: selected_semester_id,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message || "Potential schedule created successfully!");
            window.location.reload();
        }).catch((error) => {
            console.log(error);
            setErrorAlertOpen(true);
            setErrorAlertMessage("Error occurs while creating potential schedule!");
        });
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '1.5rem' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['TimePicker', 'TimePicker']}>
                                <TimePicker
                                    label="From"
                                    value={fromTime}
                                    onChange={(newValue) => setFromTime(newValue)}
                                />
                                <TimePicker
                                    label="To"
                                    value={toTime}
                                    onChange={(newValue) => setToTime(newValue)}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                marginLeft: 'auto',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: 100,
                                }}
                                onClick={handleAddTime}
                            >
                                Add
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                sx={{
                                    width: 100,
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default AddTimeModal