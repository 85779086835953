import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { tokens } from "../theme";

const BackdropComp = ({ backdropOpen, handleBackdropClose }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
        
    return (
        <Backdrop
            sx={{ color: colors.primary[400], zIndex: 2147483646 }}
            open={backdropOpen}
            onClick={handleBackdropClose}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default BackdropComp