import React from 'react'
import { Box, Button, TextField, Typography, Autocomplete } from "@mui/material";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import 'mathlive';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
  

const ExerciseEditModal = ({ 
    question, 
    setUpdated, 
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [questionLabel, setQuestionLabel] = React.useState(question.question_label || "");
    const [chosenQuestionType, setChosenQuestionType] = React.useState(question.type || null);
    const [options, setOptions] = React.useState(question.options?.split("|") || []);
    const [answers, setAnswers] = React.useState(question.answer?.split("|") || []);
    const [chosenTool, setChosenTool] = React.useState(question.answer_tool || null);
    const [numAnswers, setNumAnswers] = React.useState(question.answer?.split("|").length || 1);

    async function handleSave() {
        if (questionLabel === "") {
            setErrorAlertMessage("Question label is required!");
            setErrorAlertOpen(true);
            return;
        }

        if (chosenQuestionType === null) {
            setErrorAlertMessage("Question type is required!");
            setErrorAlertOpen(true);
            return;
        }

        if (chosenQuestionType === "multiple_choice" && options.length < 2) {
            setErrorAlertMessage("At least 2 options are required for multiple choice question!");
            setErrorAlertOpen(true);
            return;
        }

        if ((chosenQuestionType === "multiple_choice" || chosenQuestionType === "short_answer") && answers.length === 0) {
            setErrorAlertMessage("Answer is required for this question type!");
            setErrorAlertOpen(true);
            return;
        }

        if ((chosenQuestionType === "short_answer" || chosenQuestionType === "long_answer")  && chosenTool === null) {
            setErrorAlertMessage("Answer tool is required for this question type!");
            setErrorAlertOpen(true);
            return;
        }

        for (let i = 0; i < answers.length; i++) {
            if (answers[i] === "" && chosenQuestionType !== "long_answer" && chosenQuestionType !== "file_upload") {
                setErrorAlertMessage("Answer cannot be empty!");
                setErrorAlertOpen(true);
                return;
            }
        }

        let answer = null;
        if (chosenQuestionType !== "long_answer" && chosenQuestionType !== "file_upload") {
            answer = answers.join("|");
        }

        // save the question
        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/hw-questions/${question.id}`, {
            question_label: questionLabel,
            type: chosenQuestionType,
            options: options.join("|") || null,
            answer: answer || null,
            answer_tool: chosenTool || null,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            }
        }).then((response) => {
            setSuccessAlertMessage("Question updated successfully!");
            setSuccessAlertOpen(true);
            setUpdated(true);
            handleClose();
        }).catch((error) => {
            console.log("error: ", error);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    }

    const questionTypes = [
        { label: "Multiple Choice", value: "multiple_choice" },
        // { label: "True/False", value: "true_false" },
        { label: "Short Answer", value: "short_answer" },
        { label: "Long Answer", value: "long_answer" },
        { label: "File Upload", value: "file_upload" },
        // { label: "Essay", value: "essay" },
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
            }}
        >
            <Button
                variant='contained'
                color='primary'
                onClick={() => {handleOpen()}}
            >
                Edit
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box mt={2} sx={{ display: "flex", gap: "20px", justifyContent: "flex-start", alignItems: "flex-start" }}>
                        <TextField
                            variant="filled"
                            type="text"
                            label={"Question Label"}
                            onChange={(e) => {
                                setQuestionLabel(e.target.value);
                            }}
                            name={"question-label"}
                            value={questionLabel}
                            sx={{
                                minWidth: "300px", 
                                maxWidth: "400px"
                            }}
                        />
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={questionTypes}
                            sx={{
                                minWidth: "300px", 
                                maxWidth: "400px"
                            }}
                            renderInput={(params) => <TextField {...params} label="Question Type" />}
                            isOptionEqualToValue={(option, value) => {
                                return option.value === value.value;
                            }}
                            onChange={(event, newValue) => {
                                setAnswers([]);
                                setOptions([]);
                                setChosenQuestionType(newValue?.value);
                            }}
                            value={questionTypes.find((type) => type.value === chosenQuestionType)}
                        />
                    </Box>
                    <Box mt={2} sx={{ display: "flex", gap: "20px", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column" }}>
                        {chosenQuestionType === "multiple_choice" && ( <>
                            <Typography variant="h6" fontWeight="bold">
                                Options (ex: A., B., C., D. or 1., 2., 3., 4., 1), 2), etc.)
                            </Typography>
                            <Box display='flex' justifyContent='start' alignItems='center' flexWrap='wrap' gap={2}>
                                {options.map((option, index) => {
                                    return (
                                        <Box position="relative" key={index}>
                                            <TextField
                                                key={index}
                                                variant="filled"
                                                type="text"
                                                label={`Option ${index + 1}`}
                                                onChange={(e) => {
                                                    setOptions(options.map((opt, i) => i === index ? e.target.value : opt));
                                                    setAnswers([]);
                                                }}
                                                name={`option-${index}`}
                                                value={option}
                                                sx={{ maxWidth: "300px"}}
                                            />
                                            <CloseIcon 
                                                sx={{
                                                    position: 'absolute',
                                                    right: 0,
                                                    top: 0,
                                                    color: colors.redAccent[400],
                                                    cursor: 'pointer',
                                                    zIndex: 1000,
                                                }}
                                                fontSize="small"
                                                onClick={() => {
                                                    setOptions(options.filter((_, i) => i !== index));
                                                    setAnswers([]);
                                                }}
                                            />
                                        </Box>
                                    )
                                })}
                            </Box>
                            <Box display='flex' justifyContent='start' alignItems='center' flexDirection='column' gap={2}>
                                <Button type="button" color="primary" variant="contained" onClick={() => {setOptions([...options, ''])}}>Add Option</Button>
                            </Box>
                        </>)}
                    </Box>
                    {chosenQuestionType !== null && (<>
                        <Box mt={4} sx={{ display: "flex", gap: "20px", justifyContent: "flex-start", alignItems: "flex-start" }}>
                            {chosenQuestionType === "multiple_choice" ? (
                                <FormControl sx={{ minWidth: 300, maxWidth: 400 }}>
                                    <InputLabel id="demo-simple-select-label">Choose an answer</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={answers}
                                        label="Answer"
                                        onChange={(event) => {
                                            setAnswers([event.target.value]);
                                        }}
                                    >
                                        {options.map((option, index) => {
                                            return (
                                                <MenuItem key={index} value={option}>{option}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            ) : chosenQuestionType === "short_answer" || chosenQuestionType === "long_answer" ? (
                                <>
                                    <FormControl sx={{ minWidth: 300, maxWidth: 400 }}>
                                        <InputLabel id="demo-simple-select-label">Answer Tool</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={chosenTool}
                                            label="Answer"
                                            onChange={(event) => {
                                                setChosenTool(event.target.value);
                                                setAnswers([]);
                                            }}
                                        >
                                            <MenuItem value={""}>None</MenuItem>
                                            <MenuItem value={"textfield"}>Text Field</MenuItem>
                                            <MenuItem value={"mathlive"}>Mathlive</MenuItem>
                                            <MenuItem value={"tinymce"}>TinyMCE</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Box display="flex" justifyContent="start" alignItems="center" flexDirection="column" gap={2}>
                                        {chosenQuestionType === "short_answer" && chosenTool === "textfield" ? (
                                            Array(numAnswers).fill(0).map((_, index) => {
                                                return (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            gap: "10px",
                                                            alignItems: "flex-start",
                                                            width: "100%",
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <TextField
                                                            disabled={chosenQuestionType === "long_answer" || chosenQuestionType === "essay"}
                                                            variant="filled"
                                                            type="text"
                                                            label={"Answer"}
                                                            onChange={(e) => {
                                                                let temp = [...answers];
                                                                temp[index] = e.target.value;
                                                                setAnswers(temp);
                                                            }}
                                                            name={"answer"}
                                                            value={answers[index]}
                                                            sx={{ minWidth: "300px", maxWidth: "400px" }}
                                                        />
                                                        <Box
                                                            sx={{
                                                                position: 'absolute',
                                                                top: '12px',
                                                                right: -30,
                                                                color: colors.redAccent[400],
                                                                zIndex: 1000,
                                                            }}
                                                        >
                                                            <CloseIcon 
                                                                color='error' 
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    '&:hover': {
                                                                        color: colors.redAccent[600]
                                                                    }
                                                                }} 
                                                                onClick={() => {
                                                                    setAnswers(answers.filter((_, i) => i !== index));
                                                                    setNumAnswers(numAnswers - 1);

                                                                }} 
                                                            />
                                                        </Box>
                                                    </Box>
                                                )
                                            })
                                        ) : chosenQuestionType === "short_answer" && chosenTool === "mathlive" ? (
                                            Array(numAnswers).fill(0).map((_, index) => {
                                                return (
                                                    <Box 
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "10px",
                                                            alignItems: "flex-start",
                                                            width: "100%",
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <math-field 
                                                            id="mathfield"
                                                            onInput={evt => {
                                                                let temp = [...answers];
                                                                temp[index] = evt.target.value;
                                                                setAnswers(temp);
                                                            }}
                                                            style={{ width: "inherit", minWidth: "300px", maxWidth: "100%"}}
                                                        >
                                                            {/* {answers[index]} */}
                                                        </math-field>
                                                        <p>Answer: {answers[index]}</p>
                                                        <Box
                                                            sx={{
                                                                position: 'absolute',
                                                                top: '12px',
                                                                right: -30,
                                                                color: colors.redAccent[400],
                                                                zIndex: 1000,
                                                            }}
                                                        >
                                                            <CloseIcon 
                                                                color='error' 
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    '&:hover': {
                                                                        color: colors.redAccent[600]
                                                                    }
                                                                }} 
                                                                onClick={() => {
                                                                    setAnswers(answers.filter((_, i) => i !== index));
                                                                    setNumAnswers(numAnswers - 1);

                                                                }} 
                                                            />
                                                        </Box>
                                                    </Box>
                                                )
                                            })
                                        ) : chosenQuestionType === "short_answer" && chosenTool === "tinymce" ? (
                                            <Box></Box>
                                        ) : null }
                                    </Box>
                                </>
                            ) : null }
                        </Box>
                        <Box mt={2} sx={{ display: "flex", gap: "20px", justifyContent: "flex-start", alignItems: "flex-start" }}>
                            {chosenQuestionType === "short_answer" && (chosenTool === "textfield" || chosenTool === "mathlive") &&
                                <Button
                                    type="button"
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        setNumAnswers(numAnswers + 1);
                                        setAnswers([...answers, '']);
                                    }}
                                >
                                    Add Answer
                                </Button>
                            }
                        </Box></>
                    )}
                    <Box mt={2} sx={{ display: "flex", gap: "10px", justifyContent: "flex-end", alignItems: "flex-end" }}>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => {handleSave()}}
                        >
                            Save
                        </Button>
                        <Button
                            variant='contained'
                            color='error'
                            onClick={() => {handleClose()}}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default ExerciseEditModal;