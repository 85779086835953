import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useMediaQuery from '@mui/material/useMediaQuery';
import Axios from 'axios';
import ErrorSnackbar from '../../../components/ErrorSnackbar';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';


// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };

export default function AdminActionAccordion({ 
    courseId,
    students,
    userRole,
    courseTests,
    studentsStartEndDateMap, 
    authorDisabledForAdminActions, 
    setBackdropOpen, 
    setSuccessAlertOpen, 
    setErrorAlertOpen, 
    setSuccessAlertMessage,
    setErrorAlertMessage,
    tests,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dueDate, setDueDate] = React.useState(null);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const matches = useMediaQuery('(min-width:90rem)');
    const [selectedStudents, setSelectedStudents] = React.useState([]);
    const [testId, setTestId] = React.useState(null);

    const [classTests, setClassTests] = React.useState('');

    const handleTestChange = (event) => {
        event.preventDefault();    
        setClassTests(event.target.value);
    };

    let current_students = [];
    if (students !== null && students !== undefined) {
        for (let i = 0; i < students.length; i++) {
            const end_date = dayjs(studentsStartEndDateMap[students[i].id].end_date);
            const start_date = dayjs(studentsStartEndDateMap[students[i].id].start_date);
        
            if (dayjs().isAfter(end_date) || dayjs().isBefore(start_date)) {
                continue;
            }
            current_students.push(students[i]);
        }
    }

    let remaining_test = tests.filter(test => !courseTests.map(courseTest => courseTest.test_id).includes(test.id));

    React.useEffect(() => {
        setTestId(classTests);
    }, [classTests]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (dueDate === null) {
            setErrorSnackbarOpen(true);
            return;
        }

        if (classTests === '') {
            setErrorSnackbarOpen(true);
            return;
        }

        let selected_students = "";
        let unselected_students = "";

        // selected한 학생이 없으면 select에 있는 모든 학생에게 숙제 부여한다
        if (selectedStudents === null || selectedStudents.length === 0) {  
            for (let i = 0; i < current_students.length; i++) {
                selected_students += current_students[i].id;
                if (i !== current_students.length - 1) {
                    selected_students += "|";
                }
            }
        }
        
        // selected한 학생이 있으면 select에 있는 학생에게만 숙제 부여
        if (selectedStudents !== null && selectedStudents.length > 0) {
            for (let i = 0; i < selectedStudents.length; i++) {
                selected_students += selectedStudents[i].id;
                if (i !== selectedStudents.length - 1) {
                    selected_students += "|";
                }
            }
        }
        let students_list = [];
        for (let i = 0; i < current_students.length; i++) {
            students_list.push(current_students[i].id);
        }
                        
        const selectedStudentsList = selected_students?.split("|").toString() || [];
        const unselectedFilter = students_list.filter(student => !selectedStudentsList.includes(student.toString())); 

        for (let i = 0; i < unselectedFilter.length; i++) {
            unselected_students += unselectedFilter[i];
            if (i !== unselectedFilter.length - 1) {
                unselected_students += "|";
            }
        }

        // 만약 selected_students 나 unselected_students 빈 어레이면 빈 문자열로 바꿔준다
        if (Array.isArray(selected_students) && selected_students.length === 0) {
            selected_students = "";
        }
        if (Array.isArray(unselected_students) && unselected_students.length === 0) {
            unselected_students = "";
        }

        setBackdropOpen(true);

        const data = new FormData(event.currentTarget);
        
        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/tests/${testId}`, {
            posted_by: data.get('author'),
            title: data.get('title'),
            content: data.get('instructions'),
            due_date: dueDate,
            exclude_student: unselected_students,
            unexclude_student: selected_students,
        },{
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setBackdropOpen(false);
            setSuccessAlertMessage("Test created successfully!");
            setSuccessAlertOpen(true);
            window.location.reload();
        }).catch((error) => {
            console.log(error);
            setBackdropOpen(false);
            setErrorAlertMessage(error.response.data.message || error.response.data.error);
            setErrorAlertOpen(true);
        });
    };

    return (
        <div style={{
            width: "100%",
            marginBottom: "1rem",
        }}>
            <Accordion
                style={{
                    backgroundColor: colors.primary[750],
                }}
                component="div"
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h6'>Create a Test</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                            display: "flex",
                            flexDirection: "column",
                            mr: "1rem",
                        }}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <FormControl fullWidth sx={{ minWidth: "49%", backgroundColor: colors.primary[600] }}>
                            <InputLabel id="demo-simple-select-label">Select a test</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={classTests}
                                label="Select a test"
                                onChange={handleTestChange}
                            >
                                {remaining_test.map((test) => {
                                    return (
                                        <MenuItem key={test.id} value={test.id}>
                                            {test.title}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                label="Due Date"
                                value={dueDate}
                                onChange={(newValue) => setDueDate(newValue)}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        backgroundColor: colors.primary[600],
                                        width: "16rem",
                                        ...!matches && {
                                            width: "100%",
                                        },
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1rem",
                            minWidth: "100%",
                        }}>
                            <TextField
                                id="filled-basic"
                                label="Title"
                                variant="filled"
                                name='title'
                                sx={{
                                    flex: "1",
                                    maxWidth: "50%",
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[600],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                            />
                            <TextField
                                id="filled-basic"
                                label="Author"
                                variant="filled"
                                name='author'
                                sx={{
                                    flex: "1",
                                    maxWidth: "50%",
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[600],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                                defaultValue={localStorage.getItem('userName')}
                                inputProps={{ readOnly: authorDisabledForAdminActions }}
                            />
                        </Box>
                        {userRole === 'admin' ? (
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "1rem",
                                minWidth: "100%",
                            }}>
                                {/* For admin */}
                                {/* a list of students*/}
                                <Autocomplete
                                    multiple
                                    limitTags={4}
                                    id="student-list"
                                    options={current_students !== null ? current_students : []}
                                    getOptionLabel={(current_students) => current_students.first_name + " " + current_students.last_name}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}                                           
                                            label="Test Takers: If no one is selected, the test will be assigned to all students"
                                        />
                                    )}
                                    sx={{ width: "100%" }}                                
                                    onChange={(event, value) => {
                                        setSelectedStudents(value);
                                    }}                                
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                />
                            </Box>
                            ) : (
                            <></>
                            )
                        }
                        <TextField
                            id="filled-basic"
                            label="Instructions"
                            variant="filled"
                            name='instructions'
                            fullWidth={true}
                            multiline={true}
                            rows={10}
                            sx={{
                                minWidth: "100%",
                                borderRadius: "5px",
                                '& .MuiFilledInput-root': {
                                    backgroundColor: colors.primary[600],
                                    ":focus": {
                                        backgroundColor: 'white',
                                    },
                                },
                            }}
                        />
                        {/* <FormControl style={{ width: '100%', marginTop: '20px' }}>
                            <FormLabel id="demo-radio-buttons-group-label">Homework Type</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                value={materialChoice}
                                name="controlled-radio-buttons-group"
                                row
                                onChange={(e) => setMaterialChoice(e.target.value)}
                                style={{
                                    display: "flex",
                                }}
                            >
                                <FormControlLabel value="file upload" control={<Radio />} label="default" />
                                <FormControlLabel value="class material" control={<Radio />} label="Class Material" />
                            </RadioGroup>
                        </FormControl> */}
                        
                       
                        <Button
                            type="submit"
                            variant="contained"
                            style={{
                                backgroundColor: colors.primary[300],
                                color: "white",
                                float: "right",
                                marginTop: "0.5rem",
                                marginBottom: "1rem",
                                width: "10rem",
                            }}
                        >
                            Create
                        </Button>
                    </Box>
                </AccordionDetails>
                <ErrorSnackbar errorMsg="Please select test and set due date!" open={errorSnackbarOpen} setOpen={setErrorSnackbarOpen} />
            </Accordion>
        </div>
    )
}