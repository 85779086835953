import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import MoreInfoTab from './MoreInfoTab';
import ModifyTab from './ModifyTab';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
  
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function CourseInfoTabs({ courseData, courseStudents, setCourseUpdated }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', minHeight: 'inherit' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="primary tabs example"
                sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
                <Tab label="Course Info" {...a11yProps(0)} />
                <Tab label="Modify" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel
                value={value}
                index={0}
            >
                <MoreInfoTab courseData={courseData} courseStudents={courseStudents} />
            </CustomTabPanel>
            <CustomTabPanel 
                value={value} 
                index={1}
            >
                <ModifyTab courseData={courseData} setCourseUpdated={setCourseUpdated} />
            </CustomTabPanel>
        </Box>
    );
}