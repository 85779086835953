import * as React from "react";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';

const TeacherNameModal = ({ 
    classInfo,
    saveTeacherName,
    saveTeacherNameForPrivateSchedule,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 400,
        height: 220,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [teacherName, setTeacherName] = React.useState(classInfo.teacher_name !== 'No Teacher Assigned' ? classInfo.teacher_name : '');

    async function handleSaveTeacherName() {
        if (classInfo.is_private && classInfo.id !== undefined && classInfo.id !== null) {            
            saveTeacherNameForPrivateSchedule(classInfo, teacherName);
        } else {
            saveTeacherName(classInfo.course_id, teacherName);
        }
    }

    return (<>
        <Tooltip title="Assign Teacher" placement="top" arrow>
            <IconButton 
                aria-label="undo" 
                color='gray'
                sx={{ 
                    position: "absolute",
                    bottom: 5,
                    right: 5,
                    '&:hover': {
                        color: colors.redAccent[400],
                    }
                }}
                onClick={() => handleOpen()}
            >
                <EditIcon fontSize='small' />
            </IconButton>
        </Tooltip>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '1.5rem' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <Typography variant="h5" sx={{ fontWeight: 700 }}>
                                Edit Assigned Teacher Name
                            </Typography>
                            <TextField
                                id="teacher-name"
                                label="Teacher Name"
                                variant="outlined"
                                value={teacherName}
                                onChange={(e) => setTeacherName(e.target.value)}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                marginLeft: 'auto',
                                marginTop: 'auto',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: 100,
                                }}
                                onClick={() => {
                                    handleSaveTeacherName();
                                    handleClose();
                                }}
                            >
                                Save
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                sx={{
                                    width: 100,
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    </>);
};

export default TeacherNameModal