import React from 'react'
import { tokens } from "../../theme";
import { Box, useTheme, Typography, IconButton, Button } from "@mui/material";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import UndoIcon from '@mui/icons-material/Undo';
import dayjs from 'dayjs';
import ConfirmModal from './ConfirmModal';
import CopyCourseModal from './CopyCourseModal';
import EachPotentialCourseVertIcon from './EachPotentialCourseVertIcon';
import Tooltip from '@mui/material/Tooltip';
import ScheduleConfirmModal from './ScheduleConfirmModal';

// const students_data = [
//     {
//         id: 1,
//         consultation_number: 1000,
//         name: "John Doe",
//         email: "aaa@aaa.com",
//         memo: "This is a test",
//     },
//     {
//         id: 2,
//         consultation_number: 1001,
//         name: "Jane Doe",
//         email: "bbb@bbb.com",
//         memo: "This is a test222",
//     }
// ];

const EachPotentialCourse = ({
    potentialSchedules,
    potentialCourseType,
    potentialCourse,
    courseIdStudentCoursesMap,
    handleConfirmStudent,
    handlePrivateScheduleConfirm,
    fetchPotentialCourses,
    useOuterClick,
    beingModified,
    setBeingModified,
    saveCourseMemo,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [modifyingCourseMemo, setModifyingCourseMemo] = React.useState(-1);
    const [modifiedCourseMemo, setModifiedCourseMemo] = React.useState(potentialCourse.course_memo || "");
    const [finishingModifyingCourseMemo, setFinishingModifyingCourseMemo] = React.useState(false);

    const courseMemoRef = useOuterClick(e => {
        // if other input is being modified, return
        if (beingModified !== -1 && beingModified !== potentialCourse.id) return;

        if (!finishingModifyingCourseMemo) {
            setFinishingModifyingCourseMemo(true);
            setBeingModified(potentialCourse.id);
            courseMemoRef.current.focus();
            const old_val = courseMemoRef.current.value;
            courseMemoRef.current.value = "";
            courseMemoRef.current.value = old_val;
            return;
        }

        if (potentialCourse.course_memo === courseMemoRef.current.value || (potentialCourse.course_memo === null && courseMemoRef.current.value === "")) {
            console.log("No change");
            setFinishingModifyingCourseMemo(false);
            setModifyingCourseMemo(-1);
            setBeingModified(-1);
            return;
        }

        console.log("Change");
        // save the value
        saveCourseMemo(
            potentialCourse.uuid, 
            courseMemoRef.current.value,
        );
        setFinishingModifyingCourseMemo(false);
        setModifyingCourseMemo(-1);
        setBeingModified(-1);
        potentialCourse.course_memo = modifiedCourseMemo;
    });

    React.useEffect(() => {
        setModifiedCourseMemo(potentialCourse.course_memo || "");
    }, [potentialCourse]);

    const handleCourseClick = (course) => {
        // need to scroll up to the course schedule
        document.getElementById(course.course_potential_schedule.days.split('|')[0])?.scrollIntoView({ behavior: "instant" });
    }
    
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                bgcolor: potentialCourse.copy_to_new_course ? colors.primary[900] : colors.primary[600],
                opacity: potentialCourse.copy_to_new_course ? 0.5 : 1,
                width: "100%",
                minHeight: "60vh",
                border: "1px solid",
                borderRadius: "1rem",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    width: "200px",
                    padding: "20px",
                    overflow: "hidden",
                }}
            >
                <Typography 
                    variant="h4" 
                    color="primary" 
                    fontWeight="bold" 
                    sx={{
                        textDecoration: potentialCourse.copy_to_new_course ? "line-through" : "none",
                    }}
                >
                    {potentialCourse.course_title}                    
                </Typography>

                {potentialCourse.teacher_name}
                
                <Box>
                    <Typography variant="h5" color="gray">
                        {potentialCourse.course_potential_schedule?.time} ({potentialCourse.course_potential_schedule?.days?.replace("|", ", ")})
                    </Typography>
                    <Typography variant="h6" color="gray">
                        Total Students: {courseIdStudentCoursesMap[potentialCourse.id].length}
                    </Typography>
                    <Typography variant="h6" color="gray">
                        # of Confirmed: {courseIdStudentCoursesMap[potentialCourse.id].filter((student_course) => student_course.is_confirmed).length}
                    </Typography>
                </Box>
                {potentialCourseType !== 2 && (
                    <>
                    <Box
                        sx={{display: "flex", flexDirection: "column", gap: 1, marginTop: "auto"}}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{ 
                                width: "100%", 
                                marginTop: "auto",
                                '&:hover': {
                                    backgroundColor: colors.primary[900],
                                },
                            }}
                            onClick={() => handleCourseClick(potentialCourse)}
                        >
                            View Schedule
                        </Button>
                        <CopyCourseModal 
                            potentialCourseType={potentialCourseType}
                            potentialCourse={potentialCourse} 
                            student_courses={courseIdStudentCoursesMap[potentialCourse.id]} 
                        />
                    </Box>
                    </>
                )}
            </Box>
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    borderLeft: "1px solid",
                    paddingY: 2,
                }}
            >
                {/* MEMO */}
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            px: 2,
                        }}
                    >
                        <Typography variant="h5" color="primary" fontWeight="bold">
                            {potentialCourseType !== 2 ? "Code: " + potentialCourse.code : ""}
                        </Typography>
                        <EachPotentialCourseVertIcon 
                            potentialSchedules={potentialSchedules}
                            student_courses={courseIdStudentCoursesMap[potentialCourse.id]}
                            potentialCourse={potentialCourse}
                            fetchPotentialCourses={fetchPotentialCourses}
                            setSuccessAlertMessage={setSuccessAlertMessage}
                            setSuccessAlertOpen={setSuccessAlertOpen}
                            setErrorAlertMessage={setErrorAlertMessage}
                            setErrorAlertOpen={setErrorAlertOpen}
                            potentialCourseType={potentialCourseType}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            minHeight: "50px",
                            margin: "10px",
                            padding: "10px",
                            '&:hover': {
                                cursor: "text",
                                backgroundColor: colors.primary[800],
                            },
                        }}
                        onClick={() => {
                            if (beingModified !== -1 || modifyingCourseMemo === potentialCourse.id) return;

                            setModifyingCourseMemo(potentialCourse.id);
                        }}
                    >
                        {modifyingCourseMemo === potentialCourse.id ?
                            <textarea
                                type="text"
                                value={modifiedCourseMemo}
                                onChange={(e) => {
                                    setModifiedCourseMemo(e.target.value);
                                }}
                                rows={
                                    modifiedCourseMemo?.split("\n").length > 1
                                        ? modifiedCourseMemo?.split("\n").length
                                        : 1
                                }
                                ref={courseMemoRef}
                                data-key={potentialCourse.id}
                                style={{
                                    flex: 1,
                                    width: "100%",
                                    height: "100%",
                                    padding: "10px",
                                }}
                            />
                        : (
                            modifiedCourseMemo?.split("\n").map((memo, index) => {
                                return (
                                    <Typography key={index} variant="body1" color="gray">
                                        {memo}
                                    </Typography>
                                )
                            })
                        )}
                    </Box>
                </Box>
                {/* STUDENTS */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        width: "100%",
                        height: "100%",
                        minHeight: "500px",
                        maxHeight: "500px",
                        borderTop: "1px solid",
                        overflowX: "auto",
                    }}
                >
                    {courseIdStudentCoursesMap[potentialCourse.id].length === 0 ? (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                                width: "100%",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Typography 
                                variant="h3" 
                                color="primary" 
                                sx={{ 
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <PersonOffIcon fontSize='large' sx={{ mr: 1 }} />
                                No students yet
                            </Typography>
                            <Typography 
                                variant="h5" 
                                color={colors.primary[900]} 
                                sx={{ 
                                    display: "flex",
                                    alignItems: "center",
                                    width: "400px",
                                    textAlign: "center",
                                }}
                            >
                                A student will be added if you send an info mail by using the email generator!
                            </Typography>
                        </Box>
                    ) : courseIdStudentCoursesMap[potentialCourse.id]?.sort((a, b) => {
                            return dayjs(a.createdAt) - dayjs(b.createdAt);
                        }).map((student_course, index) => {
                        //courseIdStudentCoursesMap[potentialCourse.id]?.map((student_course, index) => {
                            const student = student_course.student;
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "720px",
                                        height: "46px",
                                        border: "0.1px solid lightgray",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "260px",
                                            height: "100%",
                                            borderRight: "0.1px solid lightgray",
                                            paddingX: "10px",
                                            bgcolor: colors.primary[800],
                                        }}
                                    >
                                        <Typography 
                                            variant="h6" 
                                            color={!student_course.is_confirmed ? colors.primary[400] : 'error'}
                                            fontWeight="bold"
                                        >
                                            {student.consultation_number ? '(' + student.consultation_number + ')' : '(N/A)'} {student.parent_name ? student.parent_name : "N/A"}
                                            <br />
                                            {student.first_name ? student.first_name + ' ' + (student.last_name || '') : ''}
                                        </Typography>
                                        {!student_course.is_confirmed ? (
                                            <ConfirmModal
                                                student_course_id={student_course.id}
                                                potentialCourse_uuid={potentialCourse.uuid}
                                                student_uuid={student.uuid}
                                                handleConfirmStudent={handleConfirmStudent}
                                            />
                                        ) : (
                                            <Tooltip title="Undo Confirm" placement="top" arrow>
                                                <IconButton 
                                                    classes={{ root: `confirm-student-${student_course.id}` }}
                                                    aria-label="undo" 
                                                    color='gray'
                                                    sx={{ 
                                                        '&:hover': {
                                                            color: colors.redAccent[400],
                                                        }
                                                    }}
                                                    onClick={(e) => handleConfirmStudent(e, potentialCourse.uuid, student_course.student.uuid, false, null, null)}
                                                >
                                                    <UndoIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                    <Typography 
                                        width={"200px"} 
                                        variant="h6" 
                                        color={!student_course.is_confirmed ? 'primary' : 'error'}
                                        borderRight={"0.1px solid lightgray"}
                                        paddingLeft={"10px"}
                                        paddingY={"10px"}
                                    >
                                        {student_course.email_sent_at !== null && student_course.email_author !== null
                                            ? dayjs(student_course.email_sent_at).format("MM-DD (ddd)") + " by " + student_course.email_author
                                            : student_course.email_sent_at !== null && student_course.email_author === null
                                            ? dayjs(student_course.email_sent_at).format("MM-DD (ddd)")
                                            : student.info_mail && student.info_mail.date !== null && student.info_mail.author !== null
                                            ? dayjs(student.info_mail.date).format("MM-DD (ddd)") + " by " + student.info_mail.author
                                            : student.info_mail && student.info_mail.date !== null && student.info_mail.author === null
                                            ? dayjs(student.info_mail.date).format("MM-DD (ddd)")
                                            : "N/A"
                                        }
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "260px",
                                            height: "100%",
                                            paddingX: "10px",
                                        }}
                                    >
                                        <Typography 
                                            variant="h6" 
                                            color={!student_course.is_confirmed ? 'primary' : 'error'}
                                            paddingY={"10px"}
                                        >
                                            {student.parent_email ? student.parent_email : "N/A"}
                                        </Typography>
                                        {potentialCourseType === 1 && student_course.is_confirmed && (
                                            <ScheduleConfirmModal
                                                student_course_id={student_course.id}
                                                potentialCourse_uuid={potentialCourse.uuid}
                                                student_uuid={student.uuid}
                                                private_potential_schedule={student_course.private_potential_schedule || null}
                                                handlePrivateScheduleConfirm={handlePrivateScheduleConfirm}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            )
                    })}
                </Box>
            </Box>
        </Box>
    )
}

export default EachPotentialCourse;