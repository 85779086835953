import * as React from 'react';
import { tokens } from '../../theme';
import { useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import TeacherNameModal from './TeacherNameModal';
import ScheduleConfirmModal from './ScheduleConfirmModal';

const days = ["월", "화", "수", "목", "금", "토"];

const timeSlots = [
    "9:00 am - 9:30 am",
    "9:30 am - 10:00 am",
    "10:00 am - 10:30 am",
    "10:30 am - 11:00 am",
    "11:00 am - 11:30 am",
    "11:30 am - 12:00 pm",
    "12:00 pm - 12:30 pm",
    "12:30 pm - 13:00 pm",
    "1:00 pm - 1:30 pm",
    "1:30 pm - 2:00 pm",
    "2:00 pm - 2:30 pm",
    "2:30 pm - 3:00 pm",
    "3:00 pm - 3:30 pm",
    "3:30 pm - 4:00 pm",
    "4:00 pm - 4:30 pm",
    "4:30 pm - 5:00 pm",
    "5:00 pm - 5:30 pm",
    "5:30 pm - 6:00 pm",
    "6:00 pm - 6:30 pm",
    "6:30 pm - 7:00 pm",
    "7:00 pm - 7:30 pm",
    "7:30 pm - 8:00 pm",
    "8:00 pm - 8:30 pm",
    "8:30 pm - 9:00 pm",
    "9:00 pm - 9:30 pm",
    "9:30 pm - 10:00 pm",
    "10:00 pm - 10:30 pm",
    "10:30 pm - 11:00 pm",
    "11:00 pm - 11:30 pm",
    "11:30 pm - 00:00 am",
];

const height_of_time_map = {
    "9:00 am": 0,
    "9:30 am": 1,
    "10:00 am": 2,
    "10:30 am": 3,
    "11:00 am": 4,
    "11:30 am": 5,
    "12:00 pm": 6,
    "12:30 pm": 7,
    "1:00 pm": 8,
    "1:30 pm": 9,
    "2:00 pm": 10,
    "2:30 pm": 11,
    "3:00 pm": 12,
    "3:30 pm": 13,
    "4:00 pm": 14,
    "4:30 pm": 15,
    "5:00 pm": 16,
    "5:30 pm": 17,
    "6:00 pm": 18,
    "6:30 pm": 19,
    "7:00 pm": 20,
    "7:30 pm": 21,
    "8:00 pm": 22,
    "8:30 pm": 23,
    "9:00 pm": 24,
    "9:30 pm": 25,
    "10:00 pm": 26,
    "10:30 pm": 27,
    "11:00 pm": 28,
    "11:30 pm": 29,
    "12:00 am": 30,
};

export default function CombinedSchedule({ 
    combinedScheduleMap, 
    maxCourseLen, 
    occupationMap, 
    saveTeacherName,
    saveTeacherNameForPrivateSchedule,
    handlePrivateScheduleConfirm,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const ROWHEIGHT = 40;

    const scrollRef = React.useRef();
    const dummyScrollRef = React.useRef();

    React.useEffect(() => {
        const handleScroll = () => {
            if (dummyScrollRef.current && scrollRef.current) {
                dummyScrollRef.current.scrollLeft = scrollRef.current.scrollLeft;
            }
        };
    
        const handleDummyScroll = () => {
            if (dummyScrollRef.current && scrollRef.current) {
                scrollRef.current.scrollLeft = dummyScrollRef.current.scrollLeft;
            }
        };
    
        const mainScrollEl = scrollRef.current;
        const dummyScrollEl = dummyScrollRef.current;
    
        if (mainScrollEl && dummyScrollEl) {
            mainScrollEl.addEventListener('scroll', handleScroll);
            dummyScrollEl.addEventListener('scroll', handleDummyScroll);
        }
    
        return () => {
            if (mainScrollEl && dummyScrollEl) {
                mainScrollEl.removeEventListener('scroll', handleScroll);
                dummyScrollEl.removeEventListener('scroll', handleDummyScroll);
            }
        };
    }, []);


    return (
        <Box
            sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                width: "100%",
                minWidth: 700,
                maxWidth: "100%",
                maxHeight: "80vh",
                boxShadow: 10,
                border: "1px solid grey",
                overflow: "hidden",
            }}
        >
            {/* Dummy Scrollbar */}
            <Box
                ref={dummyScrollRef}
                sx={{
                    width: "100%",
                    overflowY: "hidden",
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    borderBottom: "1px solid grey",
                    boxSizing: "border-box",
                    // height: "60px", // height of scrollbar
                }}
            >
                <Box
                    sx={{
                        display: "inline-block",
                        width: `max(100%, ${maxCourseLen * 2 * 200}px)`,
                        height: "300px",
                    }}
                />
            </Box>
    
            {/* Main Content with Scroll */}
            <Box
                ref={scrollRef}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "calc(100% - 20px)",
                    overflowY: "auto",
                    overflowX: "hidden",
                }}
            >
                {/* Days and Time Columns */}
                <Box
                    sx={{
                        position: "sticky",
                        top: 0,
                        left: 0,
                        display: "flex",
                        flexDirection: "column",
                        gap: "100px",
                        minWidth: "4rem",
                        height: "100%",
                        zIndex: 1000,
                        bgcolor: colors.primary[600],
                    }}
                >
                    {days.map((day, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                borderLeft: "1px solid grey",
                                borderRight: "1px solid grey",
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            {timeSlots.map((timeSlot, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        height: ROWHEIGHT,
                                        borderTop: index === 0 ? "1px solid grey" : "none",
                                        borderBottom: "1px solid grey",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            textAlign: "center",
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {day}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    ))}
                </Box>
    
                <Box
                    sx={{
                        position: "sticky",
                        top: 0,
                        left: "4rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "100px",
                        minWidth: "10rem",
                        height: "100%",
                        zIndex: 1000,
                        bgcolor: colors.primary[600],
                    }}
                >
                    {days.map((day, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                borderRight: "1px solid grey",
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            {timeSlots.map((timeSlot, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        height: ROWHEIGHT,
                                        borderTop: index === 0 ? "1px solid grey" : "none",
                                        borderBottom: "1px solid grey",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            textAlign: "center",
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {timeSlot}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    ))}
                </Box>
    
                {/* Classes */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "100px",
                        width: `max(100%, ${maxCourseLen * 2 * 200}px)`,
                        height: "100%",
                        borderLeft: "10px solid lightgray",
                    }}
                >
                    {days.map((day, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                height: "100%",
                                position: "relative",
                            }}
                        >
                            {timeSlots.map((timeSlot, i) => {
                                const timeSlotStart = timeSlot.split(" - ")[0];
                                const zIndex = 1 + i;
                                let overlap = 0;
    
                                return (
                                    <Box
                                        key={i}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            height: ROWHEIGHT,
                                            width: `max(100%, ${maxCourseLen * 2 * 200}px)`,
                                            borderTop: i === 0 ? "1px solid lightgray" : "none",
                                            borderBottom: "1px solid lightgray",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {combinedScheduleMap[day] &&
                                            combinedScheduleMap[day][timeSlotStart] &&
                                            combinedScheduleMap[day][timeSlotStart].map((classInfo, index) => {
                                                const height = height_of_time_map[classInfo.end_time] - height_of_time_map[classInfo.start_time];
    
                                                // Check for overlapping classes on the schedule map
                                                while (occupationMap[day]
                                                    && occupationMap[day][timeSlotStart]
                                                    && occupationMap[day][timeSlotStart][index + overlap]
                                                    && occupationMap[day][timeSlotStart][index + overlap] !== classInfo.fake_id
                                                )
                                                    overlap++
    
                                                // in each 30 minute from start to end, mark the occupationMap as true
                                                for (let i = dayjs(classInfo.start_time, "h:mm a"); i.isBefore(dayjs(classInfo.end_time, "h:mm a")); i = i.add(30, "minute")) {
                                                    if (occupationMap[day][i.format("h:mm a")] !== undefined)
                                                        occupationMap[day][i.format("h:mm a")][index + overlap] = classInfo.fake_id;
                                                }
    
                                                return (
                                                    <Box
                                                        key={index}
                                                        component="div"
                                                        className={`days-${day}-time-${timeSlotStart}-class-${index}`}
                                                        sx={{
                                                            position: "absolute",
                                                            top: height_of_time_map[classInfo.start_time] * ROWHEIGHT,
                                                            left: (index + overlap) * 200,
                                                            height: height * ROWHEIGHT,
                                                            width: "200px",
                                                            backgroundColor: colors.primary[800],
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            borderRadius: 1,
                                                            border: "1px solid white",
                                                            padding: "10px",
                                                            zIndex: zIndex,
                                                            '&:hover': {
                                                                backgroundColor: colors.primary[900],
                                                                zIndex: 100,
                                                            },
                                                        }}
                                                    >
                                                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                                            {classInfo.course_title}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            sx={{
                                                                fontWeight: 600, mt: 2,
                                                                color: classInfo.teacher_name === "No Teacher Assigned" ? colors.redAccent[500] : "default",
                                                            }}
                                                        >
                                                            {classInfo.teacher_name}
                                                        </Typography>
                                                        {classInfo.is_private && (
                                                            <Box
                                                                sx={{
                                                                    position: "absolute",
                                                                    left: "5px",
                                                                    bottom: "5px",
                                                                }}
                                                            >
                                                                <ScheduleConfirmModal
                                                                    student_course_id={classInfo.student_course_id}
                                                                    potentialCourse_uuid={classInfo.course_uuid}
                                                                    student_uuid={classInfo.student_uuid}
                                                                    private_potential_schedule={classInfo}
                                                                    handlePrivateScheduleConfirm={handlePrivateScheduleConfirm}
                                                                    from_combined_schedule={true}
                                                                />
                                                            </Box>
                                                        
                                                            // <Tooltip title="Delete Info" placement="top" arrow>
                                                            //     <IconButton
                                                            //         aria-label="delete-info"
                                                            //         color="primary"
                                                            //         sx={{
                                                            //             position: "absolute",
                                                            //             left: "5px",
                                                            //             bottom: "5px",
                                                            //             '&:hover': {
                                                            //                 color: colors.redAccent[400],
                                                            //             },
                                                            //         }}
                                                            //         onClick={() => {}}
                                                            //     >
                                                            //         <EventBusyIcon />
                                                            //     </IconButton>
                                                            // </Tooltip>
                                                        )}
                                                        <TeacherNameModal classInfo={classInfo} saveTeacherName={saveTeacherName} saveTeacherNameForPrivateSchedule={saveTeacherNameForPrivateSchedule} />
                                                    </Box>
                                                );
                                            })}
                                    </Box>
                                );
                            })}
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}