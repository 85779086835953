import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, useTheme } from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { tokens } from "../../../theme";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import Axios from 'axios';
import getStudentName from '../../../utils/getStudentName';


export default function UpdateModal({ 
    lectureModules,
    students,
    zoomRecording, 
    setBackdropOpen, 
    setSuccessAlertOpen, 
    setErrorAlertOpen, 
    setSuccessAlertMessage,
    setErrorAlertMessage,
    setDataUpdated,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [expirationDate, setExpirationDate] = React.useState(dayjs(zoomRecording.expiration_date));
    const [link, setLink] = React.useState(zoomRecording.link);
    const [selectedStudents, setSelectedStudents] = React.useState(
        zoomRecording.student_z_recordings.map((student_z_recording) => {
            return {
                id: student_z_recording.student.id,
                first_name: student_z_recording.student.first_name,
                last_name: student_z_recording.student.last_name,
                uuid: student_z_recording.student.uuid,
            }
        }));
    const [module, setModule] = React.useState(
        lectureModules.filter((lectureModule) => lectureModule.id === zoomRecording.event_id)[0].id
    );

    // const [memo, setMemo] = React.useState(zoomRecording.memo);

    // Function to format the event start date (ex. (Mon) 07/20/2024)
    const formatDate = (date) => {
        const options = { weekday: 'short', month: '2-digit', day: '2-digit', year: 'numeric' };
        const formattedDate = new Date(date).toLocaleDateString('en-US', options);
        const [dayOfWeek, rest] = formattedDate.split(', ');
        const [month, day, year] = rest.split('/');
        return `(${dayOfWeek}) ${month}/${day}/${year}`;
    };

    const handleModuleChange = (event) => {
        event.preventDefault();
        setModule(event.target.value);
    };

    const handleUpdate = async (event) => {
        event.preventDefault();

        // check if valid inputs are given
        if (module === null && module === '') {
            setErrorAlertMessage("Please select a module!");
            setErrorAlertOpen(true);
            return;
        }

        if (selectedStudents.length === 0) {
            setErrorAlertMessage("Please select at least one student!");
            setErrorAlertOpen(true);
            return;
        }

        if (link === '') {
            setErrorAlertMessage("Please enter a link!");
            setErrorAlertOpen(true);
            return;
        }

        if (expirationDate === null) {
            setErrorAlertMessage("Please enter an expiration date!");
            setErrorAlertOpen(true);
            return;
        }

        const newlySelectedStudents = selectedStudents.filter((selectedStudent) => {
            return !zoomRecording.student_z_recordings.map((student_z_recording) => {
                return student_z_recording.student.id;
            }).includes(selectedStudent.id);
        });
        const newlyUnselectedStudents = zoomRecording.student_z_recordings.filter((student_z_recording) => {
            return !selectedStudents.map((selectedStudent) => {
                return selectedStudent.id;
            }).includes(student_z_recording.student.id);
        });

        console.log(newlySelectedStudents);
        console.log(newlyUnselectedStudents);

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/zoom-recordings/${zoomRecording.id}`, {
            link: link,
            expiration_date: expirationDate,
            event_id: module,
            newly_selected_students: newlySelectedStudents,
            newly_unselected_students: newlyUnselectedStudents,
            // memo: memo,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((res) => {
            setSuccessAlertMessage(res.data.message);
            setSuccessAlertOpen(true);
            setDataUpdated(true);
        }).catch((err) => {
            setErrorAlertMessage(err.response.data.message || err.response.data.error);
            setErrorAlertOpen(true);
        }).finally(() => {
            setBackdropOpen(false);
            handleClose();
        });                
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    // set students who are already selected
    // set module to the state module

    return (
        <div>
            <Button onClick={handleOpen} sx={{ p: 1, width: "100%" }}>Update</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                                display: "flex",
                                flexDirection: "column",
                                mr: "1rem",
                            }}
                            noValidate
                        >
                            <FormControl fullWidth sx={{ minWidth: "50%", backgroundColor: colors.primary[600] }}>
                                <InputLabel id="demo-simple-select-label">Select a module</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={module}
                                    label="Select a module"
                                    onChange={handleModuleChange}
                                >
                                    {lectureModules.map((lectureModule) => {
                                        return (
                                            <MenuItem key={lectureModule.id} value={lectureModule.id}>
                                                {lectureModule.title}, {formatDate(lectureModule.start)}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                            minWidth: "100%",
                        }}>
                            <TextField
                                id="filled-basic"
                                label="Link"
                                placeholder='A link should begin with "http://"'
                                variant="filled"
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "1rem",
                                }}
                            >
                                <Autocomplete
                                    multiple
                                    id="tags-outlined-1"
                                    options={students !== null ? students : []}
                                    getOptionLabel={(students) => getStudentName(students)}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Students"
                                        />
                                    )}
                                    sx={{ width: "100%" }}
                                    value={selectedStudents}
                                    onChange={(event, value) => setSelectedStudents(value)}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        label="Expiration Date"
                                        value={expirationDate}
                                        onChange={(newValue) => setExpirationDate(newValue)}
                                        sx={{
                                            width: "16rem",
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                            {/* <TextField
                                id="filled-basic"
                                variant="filled"
                                fullWidth={true}
                                multiline={true}
                                rows={5}
                                sx={{
                                    minWidth: "40%",
                                    borderRadius: "5px",
                                    '& .MuiFilledInput-root': {
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                                defaultValue={zoomRecording.memo}
                                onChange={(e) => setMemo(e.target.value)}
                            /> */}
                            <Button
                                type="button"
                                variant="contained"
                                onClick={(e) => handleUpdate(e)}
                                style={{
                                    backgroundColor: colors.primary[300],
                                    color: "white",
                                    float: "right",
                                    marginTop: "0.5rem",
                                    marginBottom: "1rem",
                                    width: "10rem",
                                }}
                            >
                                Update
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}