import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import dayjs from 'dayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Axios from 'axios';
import UploadComp from '../../../components/UploadComp';


export default function AdminActionAccordion({ 
    courseId, 
    courseData,
    setBackdropOpen, 
    setSuccessAlertOpen, 
    setErrorAlertOpen, 
    setSuccessAlertMessage,
    setErrorAlertMessage,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [lectureDate, setLectureDate] = React.useState(dayjs());
    const [fromValue, setFromValue] = React.useState(dayjs());
    const [toValue, setToValue] = React.useState(dayjs());
    const [files, setFiles] = React.useState([]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        // check if title is empty
        // if (data.get('title') === "") {
        //     setErrorAlertMessage("Title cannot be empty!");
        //     setErrorAlertOpen(true);
        //     return;
        // }

        // check if start and end time are valid
        if (fromValue.hour() > toValue.hour() 
            || (fromValue.hour() === toValue.hour() && fromValue.minute() > toValue.minute())
            || (fromValue.hour() === toValue.hour() && fromValue.minute() === toValue.minute())) {
                setErrorAlertMessage("\"From time\" cannot be greater than or equal to \"To time\"!");
            setErrorAlertOpen(true);
            return;
        }

        // check if lecture date is valid
        const course_schedule = [];
        for (let i = 0; i < courseData.course_semesters.length; i++) {
            course_schedule.push([courseData.course_semesters[i].semester.start, courseData.course_semesters[i].semester.end]);
        }
        course_schedule.sort((a, b) => {
            return a[0] - b[0];
        });

        let isLectureDateValid = false;
        for (let i = 0; i < course_schedule.length; i++) {
            if (lectureDate >= dayjs(course_schedule[i][0]) && lectureDate <= dayjs(course_schedule[i][1])) {
                isLectureDateValid = true;
                break;
            }
        }

        if (!isLectureDateValid) {
            setErrorAlertMessage("Lecture date is not valid!");
            setErrorAlertOpen(true);
            return;
        }

        setBackdropOpen(true);

        // build start and end time
        const start_hour = fromValue.hour() < 10 ? "0" + fromValue.hour() : fromValue.hour();
        const start_minute = fromValue.minute() < 10 ? "0" + fromValue.minute() : fromValue.minute();
        const end_hour = toValue.hour() < 10 ? "0" + toValue.hour() : toValue.hour();
        const end_minute = toValue.minute() < 10 ? "0" + toValue.minute() : toValue.minute();

        const start_time = new Date(lectureDate.locale('en').format('YYYY-MM-DD') + "T" + start_hour + ":" + start_minute + ":00");
        const end_time = new Date(lectureDate.locale('en').format('YYYY-MM-DD') + "T" + end_hour + ":" + end_minute + ":00");
        
        /*
        * request body:
        * - start (lecture start time)
        * - end (lecture end time)
        * - title (lecture title)
        * - allDay (always false)
        * - progress (can be null) (always null for now)
        * - zoom_id (can be null)
        * - homework_id (always null) (homework must be created separately)
        * - course_id
        */

        // for uploading class notes
        const isClassNotesExist = files.length > 0;
        const formData = new FormData();
        if (isClassNotesExist) {
            for (let i = 0; i < files.length; i++)
                formData.append('file', files[i], files[i].name);
        }

        let doesRequestSucceed = true;
        let errorMessage = "";

        // create lecture module
        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/events`, {
            start: start_time,
            end: end_time,
            // title: data.get('title'),
            title: null,
            allDay: false,
            progress: null,
            zoom_id: null,
            homework_id: null,
            course_id: courseId,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then(async (res) => {            
            // if class notes exist, then upload them
            if (isClassNotesExist) {
                await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/events/${res.data.event_id}/class-notes`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res) => {
                    setSuccessAlertMessage("Module created successfully!");
                    setSuccessAlertOpen(true);
                    window.location.reload();
                }).catch((err) => {
                    setErrorAlertMessage(errorMessage);
                    setErrorAlertOpen(true);
                    setBackdropOpen(false);
                });
            } else {
                setSuccessAlertMessage("Module created successfully!");
                setSuccessAlertOpen(true);
                window.location.reload();
            }
        }).catch((err) => {
            setErrorAlertMessage(err.response.data.message || err.response.data.error);
            setErrorAlertOpen(true);
            setBackdropOpen(false);
        });
    };

    return (
        <div style={{
            width: "100%",
            marginBottom: "1rem",
        }}>
            <Accordion
                style={{
                    backgroundColor: colors.primary[750],
                }}
                component="div"
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h6'>Create a Module</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                            display: "flex",
                            flexDirection: "column",
                            mr: "1rem",
                        }}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                            minWidth: "100%",
                        }}>
                            {/* <TextField
                                id="filled-basic"
                                label="Lecture Title"
                                variant="filled"
                                name='title'
                                sx={{
                                    flex: "1",
                                    maxWidth: "50%",
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[600],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                            /> */}
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "1rem",
                                    minWidth: "100%",
                                }}
                            >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Class Date"
                                        value={lectureDate}
                                        onChange={(newValue) => setLectureDate(newValue)}
                                    />
                                    <TimePicker
                                        label="From"
                                        value={fromValue}
                                        onChange={(newValue) => setFromValue(newValue)}
                                        format="HH:mm"
                                    />
                                    <TimePicker
                                        label="To"
                                        value={toValue}
                                        onChange={(newValue) => setToValue(newValue)}
                                        format="HH:mm"
                                    />
                                </LocalizationProvider>
                            </Box>
                            <UploadComp text="Upload Class Notes" files={files} setFiles={setFiles} />
                        </Box>
                        <Button
                            type="submit"
                            variant="contained"
                            style={{
                                backgroundColor: colors.primary[300],
                                color: "white",
                                float: "right",
                                marginTop: "0.5rem",
                                marginBottom: "1rem",
                                width: "10rem",
                            }}
                        >
                            Create
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}