import React from 'react'
import Box from '@mui/material/Box';
import './Viewer.css';

const ClassMaterialViewer = ({ documentRender, isHidden = true }) => {
    return (
        <Box
            display="flex"
            width={isHidden ? "0px" : "100%"}
            height={isHidden ? "0px" : "100%"}
            visibility={isHidden ? "hidden" : "visible"}
        >
            {documentRender()}
        </Box>
    )
}

export default ClassMaterialViewer