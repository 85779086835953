import React from 'react';
import { tokens } from "../../theme";
import { Box, Typography, useTheme, CircularProgress, Divider } from "@mui/material";
import Header from "../../components/Header";
import Axios from 'axios';
import Loading from '../../components/Loading';
import Alert from '../../components/alert/Alert';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import './Viewer.css';
import 'mathlive';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import getCookies from '../../utils/getCookies';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ViewTest = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const currentUser = getCookies("_auth_state");
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const testId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }
    const [loading, setLoading] = React.useState(true);

    const [pdfLoading, setPdfLoading] = React.useState(true);
    const [test, setTest] = React.useState(null);
    const [numPages, setNumPages] = React.useState(null);
    const [sections, setSections] = React.useState([]);

    const [questionsForNoSectionTest, setQuestionsForNoSectionTest] = React.useState([]);

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Tests');

        async function fetchTest () {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/tests/${testId}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(async (response) => {
                setTest(response.data);
                // if there is a section -> setSections
                // console.log(response.data.test_sections)
                if (response.data.test_sections !== undefined && response.data.test_sections.length > 0) {
                    setSections(response.data.test_sections);
                    // setNumChoices(response.data.test_sections[0].test_questions[0].question_options.length);
                }
                else { // if not, it is going to be a sample
                    setQuestionsForNoSectionTest(response.data.test_questions);
                }
            }).catch((error) => {
                if (error.response.status === 404) {
                    setLoading(false);
                    setErrorAlertMessage('Test not found!');
                    setErrorAlertOpen(true);
                    return;
                } else if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message);
                setErrorAlertOpen(true);
            });
        }
        fetchTest();
        setLoading(false);
        // setUpdated(false);
    }, []);
    // }, [updated]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPdfLoading(false);
    };

    const documentRender = () => {
        return (
            <Document 
                file={test?.file_url}
                onLoadSuccess={onDocumentLoadSuccess}
                onContextMenu={(e) => e.preventDefault()}
                className="pdf-container"
            >
                {Array.from(new Array(numPages), (el, index) => (
                    <Page 
                        key={`page_${index + 1}`} 
                        pageNumber={index + 1} 
                        renderTextLayer={false} 
                        renderAnnotationLayer={false} 
                    />
                ))}
            </Document>
        );
    };

    const handleSectionAnswerChange = (sectionIndex, questionIndex, choice) => {
        const updatedSections = sections.map((section, sIndex) => {
            if (sIndex === sectionIndex) {
                const updatedQuestions = section.test_questions.map((question, qIndex) => {
                    if (qIndex === questionIndex) {
                        return { ...question, answer: choice };
                    }
                    return question;
                });
                return { ...section, test_questions: updatedQuestions };
            }
            return section;
        });
        setSections(updatedSections);
    };

    const handleNoSectionTestChange = (questionIndex, choice) => {
        const updatedQuestions = questionsForNoSectionTest.map((question, qIndex) => {
            if (qIndex === questionIndex) {            
                return { ...question, answer: choice };
            }
            return question;
        });

        setQuestionsForNoSectionTest(updatedQuestions);
    };


    const [dividerPosition, setDividerPosition] = React.useState(7); // Initial flex ratio
    const rightPaneRef = React.useRef(null);

    const handleMouseDown = (e) => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        if (rightPaneRef.current) {
            const newDividerPosition = (e.clientX / window.innerWidth) * 10;
            setDividerPosition(newDividerPosition);
        }
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    return (
        <Box m="20px" marginX={3} marginY={3}>
            <Header title={test?.title} subtitle="A ONE Institute" />
            {loading ? 
                <Loading />
            : <>
                <Box display="flex" flexDirection="row" justifyContent="left" gap={2} p={0} mb={1} mt={-2}>
                    <Typography variant="h4">
                        <span style={{ fontWeight: 'bold' }}>Category:</span> {test?.subject}
                    </Typography>                
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '20px',
                        marginBottom: '10px',
                        position: 'relative',
                        mt: 5,
                    }}
                >
                    {/* Open PDF */}
                    <a href={test?.file_url} target="_blank" style={{'textDecoration': 'none'}}>
                        <Typography 
                            sx={{
                                'fontWeight': 'bold', 
                                'cursor': 'pointer',
                                'position': 'absolute',
                                'top': '50%',
                                'transform': 'translateY(-50%)',
                                'left': '0',
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center',
                                padding: '10px',
                                borderRadius: '5px',
                                backgroundColor: colors.greenAccent[500],
                                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                                color: colors.primary[400],
                            }}
                        >
                            Open Test
                            <OpenInNewIcon fontSize='small' sx={{ cursor: 'pointer' }} />
                        </Typography>
                    </a>
                    <Box sx={{ position: 'absolute', top: '50%', right: '0', transform: 'translateY(-70%)' }}>
                        <Button
                            variant="contained"
                            href={`/admin/tests/${test?.id}/editTest`}
                            sx={{
                                backgroundColor: colors.blueAccent[600],
                                color: 'white',
                                padding: '10px 20px',
                                borderRadius: '5px',
                                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                                ':hover': {
                                    backgroundColor: colors.blueAccent[700],
                                },
                            }}
                        >
                            Edit this test                                                     
                        </Button>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    width="100%"
                    bgcolor={colors.primary[600]}
                >
                    <Box flex={dividerPosition} width="100%" sx={{ overflowY: 'scroll', height: '80vh', left: 0 }}>
                        <Box
                            sx={{
                                position: "sticky",
                                top: 0,
                            }}
                        >
                            {documentRender()}
                        </Box>
                        {pdfLoading && (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            >
                                <CircularProgress color="inherit" size={50} />
                            </Box>
                        )}
                    </Box>
                    <Divider 
                        orientation="vertical" 
                        flexItem
                        onMouseDown={handleMouseDown}
                        sx={{ cursor: 'col-resize', width: '5px', bgcolor: "grey" }}
                    />
                    <Box
                        flex={10 - dividerPosition}
                        ref={rightPaneRef} 
                        width="100%" 
                        p={5}
                        display='flex'
                        flexDirection='column'
                        gap={3}
                        sx={{
                            overflowY: 'auto',
                            overscrollBehavior: 'contain',
                            height: '80vh',
                        }}
                    > 
                    {/* Wtih Section */}
                        {test ? test.has_section ? (
                            <>
                                {test.test_sections.map((section, sectionIndex) => (
                                    <Box key={sectionIndex}>
                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Box>
                                                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                                                    {section.section_name ? section.section_name : `Section ${section.section_number}`}
                                                </Typography>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                                    Number of Questions: {section.test_questions.length}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <ul>
                                            {section.test_questions.map((question, qIndex) => (
                                                <li key={qIndex}>
                                                    <Typography variant="body1">Q{question.question_number}</Typography>
                                                    <ul>
                                                        <RadioGroup
                                                            row
                                                            value={question.answer}
                                                            onChange={(e) => handleSectionAnswerChange(sectionIndex, qIndex, e.target.value)}
                                                        >
                                                        {question.question_options.map((choice, cIndex) => (
                                                                <FormControlLabel key={cIndex} disabled value={choice.option} control={<Radio />} label={choice.option} />
                                                            ))}
                                                        </RadioGroup>
                                                    </ul>
                                                    <Typography variant="body1" sx={{mt: 2, mb: 2}}>Answer: {question.answer}</Typography>
                                                </li>
                                            ))}
                                        </ul>
                                    </Box>
                                ))}
                            </>
                        ) : (
                        // No Section
                        <>
                            <Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box>                                    
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                            Number of Questions: {test.test_questions.length}
                                        </Typography>
                                    </Box>
                                   
                                </Box>
                                <ul>
                                    {questionsForNoSectionTest.map((question, qIndex) => (
                                        <li key={qIndex}>
                                            <Typography variant="body1">Q{question.question_number}</Typography>
                                            <ul>
                                                <RadioGroup
                                                    row                                                    
                                                    value={question.answer}
                                                    onChange={(e) => handleNoSectionTestChange(qIndex, e.target.value)}
                                                >
                                                {question.question_options.map((choice, cIndex) => (
                                                        <FormControlLabel key={cIndex} disabled value={choice.option} control={<Radio />} label={choice.option} />
                                                    ))}
                                                </RadioGroup>
                                            </ul>
                                            <Typography variant="body1" sx={{mt: 2, mb: 2}}>Answer: {question.answer}</Typography>
                                        </li>
                                    ))}
                                </ul>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '10px',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '10px',
                                                alignItems: 'center',
                                                marginLeft: 'auto',
                                            }}
                                        >                                           
                                        </Box>
                                    </Box>
                            </Box>
                        </>                            
                        )
                            :                         
                            (
                                <Typography variant="h5" sx={{ 'fontWeight': 'bold' }}>
                                    No Question found in this test
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </>
            }
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
        </Box>
    )
}

export default ViewTest;
