import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
    Box,
    List,
    ListItem,
    ListItemText,
    Typography,
    useTheme,
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import Axios from 'axios';


const Calendar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [currentEvents, setCurrentEvents] = useState([]);

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Calendar');
    }, []);

    const handleDateClick = (selected) => {
        return;
        // const title = prompt("Please enter a new title for your event");
        // if (title) {
        //     // Axios.post(`${process.env.REACT_APP_URL}/api/v1/events`, {
        //     //     title: title,
        //     //     start: selected.start,
        //     //     end: selected.end,
        //     //     allDay: selected.allDay,
        //     //     userId: jwt_decode(document.cookie).id,
        //     // }).then((response) => {
        //     //     // do nothing
        //     // }).catch((error) => {
        //     //     alert(error.response.data.message);
        //     // });
        // }
    };

    const formatDate = (date) => {
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
        }).format(date);
    };

    async function handleDatesSet(date) {
        // console.log(date);
        // console.log(user_id);
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/events`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        })
            .then((response) => {
                setCurrentEvents(response.data);
            }).catch((error) => {
                if (error.response.status === 404) {
                    setCurrentEvents([]);
                    return;
                }
                alert(error.response.data.message);
            });
    };

    const handleEventClick = (selected) => {
        // const event_id = selected.event.id;

        // if (
        //     window.confirm(
        //         `Are you sure you want to delete the event '${selected.event.title}'`
        //     )
        // ) {
        //     selected.event.remove();
        //     Axios.delete(`${process.env.REACT_APP_URL}/api/v1/events/` + event_id, {
        //         // no data to send
        //     }).then((response) => {
        //         console.log(response);
        //     }).catch((error) => {
        //         alert(error.response.data.message);
        //     });
        // }
    };

    return (
        <Box m="20px" marginX={3} marginY={3}>
            <Header title="Calendar" subtitle="Full Calendar Interactive Page" />

            <Box display="flex" justifyContent="space-between">
                {/* CALENDAR SIDEBAR */}
                <Box
                    flex="1 1 20%"
                    backgroundColor={colors.primary[400]}
                    p="15px"
                    borderRadius="4px"
                    height="75vh"
                    sx={{
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                            width: "0.4em",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "rgba(0,0,0,.1)",
                        },
                        overflowx: "hidden",
                    }}
                >
                    <Typography variant="h5" color={colors.greenAccent[500]}>Events</Typography>
                    <List>
                        {currentEvents.map((event) => (
                        <ListItem
                            key={event.id}
                            sx={{
                                backgroundColor: colors.greenAccent[500],
                                margin: "10px 0",
                                borderRadius: "2px",
                            }}
                        >
                            <ListItemText
                                primary={event.title}
                                secondary={
                                    <Typography>
                                        {formatDate(new Date(event.start))}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        ))}
                    </List>
                </Box>

                {/* CALENDAR */}
                <Box flex="1 1 100%" ml="15px">
                <FullCalendar
                    height="75vh"
                    plugins={[
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                        listPlugin,
                    ]}
                    headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                    }}
                    initialView="dayGridMonth"
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    select={handleDateClick}
                    eventClick={handleEventClick}
                    events={currentEvents}
                    datesSet={(date) => handleDatesSet(date)}
                    nowIndicator={true}
                    eventColor={colors.primary[400]}
                    timeZone="America/New_York"
                />
                </Box>
            </Box>
        </Box>
    );
};

export default Calendar;