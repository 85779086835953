import * as React from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DateTime, Settings } from 'luxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Tooltip from '@mui/material/Tooltip';

Settings.defaultZone = "system";

const ConfirmModal = ({ 
    student_course_id,
    potentialCourse_uuid,
    student_uuid,
    handleConfirmStudent
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 500,
        height: 360,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
    };    

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // for time schedule in each day of class session
    const [fromValue, setFromValue] = React.useState(DateTime.now().startOf('day'));
    const [toValue, setToValue] = React.useState(DateTime.now().endOf('day'));

    return (<>
        <Tooltip title="Confirm" placement="top" arrow>
            <IconButton 
                classes={{ root: `confirm-student-${student_course_id}` }}
                aria-label="check" 
                color='gray'
                sx={{
                    '&:hover': {
                        color: colors.redAccent[400],
                    },
                }}
                onClick={handleOpen}
            >
                <CheckCircleIcon fontSize='small' />
            </IconButton>
        </Tooltip>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '1.5rem' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                            <Typography variant="h5" sx={{ fontWeight: 700 }}>
                                Please select dates for this student's class schedule
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: 500, color: colors.grey[600] }}>
                                If this student is a potential student, this action will convert the student to an enrolled student. <br />
                                However, when you undo this action, the student will not be converted back to a potential student!
                            </Typography>
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker
                                label="From"
                                value={fromValue}
                                onChange={(newValue) => setFromValue(newValue)}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <DatePicker
                                label="To"
                                value={toValue}
                                onChange={(newValue) => setToValue(newValue)}
                                sx={{ gridColumn: "span 2" }}
                            />
                        </LocalizationProvider>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                marginLeft: 'auto',
                                marginTop: 'auto',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: 100,
                                }}
                                onClick={(e) => handleConfirmStudent(e, potentialCourse_uuid, student_uuid, true, fromValue, toValue)}
                            >
                                Confirm
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                sx={{
                                    width: 100,
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    </>);
};

export default ConfirmModal