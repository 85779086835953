import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { motion } from "framer-motion";
import { Button, useTheme, Box } from "@mui/material";
import { tokens } from "../../theme";
import Axios from 'axios';

const TestCard = ({ 
    test,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    async function handleDelete(e, id) {
        e.preventDefault();

        if (!window.confirm("Are you sure you want to delete this test?")) {
            return;
        }

        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/tests/${id}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage(response.data.message);
            setSuccessAlertOpen(true);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }).catch((error) => {
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    }

    return (
        <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            layout
        >
            <Card
                sx={{
                    minWidth: 260,
                    maxWidth: 260,
                    minHeight: 230,
                    maxHeight: 230,
                    backgroundColor: colors.primary[600],
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                    border: `1px solid ${colors.primary[600]}`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: "14px",
                    ":hover": {
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                        border: "1px solid grey",
                    }
                }}
                key={test.id}
            >
                <CardContent sx={{ padding: 0, marginBottom: 2, flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <Typography variant="h5" sx={{ mb: 1.5 }}>
                        <a href={test.file_url} target="_blank" style={{'textDecoration': 'none'}}>
                            {test.title}
                        </a>
                    </Typography>
                    <Box>
                        <Typography color="text.secondary">
                            {test.subject}
                        </Typography>
                        <Typography color="text.secondary">
                            {test.title.split(".")[0]}
                        </Typography>    
                    </Box>
                </CardContent>
                <CardActions sx={{ padding: 0 }}>
                    <Button 
                        href={'/admin/tests/' + test.id}
                        size="small" 
                        style={{
                            "backgroundColor": colors.primary[800], 
                            "color": colors.primary[400]
                        }}
                    >
                        View Test
                    </Button>
                    <Button 
                        size="small" 
                        onClick={(e) => handleDelete(e, test.id)}
                        style={{
                            "backgroundColor": colors.redAccent[600], 
                            "color": colors.primary[400], 
                            "marginLeft": "auto"
                        }}
                    >
                        Delete
                    </Button>
                </CardActions>
            </Card>
        </motion.div>
    )
}

export default TestCard