import React from 'react'
import { tokens } from "../../../theme";
import { Box, useTheme, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '../../../components/alert/Alert';
import Header from "../../../components/Header";
import { useOutletContext } from 'react-router-dom';
import CreateLogsForm from './CreateLogsForm';
import Axios from 'axios';
import LogHistory from './LogHistoryAccordion';
import Loading from '../../../components/Loading';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import jwt_decode from "jwt-decode";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import getCookies from '../../../utils/getCookies';

const ClassLogs = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const matches = useMediaQuery('(min-width:1700px)');
    const matches2 = useMediaQuery('(min-width:1200px)');

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [loading, setLoading] = React.useState(true);
    const [courseId, setCourseId] = React.useState(null);
    const courseData = useOutletContext().courseData;
    const eventModules = useOutletContext().lectureModules;
    const currentUser = getCookies("_auth_state");
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const [courseStudents, setCourseStudents] = React.useState(null);
    const [eventModulesWithLogs, setEventModulesWithLogs] = React.useState(null);

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    // useEffect
    React.useEffect(() => {
        // students can't access this page
        if (userRole === "student")
            window.location.href = `/classrooms/${window.location.pathname.split('/')[2]}/home`;
        
        localStorage.setItem('selectedMenu', 'Class Logs');
        setCourseId(window.location.pathname.split('/')[2]);
        
        async function getCourseStudents() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${window.location.pathname.split('/')[2]}/students`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(async (response) => {
                setCourseStudents(response.data);
            }).catch((error) => {
                if (error.response.status === 404)
                    setCourseStudents([]);
                else if (error.response.status === 401)
                    handleLogout();
                else
                    console.log(error.response.data.message);
            });
        }

        async function getLogs() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${window.location.pathname.split('/')[2]}/special-notes`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setEventModulesWithLogs(response.data);
            }).catch((error) => {
                if (error.response.status === 401)
                    handleLogout();
                
                console.log(error.response.data.message);
            });
        }
    
        getCourseStudents();
        getLogs();
        setLoading(false);
    }, [userRole]);
    

    return (
        <Box m="20px" marginX={3} marginY={3} minHeight="90%">
            { loading || courseData === null || eventModules === null || courseStudents === null || eventModulesWithLogs === null ? 
                <Loading />
                : 
                <><Box 
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        px: "25%",
                        ...!matches && {
                            px: "18%",
                        },
                        ...!matches2 && {
                            px: "6%",
                        },
                    }}
                >
                    <Header 
                        title={
                            courseData.code !== null 
                            ? courseData.code + ". " + courseData.course_title 
                            : courseData.course_title
                        } 
                        subtitle="A ONE Institute"
                    />
                    {/* "Create Logs" Box */}
                    <Box
                        minWidth="20rem"
                        width="100%"
                        minHeight="10rem"
                        borderRadius="3px"
                        padding={3}
                        boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                        backgroundColor={colors.primary[600]}
                        display="flex"
                        flexDirection="column"
                        alignItems="left"
                        gap={3}
                        mb={3}
                    >
                        <CreateLogsForm 
                            eventModules={eventModules} 
                            courseStudents={courseStudents} 
                            setSuccessAlertOpen={setSuccessAlertOpen}
                            setSuccessAlertMessage={setSuccessAlertMessage}
                            setErrorAlertOpen={setErrorAlertOpen}
                            setErrorAlertMessage={setErrorAlertMessage}
                            setBackdropOpen={setBackdropOpen}
                        />
                    </Box>
                    <Box
                        minWidth="20rem"
                        width="100%"
                        minHeight="32rem"
                        borderRadius="3px"
                        padding={8}
                        boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                        backgroundColor={colors.primary[600]}
                        alignItems="center"
                    >
                        <Typography variant="h3" color={colors.primary[450]} borderBottom="0.1px solid" mb={2}>
                                History
                        </Typography>
                        {eventModulesWithLogs !== null && eventModulesWithLogs.length > 0 ?
                            eventModulesWithLogs.map((eventModulesWithLog, index) => (
                                <LogHistory 
                                    key={index}
                                    index={index}
                                    courseId={courseId}
                                    courseStudents={courseStudents}
                                    eventModule={eventModulesWithLog}
                                    userRole={userRole}
                                    setBackdropOpen={setBackdropOpen}
                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                    setErrorAlertOpen={setErrorAlertOpen}
                                    setErrorAlertMessage={setErrorAlertMessage}
                                />
                            ))
                            :
                            <Box sx={{
                                display: "flex",
                                justifyContent: "center",
                                minWidth: "20rem",
                                minHeight: "16rem",
                                gap: "0.4rem",
                            }}>
                                <InfoOutlinedIcon sx={{ my: "auto" }} />
                                <Typography variant="h4" color={colors.primary[450]} my="auto">
                                    No logs yet!
                                </Typography>
                            </Box>
                    }
                    </Box>
                </Box>
                <Alert
                    successAlertOpen={successAlertOpen}
                    setSuccessAlertOpen={setSuccessAlertOpen}
                    errorAlertOpen={errorAlertOpen}
                    setErrorAlertOpen={setErrorAlertOpen}
                    successMsg={successAlertMessage}
                    errorMsg={errorAlertMessage}
                    vertical="bottom"
                    horizontal="left"
                />
                <Backdrop
                    sx={{ color: colors.primary[400], zIndex: 2147483646 }}
                    open={backdropOpen}
                    onClick={handleBackdropClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop></>
            }
        </Box>
    )
}

export default ClassLogs