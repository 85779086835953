import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';


const Signup = () => {

    // const isNonMobile = useMediaQuery("(mid-width:600px)");
    const  navigateTo = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const adminCode = data.get('adminCode');

        if (adminCode !== "aone0819") {
            alert("Admin code is not correct");
            navigateTo('/signup');
            return;
        }

        let firstName = data.get('firstName');
        let lastName = data.get('lastName');
        let email = data.get('email');
        let password = data.get('password');
        let confirmPassword = data.get('confirmPassword');

        if (firstName === null || firstName === undefined || firstName === "") {
            alert("First name is required");
            return;
        } else if (lastName === null || lastName === undefined || lastName === "") {
            alert("Last name is required");
            return;
        } else if (email === null || email === undefined || email === "") {
            alert("Email is required");
            return;
        } else if (password === null || password === undefined || password === "") {
            alert("Password is required");
            return;
        }

        if (firstName.length > 30 || lastName.length > 30 || password.length > 30) {
            alert("First name, last name, and password must be less than 30 characters");
            return;
        }

        if (email.length > 50) {
            alert("Email must be less than 50 characters");
            return;
        }

        firstName = firstName.trim();
        lastName = lastName.trim();
        email = email.trim();

        if (password.length < 8) {
            alert("Password must be at least 8 characters long");
            return;
        } else if (password.length > 30) {
            alert("Password must be less than 30 characters");
            return;
        } else if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        Axios.post(`${process.env.REACT_APP_URL}/api/v1/signup`, {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
        }).then((response) => {

            if (response.data !== null && response.data !== undefined && response.data.error) {
                alert(response.data.error);
                navigateTo('/signup');
            } else {
                navigateTo('/signin');
            }
        })

      };
    
    
    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div></div>
            <div>
                <Typography variant="h1" sx={{ textAlign: 'center' }}>
                Sign up
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ width: '26vw', padding: '1.5rem' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name="adminCode"
                                fullWidth
                                id="adminCode"
                                label="Admin Code"
                                type="password"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                fullWidth
                                id="firstName"
                                label="First Name"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="family-name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                id="confirmPassword"
                            />
                        </Grid>

                        {/* <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid> */}

                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        color='secondary'
                    >
                        Sign Up
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/signin" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div style={{display: "flex", padding: "2rem", gap: "1rem"}}>
                <img
                    alt="ETT logo"
                    width="160px"
                    height="60px"
                    src={`../../imgs/ETT_logo_tran.png`}
                    style={{margin: "auto"}}
                />
                <CloseIcon fontSize='large' sx={{ m: "auto" }} />
                <img
                    alt="solple logo"
                    width="200px"
                    height="64px"
                    src={`../../imgs/aone-logo.png`}
                    style={{margin: "auto"}}
                />
            </div>
        </Box>
    )
}

export default Signup;