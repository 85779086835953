import React from 'react'
import { tokens } from "../../theme";
import { Box, Button, useTheme, Typography, Tooltip } from "@mui/material";
// import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Axios from 'axios';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';
import NoteButton from './NoteButton';

const AttendanceCheck = ({
    course,
    student_course,
    date,
    attendance_map,
    setInfoUpdated,
    findEventId,
    findAttendanceByEventId,
    setErrorAlertOpen,
    setErrorAlertMessage,
    count
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        //|| new Date(date) > new Date()
        if (findEventId(course, date) === null)
            return;
        if ((student_course.start_date !== null && student_course.end_date !== null)) {
            if (date.format('YYYY-MM-DD') < dayjs(student_course.start_date).format('YYYY-MM-DD')
            || date.format('YYYY-MM-DD') > dayjs(student_course.end_date).format('YYYY-MM-DD'))
                return;
        }

        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    // handle attendance box click to change attendance status
    const handleChangeButtonClick = async (e, student_id, event_id, date, status) => {
        e.stopPropagation();

        // if (date.format('YYYY-MM-DD') > dayjs().format('YYYY-MM-DD'))
        //     return;
        if ((student_course.start_date !== null && student_course.end_date !== null)
        && (date.format('YYYY-MM-DD') < dayjs(student_course.start_date).format('YYYY-MM-DD')
        || date.format('YYYY-MM-DD') > dayjs(student_course.end_date).format('YYYY-MM-DD')))
            return;

        // let error_occured = false;
        
        if (attendance_map[student_id] === undefined 
            || attendance_map[student_id] === null
            || attendance_map[student_id][course.id] === undefined 
            || attendance_map[student_id][course.id] === null
            || attendance_map[student_id][course.id][event_id] === undefined 
            || attendance_map[student_id][course.id][event_id].id === null) {

            // create attendance
            await Axios.post(`${process.env.REACT_APP_URL}/api/v1/attendance`, {
                student_id: student_id,
                event_id: event_id,
                status: status,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                setInfoUpdated(true);
            }).catch((err) => {
                console.log("err: ", err);
                setErrorAlertMessage(err.response.data.message);
                setErrorAlertOpen(true);
                // error_occured = true;
            });
        } else {
            const id = attendance_map[student_id][course.id][event_id].id;

            // update attendance
            await Axios.put(`${process.env.REACT_APP_URL}/api/v1/attendance/${id}`, {
                status: status,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                setInfoUpdated(true);
 
                // eventCountByStudent: student_id -> course_id -> event_id -> count
                // don't need to update because event count remains the same

                // event_modules_hash_map: course_id -> date -> event_module
                // also don't need to update because event module remains the same 
            }).catch((err) => {
                console.log("err: ", err);
                setErrorAlertMessage(err.response.data.message);
                setErrorAlertOpen(true);
                // error_occured = true;
            });
        }

        handleClose();
    };

    // check icon logic
    const checkIcon = (course, student_course, date) => {
        // if date is in the future, return null
        // if (date.format('YYYY-MM-DD') > dayjs().format('YYYY-MM-DD'))
        //     return <Box sx={{ width: "2rem", height: "2rem", cursor: "default" }}></Box>;

        // if the course date is out of student's start and end date, return null
        if ((student_course.start_date !== null && student_course.end_date !== null)
        && (date.format('YYYY-MM-DD') < dayjs(student_course.start_date).format('YYYY-MM-DD')
        || date.format('YYYY-MM-DD') > dayjs(student_course.end_date).format('YYYY-MM-DD'))) {
            return <Box sx={{ width: "2rem", height: "2rem", cursor: "default" }}></Box>;
        }

        const event_id = findEventId(course, date);

        // get attendance status and set icon and color
        const attendanceStatus = findAttendanceByEventId(course.id, event_id, student_course.student.id);
        let icon = 'CheckIcon';
        // let color = null;
        let textColor = null;
//        let opacity = 1;

        if (attendanceStatus === 0)
            // color = 'disabled';
            textColor = 'grey';
        else if (attendanceStatus === 1) {
            // color = 'success';
            textColor = 'green';
        }
        else if (attendanceStatus === 2){
            // color = 'warning';
            textColor = 'orange';
        }
        else if (attendanceStatus === 3)
            icon = 'CloseIcon';    
        else if (attendanceStatus === 4) {
            // color = 'error';
            textColor = 'red';
        }
        else { // if attendanceStatus is unchecked
            textColor = 'grey';
//            opacity = 0.1;
        }
        // check if note exists
        let note = null;
        if (attendance_map[student_course.student.id] 
        && attendance_map[student_course.student.id][course.id] 
        && attendance_map[student_course.student.id][course.id][event_id]) {
            note = attendance_map[student_course.student.id][course.id][event_id].note;
        }
        const hasNote = note !== null && note !== "";
        const noteForAttendanceCheck = note ? note : '';

        if (icon === 'CheckIcon') {
            if (hasNote) {                
                return (
                    <Tooltip title={<h2><span style={{ whiteSpace: 'pre-line' }}>{noteForAttendanceCheck}</span></h2>} placement="top">
                        <Badge color="info" variant="dot">
                            {/* <CheckIcon 
                                fontSize='large' 
                                color={color} 
                                sx={checkIconStyle} 
                            /> */}                        
                            {attendanceStatus ? 
                            <Typography variant='h3' color={textColor}>{count}</Typography>
                            : <Typography variant='h3' sx={{opacity: 0.5}} color={textColor} >{count}</Typography>}
                        </Badge>
                    </Tooltip>
                )
            }
            return (
                // <CheckIcon 
                //     fontSize='large' 
                //     color={color} 
                //     sx={checkIconStyle} 
                // />
                attendanceStatus ? 
                <Typography variant='h3' color={textColor}>{count}</Typography>
                : <Typography variant='h3' sx={{opacity: 0.5}} color={textColor} >{count}</Typography>
                )
        } else {
            if (hasNote) {
                return (
                    <Tooltip title={<h2><span style={{ whiteSpace: 'pre-line' }}>{noteForAttendanceCheck}</span></h2>} placement="top">
                        <Badge color="info" variant="dot">
                            <CloseIcon 
                                fontSize='large' 
                                color='error'
                                sx={checkIconStyle} 
                            />
                        </Badge>
                    </Tooltip>
                )
            }
            return (
                <CloseIcon 
                    fontSize='large' 
                    color='error' 
                    sx={checkIconStyle} 
                />
            )
        }
    }

    const checkIconStyle = {
        ":hover": {
            transform: "scale(1.2)",
        }
    }

    // students' start and end dates box color
    const boxBgColor = () => {
        const events_map = new Map();
        const events_list = course.events;
        for (let i = 0; i < course.events.length; i++) {
            events_map.set(dayjs(course.events[i].start).startOf('day').format('YYYY-MM-DD'), course.events[i]);
        }

        events_list.sort((a, b) => {
            return dayjs(a.start).startOf('day').diff(dayjs(b.start).startOf('day'));
        });

        let startDate = dayjs(student_course.start_date).startOf('day');
        let endDate = dayjs(student_course.end_date).startOf('day');

        if (events_list.length !== 0 && dayjs(events_list[0].start).isAfter(startDate)) {
            startDate = dayjs(events_list[0].start).startOf('day');
        }

        if (events_list.length !== 0 && dayjs(events_list[events_list.length - 1].end).isBefore(endDate)) {
            endDate = dayjs(events_list[events_list.length - 1].end).startOf('day');
        }

        // if student's session is only one day, then return red color
        if ((dayjs(date).startOf('day').isBefore(startDate.add(6, 'day')) && dayjs(date).startOf('day').isAfter(startDate.subtract(1, 'day')))
        && (dayjs(date).startOf('day').isBefore(endDate.add(1, 'day')) && dayjs(date).startOf('day').isAfter(endDate.subtract(6, 'day')))) {

            for (let i = endDate; i.isAfter(endDate.subtract(6, 'day')); i = i.subtract(1, 'day')) {
                if (events_map.get(i.format('YYYY-MM-DD')) !== undefined && i.isSame(dayjs(date).startOf('day'), 'day')) {
                    return colors.redAccent[900];
                } else if (events_map.get(i.format('YYYY-MM-DD')) !== undefined && !i.isSame(dayjs(date).startOf('day'), 'day')) {
                    return null;
                }
            }

        } else if (dayjs(date).startOf('day').isBefore(startDate.add(6, 'day'))
        && dayjs(date).startOf('day').isAfter(startDate.subtract(1, 'day'))) {
            // if the date is between start and start + 6 days, then find the cloest class from the date
            for (let i = startDate; i.isBefore(startDate.add(7, 'day')); i = i.add(1, 'day')) {
                if (events_map.get(i.format('YYYY-MM-DD')) !== undefined && i.isSame(dayjs(date).startOf('day'), 'day')) {
                    return colors.greenAccent[900];
                } else if (events_map.get(i.format('YYYY-MM-DD')) !== undefined && !i.isSame(dayjs(date).startOf('day'), 'day')) {
                    return null;
                }
            }
            
        } else if (dayjs(date).startOf('day').isBefore(endDate.add(1, 'day'))
        && dayjs(date).startOf('day').isAfter(endDate.subtract(6, 'day'))) {
            // if the date is between end - 6 and end, then find the cloest class from the date
            for (let i = endDate; i.isAfter(endDate.subtract(6, 'day')); i = i.subtract(1, 'day')) {
                if (events_map.get(i.format('YYYY-MM-DD')) !== undefined && i.isSame(dayjs(date).startOf('day'), 'day')) {
                    return colors.redAccent[900];
                } else if (events_map.get(i.format('YYYY-MM-DD')) !== undefined && !i.isSame(dayjs(date).startOf('day'), 'day')) {
                    return null;
                }
            }
        } else
            return null;
    };

    return (
        <div>
            <Box 
                width='100%'
                height='100%'
                display='flex'
                justifyContent='center'
                alignItems='center'
                onClick={handleClick} 
                bgcolor={boxBgColor()}
                style={{cursor: 'pointer'}}
            >
                    {checkIcon(course, student_course, date)}
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Button
                        onClick={(e) => handleChangeButtonClick(e, student_course.student.id, findEventId(course, date), date, 1)}
                        sx={{ color: 'success.main', textTransform: 'none', width: '100%' }}
                    >
                        PRESENT
                    </Button>
                    <Button
                        onClick={(e) => handleChangeButtonClick(e, student_course.student.id, findEventId(course, date), date, 2)}
                        sx={{ color: 'warning.main', textTransform: 'none', width: '100%' }}
                    >
                        LATE
                    </Button>
                    <Button
                        onClick={(e) => handleChangeButtonClick(e, student_course.student.id, findEventId(course, date), date, 3)}
                        sx={{ color: 'error.main', textTransform: 'none', width: '100%' }}
                    >
                        ABSENT (PERSONAL)
                    </Button>
                    <Button
                        onClick={(e) => handleChangeButtonClick(e, student_course.student.id, findEventId(course, date), date, 4)}
                        sx={{ color: 'error.main', textTransform: 'none', width: '100%' }}
                    >
                        ABSENT (GROUP)
                    </Button>
                    <Button
                        onClick={(e) => handleChangeButtonClick(e, student_course.student.id, findEventId(course, date), date, null)}
                        sx={{ color: 'grey', textTransform: 'none', width: '100%' }}
                    >
                        UNDECIDED
                    </Button>
                    <NoteButton
                        student_course={student_course}
                        course={course}
                        date={date}
                        attendance_map={attendance_map}
                        setInfoUpdated={setInfoUpdated}
                        findEventId={findEventId}
                        setErrorAlertOpen={setErrorAlertOpen}
                        setErrorAlertMessage={setErrorAlertMessage}
                        handlePopperClose={handleClose}
                    />
                </Box>
            </Popover>
        </div>
    )
}

export default AttendanceCheck