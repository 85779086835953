import React from 'react'
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '../../../components/MoreVertIcon';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteDialog from '../../../components/DeleteDialog';
import Axios from 'axios';
import UpdateModal from './UpdateModal';

var options = {
    year: "numeric",
    month: "short",
    day: "numeric"
};  

const LogHistory = ({ 
    eventModule, 
    courseStudents,
    userRole, 
    index, 
    setBackdropOpen,
    setSuccessAlertOpen,
    setErrorAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertMessage,
 }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: colors.primary[300],
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
        border: '1px solid rgba(224, 224, 224, 1)',
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: colors.primary[750],
        },
        '&:nth-of-type(even)': {
            backgroundColor: colors.primary[750],
        },
    }));
    
    // for moreVertIcon popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMoreVertIconClick = (event) => {
        if (userRole === "student")
            return;
        setAnchorEl(event.currentTarget);
    };
    const handlePopOverClose = () => {
        setAnchorEl(null);
    };
    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    const studentIdToName = {};
    if (courseStudents !== null) {
        // create a hash map of student id to student name
        if (courseStudents !== null) {
            courseStudents.forEach((element) => {
                let student_name = "";
                if (element.student.first_name && element.student.first_name !== "")
                    student_name += element.student.first_name;
                if (element.student.korean_name && element.student.korean_name !== "")
                    student_name += " " + element.student.korean_name;
                if (element.student.last_name && element.student.last_name !== "")
                    student_name += " " + element.student.last_name;

                studentIdToName[element.student_id] = student_name;
            });
        }
    }

    // for deleting log
    const handleDeleteLog = async () => {
        setBackdropOpen(true);
        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/events/${eventModule.id}/special-notes/deletion`, {
            all_special_notes: eventModule.special_notes
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((res) => {
            setSuccessAlertMessage("Log deleted successfully!");
            setSuccessAlertOpen(true);
            setBackdropOpen(false);
            window.location.reload();
        })
        .catch((err) => {
            setErrorAlertMessage(err.response.data.message);
            setErrorAlertOpen(true);
            setBackdropOpen(false);
        });
    };

    return (
        <Box sx={{ mb: "1rem" }}>
            <Accordion 
                key={eventModule.id}
                sx={{ 
                    backgroundColor: colors.primary[750],
                    boxShadow: "0px 0px 1px 2px rgba(0, 0, 0, 0.30)",
                }}
                defaultExpanded={index === 0 ? true : false}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{eventModule.title}</Typography>
                    <Typography sx={{ ml: "auto", mr: "1rem" }}>
                        {new Date(eventModule.start).toLocaleDateString("en", options)}
                    </Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails sx={{ backgroundColor: colors.primary[600] }} >
                    <Box sx={{ display: "flex", justifyContent: "space-between", py: "1rem"}}>
                        <Box sx={{ display: "flex", py: "1rem"}}>
                            <Typography alignContent="center" fontWeight="bold">Progress: </Typography>
                            <Typography sx={{ ml: "0.5rem" }}>
                                {eventModule.progress} (pp.{eventModule.progress_from} - pp.{eventModule.progress_to}){eventModule.progress_2 && `, ${eventModule.progress_2} (pp.${eventModule.progress_from_2} - pp.${eventModule.progress_to_2})`}{eventModule.progress_3 && `, ${eventModule.progress_3} (pp.${eventModule.progress_from_3} - pp.${eventModule.progress_to_3})`}
                            </Typography>
                        </Box>
                        <MoreVertIcon handleClick={handleMoreVertIconClick} />
                        <Popover
                            id={popoverId}
                            open={popoverOpen}
                            anchorEl={anchorEl}
                            onClose={handlePopOverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <UpdateModal 
                                eventModule={eventModule}
                                special_notes={eventModule.special_notes}
                                courseStudents={courseStudents}
                                setAnchorEl={setAnchorEl}
                                setBackdropOpen={setBackdropOpen}
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                setErrorAlertOpen={setErrorAlertOpen}
                                setSuccessAlertMessage={setSuccessAlertMessage}
                                setErrorAlertMessage={setErrorAlertMessage}
                            />
                            {userRole === "admin" &&
                                <DeleteDialog 
                                    handleDelete={handleDeleteLog}
                                    setAnchorEl={setAnchorEl} 
                                    dialogTitle={"Are you sure you want to delete this log?"} 
                                />
                            }
                        </Popover>
                    </Box>
                    <Divider />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell align="right">Evaluation 1</StyledTableCell>
                                    <StyledTableCell align="right">Evaluation 2</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {eventModule.special_notes !== null && eventModule.special_notes.length > 0 &&
                                    eventModule.special_notes.map((special_note) => (
                                    <StyledTableRow
                                        key={special_note.student_id}
                                    >
                                        <StyledTableCell component="th" scope="special_note">
                                            {studentIdToName[special_note.student_id]}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {special_note.eval_1 && special_note.eval_1.split("\n").map((item, key) => {
                                                return <span key={key}>{item}<br/></span>
                                            })}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {special_note.eval_2 && special_note.eval_2.split("\n").map((item, key) => {
                                                return <span key={key}>{item}<br/></span>
                                            })}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <Divider /> */}
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default LogHistory