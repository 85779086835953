import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { Box, useTheme, Typography } from "@mui/material";
import { FileUploadOutlined } from '@mui/icons-material';
import { tokens } from "../../../theme";
import ClearIcon from '@mui/icons-material/Clear';
import Axios from 'axios';

const UpdateReplyModal = ({
    courseId, 
    questionId,
    userRole,
    reply, 
    setBackdropOpen, 
    setSuccessAlertOpen, 
    setErrorAlertOpen, 
    setSuccessAlertMessage,
    setErrorAlertMessage,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [files, setFiles] = React.useState(reply.question_reply_files ? reply.question_reply_files : []);
    const [open, setOpen] = React.useState(false);

    const [author, setAuthor] = React.useState(reply.author);
    const [replyContent, setReplyContent] = React.useState(reply.reply);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleFileRemove = (event, index) => {
        event.preventDefault();
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setBackdropOpen(true);

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            if (files[i] instanceof File) {
                if (files[i].name !== undefined
                    && files[i].name !== null
                    && files[i].name !== ""
                    && files[i].name.length > 150) {
                    setErrorAlertOpen(true);
                    setErrorAlertMessage("File name should be less than 150 characters");
                    setBackdropOpen(false);
                    return;
                }
                formData.append('file', files[i], files[i].name);
            } else
                formData.append('old_files', files[i].name);
        }

        formData.append('author', author);
        formData.append('reply', replyContent);
        
        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/course-questions/${questionId}/replies/${reply.id}`, formData, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            setSuccessAlertMessage("The reply has been updated successfully!");
            setSuccessAlertOpen(true);
            setBackdropOpen(false);
            window.location.reload();
        }).catch((error) => {
            console.log(error);
            setBackdropOpen(false);
            setErrorAlertMessage(error.response.data.message || error.response.data.error || "Something went wrong!");
            setErrorAlertOpen(true);
        });
    };

    const handleUpload = (event) => {
        setFiles([...files, ...Array.from(event.target.files)]);
    };

    return (
        <div>
            <Button onClick={handleOpen} sx={{ p: 1, width: "100%" }}>Update</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                                display: "flex",
                                flexDirection: "column",
                                mr: "1rem",
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                        >
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "1rem",
                                minWidth: "100%",
                            }}>
                                <TextField
                                    id="filled-basic"
                                    label="Author"
                                    variant="filled"
                                    name='author'
                                    sx={{
                                        flex: "1",
                                        maxWidth: "50%",
                                        '& .MuiFilledInput-root': {
                                            backgroundColor: colors.primary[650],
                                            ":focus": {
                                                backgroundColor: 'white',
                                            },
                                        },
                                    }}
                                    value={author}
                                    onChange={(e) => setAuthor(e.target.value)}
                                    disabled={userRole === 'admin' ? false : true}
                                />
                            </Box>
                            <TextField
                                id="filled-basic"
                                label="Reply"
                                variant="filled"
                                name='reply'
                                fullWidth={true}
                                multiline={true}
                                rows={20}
                                sx={{
                                    minWidth: "100%",
                                    borderRadius: "5px",
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[650],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                                value={replyContent}
                                onChange={(e) => setReplyContent(e.target.value)}
                            />
                            <Box
                                display="flex"
                                minWidth="100%"
                                flexDirection="row"
                                justifyContent="space-between"
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        mb={-2}
                                    >
                                        <Typography variant="h6" fontWeight="bold" color={colors.primary[450]}>
                                            Upload Files
                                        </Typography>
                                        <IconButton component="label">
                                            <FileUploadOutlined />
                                            <input
                                                multiple={true}
                                                styles={{display:"none"}}
                                                type="file"
                                                hidden
                                                onChange={handleUpload}
                                                name="[licenseFile]"
                                            />
                                        </IconButton>
                                    </Box>
                                    <Box
                                        display="flex"
                                        minWidth="100%"
                                        flexDirection="column"
                                    >
                                        {files.length > 0 && files.map((file, index) => {
                                            return (
                                                <Box key={index} display="flex" alignItems="center">
                                                    <Typography variant="body1" color={colors.primary[450]}>
                                                        {file.name}
                                                    </Typography>
                                                    <IconButton onClick={(e) => handleFileRemove(e, index)}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{
                                    backgroundColor: colors.primary[300],
                                    color: "white",
                                    float: "right",
                                    marginTop: "0.5rem",
                                    marginBottom: "1rem",
                                    width: "10rem",
                                }}
                            >
                                Update
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default UpdateReplyModal