export const getTestCategory = () => {
    return [
        {
            value: "SAT",
            label: "SAT",
            section: [
                {
                    value: "With Section",
                    label: "With Section",
                },
                {
                    value: "No Section",
                    label: "No Section",
                },
            ],
        },
        {
            value: "ACT",
            label: "ACT",
            section: [
                {
                    value: "With Section",
                    label: "With Section",
                    numChoices: [
                        {
                            value: "4",
                            label: "4", 
                        },
                        {
                            value: "5",
                            label: "5",        
                        }
                    ]
                },
                {
                    value: "No Section",
                    label: "No Section",
                },
            ],
        },
        {
            value: "SSAT",
            label: "SSAT",
            section: [
                {
                    value: "With Section",
                    label: "With Section",
                },
                {
                    value: "No Section",
                    label: "No Section",
                },
            ],
        },
        {
            value: "ISEE",
            label: "ISEE",
            section: [
                {
                    value: "With Section",
                    label: "With Section",
                },
                {
                    value: "No Section",
                    label: "No Section",
                },
            ],
        },
        {
            value: "Vocab",
            label: "Vocab",
            section: [
                {
                    value: "With Section",
                    label: "With Section",
                },
                {
                    value: "No Section",
                    label: "No Section",
                },
            ],
        },
        {
            value: "2024 Summer Final",
            label: "2024 Summer Final",
            section: [
                {
                    value: "With Section",
                    label: "With Section",
                },
                {
                    value: "No Section",
                    label: "No Section",
                },
            ],
        },

    ];
};