import React from 'react'
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';
import EachMemo from './EachMemo';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -1,
        top: 1,
        padding: '0 4px',
    },
}));  

const MemoNotification = ({ reminders, setNotificationRemoved }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // popover for reminders
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => { setAnchorEl(event.currentTarget) };
    const handleClose = () => { setAnchorEl(null) };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (<>
        <IconButton
            style={{ marginRight: '1rem' }}
            aria-describedby={id}
            onClick={handleClick}
        >
            {theme.palette.mode === "light" ? (
                <StyledBadge badgeContent={reminders.length} max={99} color="warning" >
                    <NotificationsOutlinedIcon />
                </StyledBadge>
            ) : (
                <StyledBadge badgeContent={reminders.length} max={99} color="error" >
                    <NotificationsOutlinedIcon />
                </StyledBadge>
            )}
        </IconButton>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPopover-paper': {
                    borderRadius: '10px',
                    border: '1px solid',
                    backgroundColor: colors.primary[750],
                    px: 2,
                    width: 500
                },
            }}
        >
            <Box sx={{ display: "flex" }} >
                <Box sx={{ flex: 1, p: 1, display: "flex", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ my: 1, fontWeight: "bold" }}>Enrolled Students</Typography>
                    {reminders.map((reminder, index) => {
                        if (reminder.student.is_potential_student)
                            return null;
                        return (
                            <EachMemo key={index} reminder={reminder} setNotificationRemoved={setNotificationRemoved} />
                        );
                    })}
                </Box>
                <Box sx={{ flex: 1, p: 1, display: "flex", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ my: 1, fontWeight: "bold" }}>Potential Students</Typography>
                    {reminders.map((reminder, index) => {
                        if (!reminder.student.is_potential_student || reminder.student.is_potential_student === null)
                            return null;
                        return (
                            <EachMemo key={index} reminder={reminder} setNotificationRemoved={setNotificationRemoved} />
                        );
                    })}
                </Box>
            </Box>
        </Popover>
    </>)
}

export default MemoNotification