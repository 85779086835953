import React from 'react'
import { tokens } from "../../theme";
import { Box, IconButton, useTheme } from "@mui/material";
import Loading from '../../components/Loading';
import StudentCard from "./StudentCard";
import Header from "../../components/Header";
import Axios from 'axios';
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { motion, AnimatePresence } from "framer-motion";
import Dropdown from '../../components/dropdown/Dropdown';
import { grades, states, registrationFilter } from '../../components/dropdown/DropdownData'
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

const Students = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [loading, setLoading] = React.useState(true);
    const [students, setStudents] = React.useState([]);
    const [search, setSearch] = React.useState(''); // name search
    const [emailSearch, setEmailSearch] = React.useState(''); // email search
    const [parentEmailSearch, setParentEmailSearch] = React.useState(''); // parent email search
    const [studentUpdated, setStudentUpdated] = React.useState(false);
    const [grade, setGrade] = React.useState(0);
    const [state, setState] = React.useState('ALL');
    const [country, setCountry] = React.useState('ALL');
    const [countries, setCountries] = React.useState(null);
    const [registrationStatus, setRegistrationStatus] = React.useState('all');
    const [semesterData, setSemesterData] = React.useState([]);
    const [semester, setSemester] = React.useState('all');
    const season = {1: 'Spring', 2: 'Summer', 3: 'Fall', 4: 'Winter'};

    async function fetchStudents() {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setStudents(response.data);
            setLoading(false);
        }).catch((error) => {
            if (error.response.status === 404) {
                setStudents([]);
                setLoading(false);
                return;
            } else if (error.response.status === 401) {
                handleLogout();
                return;
            }
            alert(error.response.data.message);
        });
        setStudentUpdated(false);
    };

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Students');
        fetchStudents();
    }, [studentUpdated]);

    async function fetchSemesters() {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/semesters`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            const semesters = [];
            response.data.map((semester) => {
                semesters.push({ 
                    value: semester.id, 
                    label: `${semester.year} ${season[semester.season]}`, 
                    season: semester.season, 
                    year: semester.year 
                });
            });

            semesters.sort((a, b) => {
                if (a.year === b.year) {
                    return b.season - a.season;
                }
                return b.year - a.year;
            });

            semesters.unshift({ value: 'all', label: 'All' });
            setSemesterData(semesters);
        }).catch((error) => {
            if (error.response.status === 404) {
                setSemesterData([]);
                return;
            }
            alert(error.response.data.message);
        });
    }

    React.useEffect(() => {
        fetchSemesters();
    }, []);

    async function fetchCountries() {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students/addresses/countries`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            const countries = [];
            response.data.map((address) => {
                if (address.country !== null && address.country !== '')
                    countries.push({ value: address.country, label: address.country });
            });
            countries.unshift({ value: 'ALL', label: 'All' });
            setCountries(countries);
        }).catch((error) => {
            if (error.response.status === 404) {
                setCountries([]);
                return;
            }
            alert(error.response.data.message);
        });
    }

    React.useEffect(() => {
        fetchCountries();
    }, []);

    return (
        <Box m="20px" marginX={3} marginY={3} >
            <Header title="Students" subtitle="A ONE Institute" />
            { loading || countries === null ? 
                <Loading />
            : <>
                <Box display="flex" justifyContent="left" gap={2} p={0} minHeight={70} mb={2}>
                    {/* SEARCH BAR */}
                    <Box
                        display="flex"
                        backgroundColor={colors.primary[600]}
                        borderRadius="3px"
                        boxShadow={10}
                        mb={2}
                        maxWidth={300}
                        minWidth={220}
                    >
                        <InputBase sx={{ ml: 2, flex: 1 }} onChange={(e) => setSearch(e.target.value)} placeholder="Search by student name" />
                        <IconButton type="button" sx={{ p: 1 }}>
                            <SearchIcon />
                        </IconButton>
                    </Box>

                    <Box
                        display="flex"
                        backgroundColor={colors.primary[600]}
                        borderRadius="3px"
                        boxShadow={10}
                        mb={2}
                        maxWidth={300}
                        minWidth={220}
                    >
                        <InputBase sx={{ ml: 2, flex: 1 }} onChange={(e) => setEmailSearch(e.target.value)} placeholder="Search by student email" />
                        <IconButton type="button" sx={{ p: 1 }}>
                            <SearchIcon />
                        </IconButton>
                    </Box>

                    <Box
                        display="flex"
                        backgroundColor={colors.primary[600]}
                        borderRadius="3px"
                        boxShadow={10}
                        mb={2}
                        maxWidth={300}
                        minWidth={220}
                    >
                        <InputBase sx={{ ml: 2, flex: 1 }} onChange={(e) => setParentEmailSearch(e.target.value)} placeholder="Search by parent email" />
                        <IconButton type="button" sx={{ p: 1 }}>
                            <SearchIcon />
                        </IconButton>
                    </Box>
                    
                    {/* GRADE SELECT */}
                    <Dropdown 
                        maxWidth={100} 
                        maxHeight={3} 
                        reactUseState={grade} 
                        setReactUseState={setGrade} 
                        inputLabel="Grade" 
                        data={grades} 
                    />
                    {/* COUNTRY SELECT */}
                    <Dropdown 
                        maxWidth={200} 
                        maxHeight={3} 
                        reactUseState={country} 
                        setReactUseState={setCountry} 
                        inputLabel="Country"
                        data={countries} 
                    />
                    {/* US STATE SELECT */}
                    <Dropdown 
                        maxWidth={200} 
                        maxHeight={3} 
                        reactUseState={state} 
                        setReactUseState={setState} 
                        inputLabel="US State" 
                        data={states} 
                    />
                    {/* REGISTRATION STATUS (current semester) */}
                    <Dropdown 
                        maxWidth={200} 
                        maxHeight={3} 
                        reactUseState={registrationStatus} 
                        setReactUseState={setRegistrationStatus} 
                        inputLabel="Registration" 
                        data={registrationFilter} 
                    />
                    {/* YEAR WHEN REGISTRATION STATUS IS "REGISTERED" OR "NOT REGISTERED" */}
                    {(registrationStatus === 'registered' || registrationStatus === 'not registered') &&
                        <Dropdown
                            maxWidth={200}
                            maxHeight={3}
                            reactUseState={semester}
                            setReactUseState={setSemester}
                            inputLabel="Semester"
                            data={semesterData}
                        />
                    }
                </Box>

                {/* STUDENT CARDS */}
                <motion.div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fill, minmax(260px, 1fr))",
                        rowGap: "1rem",
                        columnGap: "1rem", 
                    }}
                    layout
                >
                    <AnimatePresence>
                        {students
                            .filter((student) => {
                                return search === '' 
                                    ? student 
                                    : student.first_name?.toLowerCase().includes(search.toLowerCase()) 
                                        || student.last_name?.toLowerCase().includes(search.toLowerCase())
                                        || student.korean_name?.toLowerCase().includes(search.toLowerCase());
                            })
                            .filter((student) => {
                                return emailSearch === ''
                                    ? student
                                    : student.email?.toLowerCase().includes(emailSearch.toLowerCase());
                            })
                            .filter((student) => {
                                return parentEmailSearch === ''
                                    ? student
                                    : student.parent_email?.toLowerCase().includes(parentEmailSearch.toLowerCase()) 
                                        || student.parent_email_2?.toLowerCase().includes(parentEmailSearch.toLowerCase());
                            })
                            .filter((student) => {
                                return grade === 0
                                    ? student
                                    : student.grade?.includes(grade);
                            })
                            .filter((student) => {
                                return country === 'ALL'
                                    ? student
                                    : student.address?.country?.includes(country);
                            })
                            .filter((student) => {
                                return state === 'ALL'
                                    ? student
                                    : student.address?.state?.includes(state);
                            })
                            .filter((student) => {
                                return registrationStatus === 'all' 
                                    ? student
                                    : registrationStatus === 'registered' && semester === 'all'
                                        ? student.student_courses.length > 0 && !student.is_blacklist_student
                                    : registrationStatus === 'registered' && semester !== 'all'
                                        ? student.student_courses.filter((student_course) => {
                                            return student_course.course.course_semesters.filter((course_semester) => {
                                                return course_semester.semester_id === semester && !student.is_blacklist_student
                                            }).length > 0
                                        }).length > 0
                                    : registrationStatus === 'not registered' && semester === 'all'
                                        ? student.student_courses.length === 0
                                    : registrationStatus === 'not registered' && semester !== 'all'
                                        ? student.student_courses.filter((student_course) => {
                                            return student_course.course.course_semesters.filter((course_semester) => {
                                                return course_semester.semester_id === semester
                                            }).length > 0
                                        }).length === 0
                                    : registrationStatus === 'blacklist'
                                        ? student.is_blacklist_student
                                    : student                                    
                            })
                        .map((student) => {
                            return (
                                <StudentCard 
                                    student={student} 
                                    setStudentUpdated={setStudentUpdated} 
                                    key={student.id}
                                />
                            );
                        })}
                    </AnimatePresence>
                </motion.div>
            </>}
        </Box>
    )
}

export default Students