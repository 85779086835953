import * as React from 'react';
import { tokens } from "../../../theme";
import { Button, useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import { Link } from 'react-router-dom';
import Confetti from "react-confetti";
import { useWindowSize } from "@uidotdev/usehooks";

const StudentAnswerConfirmModal = ({
    open, 
    onClose,
    onSubmit,
    courseId,
    testId,
    studentUuid,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const size = useWindowSize();

    const styleForConfetti={
        display: 'flex',
        width: '100%',
        height: '100%',
    }

    const style = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 1300,
        // height: 500,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
        minWidth: 350,
        minHeight: 300,
    };

    return (       
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={styleForConfetti}>
                    {open && <Confetti width={size.width} height={size.height} />}
                    <Box sx={style}>
                        <Box mt={2} display="flex" flexDirection="column" justifyContent="space-between">
                            <Typography id="modal-modal-title" variant="h2" ml={1} mb={3} color={"black"} textAlign="center" fontWeight="600">
                                🎉 Well Done! 🎉
                            </Typography>                       
                            <Typography id="modal-modal-description" variant="h4" mt={1} mb={1} ml={1} color={"black"}>                           
                                Your test will be submitted if you click the "<span style={{color: "white", backgroundColor: "green"}}>DONE!</span>" button below.
                            </Typography>
                            <Typography id="modal-modal-description" variant="h4" mt={1} mb={1} ml={1} color={"black"}>                                                       
                                Once submitted, you cannot change your answers or resubmit the test, 
                                so please make sure you have answered all questions and answered them correctly before proceeding.                            
                            </Typography>
                            <Typography id="modal-modal-description" variant="h4" mt={1} mb={1} ml={1} color={"black"}>                                                       
                                If you need to make any changes, click the "<span style={{color: "white", backgroundColor: "red"}}>GO BACK</span>" button below or any area outside of this square box.                            
                            </Typography>
                        </Box>
                        <Box mt={2} display="flex" justifyContent="space-between" width="100%" >
                            <Link to={`/classrooms/${courseId}/test/${testId}/student/${studentUuid}/submission`} style={{ textDecoration: 'none', width: "45%", }}>
                                <Button
                                    type='submit'
                                    onClick={onSubmit}
                                    variant="contained"
                                    color="success"
                                    style={{width: "100%",}}
                                >
                                    Done! (Submit Test)
                                </Button>
                            </Link>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={onClose}
                                    style={{width: "45%",}}
                                >
                                    Go Back
                                </Button>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

export default StudentAnswerConfirmModal;