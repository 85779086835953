export const getSubjects = () => {
    return [
        {
            value: "English", 
            label: "English",
            subject2: [
                {
                    value: "Basic Class", 
                    label: "Basic Class",
                    subject3: [
                        {
                            value: "Reading", 
                            label: "Reading",
                            subject4: [
                                {
                                    value: "Middle Reading V1", 
                                    label: "Middle Reading V1"
                                },
                                {
                                    value: "Middle Reading V2", 
                                    label: "Middle Reading V2"
                                },
                                {
                                    value: "Middle Reading V3", 
                                    label: "Middle Reading V3"
                                },
                                {
                                    value: "High Reading V1", 
                                    label: "High Reading V1"
                                },
                                {
                                    value: "High Reading V2", 
                                    label: "High Reading V2"
                                },
                            ]
                        },
                        {
                            value: "Writing", 
                            label: "Writing",
                            subject4: [
                                {
                                    value: "Middle Writing", 
                                    label: "Middle Writing"
                                },
                                {
                                    value: "High Writing", 
                                    label: "High Writing"
                                },
                            ]
                        }
                    ]
                },
                {
                    value: "AP", 
                    label: "AP",
                    subject3: [
                        {
                            value: "AP Language", 
                            label: "AP Language",
                        },
                        {
                            value: "AP Literature", 
                            label: "AP Literature",
                        }
                    ]
                },
            ]
        },
        {
            value: "Math", 
            label: "Math",
            subject2: [
                {
                    value: "Basic Class", 
                    label: "Basic Class",
                    subject3: [
                        {
                            value: "5th Math", 
                            label: "5th Math",
                        },
                        {
                            value: "6th Math", 
                            label: "6th Math",
                        },
                        {
                            value: "7th Math", 
                            label: "7th Math",
                        },
                        {
                            value: "8th Math", 
                            label: "8th Math",
                        },
                        {
                            value: "Pre-Algebra", 
                            label: "Pre-Algebra",
                        },
                        {
                            value: "Algebra 1", 
                            label: "Algebra 1",
                        },
                        {
                            value: "Geometry", 
                            label: "Geometry",
                        },
                        {
                            value: "Algebra 2", 
                            label: "Algebra 2",
                        },
                        {
                            value: "Pre-Calculus", 
                            label: "Pre-Calculus",
                        },
                    ]
                },
                {
                    value: "AP", 
                    label: "AP",
                    subject3: [
                        {
                            value: "AP Pre-Calculus", 
                            label: "AP Pre-Calculus",
                        },
                        {
                            value: "AP Calculus AB", 
                            label: "AP Calculus AB",
                        },
                        {
                            value: "AP Calculus BC", 
                            label: "AP Calculus BC",
                        },
                        {
                            value: "Multivariable", 
                            label: "Multivariable",
                        },
                    ]
                },
                {
                    value: "Competition", 
                    label: "Competition",
                    subject3: [
                        {
                            value: "AMC 8", 
                            label: "AMC 8",
                        },
                        {
                            value: "AMC 10", 
                            label: "AMC 10",
                        },
                        {
                            value: "AMC 12", 
                            label: "AMC 12",
                        },
                    ]
                },
            ]
        },
        {
            value: "Science", 
            label: "Science",
            subject2: [
                {
                    value: "Middle Science", 
                    label: "Middle Science",
                    subject3: [
                        {
                            value: "Session 1", 
                            label: "Session 1",
                        },
                        {
                            value: "Session 2", 
                            label: "Session 2",
                        },
                    ]
                },
                {
                    value: "Biology", 
                    label: "Biology",
                    subject3: [
                        {
                            value: "Biology (h)", 
                            label: "Biology (h)",
                        },
                        {
                            value: "AP Biology", 
                            label: "AP Biology",
                        },
                    ]
                },
                {
                    value: "Chemistry", 
                    label: "Chemistry",
                    subject3: [
                        {
                            value: "Chemistry (h)", 
                            label: "Chemistry (h)",
                        },
                        {
                            value: "AP Chemistry", 
                            label: "AP Chemistry",
                        },
                    ]
                },
                {
                    value: "Physics", 
                    label: "Physics",
                    subject3: [
                        {
                            value: "Physics (h)", 
                            label: "Physics (h)",
                        },
                        {
                            value: "AP Physics", 
                            label: "AP Physics",
                        },
                    ]
                },
            ]
        },
        {
            value: "History", 
            label: "History",
            subject2: [
                {
                    value: "Middle", 
                    label: "Middle",
                    subjects3: [
                        {
                            value: "US History", 
                            label: "US History",
                        },
                        {
                            value: "World History",
                            label: "World History",
                        }
                    ]
                },
                {
                    value: "High", 
                    label: "High",
                    subject3: [
                        {
                            value: "US History", 
                            label: "US History",
                        },
                        {
                            value: "World History", 
                            label: "World History",
                        },
                        {
                            value: "AP US History", 
                            label: "AP US History",
                        },
                        {
                            value: "AP World History", 
                            label: "AP World History",
                        },
                    ]
                },
            ]
        },
        {
            value: "Competition", 
            label: "Competition",
            subject2: [
                {
                    value: "Writing Competition", 
                    label: "Writing Competition",
                    subjects3: [
                        {
                            value: "W.C", 
                            label: "W.C",
                        },
                    ]
                },
                {
                    value: "AMC", 
                    label: "AMC",
                    subject3: [
                        {
                            value: "AMC 8", 
                            label: "AMC 8",
                        },
                        {
                            value: "AMC 10", 
                            label: "AMC 10",
                        },
                        {
                            value: "AMC 12", 
                            label: "AMC 12",
                        },
                        {
                            value: "AIME",
                            label: "AIME",
                        }
                    ]
                },
                {
                    value: "CSHP", 
                    label: "CSHP",
                    subject3: [
                        {
                            value: "Math", 
                            label: "Math",
                        },
                        {
                            value: "Biology", 
                            label: "Biology",
                        },
                        {
                            value: "Chemistry", 
                            label: "Chemistry",
                        },
                        {
                            value: "Physics", 
                            label: "Physics",
                        },
                    ]
                },
                {
                    value: "BCA", 
                    label: "BCA",
                    subject3: [
                        {
                            value: "Math", 
                            label: "Math",
                        },
                        {
                            value: "Essay", 
                            label: "Essay",
                        },
                    ]
                },
                {
                    value: "Science Bee", 
                    label: "Science Bee",
                    subject3: [
                        {
                            value: "Middle", 
                            label: "Middle",
                        },
                        {
                            value: "High", 
                            label: "High",
                        },
                    ]
                },
                {
                    value: "History Bee", 
                    label: "History Bee",
                    subject3: [
                        {
                            value: "Middle", 
                            label: "Middle",
                        },
                        {
                            value: "High", 
                            label: "High",
                        },
                    ]
                },
                {
                    value: "Olympiad", 
                    label: "Olympiad",
                    subject3: [
                        {
                            value: "Biology", 
                            label: "Biology",
                        },
                        {
                            value: "Chemistry",
                            label: "Chemistry",
                        },
                        {
                            value: "Physics",
                            label: "Physics",
                        }
                    ]
                },
            ]
        },
        {
            value: "SAT", 
            label: "SAT",
            subject2: [
                {
                    value: "English", 
                    label: "English",
                },
                {
                    value: "Math", 
                    label: "Math",
                },
            ]
        },
        {
            value: "ACT", 
            label: "ACT",
            subject2: [
                {
                    value: "English", 
                    label: "English",
                },
                {
                    value: "Math", 
                    label: "Math",
                },
            ]
        },
    ];
};