import React from 'react';
import { useTheme, Popover, Button, Modal, Fade, Box, TextField, InputAdornment } from '@mui/material';
import { tokens } from "../../theme";
import { AccountCircle } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Axios from 'axios';

const PopoverControl = ({ 
    index, 
    course, 
    courseIdStudentCoursesMap, 
    color_map,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
    fetchPotentialCourses,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [open, setOpen] = React.useState(false);
    const handleOpenTeacherModal = () => setOpen(true);
    const handleCloseTeacherModal = () => { setOpen(false); setAnchorEl(null); };
    const [anchorEl, setAnchorEl] = React.useState(null);

    const popoverOpen = Boolean(anchorEl);

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleMoreVertIconClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCourseClick = (course) => {
        handleCloseTeacherModal();
        const element = document.getElementById(course.uuid);
        element.scrollIntoView({ behavior: "instant" });
    };

    const [teacherName, setTeacherName] = React.useState("");

    const handleTeacherNameChange = (e) => {
        setTeacherName(e.target.value);
    };

    function doesCourseHaveConfimedStudent(course) {
        const student_courses = courseIdStudentCoursesMap[course.id];
        if (student_courses && student_courses.length > 0) {
            for (let i = 0; i < student_courses.length; i++) {
                if (student_courses[i].is_confirmed) {
                    return true;
                }
            }
        }
        return false;
    }

    function getBackgroundColor(course, index) {
        if (color_map[course.id]) {
            return color_map[course.id];
        }
        if (index % 2 === 0) {
            return colors.grey[700];
        }
        return "none";
    }

    const style = {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "15%",
        height: "15%",
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        p: 4,
    };

    async function saveTeacherName(course_id, teacher_name) {
        if (!course_id) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Course id is not provided!");
            return;
        }

        if (!teacher_name) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Teacher name is not provided!");
            return;
        }

        handleCloseTeacherModal();

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/courses/${course_id}/save-teacher-name`, {
            teacher_name,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message);
            fetchPotentialCourses();
        }).catch((error) => {
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.error);
        });
    }

    return (
        <>
            <Box
                key={course.uuid}
                id={`subject-${course.uuid}`}
                sx={{
                    position: "relative",
                    bgcolor: getBackgroundColor(course, index),
                    border: index % 2 !== 0 ? "1px solid " + colors.grey[700] : "none",
                    height: 50,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    cursor: "pointer",
                    margin: 0.1,
                    "&:hover": {
                        bgcolor: colors.greenAccent[500],
                    }
                }}
                onClick={(event) => handleMoreVertIconClick(event)}
            >
                {doesCourseHaveConfimedStudent(course) && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: "4px",
                            right: "4px",
                        }}
                    >
                        <CheckBoxIcon color='error' />
                    </Box>
                )}
                {course.course_title}
                <br />
                {course.teacher_name}
            </Box>
            <Popover
                id={`popover-${course.uuid}`}
                open={popoverOpen}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}               
            >
                <div>
                    <Button
                        onClick={handleOpenTeacherModal}
                        variant="outlined"
                        color="primary"
                        sx={{
                            width: "100%",
                            border: "1px solid transparent",
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        }}
                    >
                        Add Teacher
                    </Button>
                    <Modal
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        open={open}
                        onClose={handleCloseTeacherModal}
                    >
                        <Fade in={open}>
                            <Box sx={style}>
                                <Box sx={{ mb: 2 }}>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        label="Teacher Name"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountCircle />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                        onChange={handleTeacherNameChange}
                                        defaultValue={course.teacher_name ? course.teacher_name : ""}
                                        helperText={`Selected Course: ${course.course_title}`}
                                    />
                                </Box>
                                <Box>
                                    <Button
                                        variant='contained'
                                        onClick={() => saveTeacherName(course.id, teacherName)}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Fade>
                    </Modal>
                </div>
                <div>
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{
                            width: "100%",
                            border: "1px solid transparent",
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        }}
                        onClick={() => handleCourseClick(course)}
                    >
                        Go to Course Detail
                    </Button>
                </div>
            </Popover>
        </>
    );
};

export default PopoverControl;