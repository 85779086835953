import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Axios from 'axios';

const CheckBox = ({ 
    colors, 
    courseData,
    homeworkData,
    feedback, 
    setBackdropOpen,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
    handleLogout,
}) => {
    // for dialog
    const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
    const handleClose = () => {
        setOpenAlertDialog(false);
    };
    const handleOpen = () => setOpenAlertDialog(true);
    const handleDelete = async () => {
        setBackdropOpen(true);
        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/courses/${courseData.uuid}/homeworks/${homeworkData.id}/feedback/${feedback.id}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message);
            setBackdropOpen(false);
            window.location.reload();
        }).catch((error) => {
            if (error.response.status === 401) {
                handleLogout();
                return;
            }
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.message || error.response.data.error || "Something went wrong!");
            setBackdropOpen(false);
        });
    };

    return (
        <>
            <CheckBoxIcon 
                onClick={handleOpen}
                sx={{ 
                    color: colors.blueAccent[600] ,
                    '&:hover': {
                        color: 'error.main',
                        cursor: 'pointer',
                    },
                }} 
            />
            <Dialog
                open={openAlertDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure you want to delete this feedback?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {handleClose(); handleDelete(feedback.id);}}
                        color='error'
                        autoFocus
                        sx={{
                            ":hover": {
                                backgroundColor: colors.redAccent[500],
                                color: "white",
                            },
                        }}
                    >
                        Yes
                    </Button>
                    <Button onClick={handleClose}>No</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CheckBox