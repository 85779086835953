import React from 'react'
import { tokens } from "../../theme";
import { Box, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Axios from 'axios';
import { motion, AnimatePresence } from "framer-motion";
// import Dropdown from '../../components/dropdown/Dropdown';
import Loading from '../../components/Loading';
import Alert from '../../components/alert/Alert';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TestCard from './TestCard';
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";


const Tests = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [loading, setLoading] = React.useState(true);
    const [tests, setTests] = React.useState(null);
    const [search, setSearch] = React.useState('');

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Tests');

        async function fetchTests () {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/tests`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(async (response) => {
                setTests(response.data);
            }).catch((error) => {
                if (error.response.status === 404) {
                    setTests([]);
                    setLoading(false);
                    return;
                } else if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message);
                setErrorAlertOpen(true);
            });
        }

        fetchTests();
        setLoading(false);
    }, []);

    const handleDragEnd = async (result) => {
        const { destination, source } = result;

        if (!destination) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        const updatedTests = Array.from(tests);
        const [removed] = updatedTests.splice(source.index, 1);
        updatedTests.splice(destination.index, 0, removed);

        try {
            await Axios.put(`${process.env.REACT_APP_URL}/api/v1/tests/order`,
                { newTestOrder: updatedTests.map((test, index) => ({ ...test, order: index + 1 })) },
                {
                    headers: {
                        Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    },
                }
            );

            setTests(updatedTests);
        } catch (error) {
            console.error("Error updating test order:", error);
            setErrorAlertMessage(error.response?.data?.message || "An error occurred while updating the test order");
            setErrorAlertOpen(true);
        }
    };

    return (
        <Box m="20px" marginX={3} marginY={3} >
            <Header title="Test Library" subtitle="A ONE Institute" />
            {loading || tests === null || tests.length === 0 ?
                <Loading />
            : <>
                <Box display="flex" justifyContent="left" gap={2} p={0} minHeight={70} mb={2}>
                    <Box
                        display="flex"
                        backgroundColor={colors.primary[600]}
                        borderRadius="3px"
                        boxShadow={10}
                        mb={2}
                        maxWidth={300}
                        minWidth={220}
                    >
                        <InputBase sx={{ ml: 2, flex: 1 }} onChange={(e) => setSearch(e.target.value)} placeholder="Enter Test Title" />
                        <IconButton type="button" sx={{ p: 1 }}>
                            <SearchIcon />
                        </IconButton>
                    </Box>
                </Box>

                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="testList" direction="horizontal">
                        {(provided, snapshot) => (
                            <Box
                                display="grid"
                                gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))"
                                rowGap="1rem"
                                columnGap="1rem"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                <AnimatePresence>
                                    {tests
                                        .filter((test) => {
                                            return search === ''
                                                ? test
                                                : test.title?.toLowerCase().includes(search.toLowerCase());
                                        })
                                        .map((test, index) => (
                                            <Draggable key={test.id} draggableId={test.id.toString()} index={index}>
                                                {(provided, snapshot) => (
                                                    <Box
                                                        key={test.id}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        sx={{backgroundColor: snapshot.isDragging ? "LightPink" : colors.primary[50]}}
                                                    >                                                                                                              
                                                        <TestCard 
                                                            test={test}
                                                            setErrorAlertMessage={setErrorAlertMessage}
                                                            setErrorAlertOpen={setErrorAlertOpen}
                                                            setSuccessAlertMessage={setSuccessAlertMessage}
                                                            setSuccessAlertOpen={setSuccessAlertOpen}
                                                        />
                                                    </Box>
                                                )}
                                            </Draggable>
                                        ))}
                                </AnimatePresence>
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>
            </>}
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
        </Box>
    )
}

export default Tests