import React from 'react'
import Box from '@mui/material/Box';
import { Document, Page, pdfjs } from "react-pdf";
import './Viewer.css';
import { Button, CircularProgress, Divider, FormControl, TextField, Typography } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { EditorTiny } from '../../../../components/EditorTiny'
// import "//unpkg.com/mathlive";
import 'mathlive';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Axios from 'axios';
import FileUpload from '../../../../components/FileUpload';
import GradeModal from './GradeModal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TextbookViewer = ({ 
    PDF, 
    pageFrom, 
    pageTo, 
    hwQuestions, 
    setHWAnswers, 
    hwAnswers, 
    userId,
    userRole, 
    course_id,
    homework_id,
    colors,
    submissionId,
    isSubmitted,
    setHWAnswerFiles,
    hwAnswerFiles,
    setErrorAlertMessage,
    setErrorAlertOpen,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setBackdropOpen,
    handleLogout,
    setUpdatedHWAnswers,
}) => {  
    const [fromPage] = React.useState(pageFrom);
    const [toPage] = React.useState(pageTo);
    const [selectedPage, setSelectedPage] = React.useState(pageFrom);
    const [loading, setLoading] = React.useState(false);
    const [submitDialogOpen, setSubmitDialogOpen] = React.useState(false);

    const handleClickDialogOpen = () => {
        if (userRole !== 'student') {
            setErrorAlertMessage('Only students can submit homework');
            setErrorAlertOpen(true);
            return;
        }
        setSubmitDialogOpen(true);
    };
  
    const handleDialogClose = () => {
        setSubmitDialogOpen(false);
    };
  
    if (isSubmitted) {
        const mathfields = document.querySelectorAll('math-field');
        // add read-only attribute to all mathfields
        mathfields.forEach((mathfield) => {
            mathfield.setAttribute('read-only', 'true');
        });
    }

    function onDocumentLoadSuccess({ numPages }) {
        
    }

    const documentRender = () => {
        return (
            <Document 
                file={PDF} 
                onLoadSuccess={onDocumentLoadSuccess} 
                onContextMenu={(e) => e.preventDefault()}
                className="pdf-container"
            >
                <div>
                    <Page
                        key={`page_${selectedPage}`}
                        pageNumber={selectedPage}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        className="react-pdf__Page__canvas" 
                    />
                </div>
            </Document>
        );
    };

    React.useEffect(() => {
        setLoading(true);
        documentRender();
        setLoading(false);
    }, [selectedPage]);

    const handlePageChange = (e, upOrDown) => {
        e.preventDefault();
        if (upOrDown === 1) {
            setSelectedPage(selectedPage + 1);
        } else {
            setSelectedPage(selectedPage - 1);
        }
    };

    // const questionTypes = [
    //     { label: "Multiple Choice", value: "multiple_choice" },
    //     { label: "Short Answer", value: "short_answer" },
    //     { label: "Long Answer", value: "long_answer" },
    // ];

    const handleMouseOver = (e) => {
        if (userRole === 'student') {
            return;
        }

        const tooltip = document.querySelector('.tooltip');
        tooltip.style.display = 'flex';
    };

    const handleMouseLeave = (e) => {
        const tooltip = document.querySelector('.tooltip');
        tooltip.style.display = 'none';
    }

    const handleSaveLater = async (e, fromSubmitFunc) => {
        e.preventDefault();
        if (userRole !== 'student') {
            return false;
        }

        if (!fromSubmitFunc) {
            setBackdropOpen(true);
        }

        // save the answers
        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/hw-answers/save?user_id=${userId}`, {
            answers: Object.keys(hwAnswers).map((key) => {
                return {
                    id: hwAnswers[key].id,
                    answer: hwAnswers[key].answer,
                    hw_question_id: key,
                };
            }),
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).catch((error) => {
            if (error.response.status === 401) {
                handleLogout();
                return false;
            }
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return false;
        });

        const promises = [];

        // save the files
        Object.keys(hwAnswerFiles).map(async (key) => {
            const formData = new FormData();

            const new_file = hwAnswerFiles[key].file ? hwAnswerFiles[key].file : null;
            const prev_file = hwAnswerFiles[key].prev_file ? hwAnswerFiles[key].prev_file : null;
            const hw_question_id = key;
            const hw_submission_id = submissionId;

            if (!new_file || !hw_question_id) {
                setBackdropOpen(false);
                return false;
            }

            formData.append('file', new_file);
            formData.append('prev_file_id', prev_file ? prev_file.id : null);
            formData.append('hw_question_id', hw_question_id);
            formData.append('hw_submission_id', hw_submission_id);

            promises.push(
                Axios.post(`${process.env.REACT_APP_URL}/api/v1/hw-answer-files/save-file?user_id=${userId}`, formData, {
                    headers: {
                        Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    },
                })
            );
        });

        Promise.all(promises).catch((error) => {
            if (error.response.status === 401) {
                handleLogout();
                return false;
            }
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return false;
        });

        if (!fromSubmitFunc) {
            setSuccessAlertMessage('Your answers saved successfully!');
            setSuccessAlertOpen(true);
        }
        setBackdropOpen(false);
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // do homework save (handleSaveLater)
        // and then change is_submitted to true on hw_submissions table
        // finally, go back to the homework submissions page
        setBackdropOpen(true);

        // 1. save the answers
        const fromSubmitFunc = true;
        const isSuccessful = await handleSaveLater(e, fromSubmitFunc);
        if (!isSuccessful) {
            setBackdropOpen(false);
            handleDialogClose();
            return;
        }

        // 2. change is_submitted to true on hw_submissions table
        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/courses/${course_id}/homeworks/${homework_id}/students/${userId}/hw-submissions/${submissionId}/hw-questions/submit`, {
            is_submitted: true,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setBackdropOpen(false);
            setSuccessAlertMessage('Your homework submitted successfully!');
            setSuccessAlertOpen(true);
            setTimeout(() => {
                window.location.href = `/classrooms/${course_id}/homework/${homework_id}/submissions`;
            }, 1000);
        }).catch((error) => {
            if (error.response.status === 401) {
                handleLogout();
                return;
            }
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
            setBackdropOpen(false);
        });
    };

    const handleAnswerChange = (e, hw_question_id) => {
        e.preventDefault();
        
        // change the answer
        setHWAnswers((prev) => ({
            ...prev,
            [hw_question_id]: {
                ...prev[hw_question_id],
                answer: e.target.value,
            },
        }));
    };
    
    const flexDirectionForAnswer = (answer_tool, question_type) => {
        if (answer_tool === 'tinymce' || question_type === 'file_upload') {
            return 'column';
        } else {
            return 'row';
        }
    };

    const alignItemsForAnswer = (answer_tool, question_type) => {
        if (answer_tool === 'tinymce' || question_type === 'file_upload') {
            return 'flex-start';
        } else {
            return 'center';
        }
    };

    const handleResult = (hw_answer, type) => {
        if (type === 'multiple_choice') {
            return (
                <img src={hw_answer.is_correct ? '/imgs/check-icon.png' : '/imgs/x-icon-final.png'} alt={hw_answer.is_correct ? 'Correct' : 'Incorrect'} style={{ width: '30px' }} />
            );
        } else if (type === 'short_answer') {
            return (
                <img src={hw_answer.is_correct ? '/imgs/check-icon.png' : '/imgs/x-icon-final.png'} alt={hw_answer.is_correct ? 'Correct' : 'Incorrect'} style={{ width: '30px' }} />
            );
        } else if (type === 'file_upload' || type === 'long_answer') {
            return (
                <Box
                    sx={{
                        padding: '10px',
                        borderRadius: '5px',
                        backgroundColor: colors.primary[750],
                        border: '1px solid lightgrey',
                    }}
                >
                    <Typography 
                        variant="h5"
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >
                        {hw_answer?.given_score && hw_answer?.total_score ? `Score: ${hw_answer?.given_score}%` : 'Not graded yet'}
                    </Typography>
                    {hw_answer?.feedback !== null && hw_answer?.feedback !== '' && (
                        <Box
                            sx={{
                                marginTop: '20px',
                            }}
                        >
                            <Typography 
                                variant="h5"
                                sx={{
                                    fontWeight: 'bold',
                                    marginBottom: '10px',
                                }}
                            >
                                Feedback: 
                            </Typography>
                            {hw_answer?.feedback.split('\n').map((line, index) => {
                                return (
                                    <Typography key={index} variant="h6">
                                        {line === '' ? <br /> : line}
                                    </Typography>
                                );
                            })}
                        </Box>
                    )}
                    {userRole !== 'student' && (
                        <GradeModal
                            hw_answer={hw_answer}
                            setErrorAlertMessage={setErrorAlertMessage}
                            setErrorAlertOpen={setErrorAlertOpen}
                            setSuccessAlertOpen={setSuccessAlertOpen}
                            setSuccessAlertMessage={setSuccessAlertMessage}
                            setBackdropOpen={setBackdropOpen}
                            setUpdatedHWAnswers={setUpdatedHWAnswers}
                        />
                    )}
                </Box>
            );
        }
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: "1rem",
                    gap: "1rem",
                    justifyContent: "center",
                    width: "100%",
                    position: "relative",
                }}
            >
                {/* Page Navigator */}
                {selectedPage !== fromPage && (
                    <NavigateBeforeIcon fontSize='large' sx={{cursor: 'pointer'}} onClick={(e) => handlePageChange(e, 0)} />
                )}
                <Typography variant="h6" color="textSecondary">
                    Page {selectedPage} ({fromPage} - {toPage})
                </Typography>
                {selectedPage !== toPage && (
                    <NavigateNextIcon fontSize='large' sx={{cursor: 'pointer'}} onClick={(e) => handlePageChange(e, 1)} />
                )}
                {!isSubmitted && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                            display: "flex",
                            gap: "10px"
                        }}
                    >
                        <Box
                            className="tooltip"
                            sx={{
                                display: "none",
                                gap: "10px",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "absolute",
                                top: "50px",
                                right: "0",
                                backgroundColor: colors.primary[600],
                                padding: "10px",
                                borderRadius: "5px",
                                border: "1px solid #000",
                                zIndex: "100",
                                textAlign: "center",
                            }}
                        >
                            <Typography variant="h6">
                                Only students can submit homework
                            </Typography>
                        </Box>
                        <Button
                            type='button' 
                            variant="contained" 
                            color="info"
                            sx={{
                                minWidth: "8rem",
                                cursor: userRole === 'student' ? 'pointer' : 'not-allowed',
                            }}
                            onClick={(e) => handleSaveLater(e, false)}
                            onMouseOver={handleMouseOver}
                            onMouseLeave={handleMouseLeave}
                        >
                            Save for later
                        </Button>
                        <Button 
                            type='button' 
                            variant="contained" 
                            color="secondary"
                            sx={{
                                minWidth: "4rem",
                                cursor: userRole === 'student' ? 'pointer' : 'not-allowed',
                            }}
                            onClick={handleClickDialogOpen}
                            onMouseOver={handleMouseOver}
                            onMouseLeave={handleMouseLeave}
                        >
                            Submit
                        </Button>
                        <Dialog
                            open={submitDialogOpen}
                            onClose={handleDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle 
                                id="alert-dialog-title"
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '22px',
                                    minWidth: '500px',
                                }}
                            >
                                {"Are you sure you want to submit?"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description" sx={{ fontSize: '16px' }}>
                                    You cannot change your answers after submitting and 
                                    there will not be a second attempt for this homework.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ padding: '20px' }}>
                                <Button onClick={handleDialogClose} variant="contained" color='error'>Cancel</Button>
                                <Button 
                                    onClick={handleSubmit} 
                                    autoFocus
                                    variant="contained"
                                    color="secondary"
                                >
                                    Submit
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                )}
            </Box>
            <Box
                display="flex"
                width="100%"
            >
                <Box flex={8} width="100%" position="relative">
                    <Box
                        sx={{
                            position: "sticky",
                            top: 0,
                        }}
                    >
                        {documentRender()}
                    </Box>
                    {loading && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        >
                            <CircularProgress color="inherit" size={50} />
                        </Box>
                    )}
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box 
                    flex={7} 
                    width="100%" 
                    p={5}
                    display='flex'
                    flexDirection='column'
                    gap={3}
                    sx={{
                        overflowY: 'auto',
                        overscrollBehavior: 'contain',
                    }}
                >
                    {/* hw questions */}
                    {hwQuestions && hwQuestions.length > 0 ? (<>
                        {hwQuestions.map((question, index) => {
                            if (question.page !== selectedPage)
                                return null;

                            return (
                                <Box 
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '20px',
                                        paddingBottom: '20px',
                                        borderBottom: index !== hwQuestions.length - 1 ? '1px solid lightgrey' : 'none',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: flexDirectionForAnswer(question.answer_tool, question.type),
                                            alignItems: alignItemsForAnswer(question.answer_tool, question.type),
                                            gap: '20px',
                                        }}
                                    >
                                        <Typography variant="h4" sx={{'fontWeight': 'bold'}}>
                                            {question.question_label} {question.type === 'file_upload' && !isSubmitted ? 'Upload an image file for this question!' : null}
                                        </Typography>
                                        {question.type === 'multiple_choice' ? (
                                            <FormControl
                                                disabled={isSubmitted}
                                            >
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={hwAnswers[question.id]?.answer}
                                                    onChange={(e) => handleAnswerChange(e, question.id)}
                                                >
                                                    {question.options?.split('|').map((option, index) => {
                                                        return (
                                                            <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
                                                        );
                                                    })}
                                                </RadioGroup>
                                            </FormControl>
                                        ) : question.type === 'short_answer' && question.answer_tool === 'textfield' ? (
                                            <TextField
                                                disabled={isSubmitted}
                                                variant="outlined"
                                                type="text"
                                                label={userRole === 'student' ? 'Your Answer' : 'Student Answer'}
                                                value={hwAnswers[question.id]?.answer || ''}
                                                onChange={(e) => handleAnswerChange(e, question.id)}
                                                sx={{ maxWidth: '200px' }}
                                            />
                                        ) : question.type === 'short_answer' && question.answer_tool === 'mathlive' ? (
                                            <>
                                                <math-field 
                                                    id="mathfield"
                                                    onInput={evt => {
                                                        handleAnswerChange(evt, question.id);
                                                    }}
                                                    style={{ width: "200px" }}
                                                >
                                                    {/* {hwAnswers[question.id]?.answer || ''} */}
                                                </math-field>
                                                <p>{userRole === 'student' ? 'Your Answer' : 'Student Answer'}: {hwAnswers[question.id]?.answer || ''}</p>
                                            </>
                                        ) : (question.type === 'short_answer' || question.type === 'long_answer') && question.answer_tool === 'tinymce' ? (
                                            <Box width={'100%'}>
                                                {isSubmitted ? (
                                                    <div dangerouslySetInnerHTML={{__html: hwAnswers[question.id]?.answer || ''}} />
                                                ) : (
                                                    <EditorTiny 
                                                        value={hwAnswers[question.id]?.answer || ''} 
                                                        setHWAnswers={setHWAnswers} 
                                                        hw_question_id={question.id}
                                                    />
                                                )}
                                            </Box>
                                        ) : question.type === 'long_answer' && question.answer_tool === 'textfield' ? (
                                            <TextField 
                                                disabled={isSubmitted}
                                                multiline
                                                variant="outlined"
                                                type="text"
                                                label={userRole === 'student' ? 'Your Answer' : 'Student Answer'}
                                                value={hwAnswers[question.id]?.answer || ''}
                                                onChange={(e) => handleAnswerChange(e, question.id)}
                                                sx={{ width: '100%' }}
                                            />
                                        ) : question.type === 'long_answer' && question.answer_tool === 'mathlive' ? (
                                            <>
                                                <math-field 
                                                    id="mathfield"
                                                    onInput={evt => {
                                                        handleAnswerChange(evt, question.id);
                                                    }}
                                                    style={{ width: "100%" }}
                                                >
                                                    {/* {hwAnswers[question.id]?.answer || ''} */}
                                                </math-field>
                                                <p>{userRole === 'student' ? 'Your Answer' : 'Student Answer'}: {hwAnswers[question.id]?.answer || ''}</p>
                                            </>
                                        ) : question.type === 'file_upload' ? (
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                sx={{ width: "100%", gap: "10px" }}
                                            >
                                                {isSubmitted ? (
                                                    <><Typography variant="h5" sx={{'fontWeight': 'bold'}}>
                                                        {hwAnswerFiles[question.id]?.prev_file?.file_url ? 'File Uploaded' : 'No file uploaded'}
                                                    </Typography>
                                                    <img 
                                                        src={hwAnswerFiles[question.id]?.prev_file?.file_url} 
                                                        alt={hwAnswerFiles[question.id]?.prev_file?.file_name} 
                                                        style={{ width: "400px", cursor: "pointer" }}
                                                        onClick={() => {
                                                            window.open(hwAnswerFiles[question.id]?.prev_file?.file_url, '_blank');
                                                        }}
                                                    /></>
                                                ) : (
                                                    <FileUpload
                                                        setHWAnswerFiles={setHWAnswerFiles}
                                                        hwAnswerFiles={hwAnswerFiles}
                                                        hw_question_id={question.id}
                                                    />
                                                )}
                                            </Box>
                                        ) : null}
                                        {isSubmitted && handleResult(hwAnswers[question.id], question.type)}
                                    </Box>
                                    {(isSubmitted || userRole !== 'student') && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '10px',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {(question.type !== 'file_upload' && question.type !== 'long-answer' && question.answer_tool !== 'tinymce') && (
                                                <Box
                                                    sx={{
                                                        padding: '10px',
                                                        borderRadius: '5px',
                                                        backgroundColor: colors.primary[750],
                                                        border: '1px solid lightgrey',
                                                    }}
                                                >
                                                    <Typography variant="h5" sx={{'fontWeight': 'bold'}}>
                                                        Correct Answer: {question.answer}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            );
                        })}
                    </>) : (
                        <Typography variant="h5" sx={{'fontWeight': 'bold'}}>
                            No questions found in this page
                        </Typography>
                    )}
                </Box>
            </Box>
        </>
    )
}

export default TextbookViewer