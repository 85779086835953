import React from 'react'
import { tokens } from "../../theme";
import { Box, Typography, useTheme, CircularProgress, Divider, TextField, FormControl, Button } from "@mui/material";
import Header from "../../components/Header";
import Axios from 'axios';
import Loading from '../../components/Loading';
import Alert from '../../components/alert/Alert';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import './Viewer.css';
import 'mathlive';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import getCookies from '../../utils/getCookies';
import FileUpload from '../../components/FileUpload';
import { EditorTiny } from '../../components/EditorTiny';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExerciseEditModal from './ExerciseEditModal';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TextbookExercises = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const currentUser = getCookies("_auth_state");
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const textbookId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
    
    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [loading, setLoading] = React.useState(true);
    const [updated, setUpdated] = React.useState(false);
    const [pdfLoading, setPdfLoading] = React.useState(true);
    const [textbook, setTextbook] = React.useState(null);

    const [selectedPage, setSelectedPage] = React.useState(1);
    const [numPages, setNumPages] = React.useState(null);

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Textbooks');

        async function fetchTextbook () {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/textbooks/${textbookId}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(async (response) => {
                setTextbook(response.data);
            }).catch((error) => {
                if (error.response.status === 404) {
                    setLoading(false);
                    setErrorAlertMessage('Textbook not found!');
                    setErrorAlertOpen(true);
                    return;
                } else if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message);
                setErrorAlertOpen(true);
            });
        }

        fetchTextbook();
        setLoading(false);
        setUpdated(false);
    }, [updated]);

    React.useEffect(() => {
        setPdfLoading(true);
        documentRender();
        setPdfLoading(false);
    }, [selectedPage]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const documentRender = () => {
        return (
            <Document 
                file={textbook?.file_url} 
                onLoadSuccess={onDocumentLoadSuccess} 
                onContextMenu={(e) => e.preventDefault()}
                className="pdf-container"
            >
                <div>
                    <Page
                        key={`page_${selectedPage}`}
                        pageNumber={selectedPage}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        className="react-pdf__Page__canvas" 
                    />
                </div>
            </Document>
        );
    };

    const getSubjectString = (subject_1, subject_2, subject_3, subject_4) => {
        if (subject_3 === null && subject_4 === null) {
            return `${subject_1} - ${subject_2}`;
        } else if (subject_3 !== null && subject_4 === null) {
            return `${subject_1} - ${subject_2} - ${subject_3}`;
        } else if (subject_3 !== null && subject_4 !== null) {
            return `${subject_1} - ${subject_2} - ${subject_3} - ${subject_4}`;
        } else {
            return `${subject_1}`;
        }
    };

    const flexDirectionForAnswer = (answer_tool, question_type) => {
        if (answer_tool === 'tinymce' || question_type === 'file_upload') {
            return 'column';
        } else {
            return 'row';
        }
    };

    const alignItemsForAnswer = (answer_tool, question_type) => {
        if (answer_tool === 'tinymce' || question_type === 'file_upload') {
            return 'flex-start';
        } else {
            return 'center';
        }
    };

    const handlePageChange = (e, upOrDown) => {
        e.preventDefault();
        if (upOrDown === 1) {
            if (selectedPage === numPages) {
                return;
            }
            setSelectedPage(selectedPage + 1);
        } else {
            if (selectedPage === 1) {
                return;
            }
            setSelectedPage(selectedPage - 1);
        }
    };

    const handleDelete = (e, questionId) => {
        e.preventDefault();
        Axios.delete(`${process.env.REACT_APP_URL}/api/v1/hw-questions/${questionId}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage('Question deleted successfully!');
            setSuccessAlertOpen(true);
        }).catch((error) => {
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });

        setUpdated(true);
    };

    return (
        <Box m="20px" marginX={3} marginY={3} >
            <Header title={textbook?.title} subtitle="A ONE Institute" />
            { loading === null ? 
                <Loading />
            : <>
                <Box display="flex" flexDirection="row" justifyContent="left" gap={2} p={0} mb={1} mt={-2}>
                    <Typography variant="h4">
                        <span style={{ fontWeight: 'bold' }}>Category:</span> {getSubjectString(textbook?.subject_1, textbook?.subject_2, textbook?.subject_3, textbook?.subject_4)}
                    </Typography>
                    <Typography variant="h4">
                        <span style={{ fontWeight: 'bold' }}>Total Pages:</span> {textbook?.pages}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '20px',
                        marginBottom: '10px',
                        position: 'relative',
                    }}
                >
                    {/* Open PDF */}
                    <a href={textbook?.file_url} target="_blank" style={{'textDecoration': 'none'}}>
                        <Typography 
                            sx={{
                                'fontWeight': 'bold', 
                                'cursor': 'pointer',
                                'position': 'absolute',
                                'top': '50%',
                                'transform': 'translateY(-50%)',
                                'left': '0',
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center',
                                padding: '10px',
                                borderRadius: '5px',
                                backgroundColor: colors.greenAccent[500],
                                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                                color: colors.primary[400],
                            }}
                        >
                            Open Textbook
                            <OpenInNewIcon fontSize='small' sx={{ cursor: 'pointer' }} />
                        </Typography>
                    </a>
                    {/* Page Navigator */}
                    <NavigateBeforeIcon fontSize='large' sx={{ cursor: selectedPage === 1 ? 'not-allowed' : 'pointer' }} onClick={(e) => handlePageChange(e, 0)} />
                    <TextField
                        variant="outlined"
                        type="number"
                        label="Page"
                        value={selectedPage}
                        onChange={(e) => {setSelectedPage(parseInt(e.target.value))}}
                        sx={{ 
                            maxWidth: '60px',
                            '& input': {
                                textAlign: 'center',
                            },
                            '& input::-webkit-inner-spin-button': {
                                '-webkit-appearance': 'none',
                                margin: 0,
                            },
                            '& input::-webkit-outer-spin-button': {
                                '-webkit-appearance': 'none',
                                margin: 0,
                            },
                        }}
                    />
                    <NavigateNextIcon fontSize='large' sx={{ cursor: selectedPage === numPages ? 'not-allowed' : 'pointer' }} onClick={(e) => handlePageChange(e, 1)} />
                </Box>
                <Box
                    display="flex"
                    width="100%"
                    bgcolor={colors.primary[600]}
                >
                    <Box flex={8} width="100%" position="relative">
                        <Box
                            sx={{
                                position: "sticky",
                                top: 0,
                            }}
                        >
                            {documentRender()}
                        </Box>
                        {pdfLoading && (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            >
                                <CircularProgress color="inherit" size={50} />
                            </Box>
                        )}
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box 
                        flex={7} 
                        width="100%" 
                        p={5}
                        display='flex'
                        flexDirection='column'
                        gap={3}
                        sx={{
                            overflowY: 'auto',
                            overscrollBehavior: 'contain',
                        }}
                    >
                        {/* hw questions */}
                        {textbook?.hw_questions && textbook?.hw_questions.length > 0 ? (<>
                            {textbook?.hw_questions.map((question, index) => {
                                if (question.page !== selectedPage)
                                    return null;

                                return (
                                    <Box 
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '20px',
                                            paddingBottom: '20px',
                                            borderBottom: index !== textbook?.hw_questions.length - 1 ? '1px solid lightgrey' : 'none',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: flexDirectionForAnswer(question.answer_tool, question.type),
                                                alignItems: alignItemsForAnswer(question.answer_tool, question.type),
                                                gap: '20px',
                                            }}
                                        >
                                            <Typography variant="h4" sx={{'fontWeight': 'bold'}}>
                                                {question.question_label} {question.type === 'file_upload' ? 'Upload an image file for this question!' : null}
                                            </Typography>
                                            {question.type === 'multiple_choice' ? (
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                                        name="controlled-radio-buttons-group"
                                                    >
                                                        {question.options?.split('|').map((option, index) => {
                                                            return (
                                                                <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
                                                            );
                                                        })}
                                                    </RadioGroup>
                                                </FormControl>
                                            ) : question.type === 'short_answer' && question.answer_tool === 'textfield' ? (
                                                <TextField
                                                    variant="outlined"
                                                    type="text"
                                                    label={userRole === 'student' ? 'Your Answer' : 'Student Answer'}
                                                    sx={{ maxWidth: '200px' }}
                                                />
                                            ) : question.type === 'short_answer' && question.answer_tool === 'mathlive' ? (
                                                <>
                                                    <math-field 
                                                        id="mathfield"
                                                        style={{ width: "200px" }}
                                                    >

                                                    </math-field>
                                                    <p>{userRole === 'student' ? 'Your Answer' : 'Student Answer'}: </p>
                                                </>
                                            ) : (question.type === 'short_answer' || question.type === 'long_answer') && question.answer_tool === 'tinymce' ? (
                                                <Box width={'100%'}>
                                                    <EditorTiny 
                                                        value={''} 
                                                        setHWAnswers={null} 
                                                        hw_question_id={question.id}
                                                    />
                                                </Box>
                                            ) : question.type === 'long_answer' && question.answer_tool === 'textfield' ? (
                                                <TextField 
                                                    multiline
                                                    variant="outlined"
                                                    type="text"
                                                    label={userRole === 'student' ? 'Your Answer' : 'Student Answer'}
                                                    sx={{ width: '100%' }}
                                                />
                                            ) : question.type === 'long_answer' && question.answer_tool === 'mathlive' ? (
                                                <>
                                                    <math-field 
                                                        id="mathfield"
                                                        style={{ width: "100%" }}
                                                    >

                                                    </math-field>
                                                    <p>{userRole === 'student' ? 'Your Answer' : 'Student Answer'}: </p>
                                                </>
                                            ) : question.type === 'file_upload' ? (
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    sx={{ width: "100%", gap: "10px" }}
                                                >
                                                    <FileUpload
                                                        setHWAnswerFiles={null}
                                                        hwAnswerFiles={null}
                                                        hw_question_id={question.id}
                                                    />
                                                </Box>
                                            ) : null}
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '10px',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            {(question.type !== 'file_upload' && question.type !== 'long-answer' && question.answer_tool !== 'tinymce') && (
                                                <Box
                                                    sx={{
                                                        padding: '10px',
                                                        borderRadius: '5px',
                                                        backgroundColor: colors.primary[750],
                                                        border: '1px solid lightgrey',
                                                    }}
                                                >
                                                    <Typography variant="h5" sx={{'fontWeight': 'bold'}}>
                                                        Correct Answer: {question.answer}
                                                    </Typography>
                                                </Box>
                                            )}
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    gap: '10px',
                                                    alignItems: 'center',
                                                    marginLeft: 'auto',
                                                }}
                                            >
                                                <ExerciseEditModal 
                                                    question={question} 
                                                    setUpdated={setUpdated} 
                                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                                    setErrorAlertMessage={setErrorAlertMessage}
                                                    setErrorAlertOpen={setErrorAlertOpen}
                                                />
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={(e) => handleDelete(e, question.id)}
                                                >
                                                    Delete
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </>) : (
                            <Typography variant="h5" sx={{'fontWeight': 'bold'}}>
                                No questions found in this page
                            </Typography>
                        )}
                    </Box>
                </Box>
            </>}
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
        </Box>
    )
}

export default TextbookExercises