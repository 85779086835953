import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from "@mui/material";
import { tokens } from "../theme";


const DeleteDialog = ({ handleDelete, setAnchorEl, dialogTitle }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
    const handleClose = () => {
        setOpenAlertDialog(false);
        setAnchorEl(null);
    };
    const handleOpen = () => setOpenAlertDialog(true);

    return (
        <>
            <Button sx={{ p: 1, width: "100%" }} color='error' onClick={handleOpen}>Delete</Button>
            <Dialog
                open={openAlertDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {handleClose(); handleDelete();}}
                        color='error'
                        autoFocus
                        sx={{
                            ":hover": {
                                backgroundColor: colors.redAccent[500],
                                color: "white",
                            },
                        }}
                    >
                        Yes
                    </Button>
                    <Button onClick={handleClose}>No</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DeleteDialog