import React from 'react'
import Button from '@mui/material/Button';

const GoToClassRoomButton = ({ course }) => {
    const handleClick = () => {
        window.location.href = "/classrooms/" + course.uuid + "/home";
    }

    return (
        <Button onClick={handleClick} sx={{ p: 1, width: "100%" }}>Go to Classroom</Button>
    )
}

export default GoToClassRoomButton