import React from 'react'
import { tokens } from "../../../theme";
import { Box, useTheme, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '../../../components/alert/Alert';
import Header from "../../../components/Header";
import Axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import Loading from '../../../components/Loading';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import getCookies from '../../../utils/getCookies';
import AdminActionAccordion from './AdminActionAccordion';
import TestBox from './TestBox';


const Test = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const matches = useMediaQuery('(min-width:1500px)');
    const matches2 = useMediaQuery('(min-width:1200px)');

    const [loading, setLoading] = React.useState(true);
    const [courseId, setCourseId] = React.useState(null);
    const courseData = useOutletContext().courseData;
    const [students, setStudents] = React.useState(null);
    const [studentsStartEndDateMap, setStudentsStartEndDateMap] = React.useState(null);
    const [authorDisabledForAdminActions, setAuthorDisabledForAdminActions] = React.useState(false);
    const currentUser = getCookies("_auth_state");
    const userId = currentUser ? currentUser.split(',')[0]?.split(':')[1]?.split('"')[1] : null;
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const [studentId, setStudentId] = React.useState(null);
    const [tests, setTests] = React.useState([]);
    const [courseTests, setCourseTests] = React.useState([]);

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    // const current_time = new Date().getTime();

    // useEffect
    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Tests');
        setCourseId(window.location.pathname.split('/')[2]);

        if (userRole === "student" || userRole === "teacher")
            setAuthorDisabledForAdminActions(true);

        async function getStudents() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${window.location.pathname.split('/')[2]}/students`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                const all_students = [];
                for (let i = 0; i < res.data.length; i++) {
                    all_students.push(res.data[i].student);
                    setStudentsStartEndDateMap((prev) => ({
                        ...prev,
                        [res.data[i].student.id]: {
                            start_date: res.data[i].start_date,
                            end_date: res.data[i].end_date,
                        }
                    }));
                }

                setStudents(all_students);
            }).catch((err) => {
                if (err.response.status !== 404)
                    console.log(err);
                else if (err.response.status === 401)
                    handleLogout();
            });
        }
        
        async function getStudentId() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students/${userId}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                setStudentId(res.data.id);
            }).catch((err) => {
                if (err.response.status !== 404)
                    console.log(err);
                else if (err.response.status === 401)
                    handleLogout();
            });
        }

        async function getTest() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/tests`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                setTests(res.data);
            }).catch((err) => {
                if (err.response.status !== 404)
                    console.log(err);
                else if (err.response.status === 401)
                    handleLogout();
            });
        }

        async function getCourseTests() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${window.location.pathname.split('/')[2]}/tests`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                setCourseTests(res.data);
            }).catch((err) => {
                if (err.response.status !== 404)
                    console.log(err);
                else if (err.response.status === 401)
                    handleLogout();
            });
        }

        if (userRole === "student")
            getStudentId();

        getStudents();
        getTest();
        getCourseTests();
        setLoading(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box m="20px" marginX={3} marginY={3} minHeight="90%">
            { loading || courseData === null  ?
                <Loading />
                : <>
                    <Box 
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            px: "25%",
                            ...!matches && {
                                px: "15%",
                            },
                            ...!matches2 && {
                                px: "10%",
                            },
                        }}
                    >
                        <Header 
                            title={
                                courseData.code !== null 
                                ? courseData.code + ". " + courseData.course_title 
                                : courseData.course_title
                            } 
                            subtitle="A ONE Institute"
                        />
                        {/* For Admin */}
                        {userRole !== "student" && 
                            <Box
                                minWidth="20rem"
                                width="100%"
                                minHeight="10rem"
                                borderRadius="3px"
                                padding={3}
                                boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                                backgroundColor={colors.primary[600]}
                                display="flex"
                                flexDirection="column"
                                alignItems="left"
                                gap={3}
                                mb={3}
                            >
                                <Typography variant="h4" color={colors.primary[450]}>
                                    Admin Actions
                                </Typography>
                                <AdminActionAccordion
                                    courseId={courseId}
                                    students={students}
                                    userRole={userRole}
                                    courseTests={courseTests}
                                    studentsStartEndDateMap={studentsStartEndDateMap}
                                    authorDisabledForAdminActions={authorDisabledForAdminActions}
                                    setBackdropOpen={setBackdropOpen}
                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                    setErrorAlertOpen={setErrorAlertOpen}
                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                    setErrorAlertMessage={setErrorAlertMessage}
                                    tests={tests}
                                />
                            </Box>
                        }
                        {/* For Students and teachers */}
                        <Box
                            minWidth="20rem"
                            width="100%"
                            minHeight="100%"
                            borderRadius="3px"
                            padding={8}
                            boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                            backgroundColor={colors.primary[600]}
                            alignItems="center"
                        >
                            {courseTests !== null && courseTests.length > 0 ?
                                courseTests.map((test) => {
                                    let exclude_students = [];
                                    
                                    if (test.exclude_student !== null) {
                                        const exclude_student = test.exclude_student?.split('|');
                                        exclude_students = exclude_student;
                                    }                              
                                    if (userRole === "student" &&
                                        (studentId && exclude_students.includes(studentId.toString()))
                                    ) { return null; }

                                    return <TestBox
                                                key={test.id}
                                                test={test}
                                                courseId={courseId}
                                                students={students}
                                                studentsStartEndDateMap={studentsStartEndDateMap}
                                                authorDisabledForAdminActions={authorDisabledForAdminActions}
                                                setBackdropOpen={setBackdropOpen}
                                                setSuccessAlertOpen={setSuccessAlertOpen}
                                                setErrorAlertOpen={setErrorAlertOpen}
                                                userRole={userRole}
                                                userId={userId}
                                                setSuccessAlertMessage={setSuccessAlertMessage}
                                                setErrorAlertMessage={setErrorAlertMessage}                                      
                                            />
                                })
                                :
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    minWidth: "20rem",
                                    minHeight: "32rem",
                                    gap: "0.4rem",
                                }}>
                                    <InfoOutlinedIcon sx={{ my: "auto" }} />
                                    <Typography variant="h4" color={colors.primary[450]} my="auto">
                                        No test yet!
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Alert
                        successAlertOpen={successAlertOpen}
                        setSuccessAlertOpen={setSuccessAlertOpen}
                        errorAlertOpen={errorAlertOpen}
                        setErrorAlertOpen={setErrorAlertOpen}
                        successMsg={successAlertMessage}
                        errorMsg={errorAlertMessage}
                        vertical="bottom"
                        horizontal="left"
                    />
                    <Backdrop
                        sx={{ color: colors.primary[400], zIndex: 2147483646 }}
                        open={backdropOpen}
                        onClick={handleBackdropClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </>
            }
        </Box>
    )
}

export default Test;