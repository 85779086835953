import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

const ClassMaterialAssignModal = ({
    courseId,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
    setBackdropOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: 250,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);;
    const handleClose = () => setOpen(false);

    const [loading, setLoading] = React.useState(false);
    const [textbooks, setTextbooks] = React.useState([]);
    const [textbook, setTextbook] = React.useState(null);

    React.useEffect(() => {
        function fetchTextbooks() {
            Axios.get(`${process.env.REACT_APP_URL}/api/v1/textbooks`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                const textbooks = response.data;
                for (let i = 0; i < textbooks.length; i++) {
                    textbooks[i].label = textbooks[i].title;
                    textbooks[i].value = textbooks[i].id;
                }
                setTextbooks(textbooks);
                setLoading(false);
            }).catch((error) => {
                setErrorAlertOpen(true);
                setErrorAlertMessage(error.response.data.message);
                setLoading(false);
            });
        }

        setLoading(true);
        fetchTextbooks();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setBackdropOpen(true);

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/class-materials/textbooks/assign`, 
            {
                textbook_id: textbook.id,
                course_id: courseId,
                file_url: textbook.file_url,
                name: textbook.title,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`
                }
            }).then((response) => {
                setSuccessAlertOpen(true);
                setSuccessAlertMessage(response.data.message);
                setBackdropOpen(false);
                handleClose();
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }).catch((error) => {
                setErrorAlertOpen(true);
                setErrorAlertMessage(error.response.data.message);
                setBackdropOpen(false);
            });
    };


    return (
        <div>
            <Button
                type="submit"
                variant="contained"
                style={{
                    backgroundColor: colors.primary[300],
                    color: "white",
                    width: "14rem",
                    marginTop: "0.5rem",
                    minHeight: "3rem",
                }}
                size='small'
                onClick={handleOpen}
            >
                Assign Class Material (Textbook)
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={2}
                            sx={{
                                height: "100%",
                            }}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                mb={-2}
                                position="relative"
                            >
                                <Autocomplete
                                    disabled={loading}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={textbooks}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => <TextField {...params} label="Select a textbook" />}
                                    onChange={(event, newValue) => {
                                        setTextbook(newValue);
                                    }}
                                />
                                {loading ? (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: '20px',
                                            height: '20px',
                                            zIndex: 1000,
                                        }}
                                    >
                                        <CircularProgress color="inherit" size={20} />
                                    </Box>
                                ) : null}
                            </Box>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{
                                    backgroundColor: colors.primary[300],
                                    color: "white",
                                    marginTop: "auto",
                                    width: "10rem",
                                }}
                                onClick={handleSubmit}
                            >
                                Assign
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default ClassMaterialAssignModal