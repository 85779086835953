import React from 'react'
import { tokens } from "../../theme";
import { Box, useTheme, Typography } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs from 'dayjs';
import Axios from 'axios';


const TabOne = ({ courseData, courseStudents }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const matches = useMediaQuery('(min-width:1600px)');
    const [currentEvents, setCurrentEvents] = React.useState([]);

    const sortSemesters = [...courseData.course_semesters].sort((a, b) => {
        return dayjs(a.semester.start) - dayjs(b.semester.start);
    });

    const start_date = dayjs(sortSemesters[0].semester.start).format('YYYY-MM-DD');
    const end_date = dayjs(sortSemesters[courseData.course_semesters.length - 1].semester.end).format('YYYY-MM-DD');

    const handleDateClick = (selected) => {
        // const title = prompt("Please enter a new title for your event");
        // if (title) {
            // Axios.post(`${process.env.REACT_APP_URL}/api/v1/events`, {
            //     title: title,
            //     start: selected.start,
            //     end: selected.end,
            //     allDay: selected.allDay,
            //     userId: jwt_decode(document.cookie).id,
            // }).then((response) => {
            //     // do nothing
            // }).catch((error) => {
            //     alert(error.response.data.message);
            // });
        // }
    };

    async function handleDatesSet(date) {
        // const user_id = jwt_decode(document.cookie).id;
        const response = await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${courseData.uuid}/events`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        });
        setCurrentEvents(response.data);
    };

    const handleEventClick = (selected) => {
        // const event_id = selected.event.id;

        // if (
        //     window.confirm(
        //         `Are you sure you want to delete the event '${selected.event.title}'`
        //     )
        // ) {
        //     selected.event.remove();
        //     Axios.delete(`${process.env.REACT_APP_URL}/api/v1/events/` + event_id, {
        //         // no data to send
        //     }).then((response) => {
        //         console.log(response);
        //     }).catch((error) => {
        //         alert(error.response.data.message);
        //     });
        // }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "66vh",
            }}
        >
            {/* COURSE INFO */}
            <Box
                sx={{
                    flex: 1,
                }}
            >
                {courseData.teacher_courses !== null && courseData.teacher_courses.length > 0 ?
                    <Typography variant="h5" color={colors.primary[100]}>
                        <span style={{fontWeight: "bold"}}>Teacher: </span>
                        {courseData.teacher_courses.map((teacher_course, index) => {
                            return (
                                teacher_course.teacher.first_name + " " + teacher_course.teacher.last_name 
                                + (index !== courseData.teacher_courses.length - 1 ? ", " : "")
                            )
                        })}
                    </Typography>
                    :
                    <Typography variant="h5" color={colors.primary[100]}>
                        <span style={{fontWeight: "bold"}}>A teacher is not assigned yet</span>
                    </Typography>
            
                }
                <Typography variant="h5" color={colors.primary[100]}>
                    <span style={{fontWeight: "bold"}}>Date: </span>
                    {courseData.start_date !== null && courseData.end_date !== null ? (
                        <span>{dayjs(courseData.start_date).format('YYYY-MM-DD')} - {dayjs(courseData.end_date).format('YYYY-MM-DD')}</span>
                    ) : (
                        <span>{start_date} - {end_date}</span>
                    )}
                </Typography>
                <Typography variant="h5" color={colors.primary[100]}>
                <span style={{fontWeight: "bold"}}>Textbook: </span>{courseData.textbook}
                </Typography>
            </Box>

            <Box
                flex={9}
                height="fit-content"
                width="100%"
                display="grid"
                gridTemplateColumns="repeat(2, 1fr)"
                gap={3}
                mt={1}
            >
                <FullCalendar
                    height="100%"
                    plugins={[
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                        listPlugin,
                    ]}
                    headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                    }}
                    initialView="dayGridMonth"
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    select={handleDateClick}
                    eventClick={handleEventClick}
                    events={currentEvents}
                    datesSet={(date) => handleDatesSet(date)}
                    nowIndicator={true}
                    eventColor={colors.primary[400]}
                />
                <Box
                    flex={2}
                    backgroundColor={colors.primary[500]}
                    p={3}
                >
                    <Box style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                        <Typography variant="h3" color="white">Students</Typography>
                    </Box>
                    <Box
                        display="grid"
                        gridTemplateColumns="repeat(2, 1fr)"
                        gap={1}
                        mt={2}
                        sx={{
                            ...matches && {gridTemplateColumns: "repeat(4, 1fr)"},
                        }}
                    >
                        {courseStudents !== null && courseStudents.length !== 0 &&
                            courseStudents.map((data, i) => (
                                <Card
                                    sx={{
                                        minWidth: 160,
                                        backgroundColor: colors.primary[600],
                                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                                        border: `1px solid ${colors.primary[600]}`,
                                        ":hover": {
                                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                                            backgroundColor: colors.greenAccent[500],
                                            border: `1px solid ${colors.greenAccent[500]}`,
                                        }
                                    }}
                                    key={i}
                                >
                                    <CardContent key={data.student.id}>
                                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                                            {data.student.last_name}, {data.student.first_name}
                                        </Typography>
                                        <Typography color="text.secondary">
                                            {data.student.school}
                                        </Typography>
                                        <Typography variant="body2">
                                            {data.student.phone}
                                            <br />
                                            {data.student.email}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            ))
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default TabOne