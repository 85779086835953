import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, useTheme, Typography } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { tokens } from "../../../theme";
import Axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function AssignZoomModal({ 
    moduleNumber,
    zooms,
    lectureModule, 
    setBackdropOpen, 
    setSuccessAlertOpen, 
    setErrorAlertOpen, 
    setSuccessAlertMessage,
    setErrorAlertMessage,
    setAnchorEl,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for module
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {setOpen(false); setAnchorEl(null)};

    // for zoom
    const [zoom, setZoom] = React.useState(null);

    const zoomsListWithNull = zooms !== null ? [{id: 0, room_number: "None"}, ...zooms] : [];

    // for bypass
    const [bypass, setBypass] = React.useState(false);
    const handleBypassChange = (event) => {
        setBypass(event.target.checked);
    };
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleAssign = async (event) => {
        event.preventDefault();
        setBackdropOpen(true);

        if (zoom === null) {
            setErrorAlertMessage("Please select zoom");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        } else if (lectureModule.zoom?.id === zoom) {
            setErrorAlertMessage("That Zoom Room is already assigned to this module");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/zooms/assign/events`, {
            zoom_id: zoom,
            event_id: lectureModule.id,
            bypass: bypass,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage(response.data.message);
            setSuccessAlertOpen(true);
            handleClose();
            setBackdropOpen(false);
            window.location.reload();
        }).catch((error) => {
            setErrorAlertMessage(error.response.data.error);
            setErrorAlertOpen(true);
            setBackdropOpen(false);
        });
    };

    return (
        <div>
            <Button onClick={handleOpen} sx={{ p: 1, width: "100%" }}>
                Assign Zoom
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]} mb={2}>
                            Assign Zoom to Class Module {moduleNumber}
                        </Typography>
                        {lectureModule.zoom !== null ? (
                            <Typography variant="h6" color={colors.primary[450]} mb={2}>
                                Zoom {lectureModule.zoom.room_number} is currently assigned to this module
                            </Typography>
                        ) : (
                            <Typography variant="h6" color={colors.primary[450]} mb={2}>
                                No Zoom is currently assigned to this module
                            </Typography>
                        )}
                        <Box display="flex" flexDirection="column" gap={3} width="100%" pt={2}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo-1"
                                getOptionLabel={(option) => option.id === 0 
                                    ? option.room_number.toString() 
                                    : "Zoom " + option.room_number.toString()
                                }
                                options={zooms !== null ? zoomsListWithNull : []}
                                renderInput={(params) => <TextField {...params} label="Select Zoom" />}
                                onChange={(event, value) => value !== null ? setZoom(value.id) : setZoom(null)} 
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                sx={{ width: "100%" }}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Typography variant="body" color='error'>
                                    Check if you want to ignore schedule conflict and assign the zoom anyway
                                </Typography>
                                <Checkbox
                                    checked={bypass}
                                    onChange={handleBypassChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color='error'
                                    sx={{
                                        padding: "0",
                                        mt: "0.5rem",
                                    }}
                                />
                            </Box>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                sx={{ width: "7rem" }}
                                onClick={handleAssign}
                            >
                                Assign
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}