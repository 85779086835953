import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../theme";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Axios from 'axios';

const ZoomAssignModal = ({ 
    zooms, 
    setSuccessAlertOpen,
    setErrorAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertMessage,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const zoomList = [{ id: null, room_number: 'None' } , ...zooms];

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); };

    const [zoom, setZoom] = React.useState(undefined);
    const [courses, setCourses] = React.useState(null);
    const [course, setCourse] = React.useState(null);    

    const handleAssign = async () => {
        if (zoom === undefined || course === null) {
            setErrorAlertMessage("Please select zoom and course");
            setErrorAlertOpen(true);
            return;
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/zooms/assign`, {
            zoom_id: zoom,
            course_uuid: course,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage(response.data.message);
            setSuccessAlertOpen(true);
            handleClose();
        }).catch((error) => {
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    };

    React.useEffect(() => {
        const fetchCourses = async () => {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setCourses(response.data);
            }).catch((error) => {
                if (error.response.status === 404) {
                    setCourses([]);
                    return;
                }
                alert(error.response.data.message);
            });
        };

        fetchCourses();
    }, []);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Box display="flex" mb={2} ml={2}>
            <Button 
                color="primary" 
                variant="contained" 
                onClick={handleOpen}
                sx={{ 
                    ":hover": {
                        backgroundColor: colors.greenAccent[500],
                        color: colors.primary[400],
                    }
                }}
            >
                Assign Zoom to Class
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]}>
                            Assign Zoom to Class
                        </Typography>
                        <Box display="flex" flexDirection="column" gap={3} width="100%" pt={2}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo-1"
                                    getOptionLabel={(option) => option.room_number.toString()}
                                    options={zoomList !== null ? zoomList : []}
                                    renderInput={(params) => <TextField {...params} label="Select Zoom" />}
                                    onChange={(event, value) => setZoom(value.id)} 
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: "100%" }}
                                />
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo-1"
                                    getOptionLabel={(option) => option.code + ". " + option.course_title}
                                    options={courses !== null ? courses : []}
                                    renderInput={(params) => <TextField {...params} label="Select Course" />}
                                    onChange={(event, value) => setCourse(value.uuid)} 
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: "100%" }}
                                />
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    sx={{ width: "7rem" }}
                                    onClick={handleAssign}
                                >
                                    Assign
                                </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    )
}

export default ZoomAssignModal