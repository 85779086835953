import React from 'react'
import { tokens } from "../../theme";
import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Axios from 'axios';
import { motion, AnimatePresence } from "framer-motion";
// import Dropdown from '../../components/dropdown/Dropdown';
import Loading from '../../components/Loading';
import Alert from '../../components/alert/Alert';
import ZoomRoomCard from './ZoomRoomCard';
import ZoomAssignModal from './ZoomAssignModal';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";


const ZoomRooms = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [loading, setLoading] = React.useState(true);
    // const [search, setSearch] = React.useState('');
    const [zoomRooms, setZoomRooms] = React.useState(null);
    const [zoomRoomUpdated, setZoomRoomUpdated] = React.useState(false);

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Zoom Rooms');

        async function fetchZoomRooms () {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/zooms`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(async (response) => {
                setZoomRooms(response.data);
            }).catch((error) => {
                if (error.response.status === 404) {
                    setZoomRooms([]);
                    setLoading(false);
                    return;
                } else if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message);
                setErrorAlertOpen(true);
            });
            setZoomRoomUpdated(false);
        }

        fetchZoomRooms();
        setLoading(false);
    }, [zoomRoomUpdated]);

    // const handleZoomAssignClick = () => {
    //     window.location.href = "/admin/zoom/assign";
    // };

    return (
        <Box m="20px" marginX={3} marginY={3} >
            <Header title="Zoom Rooms" subtitle="A ONE Institute" />
            { loading || zoomRooms === null ? 
                <Loading />
            : <>
                <Box display="flex" justifyContent="left" gap={2} p={0} minHeight={70} mb={2}>
                    {/* SEARCH BAR */}
                    {/* <Box
                        display="flex"
                        backgroundColor={colors.primary[600]}
                        borderRadius="3px"
                        boxShadow={10}
                        mb={2}
                        maxWidth={300}
                        minWidth={220}
                    >
                        <InputBase sx={{ ml: 2, flex: 1 }} onChange={(e) => setSearch(e.target.value)} placeholder="Enter Class Name" />
                        <IconButton type="button" sx={{ p: 1 }}>
                            <SearchIcon />
                        </IconButton>
                    </Box> */}

                    <ZoomAssignModal 
                        zooms={zoomRooms} 
                        setSuccessAlertOpen={setSuccessAlertOpen}
                        setErrorAlertOpen={setErrorAlertOpen}
                        setSuccessAlertMessage={setSuccessAlertMessage}
                        setErrorAlertMessage={setErrorAlertMessage}
                    />

                    {/* GRADE SELECT */}
                    {/* <Dropdown 
                        maxWidth={100} 
                        maxHeight={3} 
                        reactUseState={grade} 
                        setReactUseState={setGrade} 
                        inputLabel="Grade" 
                        data={grades} 
                    /> */}
                </Box>

                {/* ZOOM ROOM CARDS */}
                <motion.div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                        rowGap: "1rem",
                        columnGap: "1rem",    
                    }}
                    layout
                >
                    <AnimatePresence>
                        {zoomRooms.map((zoomRoom) => {
                            return (
                                <ZoomRoomCard zoomRoom={zoomRoom} key={zoomRoom.id} setZoomRoomUpdated={setZoomRoomUpdated} />
                            );
                        })}
                    </AnimatePresence>
                </motion.div>
            </>}
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
        </Box>
    )
}

export default ZoomRooms