import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ModifyTab from './ModifyTab';
import InfoTab from './InfoTab';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        {value === index && (
            <Box sx={{ p: 3 }}>
                {children}
            </Box>
        )}
    </div>
  );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ModalTabs({ teacher, setTeacherUpdated }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', height: '88%', overflow: 'auto' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs" textColor="primary" indicatorColor="primary" >
                    <Tab label="Info" {...a11yProps(0)} />
                    <Tab label="Modify" {...a11yProps(1)} />
                </Tabs>
            </Box>
            {/* Info tab */}
            <CustomTabPanel value={value} index={0}>
                <InfoTab teacher={teacher} />
            </CustomTabPanel>

            {/* Modify tab */}
            <CustomTabPanel value={value} index={1}>
                <ModifyTab teacher={teacher} setTeacherUpdated={setTeacherUpdated} />
            </CustomTabPanel>
        </Box>
    );
}