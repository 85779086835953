import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

export default function Autocomplete({address, setAddress}) {

  return (
    <div>
        <GooglePlacesAutocomplete
            selectProps={{
                address,
                onChange: setAddress,
                styles: {
                    width: "100%",
                },
                placeholder: "Enter address",
            }}
            minLengthAutocomplete={3}
            onPressEnter={true}
        />
    </div>
  );
}