import * as React from "react";
import { Box, Button, Typography, TextField } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Editor } from '@tinymce/tinymce-react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import Axios from 'axios';

const daysData = [
    "월",
    "화",
    "수",
    "목",
    "금",
    "토",
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const options = {
    height: 400,
    menubar: false,
    plugins: [
        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
        'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
    ],
    toolbar: [
        ' undo redo | blocks | bold italic forecolor backcolor fontsize | fontselect |' +
        'alignleft aligncenter alignright alignjustify |' +
        'bullist numlist outdent indent | removeformat | help'
    ],
    font_formats: 'Arial=arial,helvetica,sans-serif;' +
                 'Arial Black=arial black,avant garde;' +
                 'Courier New=courier new,courier;' +
                 'Georgia=georgia,palatino;' +
                 'Tahoma=tahoma,arial,helvetica,sans-serif;' +
                 'Times New Roman=times new roman,times;' +
                 'Verdana=verdana,geneva;' +
                 'Impact=impact,chicago;' +
                 'Comic Sans MS=comic sans ms,sans-serif;' +
                 'Trebuchet MS=trebuchet ms,geneva;',
    htmlAllowedTags:  ['.*'],
    htmlAllowedAttrs: ['.*'],
    extended_valid_elements: '*[.*]',
};

const categoryData = [
    "English",
    "Math",
    "Science",
    "Social",
    "Competition",
    "Test Prep",
]

function getStyles(day, days, theme) {
    return {
        fontWeight:
            days.indexOf(day) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const UpdatePotentialCourseModal = ({ 
    potentialSchedules,
    setAnchorEl, 
    potentialCourse,
    fetchPotentialCourses,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
    potentialCourseType,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 940,
        height: 820,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); setAnchorEl(null); };

    // for update time
    const [changeTime, setChangeTime] = React.useState(false);
    const [selectedPotentialSchedule, setSelectedPotentialSchedule] = React.useState(null);
    const [availableTime] = React.useState(
        potentialSchedules.map((schedule) => {
            if (!schedule.start_time || !schedule.end_time) return null;

            return {
                value: schedule.id,
                label: `${dayjs(schedule.start_time).format("h:mm a")} - ${dayjs(schedule.end_time).format("h:mm a")}`,
            };
        })
    );
    const handleTimeChange = (event) => {
        setSelectedPotentialSchedule(event.target.value);
    };

    // for course info
    const [code, setCode] = React.useState(potentialCourse.code || "");
    const [course_title, setCourseTitle] = React.useState(potentialCourse.course_title || "");
    const [course_memo, setCourseMemo] = React.useState(potentialCourse.course_memo || "");
    const [emailContent, setEmailContent] = React.useState(potentialCourse.course_potential_schedule.email_template || "");
    const [category, setCategory] = React.useState(potentialCourse.course_potential_schedule.category || "");
    const [days, setDays] = React.useState(potentialCourse.course_potential_schedule?.days?.split('|') || []);
    const [teacherName, setTeacherName] = React.useState(potentialCourse.teacher_name || "");

    async function handleUpdatePotentialCourse(e) {
        e.preventDefault();

        if (code === "" || course_title === "" || (potentialCourseType !== 2 && category === "")) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please fill code, course title, and category!");
            return;
        }

        if (potentialCourseType === 0 && days.length === 0) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please select at least one day!");
            return;
        }

        if (changeTime && !selectedPotentialSchedule) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please select available time!");
            return;
        }

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/potential-schedules/${potentialCourse.course_potential_schedule?.potential_schedule?.id}/potential-courses/${potentialCourse.uuid}`, {
            potential_schedule_id: changeTime ? selectedPotentialSchedule : null,
            code,
            course_title,
            course_memo,
            teacher_name: teacherName,
            days: days.join("|"),
            category,
            email_template: emailContent !== "" ? emailContent : null,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message || "Potential course updated successfully!");

            // Set local storage to open scroll down alert
            window.localStorage.setItem("pc_potentialCourseUpdated", potentialCourse.uuid);
            fetchPotentialCourses();
            // setTimeout(() => {
            //     window.location.href = '/admin/potentialCourses';
            // }, 1000);
        }).catch((error) => {
            console.log(error);
            setErrorAlertOpen(true);
            setErrorAlertMessage("Error occurs while updating potential course!");
        });
    }

    const handleChange = (event) => {
        const { target: { value }, } = event;
        setDays(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const getDefaultTime = () => {
        if (!potentialCourse.course_potential_schedule?.potential_schedule?.start_time 
            || !potentialCourse.course_potential_schedule?.potential_schedule?.end_time) 
            return null;

        const start_time = dayjs(potentialCourse.course_potential_schedule?.potential_schedule?.start_time).format("h:mm a");
        const end_time = dayjs(potentialCourse.course_potential_schedule?.potential_schedule?.end_time).format("h:mm a");

        return `${start_time} - ${end_time}`;
    };

    return (<>
        <Button
            variant="outlined"
            color="primary"
            sx={{ 
                width: "100%", 
                border: "1px solid transparent",
                borderRadius: 0,
            }}
            onClick={() => handleOpen()}
        >
            Update
        </Button>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '1.5rem' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '1rem',
                                    alignItems: 'center',
                                }}
                            >
                                {!changeTime ? (
                                    <Typography
                                        variant="body1"
                                    >
                                        Time: <span style={{ fontWeight: "bold", fontSize: "1rem", }}>{getDefaultTime()}</span>
                                    </Typography>
                                ) : (
                                    <>
                                        <Typography
                                            variant="body1"
                                        >
                                            Time:
                                        </Typography>
                                        <FormControl
                                            size="small"
                                            sx={{
                                                width: 200,
                                            }}
                                        >
                                            <InputLabel id="demo-simple-select-label">
                                                Available Time
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedPotentialSchedule}
                                                label="Available Time"
                                                onChange={handleTimeChange}
                                            >
                                                {availableTime.map((time) => (
                                                    <MenuItem key={time.value} value={time.value}>
                                                        {time.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </>                          
                                )}
                                {potentialCourse.course_potential_schedule?.potential_schedule?.start_time && potentialCourse.course_potential_schedule?.potential_schedule?.end_time && (
                                    <Button
                                        variant="contained"
                                        color={changeTime ? "error" : "primary"}
                                        sx={{
                                            width: 120,
                                            fontSize: "10px",
                                        }}
                                        onClick={(e) => {
                                            setChangeTime(!changeTime);
                                            setSelectedPotentialSchedule(null);
                                        }}
                                    >
                                        {changeTime ? "Cancel" : "Change Time"}
                                    </Button>
                                )}
                            </Box>
                            <Typography
                                variant="body1"
                            >
                                Subject: <span style={{ fontWeight: "bold", fontSize: "1rem", }}>{potentialCourse.course_potential_schedule?.subject}</span>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                alignItems: 'center',
                            }}
                        >
                            <TextField
                                label="Code"
                                variant="outlined"
                                value={code}
                                onChange={(event) => setCode(event.target.value)}
                            />
                            <TextField
                                fullWidth
                                label="Course Title"
                                variant="outlined"
                                value={course_title}
                                onChange={(event) => setCourseTitle(event.target.value)}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                alignItems: 'center',
                            }}
                        >
                            <FormControl sx={{ m: 1, width: 250 }}>
                                <InputLabel id="demo-multiple-chip-label">
                                    Days
                                </InputLabel>
                                <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={days}
                                    onChange={handleChange}
                                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {daysData.map((day) => (
                                        <MenuItem
                                            key={day}
                                            value={day}
                                            style={getStyles(day, days, theme)}
                                        >
                                            {day}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, width: 200 }}>
                                <InputLabel id="demo-multiple-chip-label">
                                    Category
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={category}
                                    label="Category"
                                    onChange={(event) => setCategory(event.target.value)}
                                >
                                    {categoryData.map((category, index) => (
                                        <MenuItem key={index} value={category}>
                                            {category}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField                                    
                                sx={{ width: 210 }}
                                label="Teacher Name"
                                variant="outlined"
                                value={teacherName}
                                onChange={(event) => setTeacherName(event.target.value)}
                            />  
                        </Box>
                        <TextField
                            fullWidth
                            multiline
                            minRows={3}
                            maxRows={10}
                            label="Course Memo (Optional)"
                            variant="outlined"
                            value={course_memo}
                            onChange={(event) => setCourseMemo(event.target.value)}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                            }}
                        >
                            <Typography
                                variant="h5"
                                fontWeight={700}
                            >
                                Email Template (Optional)
                            </Typography>
                            <Editor
                                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                init={options}
                                value={emailContent}
                                onEditorChange={
                                    (newValue, editor) => {
                                        setEmailContent(newValue);
                                    }
                                }
                            />
                            {emailContent !== "" && (<>
                                <h3 style={{ marginBottom: 0 }}>Preview</h3>
                                <div 
                                    dangerouslySetInnerHTML={{ __html: emailContent }} 
                                    style={{
                                        padding: "10px",
                                        backgroundColor: colors.primary[600],
                                    }}
                                />
                            </>)}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: 100,
                                }}
                                onClick={(e) => handleUpdatePotentialCourse(e)}
                            >
                                Update
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                sx={{
                                    width: 100,
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    </>);
};

export default UpdatePotentialCourseModal