import * as React from 'react';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import ModalTabs from './ModalTabs';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import Alert from '../../../components/alert/Alert';

export default function MoreInfoModal({ student, setStudentUpdated }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        height: 800,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
    };
    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState('');
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState('');

    return (
        <div>
            <Button size="small" style={{"backgroundColor": colors.primary[800], "color": colors.primary[400]}} onClick={handleOpen}>More Info</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
            >
                <Fade in={open}>
                    {!student.is_potential_student ? (
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h1" ml={1} borderBottom={1}>
                                {student.last_name}, {student.first_name} {student.korean_name} {student.consultation_number && `(${student.consultation_number})`}
                            </Typography>
                            <Typography id="modal-modal-description" variant="h6" mt={1} mb={1} ml={1} color={"grey"}>
                                <SchoolRoundedIcon style={{marginBottom: "-5px", marginRight: "5px"}} /> {student.school} (Grade: {student.grade})
                            </Typography>
                            <ModalTabs 
                                student={student} 
                                setStudentUpdated={setStudentUpdated} 
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                setSuccessAlertMessage={setSuccessAlertMessage}
                                setErrorAlertOpen={setErrorAlertOpen}
                                setErrorAlertMessage={setErrorAlertMessage}
                            />
                            <Alert
                                successAlertOpen={successAlertOpen}
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                errorAlertOpen={errorAlertOpen}
                                setErrorAlertOpen={setErrorAlertOpen}
                                successMsg={successAlertMessage}
                                errorMsg={errorAlertMessage}
                                vertical='bottom'
                                horizontal='left'
                            />
                        </Box>
                    ) : (
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h1" ml={1} borderBottom={1}>
                                {student.consultation_number} (Consultation Number)
                            </Typography>
                            <Typography id="modal-modal-description" variant="h6" mt={1} mb={1} ml={1} color={"grey"}>
                                <SchoolRoundedIcon style={{marginBottom: "-5px", marginRight: "5px"}} /> 
                                {student.school ? student.school : "N/A"} (Grade: {student.grade ? student.grade : "N/A"})
                            </Typography>
                            <ModalTabs 
                                student={student} 
                                setStudentUpdated={setStudentUpdated}
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                setSuccessAlertMessage={setSuccessAlertMessage}
                                setErrorAlertOpen={setErrorAlertOpen}
                                setErrorAlertMessage={setErrorAlertMessage}
                            />
                            <Alert
                                successAlertOpen={successAlertOpen}
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                errorAlertOpen={errorAlertOpen}
                                setErrorAlertOpen={setErrorAlertOpen}
                                successMsg={successAlertMessage}
                                errorMsg={errorAlertMessage}
                                vertical='bottom'
                                horizontal='left'
                            />
                        </Box>
                    )}
                </Fade>
            </Modal>
        </div>
    );
}